import React, { useState } from 'react';
import cs from 'classnames';

type Props = {
    hideFirst?: boolean;
    children?: React.ReactNode;
    firstElement: React.ReactNode;
    customClassName?: string;
};
const CustomCollapse: React.FC<Props> = ({
    firstElement,
    hideFirst,
    children,
    customClassName,
}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [height, setHeight] = useState<string>('0px');

    const childrenElm = !!children && (
        <div
            style={{ height: isOpen ? height : '0px' }}
            className={`custom-collapse__children-content `}
        >
            <div
                ref={(elm: any) =>
                    elm !== undefined && elm !== null
                        ? setHeight(elm.getBoundingClientRect().height)
                        : null
                }
                className="custom-collapse__children-content-element"
            >
                {children}
            </div>
        </div>
    );

    const showCollapse = () => {
        setHeight('0px');
        setOpen(!isOpen);
    };

    const handleClick = () => (isOpen ? showCollapse() : setOpen(!isOpen));

    return (
        <div
            className={cs('custom-collapse', {
                [`${customClassName}`]: customClassName,
            })}
        >
            <div className="custom-collapse__display-first-element">
                {hideFirst ? !isOpen && firstElement : firstElement}

                {children && (
                    <span
                        className={`custom-collapse__display-first-element__btn ${
                            isOpen
                                ? 'custom-collapse__display-first-element__btn-open'
                                : ''
                        }`}
                        onClick={handleClick}
                        role="button"
                    >
                        +
                    </span>
                )}
            </div>

            {childrenElm}
        </div>
    );
};

export default CustomCollapse;
