import React from 'react';

import { TPiece, TInvoice, TPrepayment, TAdhoc, TEPInvoice } from 'Models';

import { getFormattedDate, findNextAndLastPieces } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PaymentStatusContainer;

type Props = {
    pieces: TEPInvoice[];
};

const PaymentStatusContainer: React.FC<Props> = ({ pieces }) => {
    if (pieces && pieces.length === 0) {
        return null;
    }
    const data = findNextAndLastPieces(pieces);

    return (
        <div className="payment-status-container">
            <div className="payment-status-container__block">
                <p>{Wording.lastPayment}</p>
                <div className="payment-status-container__block__data">
                    {data && data.last ? (
                        <React.Fragment>
                            <h3>{`${(data.last as TEPInvoice).amount ||
                                (data.last as TEPInvoice).amount} €`}</h3>
                            <p className="payment-status-container__block__data__date">
                                le {data.last.date}
                            </p>
                        </React.Fragment>
                    ) : (
                        <p className="payment-status-container__block__data__none">
                            {Wording.noneData}
                        </p>
                    )}
                </div>
            </div>
            <div className="payment-status-container__block">
                <p>{Wording.nextPayment}</p>
                <div className="payment-status-container__block__data">
                    {data && data.next ? (
                        <React.Fragment>
                            <h3>{`${data.next.amount} €`}</h3>
                            <p className="payment-status-container__block__data__date">{`le ${getFormattedDate(
                                data.next.date,
                            )}`}</p>
                        </React.Fragment>
                    ) : (
                        <p className="payment-status-container__block__data__none">
                            {Wording.noneData}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PaymentStatusContainer;
