import React from 'react';

import { View, StyleSheet, Image, Text } from '@react-pdf/renderer';

import LogoWekiwi from '../../../../assets/icons/PDFs/ProofOfPayment/ic-logo-wekiwi.png';

import { footer } from '../wording.json';

const styles = StyleSheet.create({
    container: {
        alignItems: 'flex-end',
        position: 'relative',
    },

    img: {
        width: 90,
        height: 90,
        position: 'absolute',
        right: 0,
        opacity: 0.1,
    },

    textContainer: {
        position: 'absolute',
        right: 0,
        bottom: -60,
        alignItems: 'flex-end',
    },

    text: {
        fontSize: 13,
    },
});

const Footer = () => (
    <View style={styles.container}>
        <Image src={LogoWekiwi} style={styles.img} />

        <View style={styles.textContainer}>
            <Text style={styles.text}>{footer.text1}</Text>
            <Text style={styles.text}>{footer.text2}</Text>
        </View>
    </View>
);

export default Footer;
