import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as actions from '../_actions/searchOrder.actions';

import { TOrder } from 'Models';

export type OrderState = Readonly<{
    error?: string;
    message?: string;
    order: TOrder[];
    searchClient: {
        error?: string;
        message?: string;
        order: actions.TOrderResponse[];
    };
}>;

const initialState = {
    order: [],
    error: undefined,
    message: undefined,
    searchClient: {
        order: [],
        error: undefined,
        message: undefined,
    },
};

type Actions = actions.FetchOrderAction;

const orderReducer: Reducer<OrderState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchOrderAsync.success): {
            return {
                ...state,
                order: action.payload,
                error: undefined,
                message: undefined,
            };
        }

        case getType(actions.fetchOrderAsync.failure): {
            return {
                ...state,
                order: [],
                error: action.payload.code,
                message: action.payload.message,
            };
        }

        // SEARCH CLIENT
        case getType(actions.searchClientAsync.success): {
            return {
                ...state,
                searchClient: {
                    order: action.payload,
                    error: undefined,
                    message: undefined,
                },
            };
        }

        case getType(actions.searchClientAsync.failure): {
            return {
                ...state,
                searchClient: {
                    order: [],
                    error: action.payload.code,
                    message: action.payload.message,
                },
            };
        }

        default:
            return { ...state };
    }
};

export default orderReducer;
