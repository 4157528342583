import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';

import PaymentContainer, {
    Props as ContainerProps,
} from '../../containers/PaymentContainer/PaymentContainer';
import { ChangePiece } from '../../containers/FailPieceContainer/FailPieceContainer';
import withBanner, {
    ExternalProps as ExternalPropsBanner,
} from '../../libraries/withBanner';
import withModal, {
    ExternalProps as ExternalPropsModal,
    InjectedProps as InjectedPropsModal,
} from '../../libraries/withModal';

import {
    fetchContractAsync,
    resetContractState,
    setContract,
} from '../../_actions/contract.actions';
import {
    fetchPiecesAsync,
    createPaymentAsync,
    resetUpdate,
    getSupportingDocumentsAsync,
} from '../../_actions/payment.actions';
import {
    findContractByNumber,
    transformPieceType,
    findNextAndLastPieces,
} from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

import { TContract, TPiece, TEPInvoice } from 'Models';

import { generateTimelinePDF } from '../../components/PDFs/Timeline';
import { fetchFilesListAsync } from '../../_actions/file.actions';
import { initialEPInvoice } from '../../utils/initialState';

const Wording = WordingConstant.PaymentPage;

const mapStateToProps = (state: RootState) => ({
    profileState: state.profileReducer,
    contractState: state.contractReducer,
    paymentState: state.paymentReducer,
    fileState: state.fileReducer,
});

const dispatchProps = {
    contractDispatch: fetchContractAsync.request,
    // fetchPiecesDispatch: fetchPiecesAsync.request,
    filesListDispatch: fetchFilesListAsync.request,
    createPaymentDispatch: createPaymentAsync.request,
    resetCreatePaymentDispatch: resetUpdate,
    resetContractDispatch: resetContractState,
    setContractDispatch: setContract,
    getSupportingDocumentsDispatch: getSupportingDocumentsAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const WrappedPayment = compose<
    ContainerProps,
    Subtract<ContainerProps, InjectedPropsModal> &
        ExternalPropsBanner &
        ExternalPropsModal
>(
    withBanner(),
    withModal(),
)(PaymentContainer);

type State = {
    fields: ChangePiece;
};

export type GenerateProofProps = {
    contract: TContract;
    piece: TEPInvoice;
};

export type GenerateTimelineProps = {
    contract: TContract;
    pieces: TEPInvoice[];
};

class PaymentPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fields: {
                dueDate: '',
                piece: initialEPInvoice,
            },
        };
    }

    componentDidMount = () => {
        const {
            contractState: { selectedContractNumber },
            // fetchPiecesDispatch,
            filesListDispatch,
            profileState: { customerNumber },
        }: Props = this.props;

        const payload = {
            contractNbr: selectedContractNumber,
            customerNbr: customerNumber,
            mode: 'PAYMENT',
        };
        // fetchPiecesDispatch(payload);
        filesListDispatch({
            contractNbr: selectedContractNumber,
            customerNbr: customerNumber,
            mode: 'PAYMENT',
        });
    };

    handleChangeBanner = (contractNumber: string) => {
        const {
            contractState: { contracts },
            profileState: { customerNumber },
            contractDispatch,
            // fetchPiecesDispatch,
            setContractDispatch,
            filesListDispatch,
        } = this.props;
        const payload = {
            contractNbr: contractNumber,
            customerNbr: customerNumber,
        };
        const contract = findContractByNumber(contractNumber, contracts);
        if (contract.isFetched) {
            setContractDispatch({ contract });
        } else {
            contractDispatch(payload);
        }
        filesListDispatch({
            contractNbr: contractNumber,
            customerNbr: customerNumber,
            mode: 'PAYMENT',
        });
    };

    handleChangeFailPiece = (ff: TEPInvoice, dd: string) => {
        const { fields } = this.state;
        fields.dueDate = dd;
        fields.piece = ff;
        this.setState({ fields });
    };

    handleUpdate = () => {
        const { fields } = this.state;
        const { createPaymentDispatch } = this.props;
        createPaymentDispatch(fields);
        fields.dueDate = '';
        // fields.piece = '';
        this.setState({ fields });
    };

    generateProof = (props: GenerateProofProps) => {
        const { getSupportingDocumentsDispatch, profileState } = this.props;
        const {
            contact: { customerFullName, address, email, phone },
            customerType,
            customerNumber,
        } = profileState;

        const { contract, piece } = props;

        if (!piece) return;

        getSupportingDocumentsDispatch({
            mode: 'proofOfPayment',
            contractNbr: contract.contractNumber,
            cunstomerName: customerFullName,
            customerType,
            energyType: contract.energy,
            pieceReference: piece.id,
            pieceType: transformPieceType(piece.internal_invoice_nature_code),
            customerNbr: customerNumber,
            residenceAdress: address,
            subscriptionDate: contract.subscriptionDate,
            coholderName: contract.thirdParties.find(
                tp => tp.role === 'COHOLDER',
            ) as any,
            actionProps: {
                contract: props.contract,
                piece: props.piece,
                customer: {
                    name: customerFullName,
                    customerNbr: customerNumber,
                    email,
                    phone,
                },
                address,
            },
        });
    };

    generateTimeline = (props: GenerateTimelineProps) => {
        const { profileState, fileState } = this.props;

        const {
            contact: { customerFullName, address },
            customerType,
            customerNumber,
        } = profileState;
        const { payments } = fileState;
        const { contract } = props;

        generateTimelinePDF({
            contract,
            payments,
            customer: {
                name: {
                    firstName: customerFullName.firstName,
                    lastName: customerFullName.lastName,
                },
                customerNbr: customerNumber,
            },
            address: {
                number: address.number,
                street: address.street,
                netArea: address.netArea,
                postalCode: address.postalCode,
                country: address.country,
                townName: address.townName,
            },
        });
    };

    handleReset = () => {
        const {
            filesListDispatch,
            resetCreatePaymentDispatch,
            profileState: { customerNumber },
            contractState: { selectedContractNumber },
        } = this.props;

        resetCreatePaymentDispatch();
        filesListDispatch({
            contractNbr: selectedContractNumber,
            customerNbr: customerNumber,
            mode: 'PAYMENT',
        });
    };

    render() {
        const {
            profileState: { contact, customerType, customerNumber },
            contractState: { contracts, selectedContractNumber, error },
            paymentState: { updateResponse, supportingDocument },
            fileState: { payments },
            resetCreatePaymentDispatch,
            resetContractDispatch,
        }: Props = this.props;

        const contract = findContractByNumber(
            selectedContractNumber,
            contracts,
        );

        return (
            <WrappedPayment
                pieces={payments}
                customerNumber={customerNumber}
                contract={contract}
                handleChangeFailPiece={this.handleChangeFailPiece}
                supportingDocument={supportingDocument}
                generateProof={this.generateProof}
                generateTimeline={this.generateTimeline}
                // Banner
                title={Wording.title}
                bannerError={error}
                handleCloseModalBanner={() => resetContractDispatch()}
                contracts={contracts}
                contact={contact}
                customerType={customerType}
                handleChangeBanner={this.handleChangeBanner}
                selectedContractNumber={selectedContractNumber}
                // Modal
                modalMessage={Wording.popup}
                updateResponse={updateResponse}
                handleValidate={this.handleUpdate}
                handleReset={this.handleReset}
            />
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(PaymentPage);
