import React from 'react';
import { buildClassName as bcn } from '../../../../../utils/helpers';

const baseClassName = 'admin-subscription-container';

interface IProps {
    currOrderNbr: string | null;
    orderStatusStrs: [string, string];
    firstPaymentCBStatusStrs: [string, string];
}

const TopInformationSection: React.FC<IProps> = ({
    currOrderNbr,
    firstPaymentCBStatusStrs,
    orderStatusStrs,
}) => (
    <div className={bcn([baseClassName, 'body', 'top'])}>
        {currOrderNbr !== null && currOrderNbr !== undefined ? (
            <div
                className={bcn([
                    baseClassName,
                    'body',
                    'top',
                    'display-order-number',
                ])}
            >
                <p>Affaire n°: {currOrderNbr}</p>
            </div>
        ) : (
            <div />
        )}

        <div className={bcn([baseClassName, 'body', 'top', 'status'])}>
            <span>{orderStatusStrs[0]}</span>
            <span>{orderStatusStrs[1]}</span>
            <span>{firstPaymentCBStatusStrs[0]}</span>
            <span>{firstPaymentCBStatusStrs[1]}</span>
        </div>
    </div>
);

export default TopInformationSection;
