import { combineReducers } from 'redux';

import authReducer from '../_reducers/auth.reducer';
import profileReducer from '../_reducers/profile.reducer';
import contractReducer from '../_reducers/contract.reducer';
import eventReducer from '../_reducers/event.reducer';
import consumptionReducer from '../_reducers/consumption.reducer';
import fileReducer from '../_reducers/file.reducer';
import paymentReducer from '../_reducers/payment.reducer';
import packageReducer from '../_reducers/package.reducer';
import customersContractReducer from '../_reducers/customerContract.reducer';
import orderReducer from '../_reducers/order.reducer';
import userGroupReducer from '../_reducers/searchUserGroup.reducer';
import retryFirstPaymentReducer from '../_reducers/caseRetryPayment.reducer';
import transferContractReducer from '../_reducers/transferContract.reducer';
import paymentCBReducer from '../_reducers/paymentCB.reducer';

const rootReducer = combineReducers({
    authReducer,
    profileReducer,
    contractReducer,
    eventReducer,
    consumptionReducer,
    fileReducer,
    paymentReducer,
    packageReducer,
    customersContractReducer,
    orderReducer,
    userGroupReducer,
    transferContractReducer,
    paymentCBReducer,
    retryFirstPaymentReducer,
});

export default rootReducer;
