import React, { FC, useState } from 'react';
import _ from 'lodash';
import { buildClassName as bcn } from '../../../../../utils/helpers';
import { TAuthorizedPackage } from '../../../../../utils/network/types';
import WordingConstant from '../../../../../utils/wording.json';
import {
    CloseButton,
    CustomButton,
    CustomRadio,
    EnergyGauge,
} from '../../../../../components';

interface IProps {
    authorizedElectricityPackages: TAuthorizedPackage[];
    authorizedGasPackages: TAuthorizedPackage[];
    dismiss: () => void;
    electricityEnabled: boolean;
    gasEnabled: boolean;
    initialElectricitySelection: TAuthorizedPackage | null;
    initialGasSelection: TAuthorizedPackage | null;
    onValidate: (
        electricity: TAuthorizedPackage | null,
        gas: TAuthorizedPackage | null,
    ) => void;

    handleSetGaugeProps: (gaugeProps: {
        electricityGaugeProps: GaugeProps | null;
        gasGaugeProps: GaugeProps | null;
    }) => void;
}

type GaugeProps = {
    initial: number;
    ranges: number[][];
    maxValue: number;
};

const Wording = WordingConstant.AdminSubscriptionContainer.SeasonalOffersModal;
const baseClassName = 'seasonal-offers';
const gaugeClassName = bcn([baseClassName, 'gauge']);
const headerClassName = bcn([gaugeClassName, 'header']);
const bodyClassName = bcn([gaugeClassName, 'body']);

function getDefaultIndex(arr: any[]): number | null {
    return arr.length > 0 ? Math.floor(arr.length / 2) : null;
}

export function parseGaugeProps(
    authorizedPackages: TAuthorizedPackage[],
    selection: TAuthorizedPackage | null = null,
): GaugeProps {
    const ranges: number[][] = _.map(
        authorizedPackages,
        (pckg: TAuthorizedPackage) => {
            return [pckg[0].amount, pckg[1].amount];
        },
    );
    let initialProgress = _.findIndex(
        authorizedPackages,
        (pkg: TAuthorizedPackage) => {
            const a = pkg[0].id === _.get(selection, '0.id', '');
            const b = pkg[1].id === _.get(selection, '1.id', '');
            return a && b;
        },
    );
    if (initialProgress === -1) {
        initialProgress = getDefaultIndex(authorizedPackages) || 0;
    }
    const reducer = (result: number, value: TAuthorizedPackage) => {
        const sum = Number(value[0].amount);
        const win = Number(value[1].amount);
        const max = sum > win ? sum : win;
        return result > max ? result : max;
    };
    const gaugeMaxValue = _.reduce(authorizedPackages, reducer, 0);
    return {
        initial: initialProgress,
        maxValue: gaugeMaxValue,
        ranges,
    };
}

function parseGaugeSelectedItem(
    authorizedPackages: TAuthorizedPackage[],
    value: any,
): TAuthorizedPackage | null {
    let pkg: TAuthorizedPackage | undefined;
    if (value instanceof Array && value.length === 2) {
        pkg = _.find(authorizedPackages, (p: TAuthorizedPackage): boolean => {
            const sum = Number(p[0].amount);
            const win = Number(p[1].amount);
            const valueSum = Number(value[0]);
            const valueWin = Number(value[1]);
            return sum === valueSum && win === valueWin;
        });
    }
    return pkg || null;
}

const SeasonalOffersModal: FC<IProps> = (props: IProps) => {
    const {
        authorizedElectricityPackages,
        authorizedGasPackages,
        dismiss,
        electricityEnabled,
        gasEnabled,
        initialElectricitySelection,
        initialGasSelection,
        onValidate,
        handleSetGaugeProps,
    } = props;
    const [
        electricitySelection,
        setElectricitySelection,
    ] = useState<TAuthorizedPackage | null>(initialElectricitySelection);
    const [gasSelection, setGasSelection] = useState<TAuthorizedPackage | null>(
        initialGasSelection,
    );
    const [electricityActivated, setElectricityActivated] = useState<boolean>(
        true,
    );
    const [gasActivated, setGasActivated] = useState<boolean>(true);
    const electricityGaugeProps: GaugeProps = parseGaugeProps(
        authorizedElectricityPackages,
        electricitySelection,
    );

    const gasGaugeProps: GaugeProps = parseGaugeProps(
        authorizedGasPackages,
        gasSelection,
    );

    const electricityDefault: TAuthorizedPackage | null =
        authorizedElectricityPackages[electricityGaugeProps.initial] || null;
    const gasDefault: TAuthorizedPackage | null =
        authorizedGasPackages[gasGaugeProps.initial] || null;
    const handleElectricityGaugeChange = (prices: number[]) => {
        const pkg: TAuthorizedPackage | null = parseGaugeSelectedItem(
            authorizedElectricityPackages,
            prices,
        );
        setElectricitySelection(pkg);
    };
    const handleGasGaugeChange = (prices: number[]) => {
        const pkg: TAuthorizedPackage | null = parseGaugeSelectedItem(
            authorizedGasPackages,
            prices,
        );
        setGasSelection(pkg);
    };
    const handleElectricityToggle = () => {
        setElectricityActivated(!electricityActivated);
    };
    const handleGasToggle = () => {
        setGasActivated(!gasActivated);
    };
    const validate = () => {
        const electricity = !electricityActivated
            ? null
            : !!electricitySelection
            ? electricitySelection
            : electricityDefault;
        const gas = !gasActivated
            ? null
            : !!gasSelection
            ? gasSelection
            : gasDefault;

        onValidate(electricity, gas);
    };
    return (
        <div className={baseClassName}>
            <CloseButton onClick={dismiss} />
            {electricityEnabled && authorizedElectricityPackages.length > 0 && (
                <div className={gaugeClassName}>
                    <CustomRadio
                        checked={electricityActivated}
                        handleCheck={handleElectricityToggle}
                        text={Wording.electricityTitle}
                        value={Wording.electricityTitle}
                    />
                    {electricityActivated && (
                        <div className={bodyClassName}>
                            <EnergyGauge
                                {...electricityGaugeProps}
                                onChange={handleElectricityGaugeChange}
                            />
                        </div>
                    )}
                </div>
            )}
            {gasEnabled && authorizedGasPackages.length > 0 && (
                <div className={gaugeClassName}>
                    <CustomRadio
                        checked={gasActivated}
                        handleCheck={handleGasToggle}
                        text={Wording.gasTitle}
                        value={Wording.gasTitle}
                    />
                    {gasActivated && (
                        <div className={bodyClassName}>
                            <EnergyGauge
                                {...gasGaugeProps}
                                onChange={handleGasGaugeChange}
                            />
                        </div>
                    )}
                </div>
            )}
            <CustomButton
                color={'orange'}
                onClick={() => {
                    validate();

                    handleSetGaugeProps({
                        electricityGaugeProps,
                        gasGaugeProps,
                    });
                }}
                title={Wording.validate}
            />
        </div>
    );
};

export default SeasonalOffersModal;
