import { Component } from 'react';

async function setState<S, K extends keyof S>(
    context: Component<any, S>,
    nextState: Pick<S, K> | S | null,
): Promise<void> {
    return new Promise((resolve: () => void) => {
        context.setState(nextState, () => {
            resolve();
        });
    });
}

export { setState };
