import React from 'react';

import { TContact, TAddress, TContract } from 'Models';
import { ECustomerType } from '../../utils/enums';
import resources from '../../utils/resources';
import { getLabelCivility } from '../../utils/helpers';

export const BannerElement: React.FC<{
    imgSrc: string;
    contact: TContact;
    customerType: ECustomerType;
    address?: TAddress;
    contractNumber: string;
    span: boolean;
}> = ({ imgSrc, contact, customerType, address, contractNumber, span }) => {
    const customerFullName = contact.customerFullName;

    let name = `${customerFullName.firstName} ${customerFullName.lastName}`;
    if (customerFullName.lastName === '') {
        name = `${customerFullName.firstName}`;
    } else {
        if (customerFullName.civility) {
            name = `${getLabelCivility(customerFullName.civility)} ${name}`;
        }
    }
    if (customerType === ECustomerType.PROFESSIONAL) {
        name = contact.companyName;
    }

    return (
        <React.Fragment>
            <img src={imgSrc} alt="img-banner" />
            <p className="banner__item__name">{name}</p>
            {address && (
                <p className="banner__item__address">
                    {`${address.number || ''} ${address.street ||
                        ''} ${address.postalCode || ''} ${address.townName ||
                        ''}`}
                </p>
            )}
            <p className="banner__item__client-number">N° {contractNumber}</p>
            {span && <span />}
        </React.Fragment>
    );
};

export type Props = {
    currentContract: TContract;
    contracts: TContract[];
    contact: TContact;
    customerType: ECustomerType;
    handleChange: (contractNumber: string) => void;
};

type State = {
    isMenuOpen: boolean;
};

class Banner extends React.Component<Props, State> {
    state = {
        isMenuOpen: false,
    };

    dropDown = () => {
        const { isMenuOpen } = this.state;
        this.setState({ isMenuOpen: !isMenuOpen });
    };

    selectItem = (contractNumber: string) => {
        const { handleChange } = this.props;
        handleChange(contractNumber);
        this.setState({
            isMenuOpen: false,
        });
    };

    setImage(energy: string) {
        if (energy === 'EL') return resources['ic-elec-off'];
        return resources['ic-gas-on'];
    }

    renderDefaultItem() {
        const {
            contact,
            customerType,
            contracts,
            currentContract,
        } = this.props;
        const address = currentContract.deliveryPoint.address;
        return (
            <BannerElement
                imgSrc={this.setImage(currentContract.energy)}
                contact={contact}
                customerType={customerType}
                address={address}
                contractNumber={currentContract.contractNumber}
                span={contracts !== undefined && contracts.length > 1}
            />
        );
    }

    render() {
        const { isMenuOpen } = this.state;
        const {
            contracts,
            contact,
            customerType,
            currentContract,
        } = this.props;
        return (
            <div
                role="button"
                className={`banner ${
                    isMenuOpen && contracts.length > 1 ? 'banner--open' : ''
                }`}
                onClick={this.dropDown}
                tabIndex={0}
            >
                <div className="banner__item">{this.renderDefaultItem()}</div>
                <div
                    className="banner__items"
                    style={{
                        display:
                            isMenuOpen && contracts.length > 1
                                ? 'block'
                                : 'none',
                    }}
                >
                    {contracts &&
                        contracts.map((contract: TContract, index: number) => {
                            if (
                                contract.contractNumber ===
                                currentContract.contractNumber
                            ) {
                                return false;
                            }

                            const address = contract.deliveryPoint.address;
                            const energy = contract.energy;
                            const contractNumber = contract.contractNumber;
                            return (
                                <React.Fragment key={index}>
                                    <span className="banner__items__line" />
                                    <div
                                        key={index}
                                        role="button"
                                        onClick={() =>
                                            this.selectItem(contractNumber)
                                        }
                                        className="banner__items__item"
                                        tabIndex={0}
                                    >
                                        <BannerElement
                                            imgSrc={this.setImage(energy)}
                                            contact={contact}
                                            customerType={customerType}
                                            address={address}
                                            contractNumber={contractNumber}
                                            span={true}
                                        />
                                    </div>
                                </React.Fragment>
                            );
                        })}
                </div>
            </div>
        );
    }
}

export default Banner;
