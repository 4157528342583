import React from 'react';
import { RootState, TSchemaProps } from 'Types';
import { connect } from 'react-redux';
import { BackgroundElement } from '../../../components';
import CustomTable from '../../../components/admin/CustomTable';
import WordingConstant from '../../../utils/wording.json';
import { isEmpty, getImgSrcByUserStatus } from '../../../utils/helpers';
import { TUser, TResponse } from 'Models';
import resources from '../../../utils/resources';
import RemoveUserButton from './RemoveUserButton';
import EnableOrDesableuserButton from './EnableOrDesableUserButton';
import _ from 'lodash';
import ErrorElement from '../../../components/ErrorElement';
import { resetManageUserResponse } from '../../../_actions/manageUser.actions';
import OutsideClickClass from '../../../libraries/OutsideClickClass';
import { withTriangle } from '../../../components/admin/CustomTable/CustomTable';

interface IPayloadManageUser {
    mode: string;
    firstName: string;
    lastName: string;
    userName: string;
}

const Wording = WordingConstant.UserAdministrationResult;
const EMPTY_RESPONSE = 'EMPTY_RESPONSE';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {
    resetManageUserResponse,
};

type Props = {
    setOpen?: (value: boolean) => void;
    setOpenGroupModal?: (value: boolean) => void;
    userList: TUser[];
    manageUserResponse: TResponse;
    resetResponse: () => void;
    getGroup: () => void;
    groupList: string[];
    manageUser: (props: IPayloadManageUser) => void;
    setAdminSuccessModal: (props: boolean) => void;
    resetAdminClientSpaceResponse: () => void;
    isAdminSpace: boolean;
    checkUderAdministrationResultPermission: boolean;
} & ReturnType<typeof mapStateToProps> &
    typeof dispatchProps;

type State = {
    isOpen: boolean;
    openGroupModal: boolean;
    showPopUp: boolean;
    currentItem: number;
};

class UserAdministrationResult extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false,
            openGroupModal: false,
            showPopUp: false,
            currentItem: -1,
        };
    }

    /*componentDidMount() {
        this.props.getGroup();
    }*/
    prepareColumns = () => {
        const { checkUderAdministrationResultPermission } = this.props;

        return [
            {
                Header: withTriangle(Wording.group),
                accessor: 'group',
                filterable: false,
            },
            {
                Header: withTriangle(Wording.userId),
                accessor: 'userName',
                filterable: false,
            },
            {
                Header: withTriangle(Wording.clientFirstName),
                accessor: 'firstName',
                filterable: false,
            },

            {
                Header: withTriangle(Wording.clientLastName),
                accessor: 'lastName',
                filterable: false,
            },
            {
                Header: withTriangle(Wording.clientEmail),
                accessor: 'email',
                filterable: false,
            },
            {
                Header: withTriangle(Wording.status),
                accessor: 'userStatus',
                filterable: false,
                width: 110,
                Cell: ({ value }: any) =>
                    value !== undefined && value !== '' ? (
                        <img src={getImgSrcByUserStatus(value)} alt="" />
                    ) : null,
            },
            {
                Header: '',
                accessor: '',
                show: checkUderAdministrationResultPermission,
                filterable: false,
                width: 40,
                Cell: (original: any) => {
                    return (
                        <div>
                            <img
                                className="menu-style"
                                src={resources['ic-menu-orange']}
                                alt="ic-menu"
                                onClick={() => {
                                    this.handleshowPopUp(original.viewIndex);
                                }}
                            />

                            {this.state.showPopUp &&
                                this.state.currentItem ===
                                    original.viewIndex && (
                                    <OutsideClickClass
                                        callback={() =>
                                            this.setState({
                                                showPopUp: false,
                                            })
                                        }
                                    >
                                        <div className="pop-up">
                                            <RemoveUserButton
                                                setAdminSuccessModal={
                                                    this.props
                                                        .setAdminSuccessModal
                                                }
                                                original={original}
                                                manageUser={
                                                    this.props.manageUser
                                                }
                                                resetAdminClientSpaceResponse={
                                                    this.props
                                                        .resetAdminClientSpaceResponse
                                                }
                                                resetManageUserResponse={
                                                    this.props
                                                        .resetManageUserResponse
                                                }
                                                manageUserResponse={
                                                    this.props
                                                        .manageUserResponse
                                                }
                                                isAdminSpace={false}
                                            />

                                            <EnableOrDesableuserButton
                                                status={_.get(
                                                    original.original,
                                                    'userStatus',
                                                    '',
                                                )}
                                                original={original}
                                                manageUser={
                                                    this.props.manageUser
                                                }
                                                manageUserResponse={
                                                    this.props
                                                        .manageUserResponse
                                                }
                                                setAdminSuccessModal={
                                                    this.props
                                                        .setAdminSuccessModal
                                                }
                                                isAdminSpace={false}
                                                resetManageUserResponse={
                                                    this.props
                                                        .resetManageUserResponse
                                                }
                                                resetAdminClientSpaceResponse={
                                                    this.props
                                                        .resetAdminClientSpaceResponse
                                                }
                                            />
                                        </div>
                                    </OutsideClickClass>
                                )}
                        </div>
                    );
                },
            },
        ];
    };

    setOpen = (value: boolean) => {
        this.setState({ isOpen: value });
    };
    setOpenGroupModal = (value: boolean) => {
        this.setState({ openGroupModal: value });
    };
    handleshowPopUp = (original: number) => {
        this.setState({
            showPopUp: !this.state.showPopUp,
            currentItem: original,
        });
    };

    downloadDataTable = () => {
        const {
            userList,
            checkUderAdministrationResultPermission,
        } = this.props;

        return checkUderAdministrationResultPermission
            ? {
                  downloadData: userList,
                  downloadButtonTitle: 'TÉLÉCHARGER CSV',
                  downloadFilename: 'list-users.csv',
              }
            : {};
    };

    render() {
        const { userList } = this.props;
        const { message, code } = this.props.manageUserResponse;
        return (
            <div className="user-administration-result">
                {isEmpty(userList) && (
                    <BackgroundElement>
                        <CustomTable
                            columns={this.prepareColumns()}
                            data={userList}
                            className="-striped -highlight"
                            showPagination={true}
                            filterable={false}
                            {...this.downloadDataTable()}
                        />
                    </BackgroundElement>
                )}
                {message && code === EMPTY_RESPONSE && (
                    <ErrorElement error={message} />
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    dispatchProps,
)(UserAdministrationResult);
