import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { RootState, RouteProps, SubRouteProps, TSchemaProps } from 'Types';
import { SideBar, Footer } from '../../components';
import { resetReduxStore } from '../../_actions/auth.actions';
import withLoader from '../../libraries/withLoader';
import AuthService from '../../services/auth-service';
import { TScheme } from 'Models';

import AdminHomePage from '../admin/AdminHomePage';
import AdminSubscriptionPage from '../admin/AdminSubscriptionPage';
import NotFoundPage from '../defaults/NotFoundPage';
import CustomerContractPage from '../admin/CustomerContractPage';
import CasesPage from '../admin/CasesPage';
import UserAdministrationPage from '../admin/UserAdministrationPage';
import _ from 'lodash';

type ContentProps = {
    autorizationsScheme: TScheme[];
    group: string;
};

function getComponent(root: string): any {
    switch (root) {
        case 'userAdmin':
            return UserAdministrationPage;
        case 'customerContract':
            return CustomerContractPage;
        case 'case':
            return AdminSubscriptionPage;
        case 'searchCase':
            return CasesPage;

        default:
            return NotFoundPage;
    }
}

function routingMap(props: ContentProps): RouteProps[] {
    const routes = props.autorizationsScheme!.map(category => {
        return {
            title: category.label!,
            path: '/',
            links: [
                ..._.uniqBy(category.subMenu, 'root').map(
                    ({ root, label, ...schemaProps }) => {
                        return {
                            path: root,
                            sidebar: () => <div>{label}</div>,
                            main: getComponent(root),
                            title: label,
                            schemaProps: {
                                ...schemaProps,
                                currentGroup: props.group,
                            },
                        };
                    },
                ),

                {
                    path: '/',
                    sidebar: () => <div>Home</div>,
                    main: AdminHomePage,
                    title: 'Home',
                    schemaProps: {},
                } as any,
            ],
        };
    });

    return routes;
}

const Content: React.FC<ContentProps> = (props: ContentProps) => {
    return (
        <div className="content" id="content-admin">
            <Switch>
                {routingMap(props).map((route: RouteProps | any) =>
                    route.links.map(
                        (subRoute: SubRouteProps, index: number) => {
                            const Component: React.ComponentType<{
                                schemaProps: TSchemaProps | undefined;
                            }> = subRoute.main;
                            return (
                                <Route
                                    key={index}
                                    path={route.path + subRoute.path}
                                    exact
                                    // component={subRoute.main}
                                >
                                    <Component
                                        schemaProps={subRoute.schemaProps}
                                    />
                                </Route>
                            );
                        },
                    ),
                )}
            </Switch>
        </div>
    );
};

const WrappedContent = withLoader()(Content);

const mapStateToProps = (state: RootState) => ({
    authState: state.authReducer,
    profileState: state.profileReducer,
});

const dispatchProps = {
    resetReduxStoreDispatch: resetReduxStore,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

class Main extends React.PureComponent<Props, {}> {
    render() {
        const {
            profileState: { contact, customerNumber },
            authState: {
                loading,
                unauthorized,
                autorizationsScheme,
                userId,
                groupe,
            },
            resetReduxStoreDispatch,
        }: Props = this.props;

        const routes = autorizationsScheme.map(category => {
            return {
                title: category.label,
                path: '/',
                links: category.subMenu!.map(
                    ({ root, label, ...schemaProps }) => {
                        return {
                            path: root,
                            sidebar: () => <div>{label}</div>,
                            main: () => getComponent(root) as JSX.Element,
                            title: label,
                            schemaProps: {
                                ...schemaProps,
                                currentGroup: groupe || '',
                            },
                        };
                    },
                ),
            };
        });
        const props = {
            routes,
            firstName: contact.customerFullName.firstName,
            lastName: contact.customerFullName.lastName,
            customerNbr: customerNumber,
            userId,
            groupe,
            isAdmin: true,
            handleLogout: () => resetReduxStoreDispatch(),
        };
        const isSignIn = AuthService.isAuthenticated();
        if (!isSignIn) {
            return <Redirect to={{ pathname: '/login' }} />;
        }
        return (
            <div className="main-page">
                <div className="main-page__content">
                    <SideBar {...props} />
                    <WrappedContent
                        autorizationsScheme={autorizationsScheme}
                        group={groupe || ''}
                        loading={loading}
                        unauthorized={unauthorized}
                        handleCloseModal={() => {
                            AuthService.logout();
                            props.handleLogout();
                        }}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(Main);
