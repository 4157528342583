import { Font } from '@react-pdf/renderer';

import Montserrat400 from '../../../assets/fonts/Montserrat/Montserrat-Thin.ttf';
import Montserrat800 from '../../../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf';
import Montserrat100 from '../../../assets/fonts/Montserrat/Montserrat-Light.ttf';

Font.register({
    family: 'Montserrat',
    fonts: [
        {
            fontWeight: 400,
            src: Montserrat400,
        },
        {
            fontWeight: 800,
            src: Montserrat800,
        },
        {
            fontWeight: 100,
            src: Montserrat100,
        },
    ],
});
