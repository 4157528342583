import React, { CSSProperties } from 'react';

import CustomButton from '../CustomButton';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';
import { isHtml } from '../../utils/helpers';

const Wording = WordingConstant.Common;

type Props = {
    show?: boolean;
    showCloseButton?: boolean;
    title: string;
    description: string;
    state?: number;
    customDescription?: string;
    descriptionStyle?: CSSProperties;
    showLogo?: boolean;
    handleCancel?: () => void;
    handleValidate?: () => void;
};

// state = 0 : success
// state = 1 : failure
// state = 2 : request
const Modal: React.FC<Props> = ({
    show,
    state = 2,
    showCloseButton = true,
    title,
    description,
    handleCancel,
    handleValidate,
    children,
    customDescription,
    descriptionStyle,
    showLogo,
}) => {
    // const show = true;
    // const state = 1;
    const showBottom = () => {
        switch (state) {
            case 2:
                return (
                    <div className="modal__content__buttons">
                        <CustomButton
                            title={Wording.popup.cancelButton}
                            onClick={handleCancel}
                            color={'gray'}
                            arrowLeft
                        />
                        <CustomButton
                            title={Wording.popup.validateButton}
                            onClick={handleValidate}
                            color={'orange'}
                        />
                    </div>
                );
            case 1:
                return (
                    <div className="modal__content__description">
                        <p className="desc-text">
                            Merci de votre compréhension,
                        </p>
                        <p className="desc-text">La team Wekiwi</p>
                    </div>
                );

            case 0:

            default:
                return false;
        }
    };

    if (!show) {
        return null;
    }

    const prepareDescription = (str: string) =>
        str.split('\n').map((item, i) => (
            <p style={descriptionStyle} key={i}>
                {item}
            </p>
        ));

    const renderCustomDescriptipn = () => {
        if (customDescription) {
            if (isHtml(customDescription)) {
                return (
                    <p
                        style={descriptionStyle}
                        dangerouslySetInnerHTML={{ __html: customDescription }}
                    ></p>
                );
            }
            return <p>{customDescription}</p>;
        }
        return prepareDescription(description);
    };

    return (
        <div
            role="button"
            tabIndex={0}
            className={`modal ${show ? ' modal--show-modal' : ''}`}
            onClick={handleCancel}
            onKeyDown={handleCancel}
        >
            <div
                role="button"
                tabIndex={0}
                className={`modal__content ${
                    state === 1 ? 'modal__content--error' : ''
                }`}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                {showCloseButton && (
                    <span
                        className="modal__content__close-button"
                        onClick={handleCancel}
                        role="button"
                        tabIndex={0}
                    >
                        {Wording.popup.closeButton}
                    </span>
                )}
                {(state === 1 || showLogo) && (
                    <div className="modal__content__image">
                        <img
                            src={resources['ic-wekiwi-popup-orange']}
                            alt="logo-wekiwi"
                        />
                    </div>
                )}

                {title && title !== '' && (
                    <div className="modal__content__title">
                        {/* <div className="layer">?</div> */}
                        <h3>{title}</h3>
                    </div>
                )}

                <div className="modal__content__description">
                    {renderCustomDescriptipn()}
                </div>

                {showBottom()}
                {children}
            </div>
        </div>
    );
};

export default Modal;
