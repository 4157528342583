import React, { FC } from 'react';
import WordingConstant from '../../../../../utils/wording.json';
import Icon from '../../../../../assets/icons/ic-logo-wekiwi.svg';

const Wording =
    WordingConstant.AdminSubscriptionContainer.PaymentIframeModal.Success;
const baseClassName: string = 'success';

interface IProps {
    orderReference: string | null;
}

const Success: FC<IProps> = ({ orderReference }) => {
    return (
        <div className={baseClassName}>
            <img src={Icon} />
            <span>{Wording.congratulations}</span>
            <span>{Wording.contract}</span>
            {orderReference !== null && (
                <div className="orderNumberDisplay">
                    {' '}
                    Votre numéro de souscription a bien été créé :{' '}
                    <strong>{orderReference}</strong>
                </div>
            )}
        </div>
    );
};

export default Success;
