import React, { FC, Fragment } from 'react';

import MailIcon from '../../../../assets/icons/PDFs/ProofOfPayment/ic-email.png';
import FacebookIcon from '../../../../assets/icons/PDFs/ProofOfPayment/Facebook.png';
import PhoneIcon from '../../../../assets/icons/PDFs/ProofOfPayment/ic-phone.png';

import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';

import Wording from '../wording.json';

const styles = StyleSheet.create({
    container: {
        borderWidth: 2,
        borderColor: '#000000',
        borderRadius: '25',
        width: '35%',
        padding: 15,
        marginTop: 15,
    },
    imgTextContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: '2px 0px',
    },
    phoneText: {
        width: 120,
    },
    img: {
        width: 18,
        height: 18,
        marginRight: 5,
    },
    text: {
        fontSize: 9,
    },
    sentence: {
        fontSize: 12,
        justifyContent: 'center',
        marginLeft: 20,
    },
    sentenceTop: {
        fontSize: 12,
        justifyContent: 'center',
        marginLeft: 20,
        marginTop: 10,
    },
    margin: {
        fontSize: 9,
        padding: '6px 0px',
    },
    moreText: {
        marginTop: 5,
        padding: '5px 0px',
        fontSize: 9,
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    bloc: {
        position: 'absolute',
        bottom: 45,
        left: 0,
        right: 0,
    },
});

const ImageText: FC<{ src: any; text?: string; elm?: any }> = ({
    src,
    text,
    children,
}) => (
    <View style={styles.imgTextContainer}>
        <Image src={src} style={styles.img} />
        {children && children}
        {text && <Text style={styles.text}>{text}</Text>}
    </View>
);

const infoWording = Wording.Informations;

export default () => (
    <View style={styles.bloc}>
        <View>
            <Text style={styles.sentenceTop}>{infoWording.text_1}</Text>
            <Text style={styles.sentence}>{infoWording.text_2}</Text>
            <Text style={styles.sentence}>{infoWording.text_3}</Text>
        </View>
        <View style={styles.mainContainer}>
            <View style={styles.container}>
                <ImageText src={MailIcon} text={infoWording.mail} />
                <ImageText src={FacebookIcon} text={infoWording.facebook} />
                <ImageText src={PhoneIcon}>
                    <View>
                        <Text style={styles.text}>{infoWording.phone}</Text>
                        <Text style={{ ...styles.text, ...styles.phoneText }}>
                            {infoWording.phoneDescription}
                        </Text>
                    </View>
                </ImageText>
            </View>
        </View>
    </View>
);
