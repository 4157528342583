import React from 'react';
import { connect } from 'react-redux';
import { RootState, TSchemaProps } from 'Types';
import UserAdministrationContainer from '../../../containers/admin/UserAdministrationContainer/UserAdministrationContainer';
import UserAdministrationResult from '../../../containers/admin/UserAdministrationContainer/UserAdministrationResult';
import { searchUserGroupAsync } from '../../../_actions/searchUserGroup.actions';
import {
    createUserAsync,
    resetCreateUserResponse,
} from '../../../_actions/createUser.actions';
import {
    createGroupAsync,
    resetGroupResponse,
} from '../../../_actions/createGroup.actions';
import { getGroupAsync } from '../../../_actions/getGroup.actions';
import { manageUserAsync } from '../../../_actions/manageUser.actions';

import {
    isHaveActionsPermissionBySchema,
    isLimitedAuthorizationBySchema,
} from '../../../utils/helpers';
import { EMenuLabels, ESubMenuAdministrationLabel } from '../../../utils/enums';

const mapStateToProps = (state: RootState) => ({
    userGroupState: state.userGroupReducer.userList,
    groupListState: state.userGroupReducer.groupList,
    manageUserState: state.userGroupReducer.createUserResponse,
    manageGroupState: state.userGroupReducer.createGroupResponse,
    searchState: state.userGroupReducer.manageUserResponse,
    autorizationsScheme: state.authReducer.autorizationsScheme,
});

const dispatchProps = {
    searchUserGroupAsyncDispatch: searchUserGroupAsync.request,
    createGroupAsyncDispatch: createGroupAsync.request,
    resetGroupResponse,
    resetCreateUserResponse,
    getGroupAsyncDispatch: getGroupAsync.request,
    createUserAsyncDispatch: createUserAsync.request,
    manageUserAsyncDispatch: manageUserAsync.request,
};

type Props = {
    setAdminSuccessModal: (props: boolean) => void;
    resetAdminClientSpaceResponse: () => void;
    isAdminSpace: boolean;
    schemaProps: TSchemaProps;
} & ReturnType<typeof mapStateToProps> &
    typeof dispatchProps;

const UserAdministrationPage: React.FC<Props> = ({
    searchUserGroupAsyncDispatch,
    userGroupState,
    createGroupAsyncDispatch,
    resetGroupResponse,
    resetCreateUserResponse,
    getGroupAsyncDispatch,
    groupListState,
    createUserAsyncDispatch,
    manageUserAsyncDispatch,
    manageUserState,
    setAdminSuccessModal,
    resetAdminClientSpaceResponse,
    manageGroupState,
    searchState,
    isAdminSpace,
    schemaProps,
    autorizationsScheme,
}) => {
    const checkUderAdministrationResultPermission = isHaveActionsPermissionBySchema(
        EMenuLabels.ADMINISTRATION,
        ESubMenuAdministrationLabel.MANAGE_USERS,
        autorizationsScheme,
    );

    const checkCreateGroupPermission = isLimitedAuthorizationBySchema(
        EMenuLabels.ADMINISTRATION,
        ESubMenuAdministrationLabel.CREATE_GROUP,
        autorizationsScheme,
    );

    const checkCreateUserPermission = isLimitedAuthorizationBySchema(
        EMenuLabels.ADMINISTRATION,
        ESubMenuAdministrationLabel.CREATE_USER,
        autorizationsScheme,
    );

    return (
        <div>
            <UserAdministrationContainer
                searchUserGroup={searchUserGroupAsyncDispatch}
                groupList={groupListState}
                schemaProps={schemaProps}
                createUser={createUserAsyncDispatch}
                createUserResponse={manageUserState}
                createGroupResponse={manageGroupState}
                resetGroupResponse={resetGroupResponse}
                checkCreateGroupPermission={checkCreateGroupPermission}
                checkCreateUserPermission={checkCreateUserPermission}
                createGroup={createGroupAsyncDispatch}
                resetCreateUserResponse={resetCreateUserResponse}
                resetAdminClientSpaceResponse={resetAdminClientSpaceResponse}
            />

            <UserAdministrationResult
                setAdminSuccessModal={setAdminSuccessModal}
                userList={userGroupState}
                checkUderAdministrationResultPermission={
                    checkUderAdministrationResultPermission
                }
                resetResponse={resetGroupResponse}
                getGroup={getGroupAsyncDispatch}
                groupList={groupListState}
                manageUser={manageUserAsyncDispatch}
                manageUserResponse={searchState}
                resetAdminClientSpaceResponse={resetAdminClientSpaceResponse}
                isAdminSpace={isAdminSpace}
            />
        </div>
    );
};

export default connect(mapStateToProps, dispatchProps)(UserAdministrationPage);
