import { Epic } from 'redux-observable';
import { of, forkJoin, throwError, Observable } from 'rxjs';
import { mergeMap, switchMap, catchError, filter, merge } from 'rxjs/operators';
import {
    createAsyncAction,
    createStandardAction,
    isActionOf,
    ActionType,
} from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';
import { TMeterRead, TResponse } from 'Models';
import { ERateOption } from '../utils/enums';

import {
    FETCH_METER_CONFIG_REQUEST,
    FETCH_METER_CONFIG_SUCCESS,
    FETCH_METER_CONFIG_FAILURE,
    FETCH_METER_READING_REQUEST,
    FETCH_METER_READING_SUCCESS,
    FETCH_METER_READING_FAILURE,
    CREATE_INDEX_REQUEST,
    CREATE_INDEX_SUCCESS,
    CREATE_INDEX_FAILURE,
    CREATE_INDEX_RESET,
} from './actionTypes';

interface IPayload {
    contractNbr: string;
    pointOfDelivery: string;
    readingDate?: string;
    energyType?: string;
    meterReads?: Array<{
        meterNumber: string;
        registerName: string;
        timeframeCode: string;
        value: string;
    }>;
}

export interface IMeterConfigResponse {
    rateOption: ERateOption;
    digitSize: number;
    meterNumber: string;
    error?: string;
    code?: string;
    message?: string;
}

const resetCreateIndex = createStandardAction(CREATE_INDEX_RESET)();

const fetchMeterConfigAsync = createAsyncAction(
    FETCH_METER_CONFIG_REQUEST,
    FETCH_METER_CONFIG_SUCCESS,
    FETCH_METER_CONFIG_FAILURE,
)<IPayload, IMeterConfigResponse, IMeterConfigResponse>();

const fetchMeterReadingAsync = createAsyncAction(
    FETCH_METER_READING_REQUEST,
    FETCH_METER_READING_SUCCESS,
    FETCH_METER_READING_FAILURE,
)<IPayload, TMeterRead[], TResponse>();

const createIndexAsync = createAsyncAction(
    CREATE_INDEX_REQUEST,
    CREATE_INDEX_SUCCESS,
    CREATE_INDEX_FAILURE,
)<IPayload, string, TResponse>();

export type ConsumptionAction =
    | ActionType<typeof fetchMeterConfigAsync>
    | ActionType<typeof fetchMeterReadingAsync>
    | ActionType<typeof createIndexAsync>
    | ActionType<typeof resetCreateIndex>;

const preparePayloadMeterReading = ({
    contractNbr,
    pointOfDelivery,
    energyType,
}: IPayload) => {
    return {
        contractNbr,
        pointOfDelivery,
        energyType,
    };
};

const preparePayloadMeterConfig = ({
    contractNbr,
    pointOfDelivery,
    readingDate,
}: IPayload) => {
    return {
        contractNbr,
        pointOfDelivery,
        readingDate,
    };
};

const mapGetMeterReading = (
    action: RootAction,
    { apiRequest }: Services,
): Observable<{
    reads: TMeterRead[];
    error?: string;
    code?: string;
    message?: string;
}> => {
    const payload = preparePayloadMeterReading(action.payload);
    return apiRequest<any>({
        path: '/getMeterReads',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            if (response && !response.code) {
                return of({
                    reads: response,
                    code: '200',
                });
            }
            const message = 'Une erreur est survenue';
            return of({
                message,
                reads: [],
                code: '500',
            });
        }),
        catchError(error => {
            return of({
                reads: [],
                error: error.message,
                code: error.code,
                message: error.message,
            });
        }),
    );
};

const mapGetMeterConfig = (
    action: RootAction,
    { apiRequest }: Services,
): Observable<IMeterConfigResponse | TResponse> => {
    const payload = preparePayloadMeterConfig(action.payload);
    return apiRequest<IMeterConfigResponse>({
        path: '/getMeterConfiguration',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IMeterConfigResponse) => {
            if (response && !response.code) {
                return of({
                    ...response,
                    code: '200',
                });
            }
            const message = response.message || 'Une erreur est survenue';
            return of({ code: '500', message });
        }),
        catchError(error => {
            return of({
                error: error.message,
                code: error.code,
                message: error.message,
            });
        }),
    );
};

const fetchMeterEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchMeterConfigAsync.request)),
        switchMap(
            (action: RootAction) => mapGetMeterConfig(action, dependency),
            (action: RootAction, r: IMeterConfigResponse | TResponse) => [
                action,
                r,
            ],
        ),
        switchMap(([action, response]) => {
            if (response.code !== '200') {
                throwError({ code: response.code, message: response.message });
            }
            return forkJoin(
                of(response),
                mapGetMeterReading(action, dependency),
            );
        }),
        switchMap(([meterConfigResponse, meterReadingResponse]) => {
            let meterConfig;
            let meterReading;

            if (meterConfigResponse.code !== '200') {
                meterConfig = fetchMeterConfigAsync.failure(
                    meterConfigResponse,
                );
            } else {
                meterConfig = fetchMeterConfigAsync.success(
                    meterConfigResponse,
                );
            }
            if (meterReadingResponse.code && meterReadingResponse.message) {
                meterReading = fetchMeterReadingAsync.failure({
                    code: meterReadingResponse.code,
                    message: meterReadingResponse.message,
                });
            } else {
                meterReading = fetchMeterReadingAsync.success(
                    meterReadingResponse.reads,
                );
            }
            return of(meterConfig, meterReading);
        }),
        catchError(error => of(fetchMeterConfigAsync.failure(error))),
    );

const fetchMeterReadingEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(fetchMeterReadingAsync.request)),
        switchMap(
            (action: RootAction) => mapGetMeterReading(action, dependency),
            (action, r) => [action, r],
        ),
        switchMap(([action, response]) => {
            if (response && response.code === '200') {
                return of(fetchMeterReadingAsync.success(response.reads));
            }
            const message = response.message || 'Une erreur est survenue';
            return of(
                fetchMeterReadingAsync.failure({
                    code: response.code || '500',
                    message,
                }),
            );
        }),
        catchError(error => of(fetchMeterReadingAsync.failure(error))),
    );

const preparePayloadIndex = ({
    contractNbr,
    pointOfDelivery,
    readingDate,
    meterReads,
    energyType,
}: IPayload) => {
    return {
        energyType,
        contractNbr,
        pointOfDelivery,
        readingDate,
        meterReads,
    };
};

const mapPostIndex = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadIndex(action.payload);
    return apiRequest<any>({
        path: '/createIndex',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any) => {
            if (response.code !== undefined && response.code !== '200') {
                return of(
                    createIndexAsync.failure({
                        code: response.code,
                        message: response.message,
                    }),
                );
            }

            return of(createIndexAsync.success('success'));
        }),
        catchError(error => {
            return of(createIndexAsync.failure(error));
        }),
    );
};

const createIndexEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(createIndexAsync.request)),
        mergeMap(action => mapPostIndex(action, dependency)),
    );

export {
    fetchMeterConfigAsync,
    fetchMeterReadingAsync,
    fetchMeterEpic,
    fetchMeterReadingEpic,
    resetCreateIndex,
    createIndexAsync,
    createIndexEpic,
};
