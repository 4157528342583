import React, { FormEvent, useState } from 'react';

import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';
import config from '../../utils/config';
import { log } from '../../utils/helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LoadingSpinner } from '../../libraries/withLoader';

const wording = WordingConstant.PaymentCBModal;

type StripeFormProps = {
    clientSecret: string;
    amount: string;
    clientName: string;
    onFormValidate: (sucess: boolean, errMsg?: string) => void;
    handleClose: () => void;
};

const StripeForm = ({
    clientSecret,
    amount,
    onFormValidate,
    clientName,
    handleClose,
}: StripeFormProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const elements = useElements();
    const stripe = useStripe();

    const onValidateForm = async (e: FormEvent) => {
        e.preventDefault();

        if (!stripe || !elements) {
            if (onFormValidate) {
                onFormValidate(false, wording.stripeElementError);
            }
            return;
        }
        const element = elements.getElement(CardNumberElement);
        if (!element) {
            if (onFormValidate) {
                onFormValidate(false, wording.elementError);
            }
            return;
        }

        setIsLoading(true);
        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: element,
                billing_details: {
                    name: clientName,
                },
            },
        });
        setIsLoading(false);
        log('STRIPE ANSWER ===>', result);

        if (result.error) {
            if (onFormValidate) onFormValidate(false, result.error.message);
        } else if (onFormValidate) onFormValidate(true);
    };

    const renderFakeCard = (cardNumber: string, title: string) => {
        const [isClicked, setisClicked] = useState(false);

        const handleClickCard = () => {
            setisClicked(true);
            setTimeout(() => setisClicked(false), 1600);
        };

        return (
            <div>
                {title}:
                <CopyToClipboard text={cardNumber} onCopy={handleClickCard}>
                    <button type="button">
                        {cardNumber}
                        <div className="clicked-fake-card-label">
                            {isClicked && <span>Copié</span>}
                            <img
                                alt="clipboard"
                                src={resources['ic-copy-clipboard']}
                            />
                        </div>
                    </button>
                </CopyToClipboard>
            </div>
        );
    };

    const isDebugMode = config.ENV === 'PREPRODUCTION';

    return (
        <div className="stripe-form-container">
            <form className="form-content" onSubmit={onValidateForm}>
                <div className="logo-container">
                    <img
                        src={resources['ic-logo-login']}
                        alt="ic-logo-wekiwi"
                        width={250}
                    />
                </div>

                <div className="stripe-card-container">
                    <div>
                        <span> {wording.label_card_number} </span>
                        <CardNumberElement className="stripe-card-number-field" />
                    </div>

                    <div>
                        <span> {wording.label_card_expiry} </span>
                        <CardExpiryElement className="stripe-card-expiry-field" />
                    </div>

                    <div>
                        <span> {wording.label_card_cvc} </span>
                        <CardCvcElement className="stripe-card-cvc-field" />
                    </div>
                </div>

                {isDebugMode && (
                    <div className="stripe-card-debug">
                        <span>{wording.debugLabel}</span>
                        {renderFakeCard(
                            '4242424242424242',
                            'Paiement direct avec réussite',
                        )}
                        {renderFakeCard(
                            '4000000000003220',
                            'Paiement 3DSecure avec réussite',
                        )}
                        {renderFakeCard(
                            '4000008400001280',
                            'Paiement 3DSecure avec erreur ',
                        )}
                    </div>
                )}

                <div className="button-container">
                    <button
                        onClick={handleClose}
                        type="button"
                        className="button-cancel"
                    >
                        {wording.button_cancel}
                    </button>
                    <button type="submit" className="button-validate">
                        {wording.button_validate}: {amount}€
                    </button>
                </div>

                {isLoading && (
                    <div className="loader">
                        <LoadingSpinner />
                    </div>
                )}
            </form>
        </div>
    );
};

export default StripeForm;
