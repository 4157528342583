import _ from 'lodash';
import moment from 'moment';
import apiRequest, { invokeLambda } from '../../services/api-service';
import Config from '../config';
import { getDateFormatted as getFormattedDate, log as hLog } from '../helpers';
import Wording from '../wording.json';
import * as RequestErrors from './errors';
import {
    EBillingModes,
    ECustomerTypes,
    EEnergyTypes,
    EResultSponsor,
    ESavingModes,
    ETimeslots,
    TAppointmentDay,
    TCasesExtractResponse,
    TContract,
    TEstimate,
    TGetOrderStatusResponse,
    TGetPackageResponse,
    TGetProductResponse,
    TOffer,
    TOrder,
    TOrderResponse,
    TPaymentCBResponse,
    TProduct,
    TSaveOrderResponse,
    TSignOrderResponse,
    TSurvey,
} from './types';

function log(message: string, data: any) {
    hLog(`API Request: ${message}`, data);
}

const handleRequest = async <T>(
    fn: () => Promise<T | RequestErrors.RequestError>,
    method?: string,
    path?: string,
    args?: any,
    defaultError?: string,
): Promise<any | RequestErrors.RequestError> => {
    const pathStr = !!path ? `/${path} ` : '';
    try {
        const res = await fn();
        const errCode: string | null = _.get(res, 'code', null);
        const errMessage: string | null = _.get(res, 'message', null);
        if (!!errCode && !!errMessage) {
            throw new RequestErrors.ExternalError(errMessage);
        }
        return res;
    } catch (err) {
        log(`Error from API ${pathStr}====> `, err.message);
        if (!(err instanceof RequestErrors.RequestError)) {
            let message;
            if (err instanceof Error || typeof err === 'object') {
                message = _.get(err, 'message');
            } else if (typeof err === 'string') {
                message = err;
            }
            return new RequestErrors.RequestError(message);
        }
        return err;
    }
};

const checkSponsor = async (
    code: string,
): Promise<boolean | RequestErrors.RequestError> => {
    const fn = async () => {
        const res = await apiRequest({
            path: '/sponsoring',
            method: 'POST',
            body: {
                sponsorNumber: code,
            },
            isSubscription: true,
        }).toPromise();
        const resultSponsor: string = _.get(res, 'resultSponsor');
        if (!!resultSponsor) {
            if (resultSponsor === EResultSponsor.OK) {
                return true;
            }
            if (resultSponsor === EResultSponsor.KO) {
                return false;
            }
        }
        return new RequestErrors.RequestError();
    };
    return await handleRequest(fn, 'POST', 'sponsoring', {
        code,
    });
};

const searchSiret = async (
    value: string,
): Promise<any | RequestErrors.RequestError> => {
    const fn = async () => {
        const res: any = await fetch(`${Config.URL_GET_SIRET}/${value}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer b6a24984-f301-3418-b06f-5ebb0081af43',
            },
        });

        if (res.status && res.status === 200) {
            const response = await res.json();

            return {
                ...response,
                status: res.status,
            };
        }
        throw new RequestErrors.RequestError(
            res.message || 'Service indisponible',
        );
    };
    return await handleRequest(fn, 'GET', 'getSiret', { value });
};

const getCalendarAppointment = async (
    channel: string = 'WEB',
    energyType: EEnergyTypes,
    isIndividual: boolean = true,
    order?: TOrder,
): Promise<TAppointmentDay[] | RequestErrors.RequestError> => {
    const fn = async () => {
        let fromDate = getFormattedDate(new Date(Date.now()));
        if (!!order) {
            const effectiveStartDate: Date = new Date(
                _.get(order, 'contracts.0.effectiveStartDate'),
            );
            if (!isNaN(effectiveStartDate.getTime())) {
                effectiveStartDate.setDate(effectiveStartDate.getDate() - 1);
                fromDate = getFormattedDate(effectiveStartDate);
            }
        }
        let body: any = {
            channel,
            seller: 'WEKIWI',
            customerType: isIndividual ? 'INDIVIDUAL' : 'PROFESSIONAL',
            energyType,
            fromDate,
            processType: 'MOVE_IN',
        };
        const previousContract: TContract | undefined = _.find(
            _.get(order, 'contracts', []),
            (contract: TContract) => {
                return energyType === contract.energy;
            },
        );
        const billingModeCode: EBillingModes | undefined = _.get(
            previousContract,
            'billingModeCode',
        );
        const isPaymentSchedule: boolean =
            billingModeCode === EBillingModes.PAYMENT_SCHEDULE;
        if (!!order && isPaymentSchedule) {
            body = {
                ...body,
                customerType: _.get(order, 'customer.type', ''),
                deliveryState: _.get(
                    previousContract,
                    'deliveryPoint.state',
                    '',
                ),
                deliveryStatus: _.get(
                    previousContract,
                    'deliveryPoint.deliveryStatus',
                    '',
                ),
                meterType: _.get(
                    previousContract,
                    'deliveryPoint.meterType',
                    '',
                ),
                smartMeterStatus: _.get(
                    previousContract,
                    'deliveryPoint.smartMeterStatus',
                    '',
                ),
            };
        }
        const res: any = await apiRequest({
            path: '/getCalendarAppointment',
            method: 'POST',
            body,
            isSubscription: true,
        }).toPromise();
        const daysList = _.get(res, '0.daysList');
        if (!!daysList) {
            return daysList as TAppointmentDay[];
        }
        throw new RequestErrors.NoDaysListError();
    };
    return await handleRequest(fn, 'POST', '/getCalendarAppointment', {
        energyType,
        isIndividual,
    });
};

const getDeliveryPointPackages = async (
    channel: string = 'WEB',
    electricity?: string | null,
    gas?: string | null,
    isIndividual: boolean = true,
    email: string = '',
    firstName: string = '',
    lastName: string = '',
): Promise<TGetPackageResponse | RequestErrors.RequestError> => {
    if (electricity === null && gas === null) {
        throw new RequestErrors.NoEnergySelectionError();
    }
    const pointOfDeliveryList = [];
    if (electricity) {
        pointOfDeliveryList.push({ energy: 'EL', reference: electricity });
    }
    if (gas) pointOfDeliveryList.push({ energy: 'NG', reference: gas });
    const payload = {
        channel,
        customerType: isIndividual ? 'INDIVIDUAL' : 'PROFESSIONAL',
        mode: 'POINT_OF_DELIVERY',
        pointOfDeliveryList,
        seller: 'WEKIWI',
        email,
        firstName,
        lastName,
    };
  
    let res :
    | TGetPackageResponse
    | RequestErrors.RequestError = await apiRequest({
    path: '/getPackages',
    method: 'POST',
    body: payload,
    isSubscription: true,
}).toPromise() as TGetPackageResponse | RequestErrors.RequestError;
    const energies: EEnergyTypes[] = [];
    if (!!electricity) energies.push(EEnergyTypes.ELECTRICTY);
    if (!!gas) energies.push(EEnergyTypes.GAS);
    res = filterGetPackagesError(res, energies);
    return res;
};

const getRawDeliveryPointPackages = async (
    channel: string = 'WEB',
    electricity?: string | null,
    gas?: string | null,
    isIndividual: boolean = true,
    email: string = '',
    firstName: string = '',
    lastName: string = '',
): Promise<TGetPackageResponse | RequestErrors.RequestError> => {
    if (electricity === null && gas === null) {
        throw new RequestErrors.NoEnergySelectionError();
    }
    const pointOfDeliveryList = [];
    if (electricity) {
        pointOfDeliveryList.push({ energy: 'EL', reference: electricity });
    }
    if (gas) pointOfDeliveryList.push({ energy: 'NG', reference: gas });
    const payload = {
        channel,
        customerType: isIndividual ? 'INDIVIDUAL' : 'PROFESSIONAL',
        mode: 'POINT_OF_DELIVERY',
        pointOfDeliveryList,
        seller: 'WEKIWI',
        email,
        firstName,
        lastName,
    };

    const res = await apiRequest({
    path: '/getPackages',
    method: 'POST',
    body: payload,
    isSubscription: true,
    }).toPromise() as TGetPackageResponse | RequestErrors.RequestError;
    return res;
};

const getEstimatesPackages = async (
    channel: string = 'WEB',
    estimates: TEstimate[],
    isIndividual: boolean = true,
    energies: EEnergyTypes[],
): Promise<TGetPackageResponse | RequestErrors.RequestError> => {
    const payload = {
        channel,
        customerType: isIndividual ? 'INDIVIDUAL' : 'PROFESSIONAL',
        mode: 'ESTIMATE',
        estimates,
        seller: 'WEKIWI',
    };

    let res :
        | TGetPackageResponse
        | RequestErrors.RequestError = await apiRequest({
        path: '/getPackages',
        method: 'POST',
        body: payload,
        isSubscription: true,
    }).toPromise() as TGetPackageResponse | RequestErrors.RequestError;
    res = filterGetPackagesError(res, energies);
    return res;
};

const getOffers = async (
    channel: string = 'WEB',
    contracts: TContract[],
    isIndividual: boolean,
): Promise<TOffer[] | RequestErrors.RequestError> => {
    const payload = {
        channel,
        customerType: isIndividual ? 'INDIVIDUAL' : 'PROFESSIONAL',
        seller: 'WEKIWI',
        contracts,
    };
    const res = await apiRequest({
        path: '/getOffers',
        method: 'POST',
        body: payload,
        isSubscription: true,
    }).toPromise();
    if (!(res instanceof RequestErrors.RequestError)) {
        return _.get(res, 'offers', []) as TOffer[];
    }
    return res;
};

const getOrder = async (
    channel: string = 'WEB',
    orderNumber: string,
): Promise<TOrderResponse | RequestErrors.RequestError> => {
    const fn = async () => {
        const res = await apiRequest({
            path: '/getOrder',
            method: 'POST',
            body: {
                channel,
                seller: 'WEKIWI',
                orderNumber,
            },
            isSubscription: true,
        }).toPromise();
        if (!(res instanceof RequestErrors.RequestError)) {
            return res as TOrderResponse;
        }
    };
    return await handleRequest(fn, 'POST', 'getOrder', {
        orderNumber,
    });
};

const getOrderStatus = async (
    channel: string = 'WEB',
    orderNumber: string,
): Promise<TGetOrderStatusResponse | RequestErrors.RequestError> => {
    const fn = async () => {
        const res = (await apiRequest({
            path: '/getOrderStatus',
            method: 'POST',
            body: {
                channel,
                seller: 'WEKIWI',
                orderNumber,
            },
            isSubscription: true,
        }).toPromise()) as TGetOrderStatusResponse | RequestErrors.RequestError;
        return res;
    };
    return await handleRequest(fn, 'POST', 'getOrderStatus', {
        orderNumber,
    });
};

const getSurveyPackages = async (
    channel: string = 'WEB',
    survey: TSurvey,
    isIndividual = true,
): Promise<TGetPackageResponse | RequestErrors.RequestError> => {
    const payload = {
        channel,
        customerType: isIndividual ? 'INDIVIDUAL' : 'PROFESSIONAL',
        mode: 'SURVEY',
        seller: 'WEKIWI',
        survey,
    };
    
    let res :
    | TGetPackageResponse
    | RequestErrors.RequestError = await apiRequest({
    path: '/getPackages',
    method: 'POST',
    body: payload,
    isSubscription: true,
    }).toPromise() as TGetPackageResponse | RequestErrors.RequestError;
    res = filterGetPackagesError(res, survey.energyList || []);
    if (!(res instanceof RequestErrors.RequestError)) {
        const packages = _.get(res, 'packagesList', []);
        if (packages.length <= 0) {
            throw new RequestErrors.NoMatchingPackagesError(
                'Could not fetch survey packages',
            );
        }
    }
    return res;
};

const getProducts = async (
    channel: string = 'WEB',
    contracts: TContract[],
    customerType: ECustomerTypes,
): Promise<TGetProductResponse | RequestErrors.RequestError> => {
    const fn = async () => {
        const res: TProduct[] = (await apiRequest({
            path: '/getProducts',
            method: 'POST',
            body: {
                channel,
                seller: 'WEKIWI',
                contracts: contracts.map(ctr => {
                    const nextCtr = { ...ctr };
                    delete nextCtr.installmentFrequency;
                    return nextCtr;
                }),
                customerType,
                contextOfUse: 'MARKET',
            },
            isSubscription: true,
        }).toPromise()) as TProduct[];
        return res;
    };
    return await handleRequest(fn, 'POST', 'getProducts', {
        contracts,
        customerType,
    });
};

const filterGetPackagesError = (
    res: TGetPackageResponse | RequestErrors.RequestError,
    energies: EEnergyTypes[],
): TGetPackageResponse | RequestErrors.RequestError => {
    const hasElectricity: boolean = _.includes(
        energies,
        EEnergyTypes.ELECTRICTY,
    );
    const hasGas: boolean = _.includes(energies, EEnergyTypes.GAS);
    const packages = _.get(res, 'packagesList', []);
    const hasElectricityMatch =
        _.filter(packages, pkg => pkg.energy === 'EL').length > 0;
    const hasGasMatch =
        _.filter(packages, pkg => pkg.energy === 'NG').length > 0;
    const errMessage = _.get(res, 'message');
    if (!!errMessage) {
        return new RequestErrors.ExternalError(errMessage);
    } else if (!_.get(res, 'packagesList')) {
        return new RequestErrors.NoPackageListError();
    } else if (packages.length <= 0) {
        return new RequestErrors.NoMatchingPackagesError();
    } else {
        const err: string[] = [];
        if (hasElectricity && !hasElectricityMatch) {
            err.push(Wording.Errors.noMatchingElectricityPackages);
        }
        if (hasGas && !hasGasMatch) {
            err.push(Wording.Errors.noMatchingGasPackages);
        }
        if (err.length > 0) {
            return new RequestErrors.NoMatchingPackagesError(err.join(' + '));
        }
    }
    return res;
};

const paymentCB = async (
    channel: string = 'WEB',
    order: TOrder,
): Promise<TPaymentCBResponse | RequestErrors.RequestError> => {
    const fn = async () => {
        const res = (await apiRequest({
            path: '/paymentCB',
            method: 'POST',
            body: {
                channel,
                seller: 'WEKIWI',
                order,
            },
            isSubscription: true,
        }).toPromise()) as TPaymentCBResponse | RequestErrors.RequestError;
        return res;
    };
    return await handleRequest(fn, 'POST', 'paymentCB', {
        order,
    });
};

const saveOrder = async (
    channel: string = 'WEB',
    order: TOrder,
): Promise<TSaveOrderResponse | RequestErrors.RequestError> => {
    const fn = async () => {
        const res: TSaveOrderResponse = (await apiRequest({
            path: '/saveOrder',
            method: 'POST',
            body: {
                channel,
                seller: 'WEKIWI',
                order,
                savingMode: ESavingModes.SEND_EMAIL_LINK,
            },
            isSubscription: true,
        }).toPromise()) as TSaveOrderResponse;
        return res;
    };
    return await handleRequest(fn, 'POST', 'saveOrder', {
        order,
    });
};

const searchAppointmentTimeslot = async (
    date: Date,
    energy: EEnergyTypes,
): Promise<ETimeslots[] | RequestErrors.RequestError> => {
    const fn = async () => {
        const day: number = date.getDate();
        const dayStr = `${day < 10 ? `0${day}` : day}`;
        const month: number = date.getMonth();
        const monthStr = `${month + 1 < 10 ? `0${month + 1}` : month + 1}`;
        const dateStr: string = `${date.getFullYear()}-${monthStr}-${dayStr}`;
        const res = await apiRequest({
            path: '/getAppointmentTimeslot',
            method: 'POST',
            body: {
                date: dateStr,
                energy,
            },
            isSubscription: true,
        }).toPromise();
        let timeslots: ETimeslots[] = _.get(res, 'timeslots');
        if (!!timeslots) {
            if (_.includes<ETimeslots>(timeslots, ETimeslots.NONE)) {
                timeslots = _.without<ETimeslots>(timeslots, ETimeslots.NONE);
            }
            return timeslots;
        }
        throw new RequestErrors.RequestError(
            _.get(res, 'message') || 'Service indisponible',
        );
    };
    return await handleRequest(fn, 'POST', 'getAppointmentTimeslot', {
        date,
        energy,
    });
};

const signOrder = async (
    channel: string = 'WEB',
    order: TOrder,
    userId?: string,
): Promise<TSignOrderResponse | RequestErrors.RequestError> => {
    const fn = async () => {
        const payload: any = {
            channel,
            seller: 'WEKIWI',
            order,
        };
        if (!!userId) payload.order.createdBy = userId;
        const res = await apiRequest<
            TSignOrderResponse | RequestErrors.RequestError
        >({
            path: '/signOrder',
            method: 'POST',
            body: { ...payload },
            isSubscription: true,
        }).toPromise();
        return res;
    };
    return await handleRequest(fn, 'POST', 'signOrder', {
        channel,
        order,
        userId,
    });
};

const getCasesHistory = async (): Promise<
    TCasesExtractResponse | RequestErrors.RequestError
> => {
    const date = moment()
        .subtract(1, 'M')
        .toISOString()
        .slice(0, 10);

    const res = await apiRequest<
        TCasesExtractResponse | RequestErrors.RequestError
    >({
        lambdaFunctionName: Config.FunctionNames.casesExtract,
        path: '/casesExtract',
        method: 'POST',
        body: {
            fromDate: date,
            offset: 0,
            limit: 10000,
        },
        isSubscription: true,
    }).toPromise();
    return res;
};

const getNewAdditionalRates = async (payload: any) => {
    const payloadWithoutInstallmentFrequency = delete payload.contract
        .installmentFrequency;
    const res = await apiRequest<any | RequestErrors.RequestError>({
        lambdaFunctionName: Config.FunctionNames.getOffers,
        path: '/getOffers',
        method: 'POST',
        body: payloadWithoutInstallmentFrequency,
        isSubscription: true,
    }).toPromise();
    log('res: ', res);
    return res;
};

export {
    checkSponsor,
    getCalendarAppointment,
    getCasesHistory,
    getDeliveryPointPackages,
    getEstimatesPackages,
    getNewAdditionalRates,
    getOffers,
    getOrder,
    getOrderStatus,
    getProducts,
    getRawDeliveryPointPackages,
    getSurveyPackages,
    paymentCB,
    saveOrder,
    searchAppointmentTimeslot,
    searchSiret,
    signOrder,
};
