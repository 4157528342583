import React from 'react';
import ProgressBar from 'react-customizable-progressbar';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ConsumptionPage;

type Props = {
    progress: number;
    steps?: number;
    pathImg: string;
};

const CustomProgressbar = (props: Props) => {
    return (
        <div className="custom-progressbar">
            <h3>{Wording.progressBar.title}</h3>

            <div className="custom-progressbar__section">
                <ProgressBar
                    steps={props.steps ? props.steps : 100}
                    radius={125}
                    progress={props.progress}
                    strokeWidth={5}
                    strokeColor="#ffb40a"
                    trackStrokeWidth={4}
                    pointerRadius={4}
                    pointerStrokeWidth={8}
                    pointerStrokeColor="#ffb40a"
                >
                    <div className="custom-progressbar__bar-content">
                        <div>
                            <img src={props.pathImg} alt="path-img" />
                            <strong>
                                {props.progress}
                                <span>%</span>
                            </strong>
                        </div>
                    </div>
                </ProgressBar>
                <div className="custom-progressbar__content">
                    <span className="custom-progressbar__content__rect1 rect" />
                    <p>{Wording.progressBar.offer}</p>
                    <span className="custom-progressbar__content__rect2 rect" />
                    <p>{Wording.progressBar.consumption}</p>
                </div>
            </div>
        </div>
    );
};

export default CustomProgressbar;
