import React from 'react';

import { TFile, TFiles } from 'Models';
import { CustomTable, CustomButton } from '../../components';

import { getLabelByValue, getFormattedDate } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.FilePage;
const HeaderWording = WordingConstant.TableHeader.file;

type Props = {
    data: TFiles[];
    isLoading: boolean;
    handleDownloadProofOfResidence(): void;
    handleDownloadFile(key: string, name: string): any;
};

const FileContainer: React.FC<Props> = props => {
    const columnsTable = [
        {
            Header: HeaderWording.date,
            accessor: 'documentDate',
            Cell: (propsCell: any) => getFormattedDate(propsCell.value),
            width: 150,
        },
        {
            Header: HeaderWording.type,
            accessor: 'documentName',
            Cell: (propsCell: any) =>
                getLabelByValue(propsCell.value, Wording.fileNameMapping) ||
                'Document',

            width: 200,
        },
        {
            Header: HeaderWording.contractNbr,
            accessor: 'contractNbr',
            width: 200,
        },
        {
            Header: HeaderWording.energy,
            accessor: 'energyType',
            width: 180,
        },
        {
            Header: HeaderWording.download,
            accessor: 'documentKey',
            Cell: (propsCell: any) => {
                const { value, original } = propsCell;

                const name = getLabelByValue(
                    original.documentName,
                    Wording.fileNameMapping,
                );

                return (
                    <a
                        href={`#telechargement:${name
                            .toLocaleLowerCase()
                            .replace(' ', '_')}`}
                        onClick={() =>
                            props.handleDownloadFile(
                                value,
                                original.documentName,
                            )
                        }
                    >
                        Fichier
                    </a>
                );
            },
            width: 200,
        },
    ];

    return (
        <div className="file-container">
            <h2 className="file-container__file">{Wording.file}</h2>
            <CustomTable
                columns={columnsTable}
                data={props.data}
                defaultPageSize={props.data && props.data.length}
                noDataText={Wording.noDataText}
                sortable={true}
            />

            <p className="file-container__paragraph">
                {Wording.description.generalCondition}{' '}
                <a
                    href={WordingConstant.Common.wekiwi}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {Wording.description.wekiwiLink}
                </a>
                {Wording.description.section}
                <b>
                    {' '}
                    <a
                        href={Wording.CGVLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {Wording.description.CGV}
                    </a>
                </b>
                .
            </p>
            <CustomButton
                color="orange"
                title={Wording.buttons.ProofOfResidence}
                isLoading={props.isLoading}
                onClick={
                    !props.isLoading
                        ? props.handleDownloadProofOfResidence
                        : null
                }
            />
        </div>
    );
};

// ReactPDF.render(<ProofOfPayment />, `${__dirname}/output.pdf`);

export default FileContainer;
