import React, { Fragment, useRef, useState } from 'react';

import { TCustomer } from '../../../constants/key-system';

import {
    getFormattedDate,
    getLabelByType,
    isHaveActionsPermission,
    isOdd,
} from '../../../utils/helpers';
import WordingConstant from '../../../utils/wording.json';
import resources from '../../../utils/resources';
import { CustomButton, CustomInput, CustomModal } from '../../../components';
import { InputEvent, TSchemaProps } from 'Types';

import { TCustomerContractPayload } from '../../../_actions/customerContract.actions';
import ErrorElement from '../../../components/ErrorElement';
import CustomTable from '../../../components/admin/CustomTable';
import useOutsideClick from '../../../libraries/useOutsideClick';
import { ITransferContract } from '../../../_actions/transferContract.actions';
import Status from '../../../components/admin/Status';
import { TContract, TOrder } from 'Models';

const Wording = WordingConstant.SubComponent;

type Props = {
    customer: TCustomer;
    transferCustomerContract: (props: TCustomerContractPayload) => void;
    resetResponse: () => void;
    transferContract: TOrder[];
    error: string;
    transferContractDispatch: (props: ITransferContract) => void;
    userId: string;
    transferContractResponse: any;
    resetResponseTransfer: () => void;
    schemaProps: TSchemaProps;
    index: number;
    transferError?: string;
};

const SubComponent: React.FC<Props> = ({
    customer,
    transferCustomerContract,
    transferContract,
    error,
    resetResponse,
    userId,
    schemaProps,
    transferContractDispatch,
    transferContractResponse,
    transferError,
    resetResponseTransfer,
    index,
}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<number>(-1);
    const [showModal, setModal] = useState<boolean>(false);
    const [clientId, setClientId] = useState<string>('');

    const [currentContract, setCurrentContract] = useState<
        TContract | undefined
    >(undefined);

    const divRef = useRef<HTMLDivElement>(null);

    useOutsideClick(divRef, () => {
        if (isOpen) setOpen(false);
    });

    const allContracts = customer.contracts;

    const renderTransferContractModal = () => {
        const elementMap = {
            input_client_id: {
                text: '',
                value: clientId,
                onInputChange: (e: InputEvent) =>
                    setClientId(e.currentTarget.value),
            },

            btn_client_access: {
                title: 'Valider',
                small: true,
                color: 'gray',
                onClick: () => {
                    if (clientId !== '') {
                        setModal(false);
                        transferCustomerContract({
                            customerId: clientId,
                        });
                    }
                },
            },

            btn_cancel: {
                title: 'Retour',
                small: true,
                color: 'orange',
                onClick: () => {
                    setModal(false);
                },
            },
        };
        return (
            <CustomModal show={showModal}>
                <span
                    className="btn-close"
                    onClick={() => {
                        setModal(false);
                    }}
                >
                    {'X'}
                </span>
                <div className="transfer-contract-modal">
                    <p className="title">{Wording.modalTitle}</p>
                    <p className="sub-title">{Wording.subTitle}</p>
                    <CustomInput {...elementMap.input_client_id} />

                    <div className="transfer-contract-modal__buttons">
                        <CustomButton {...elementMap.btn_cancel} />
                        <CustomButton {...elementMap.btn_client_access} />
                    </div>
                </div>
            </CustomModal>
        );
    };

    const renderTransferContractErrorModal = () => {
        return (
            <CustomModal show={error === Wording.noResult}>
                <span
                    className="btn-close"
                    onClick={() => {
                        resetResponse();
                        setClientId('');
                    }}
                >
                    {'X'}
                </span>

                <span className="error-component">
                    <p className="title">
                        {'Aucun résultat trouvé pour cette recherche'}
                    </p>
                    <ErrorElement error={error} />

                    <div className="btn">
                        <CustomButton
                            title={'Retour'}
                            color="orange"
                            onClick={() => {
                                setClientId('');
                                resetResponse();
                                setModal(true);
                            }}
                        />

                        <CustomButton
                            title={'Réessayer'}
                            color="gray"
                            onClick={() => {
                                setClientId('');
                                resetResponse();
                                setModal(true);
                            }}
                        />
                    </div>
                </span>
            </CustomModal>
        );
    };

    const renderTranssferContractSuccessModal = () => {
        return (
            <CustomModal show={transferContract.length > 0}>
                <span
                    className="btn-close"
                    onClick={() => {
                        resetResponse();
                        setClientId('');
                    }}
                >
                    {'X'}
                </span>

                <span className="success-component">
                    <p className="title">
                        {'Client cible correspondant à votre recherche'}
                    </p>
                    <CustomTable
                        data={transferContract}
                        showPagination={false}
                        filterable={false}
                        className="-striped -highlight"
                        columns={prepareColumns()}
                        SubComponent={({ original }: any) => (
                            <div>{transfertContractSubComponent(original)}</div>
                        )}
                    />
                </span>
                <div className="group-btn">
                    <CustomButton
                        title={'Retour'}
                        color="orange"
                        onClick={() => {
                            resetResponse();
                            setModal(true);
                        }}
                    />

                    <CustomButton
                        title={'Continuer'}
                        color="gray"
                        onClick={() => {
                            if (currentContract) {
                                transferContractDispatch({
                                    customerNbrOutdated: customer.id,
                                    customerNbrTargeted: clientId,
                                    contractNbr: currentContract.contractNumber,
                                    userName: userId,
                                });
                            }
                        }}
                    />
                </div>
            </CustomModal>
        );
    };

    const prepareColumns = () => {
        return [
            {
                Header: Wording.transferContract.customerId,
                accessor: 'order.orderReference',
                filterable: false,
            },
            {
                Header: Wording.transferContract.companyName,
                accessor: 'order.customer.contact.companyName',
                filterable: false,
            },
            {
                Header: Wording.transferContract.clientFirstName,
                accessor: 'order.customer.contact',
                filterable: false,
                Cell: ({ original }: { original: { order: TOrder } }) => (
                    <div>
                        {`${original.order.customer.contact.firstName}
                        ${original.order.customer.contact.lastName}`}
                    </div>
                ),
            },
            {
                Header: Wording.transferContract.address,
                accessor: 'order.customer.contact.address.street',
                filterable: false,
            },
            {
                Header: Wording.transferContract.invoiceId,
                accessor: 'order.customer.contact.phone',
                filterable: false,
            },
            {
                Header: Wording.transferContract.email,
                accessor: 'order.customer.contact.email',
                filterable: false,
            },
        ];
    };

    const transfertContractSubComponent = (original: any) => {
        return (
            <div className="sub-component">
                <table className="sub-component__table">
                    <thead>
                        <tr>
                            {[
                                Wording.customerId,
                                Wording.energie,
                                Wording.to,
                                Wording.status,
                            ].map((el: string, index: number) => (
                                <th key={index}>{el}</th>
                            ))}
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {allContracts ? (
                            allContracts.map((contract, index) => {
                                return (
                                    <Fragment key={index}>
                                        <tr className="transaction-status">
                                            <td>{contract.contractNumber}</td>
                                            <td>{contract.energy}</td>
                                            <td>
                                                {getFormattedDate(
                                                    contract.subscriptionDate,
                                                )}
                                            </td>
                                            <td>{contract.status}</td>
                                        </tr>
                                    </Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td>{Wording.no_contract}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderSuccessFailureModal = () => {
        return (
            <CustomModal
                show={
                    transferContractResponse !== '' &&
                    transferContractResponse !== undefined
                }
            >
                <>
                    <div>
                        {transferContractResponse === 'SUCCESS' && (
                            <div className="transfer-modal">
                                <h3>{Wording.success}</h3>
                                <p>{Wording.success_text}</p>
                            </div>
                        )}

                        {transferContractResponse === 'ERROR' && (
                            <div className="transfer-modal">
                                <h3>{Wording.error}</h3>
                                <p>{transferError || Wording.error_text}</p>
                            </div>
                        )}
                    </div>
                    <div className="group-btn">
                        <CustomButton
                            title={'Retour'}
                            color="orange"
                            onClick={() => {
                                setModal(false);
                                resetResponseTransfer();
                                setOpen(false);
                                setCurrentContract(undefined);
                            }}
                        />
                    </div>
                </>
            </CustomModal>
        );
    };

    return (
        <div className={`sub-component ${isOdd(index) ? '-odd' : ''}`}>
            <table className="sub-component__table">
                <thead>
                    <tr>
                        {[
                            Wording.customerId,
                            Wording.energie,
                            Wording.to,
                            Wording.status,
                        ].map((el: string, index: number) => (
                            <th key={index}>{el}</th>
                        ))}

                        <th />
                    </tr>
                </thead>
                <tbody>
                    {allContracts ? (
                        allContracts.map((contract, index) => {
                            return (
                                <Fragment key={index}>
                                    <tr className="transaction-status">
                                        <td>{contract.contractNumber}</td>
                                        <td>
                                            {getLabelByType(
                                                contract.energy,
                                                'energyTypes',
                                            )}
                                        </td>
                                        <td>
                                            {getFormattedDate(
                                                contract.subscriptionDate,
                                            )}
                                        </td>
                                        <td>
                                            <Status
                                                status={contract.status || ''}
                                                type="contractStatus"
                                            />
                                        </td>
                                        <td>
                                            {isHaveActionsPermission(
                                                schemaProps,
                                            ) && (
                                                <img
                                                    className="subcomponent-menu-style"
                                                    src={
                                                        resources[
                                                            'ic-menu-orange-creu'
                                                        ]
                                                    }
                                                    alt="ic-menu"
                                                    onClick={() => {
                                                        setOpen(!isOpen);
                                                        setCurrentItem(index);
                                                    }}
                                                />
                                            )}
                                            {isOpen && currentItem === index && (
                                                <div
                                                    ref={divRef}
                                                    className="pop-up"
                                                >
                                                    <p
                                                        onClick={() => {
                                                            setCurrentContract(
                                                                contract,
                                                            );
                                                            setModal(true);
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        {
                                                            'Transférer le contrat'
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td>{Wording.no_contract}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {showModal && renderTransferContractModal()}
            {renderTransferContractErrorModal()}
            {renderTranssferContractSuccessModal()}

            {renderSuccessFailureModal()}
        </div>
    );
};

export default SubComponent;
