import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as actions from '../_actions/transferContract.actions';

import { TOrder } from 'Models';
import { log } from '../utils/helpers';

export type TransferContractState = Readonly<{
    error?: string;
    transferContract: TOrder[];
    transferContractResponse: any;
}>;

const initialState = {
    transferContract: [],
    transferContractResponse: undefined,
};

type Actions = actions.TransferContractAction;

const transferContractReducer: Reducer<TransferContractState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.transferGetContractAsync.success): {
            log('payload => ', action.payload);
            return {
                ...state,
                transferContract: action.payload,
                error: undefined,
            };
        }
        case getType(actions.transferGetContractAsync.request):
        case getType(actions.transferContractAsync.request):
            return {
                ...state,
                error: '',
                transferContractResponse: '',
            };
        case getType(actions.transferContractAsync.success):
            return {
                ...state,
                error: '',
                transferContractResponse: 'SUCCESS',
            };

        case getType(actions.transferGetContractAsync.failure):
            return {
                ...state,
                transferContract: [],
                error: action.payload.code,
            };

        case getType(actions.transferContractAsync.failure): {
            return {
                ...state,
                error: action.payload.message,
                transferContractResponse: 'ERROR',
            };
        }

        case getType(actions.resetResponse):
            return {
                ...state,
                transferContract: [],
                error: '',
            };

        case getType(actions.resetResponseTransfer):
            return {
                ...state,
                error: '',
                transferContractResponse: '',
                transferContract: [],
            };
        default:
            return { ...state };
    }
};

export default transferContractReducer;
