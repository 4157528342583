import { TAdhoc, TContract, TEPInvoice } from 'Models';
import React, { useState } from 'react';
import {
    EBillingMode,
    ECustomerType,
    ETransactionStatus,
} from '../../utils/enums';
import { getFormattedDate } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.Components.TimelineStatus;

import { Segment, Step, getDataSegment } from './Segment';

interface IProps {
    piece?: TEPInvoice;
    customerType: ECustomerType;
    contract: TContract;
}

const TimelineStatus: React.FC<IProps> = ({
    contract,
    piece,
    customerType,
}) => {
    const [openDetails, setOpen] = useState<'up' | 'down'>('down');

    const { billingMode, contractStatus } = contract;
    const { effectiveStartDate, subscriptionDate } = contract;

    const isPro = Boolean(
        customerType === ECustomerType.PROFESSIONAL &&
            billingMode &&
            billingMode === EBillingMode.CYCLICAL_BILLING,
    );

    const handleOpen = () => setOpen(openDetails === 'up' ? 'down' : 'up');

    const buildDates = (): Array<string | undefined> => {
        const activContract = (() => {
            /*const proce =
                piece &&
                piece.processusList &&
                piece.processusList.find(
                    pr =>
                        pr.nature === 'contractActivation' &&
                        pr.status === 'terminated',
                );

            const tr =
                proce &&
                proce.transactions.find(
                    tr => tr.status === ETransactionStatus.SUCCESS,
                );
                */
            // return tr && tr.dueDate;
            return piece && piece.invoicing_period_end_date;
        })();
        const prepapre = isPro
            ? [subscriptionDate, activContract, effectiveStartDate]
            : [subscriptionDate, activContract, undefined, effectiveStartDate];

        const prepare = prepapre.map(dt => (!!dt ? getFormattedDate(dt) : dt));

        if (!prepare[2]) {
            prepare[2] = 'Activation en cours';
        }
        return prepare;
    };

    const dates = buildDates();

    const renderIndividual = () => {
        const [first, second, third, labels] = getDataSegment(
            contractStatus,
            false,
        );

        const isNextFull = {
            check: second === 'grey' || second === 'red-pending',
            color: second,
        };

        const secondLabel =
            first === 'orange' ? 'Erreur 1er paiement' : '1er paiement reçu';

        return {
            component: (
                <>
                    <Step label="Souscription" date={dates[0]} />
                    <Segment radius="start" color={first} />
                    <Step label={secondLabel} date={dates[1]} />

                    {isNextFull.check ? (
                        <>
                            <Segment color={isNextFull.color} />
                            <Segment borderRadius color={isNextFull.color} />
                        </>
                    ) : (
                        <>
                            <Segment color={second} />
                            <Step label="Activation du contrat" date={''} />
                            <Segment radius="end" color={third} />
                            <Step
                                isLast
                                date={dates[3]}
                                label="Contrat activé"
                            />
                        </>
                    )}
                </>
            ),
            labels,
        };
    };

    const renderProfessional = () => {
        const [first, second, labels] = getDataSegment(contractStatus, true);

        const isNextFull = {
            check: second === 'grey' || second === 'red-pending',
            color: second,
        };
        return {
            component: (
                <>
                    <Step label="Souscription" date={dates[0]} />
                    <Segment radius="start" color={first} />
                    <Step label="Activation du contrat" date={dates[1]} />
                    {isNextFull.check ? (
                        <Segment
                            borderRadius={isNextFull.check}
                            color={isNextFull.color}
                        />
                    ) : (
                        <>
                            <Segment radius="end" color={second} />
                            <Step
                                isLast
                                date={dates[2]}
                                label="Contrat activé"
                            />
                        </>
                    )}
                </>
            ),
            labels,
        };
    };

    const renderByType = () =>
        !isPro ? renderIndividual() : renderProfessional();

    const { component, labels } = renderByType();

    return (
        <section className="timeline-status">
            <div className={`timeline ${isPro ? 'timeline-small' : ''}`}>
                {component}
            </div>
            <div className="timeline-status__details">
                <h4 onClick={handleOpen}>
                    {Wording.details.title}{' '}
                    <i className={`arrow ${openDetails}`}></i>
                </h4>

                {openDetails === 'up' && (
                    <div>
                        <ul className="ul-dates">
                            {labels.map((lb: string, idx: number) => {
                                const index = labels.length - 1 - idx;
                                const item = dates[index];

                                return (
                                    <li
                                        className={`${
                                            idx === 0 ? 'first' : ''
                                        }`}
                                        key={idx}
                                    >
                                        {item ? item : '-'}
                                    </li>
                                );
                            })}
                        </ul>
                        <ul className="ul-labels">
                            {labels.map((lb: string, idx: number) => (
                                <li
                                    key={idx}
                                    className={`${idx === 0 ? 'first' : ''}`}
                                >
                                    {lb}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </section>
    );
};

export default TimelineStatus;
