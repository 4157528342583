import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    ActionType,
    isActionOf,
    createStandardAction,
} from 'typesafe-actions';
import { ReducerError, RootAction, RootState, Services, TError } from 'Types';
import {
    MANAGE_USER_REQUEST,
    MANAGE_USER_SUCCESS,
    MANAGE_USER_FAILURE,
    RESET_RESPONSE,
} from './actionTypes';
import { TResponse } from 'Models';
import { Epic } from 'redux-observable';

export type TManageUserPayload = Partial<{
    mode: string;
    firstName: string;
    lastName: string;
    userName: string;
}>;

const manageUserAsync = createAsyncAction(
    MANAGE_USER_REQUEST,
    MANAGE_USER_SUCCESS,
    MANAGE_USER_FAILURE,
)<TManageUserPayload, TResponse, ReducerError>();

const resetManageUserResponse = createStandardAction(RESET_RESPONSE)();

export type ManageUserAction =
    | ActionType<typeof manageUserAsync>
    | ActionType<typeof resetManageUserResponse>;

const preparePayloadManageUser = (
    payload: TManageUserPayload,
): TManageUserPayload => {
    return { ...payload };
};

const mapManageUser = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadManageUser(action.payload);
    return apiRequest<TResponse>({
        path: '/manageUser',
        method: 'post',
        body: payload,
        isPay: false,
        isSubscription: false,
    }).pipe(
        mergeMap((response: any & TError) => {
            if (
                response.code === 'MISSING_PARAMETER' ||
                response.code === 'NO_RESPONSE_FROM_PARTNER'
            ) {
                return of(
                    manageUserAsync.failure({
                        code: response.code,
                        message: response.description,
                    }),
                );
            }
            return of(manageUserAsync.success(response));
        }),
        catchError(error => {
            return of(
                manageUserAsync.failure({
                    code: error.code,
                    message: error.description,
                }),
            );
        }),
    );
};

const manageUserEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(manageUserAsync.request)),
        mergeMap(action => mapManageUser(action, dependency)),
    );

export {
    manageUserAsync,
    manageUserEpic,
    mapManageUser,
    resetManageUserResponse,
};
