import React, { useEffect } from 'react';
import InputRange, { Range } from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import WordingOption from '../../utils/wording.json';

const Wording = WordingOption.PackagePage;

export type Props = {
    initial: number;
    ranges: number[][];
    maxValue: number;
    onChange: (prices: number[]) => void;
};

const EnergyGauge: React.FC<Props> = ({
    initial,
    ranges,
    maxValue,
    onChange,
}) => {
    useEffect(() => {
        onChange(ranges[initial]);
    }, [ranges]);

    const renderProgressBar = (title: string, idx: any) => {
        const height = ranges[initial][idx];
        const style = {
            height: `${((maxValue - height) * 100) / maxValue}%`,
        };
        return (
            <div className="progress">
                <div className="progress__bar" style={style} />
                <div className="progress__label">
                    <span className="progress__label__value">{title}</span>
                    <span className="progress__label__value">{height} €</span>
                </div>
            </div>
        );
    };

    const renderRangeSlider = () => {
        return (
            <InputRange
                formatLabel={() => ''}
                maxValue={ranges.length - 1}
                minValue={0}
                step={1}
                value={initial}
                onChange={(value: any) => {
                    onChange(ranges[value]);
                }}
            />
        );
    };

    return (
        <div className="energy-gauge">
            <div className="energy-gauge__progress">
                {renderProgressBar(Wording.plan.gauge.summer, 0)}
                {renderProgressBar(Wording.plan.gauge.winter, 1)}
            </div>
            {renderRangeSlider()}
        </div>
    );
};

export default EnergyGauge;
