import React, { FC } from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';

import MailIcon from '../../../../../assets/icons/PDFs/ProofOfPayment/ic-email.png';
import PhoneIcon from '../../../../../assets/icons/PDFs/ProofOfPayment/ic-phone.png';
import FacebookIcon from '../../../../../assets/icons/PDFs/ProofOfPayment/Facebook.png';
import WebIcon from '../../../../../assets/icons/PDFs/ProofOfPayment/Web.png';
import YoutubeIcon from '../../../../../assets/icons/PDFs/ProofOfPayment/Youtube.png';

import { beneficiaryInfo } from '../../wording.json';

import Title from '../titles/BeneficiaryTitle';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        marginTop: 10,
        padding: '10px 0px',
    },

    imgTextContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: '2px 0px',
    },
    phoneText: {
        width: 120,
    },

    img: {
        width: 18,
        height: 18,
        marginRight: 5,
    },
    text: {
        fontSize: 9,
    },

    margin: {
        fontSize: 9,
        padding: '6px 0px',
    },
    moreText: {
        marginTop: 5,
        padding: '5px 0px',
        fontSize: 9,
    },
});

const ImageText: FC<{ src: any; text?: string; elm?: any }> = ({
    src,
    text,

    children,
}) => (
    <View style={styles.imgTextContainer}>
        <Image src={src} style={styles.img} />
        {children && children}
        {text && <Text style={styles.text}>{text}</Text>}
    </View>
);

const BeneficiaryInfo: FC = () => {
    return (
        <View style={styles.container}>
            <Title />

            <ImageText src={MailIcon} text={beneficiaryInfo.email} />
            <ImageText src={PhoneIcon}>
                <View>
                    <Text style={styles.text}>{beneficiaryInfo.phone}</Text>
                    <Text style={{ ...styles.text, ...styles.phoneText }}>
                        {beneficiaryInfo.phoneDescription}
                    </Text>
                </View>
            </ImageText>

            <Text style={styles.moreText}>{beneficiaryInfo.more}</Text>

            <ImageText src={FacebookIcon} text={beneficiaryInfo.facebook} />
            <ImageText src={WebIcon}>
                <View>
                    <Text style={styles.text}>{beneficiaryInfo.url}</Text>
                    <Text style={styles.text}>{beneficiaryInfo.urlfq}</Text>
                </View>
            </ImageText>

            <ImageText src={YoutubeIcon} text={beneficiaryInfo.youtube} />
        </View>
    );
};

export default BeneficiaryInfo;
