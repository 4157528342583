import React from 'react';

import { InputEvent } from 'Types';
import { IFields, ISetting } from './types';
import { CustomInput, CustomSelect, CustomToggle } from '../../components';

import { isValidName } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

const ModificationCoholder: React.FC<{
    fields: IFields;
    setting: ISetting;
    showError: boolean;
    handleChangeCivility(section: string, value: string): void;
    handleInputChange(e: InputEvent, section: string, type: string): void;
    handleResetCoholder(civility?: string, firstName?: string): void;
}> = ({
    fields: {
        contact: { coholderCivility, coholderFirstName },
    },
    setting: { checkCoholder },
    showError,
    handleChangeCivility,
    handleInputChange,
    handleResetCoholder,
}) => {
    return (
        <div className="profile-container__coholder">
            <CustomToggle
                id={Wording.addOwner}
                text={Wording.addOwner}
                checked={checkCoholder}
                handleCheck={() => {
                    handleResetCoholder(coholderCivility, coholderFirstName);
                }}
            />
            {checkCoholder && (
                <div className="profile-container__coholder__content">
                    <div className="profile-container__coholder__content__inputs">
                        <CustomSelect
                            id="civility"
                            defaultValue={
                                coholderCivility ? coholderCivility : 'MR'
                            }
                            options={Wording.civility.values}
                            handleChangeSelect={(e: any) =>
                                handleChangeCivility('contact', e.value)
                            }
                            value={coholderCivility}
                        />
                        <CustomInput
                            placeholder={Wording.coholder.firstName}
                            value={coholderFirstName || ''}
                            onInputChange={(e: InputEvent) =>
                                handleInputChange(
                                    e,
                                    'contact',
                                    'coholderFirstName',
                                )
                            }
                            error={
                                showError && !isValidName(coholderFirstName)
                                    ? Wording.error.contact.firstName
                                    : undefined
                            }
                        />
                    </div>
                    <div className="profile-container__coholder__content__right" />
                </div>
            )}
        </div>
    );
};

export default ModificationCoholder;
