import React, { useState } from 'react';
import { CustomModal, CustomButton } from '../../../components';
import WordingConstant from '../../../utils/wording.json';

const Wording = WordingConstant.CustomerContractContainer;
interface IPayload {
    user?: string;
    password?: string;
    email?: string;
    userId?: string;
    token?: string;
    accessToken?: string;
    error?: string;
    message?: string;
}

type Props = {
    email: string;
    resetPasswordDispatch(props: IPayload): void;
};

const ResetPasswordButton: React.FC<Props> = ({
    resetPasswordDispatch,
    email,
}) => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const renderModal = () => {
        return (
            <CustomModal show={isOpen}>
                <span
                    className="btn-close"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    {'X'}
                </span>
                <div className="reset-modal">
                    <p className="warning-title">
                        {Wording.warningMessageTitle}
                    </p>
                    <p>{Wording.message}</p>
                    <p className="reponse">{Wording.confMessage}</p>

                    <div className="reset-modal__buttons">
                        <CustomButton
                            onClick={() => setOpen(false)}
                            color="orange"
                            title="Retour"
                        />
                        <CustomButton
                            color="gray"
                            title="Continuer"
                            onClick={() => {
                                resetPasswordDispatch({
                                    email,
                                });
                            }}
                        />
                    </div>
                </div>
            </CustomModal>
        );
    };

    return (
        <div className="reset-password">
            <p
                onClick={() => {
                    setOpen(true);
                }}
            >
                {Wording.labelTitle}
            </p>
            {renderModal()}
        </div>
    );
};

export default ResetPasswordButton;
