import React, { useRef, useEffect, useState } from 'react';
import { Chart, ChartOptions, ChartDataSets } from 'chart.js';
import '../../utils/chart';
import CustomToggle from '../CustomToggle';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ConsumptionPage;

interface IKey {
    [key: string]: string;
}

const plugin = {
    afterDraw: (
        chartInstance: Chart,
        easing: Chart.Easing,
        options?: any,
    ) => {},
};

const color = {
    orange: '#ffb40a',
    gray: '#b1b1b1',
    black: '#000000',
    grayLight: '#dddddb',
};

const options = (isDetailed: boolean): ChartOptions => ({
    responsive: true,

    legend: {
        display: false,
    },
    scales: {
        yAxes: [
            {
                stacked: isDetailed,

                gridLines: {
                    lineWidth: 4,
                },
                ticks: {
                    fontSize: 20,
                    beginAtZero: true,
                },
            },
        ],
        xAxes: [
            {
                stacked: false,
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontSize: 20,
                },
            },
        ],
    },
    tooltips: {
        backgroundColor: '#FFF',
        titleFontSize: 28,
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 22,
        xPadding: 10,
        mode: 'label',
        callbacks: {
            label: (tooltipItem, data) => {
                const label = data.datasets
                    ? data.datasets[tooltipItem.datasetIndex as number].label ||
                      ''
                    : '';

                return `${label}: ${Math.round(
                    (tooltipItem.yLabel as number) * 100,
                ) / 100} ${Wording.chart.legend.afterLabel}`;
            },

            title: (tooltipItem, data) => {
                const currentMonth = (data.labels as string[])[
                    tooltipItem[0].index as number
                ];

                return (Wording.chart.monthsMapping as IKey)[currentMonth];
            },
        },
    },
});

type Props = {
    packages: number[];
    consumptions: number[];
    highs: number[];
    lows: number[];
    totalhours: number[];
    labels: string[];
};

const StackedBar = ({
    packages,
    consumptions,
    highs,
    lows,
    totalhours,
    labels,
}: Props) => {
    const canvasRef = useRef(null);
    const [detailed, setDetailed] = useState<boolean>(false);
    const [chart, setChart] = useState<Chart | null>(null);

    const detailData: ChartDataSets[] = [
        {
            label: Wording.chart.legend.package,
            backgroundColor: color.gray,
            borderWidth: 1,
            barThickness: 18,
            data: packages,
            stack: '1',
        },
        {
            label: Wording.chart.legend.low,
            backgroundColor: color.black,
            barThickness: 18,
            borderWidth: 1,
            data: lows,
            stack: '2',
            borderSkipped: 'top',
        },
        {
            label: Wording.chart.legend.high,
            backgroundColor: color.orange,
            barThickness: 18,
            borderWidth: 1,
            data: highs,
            stack: '2',
        },
        {
            label: Wording.chart.legend.totalhour,
            backgroundColor: color.grayLight,
            barThickness: 18,
            borderWidth: 1,
            data: totalhours,
            stack: '1',
        },
    ];

    const normalData: ChartDataSets[] = [
        {
            label: Wording.chart.legend.package,
            backgroundColor: color.gray,
            borderWidth: 1,
            barThickness: 18,
            data: packages,
        },
        {
            label: Wording.chart.legend.consumption,
            backgroundColor: color.orange,
            borderWidth: 1,
            barThickness: 18,
            data: consumptions,
        },
    ];

    useEffect(() => {
        if (canvasRef && canvasRef.current) {
            if (chart) {
                if (chart.data.datasets) {
                    chart.data.datasets = detailed ? detailData : normalData;
                    chart.data.labels = labels;
                    chart.options = options(detailed);

                    chart.update();
                    (document.getElementById(
                        'chartjsLegend',
                    ) as any).innerHTML = chart.generateLegend();
                }
            } else {
                const ctx = (canvasRef.current as any).getContext('2d');
                const data = {
                    labels,
                    datasets: normalData,
                };

                const c = new Chart(ctx, {
                    data,

                    options: options(detailed),
                    plugins: [plugin, plugin],
                    type: 'roundedBar',
                });
                setChart(c);
                (document.getElementById(
                    'chartjsLegend',
                ) as any).innerHTML = c.generateLegend();
            }
        }
    }, [detailed, consumptions]);

    return (
        <div className="stacked-bar">
            <h3>{Wording.chart.title}</h3>
            <div className="stacked-bar__container">
                <div className="stacked-bar__legend">
                    <CustomToggle
                        id="toggleChart"
                        text={Wording.chart.toggleButtonText}
                        handleCheck={() => setDetailed(!detailed)}
                    />
                    <div id="chartjsLegend" className="chartjsLegend" />
                </div>
                <canvas ref={canvasRef} />
            </div>
        </div>
    );
};

export default StackedBar;
