import React from 'react';

import { InputEvent, KeyboardHandler } from 'Types';
import { CustomInput, CustomButton } from '../../components';
import resources from '../../utils/resources';
import { isValidEmail } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
import CustomInputNewGen from '../../components/CustomInput/CustomInputNewGen';
import CustomButtonNewGen from '../../components/CustomButton/CustomButtonNewGen';
import Footer from '../../components/FooterAuth/FooterAuth';
import SlidingMenu from '../../components/SlidingMenu';

const Wording = WordingConstant.AuthPage;

type Props = {
    fields: IFields;
    responseMessage?: {
        msg?: string;
        errorMsg?: string;
    };
    toggle: {
        passForgot: boolean;
    };
    handleToggle: any;
    isSlidingMenuOpen: boolean;
    handleInputChange(e: InputEvent, type: string): void;
    handleInputError(): boolean;
    handleButtonLogin(): void;
    handleButtonPassForgot(): void;
    handleSlidingMenu(): void;
};

export interface IFields {
    login: string;
    password: string;
    email: string;
    errorLogin?: string;
    errorPassword?: string;
    errorEmail?: string;

    [key: string]: string | undefined;
}

const AuthContainer: React.FC<Props> = ({
    fields,
    toggle,
    responseMessage,
    handleInputChange,
    handleButtonLogin,
    handleButtonPassForgot,
    handleInputError,
    handleToggle,
    isSlidingMenuOpen,
    handleSlidingMenu,
}) => {
    const isEmail = isValidEmail(fields.email);

    const renderForgotPassword = () => (
        <div className="auth-page__inner__password-forgot">
            <img
                src={resources['ic-wekiwi-new']}
                className="auth-page__inner__password-forgot__logo"
                alt="logo-login"
            />
            <h1>
                {Wording.passForgot.title}{' '}
                <span>{Wording.passForgot.title2}</span>
            </h1>
            <h3 className={'bold'}>{Wording.passForgot.subTitle}</h3>
            <h3>{Wording.passForgot.subTitle2}</h3>
            <CustomInputNewGen
                value={fields.email}
                placeholder={Wording.passForgot.email}
                genericError={responseMessage && responseMessage.errorMsg}
                onInputChange={(e: InputEvent) => handleInputChange(e, 'email')}
            />

            {responseMessage && responseMessage.errorMsg && (
                <p className="auth-page__inner__password-forgot__error">
                    {responseMessage.errorMsg}
                </p>
            )}

            {responseMessage && responseMessage.msg && (
                <p className="auth-page__inner__password-forgot__success">
                    {responseMessage.msg}
                </p>
            )}
            <div className="auth-page__inner__password-forgot__buttons">
                <CustomButtonNewGen
                    title={Wording.cancelButton}
                    color="inherit"
                    onClick={() => handleToggle()}
                />
                <CustomButtonNewGen
                    title={Wording.passForgot.validButton}
                    disabled={!isEmail}
                    color={!isEmail ? 'gray' : 'purple'}
                    onClick={() => {
                        if (handleInputError()) {
                            handleButtonPassForgot();
                        }
                    }}
                />
            </div>
        </div>
    );

    const handleEnterPressed = (event: KeyboardHandler) => {
        const code = event.keyCode || event.which;
        if (code === 13 && handleInputError()) {
            // 13 is the enter keycode
            handleButtonLogin();
        }
    };

    const renderConnexion = () => {
        const errorLoginPassword =
            fields.login.length < 6 || fields.password.length < 6;

        return (
            <div className="auth-page__inner__connection">
                <img
                    src={resources['ic-wekiwi-new']}
                    className="auth-page__inner__connection__logo"
                    alt="logo-login"
                />
                <div className={'auth-page__inner__connection__title'}>
                    <span>{Wording.title}</span>
                    <span className={'bold'}>{Wording.title2}</span>
                </div>
                <CustomInputNewGen
                    value={fields.login}
                    error={fields.errorLogin}
                    genericError={responseMessage && responseMessage.errorMsg}
                    placeholder={Wording.login}
                    onInputChange={(e: InputEvent) =>
                        handleInputChange(e, 'login')
                    }
                    onKeyPress={handleEnterPressed}
                />
                <CustomInputNewGen
                    value={fields.password}
                    error={fields.errorPassword}
                    placeholder={Wording.pass}
                    genericError={responseMessage && responseMessage.errorMsg}
                    type="password"
                    onInputChange={(e: InputEvent) =>
                        handleInputChange(e, 'password')
                    }
                    onKeyPress={handleEnterPressed}
                />

                {responseMessage && responseMessage.errorMsg && (
                    <p>{responseMessage.errorMsg}</p>
                )}

                <CustomButtonNewGen
                    title={Wording.button}
                    color={errorLoginPassword ? 'purple' : 'purple'}
                    onClick={() => {
                        if (handleInputError()) {
                            handleButtonLogin();
                        }
                    }}
                />

                <a onClick={() => handleToggle()} href="#">
                    {Wording.passForgotLoginPage}
                </a>
            </div>
        );
    };

    return (
        <div className="auth-page">
            <SlidingMenu
                isOpen={isSlidingMenuOpen}
                setIsOpen={handleSlidingMenu}
            />
            <img
                src={resources['ic-new-logo-accueil-background']}
                className="auth-page__logo"
                alt="logo-login"
            />
            <div className="auth-page__inner">
                {!toggle.passForgot
                    ? renderConnexion()
                    : renderForgotPassword()}
            </div>
            <Footer />
        </div>
    );
};

export default AuthContainer;
