import ReactPDF, { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';

export type TableCellProps = {
    weighting?: number;

    style?: ReactPDF.Style;

    textAlign?: 'left' | 'center' | 'right';

    isHeader?: boolean;

    fontSize?: number | string;
};

export const Cell: FC<TableCellProps> = props => {
    let content: any;

    if (typeof props.children === 'string') {
        content = <Text>{props.children}</Text>;
    } else if (typeof props.children === 'number') {
        content = <Text>{props.children.toString()}</Text>;
    } else {
        content = props.children;
    }

    const defaultStyle: ReactPDF.Style = {
        flex: props.weighting || 1,
        // @ts-ignore
        justifyContent: 'stretch',
        textAlign: props.textAlign || 'left',
        fontSize: props.fontSize || (props.isHeader === true ? 14 : 12),
        borderRight: '1pt solid black',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    };

    const mergedStyles: ReactPDF.Style[] = [defaultStyle, props.style || {}];
    return (
        <View style={mergedStyles} wrap={true}>
            {content}
        </View>
    );
};

export default Cell;
