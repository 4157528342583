import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { InputEvent, KeyboardHandler } from 'Types';
import { CustomInput, CustomButton } from '../../components';
import resources from '../../utils/resources';

import WordingConstant from '../../utils/wording.json';
import AuthService from '../../services/auth-service';
import SlidingMenu from '../../components/SlidingMenu';
import FooterAuth from '../../components/FooterAuth';
import CustomInputNewGen from '../../components/CustomInput/CustomInputNewGen';
import CustomButtonNewGen from '../../components/CustomButton/CustomButtonNewGen';
const Wording = WordingConstant.ResetPasswordPage;

type IProps = {
    fields: IFields;
    responseMessages?: {
        errorMsg?: string;
        msg?: string;
    };
    toggle: {
        passForgot: boolean;
    };
    handleToggle: any;
    isSlidingMenuOpen: boolean;
    handleInputChange(e: InputEvent, type: string): void;
    handleInputError(): boolean;
    handleButtonLogin(): any;
    handleSlidingMenu(): void;
};
type Props = IProps & RouteComponentProps<any>;

export interface IFields {
    login: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
    email: string;
    errorLogin?: string;
    errorPassword?: string | undefined;
    errorEmail?: string;
    [key: string]: string | undefined;
}

const PasswordContainer: React.FC<Props> = ({
    fields,
    toggle,
    responseMessages,
    handleInputChange,
    handleButtonLogin,
    handleInputError,
    handleToggle,
    history,
    isSlidingMenuOpen,
    handleSlidingMenu,
}) => {
    const renderForgotPassword = () => (
        <div className="auth-page__inner_password__password-forgot">
            <h1>{Wording.passForgot.title}</h1>
            <h2>{Wording.passForgot.subTitle}</h2>

            <p>{Wording.passForgot.email}</p>
            <CustomInput
                value={fields.email}
                onInputChange={(e: InputEvent) => handleInputChange(e, 'email')}
            />
        </div>
    );

    const handleEnterPressed = (event: KeyboardHandler) => {
        const code = event.keyCode || event.which;
        if (code === 13 && handleInputError()) {
            // 13 is the enter keycode
            if (
                (fields.newPassword !== '' || fields.confirmPassword !== '') &&
                fields.newPassword !== fields.confirmPassword
            ) {
                return;
            }
            handleButtonLogin();
        }
    };

    const handleGoBack = (e: MouseEvent) => {
        e.preventDefault();
        AuthService.logout();
        history.push('/');
    };

    const handleValidate = () => {
        if (handleInputError()) handleButtonLogin();
    };

    const formatErrorMessage = () => {
        const regex = new RegExp(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g,
        );
        if (!fields.newPassword) {
            return '';
        }
        if (fields.newPassword.length < 8) {
            return 'Le mot de passe doit être de 8 caractères minimum.';
        }
        if (!regex.test(fields.newPassword)) {
            return 'Le mot de passe doit contenir une majuscule, une minuscule et un caractère spécial (@$!%*#?&)';
        }
        if (fields.newPassword !== fields.confirmPassword) {
            return 'Le mot de passe de confirmation doit être le même que celui ci.';
        }
        return undefined;
    };

    const renderConnexion = () => {
        const errorForm = formatErrorMessage();
        return (
            <div className="auth-page__inner_password__reset-password">
                <img
                    src={resources['ic-wekiwi-new']}
                    className="auth-page__inner_password__reset-password__logo"
                    alt="logo-login"
                />
                <div
                    className={
                        'auth-page__inner_password__reset-password__title'
                    }
                >
                    <span>{Wording.title}</span>
                    <span className={'bold'}>{'Mot de passe'}</span>
                </div>
                <h6>{Wording.login}</h6>
                <CustomInputNewGen
                    value={fields.login}
                    error={fields.errorLogin}
                    onInputChange={(e: InputEvent) =>
                        handleInputChange(e, 'login')
                    }
                    placeholder={Wording.login}
                    onKeyPress={handleEnterPressed}
                />
                <h6 className="pass">{Wording.pass}</h6>
                <CustomInputNewGen
                    value={fields.password}
                    error={fields.errorPassword}
                    type="password"
                    placeholder={Wording.pass}
                    onInputChange={(e: InputEvent) =>
                        handleInputChange(e, 'password')
                    }
                    onKeyPress={handleEnterPressed}
                />
                <h6 className="pass">{Wording.NewPass}</h6>
                <CustomInputNewGen
                    value={fields.newPassword}
                    error={errorForm}
                    placeholder={Wording.NewPass}
                    type="password"
                    onInputChange={(e: InputEvent) =>
                        handleInputChange(e, 'newPassword')
                    }
                    onKeyPress={handleEnterPressed}
                />
                <h6 className="pass">{Wording.passValidate}</h6>
                <CustomInputNewGen
                    value={fields.confirmPassword}
                    type="password"
                    placeholder={Wording.passValidate}
                    onInputChange={(e: InputEvent) =>
                        handleInputChange(e, 'confirmPassword')
                    }
                    onKeyPress={handleEnterPressed}
                />
                {responseMessages && responseMessages.errorMsg && (
                    <p className="error-message">{responseMessages.errorMsg}</p>
                )}
                {responseMessages && responseMessages.msg && (
                    <p className="success-message">{responseMessages.msg}</p>
                )}

                <div className="buttonContainers">
                    <CustomButtonNewGen
                        title={Wording.backButton}
                        color={'inherit'}
                        onClick={handleGoBack}
                    />

                    <CustomButtonNewGen
                        title={Wording.button}
                        disabled={
                            !!(responseMessages && responseMessages.msg) ||
                            !!errorForm
                        }
                        color={
                            Boolean(errorForm || !fields.newPassword)
                                ? 'gray'
                                : 'purple'
                        }
                        onClick={() => handleValidate()}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="auth-page">
            <SlidingMenu
                isOpen={isSlidingMenuOpen}
                setIsOpen={handleSlidingMenu}
            />
            <img
                src={resources['ic-new-logo-accueil-background']}
                className="auth-page__logo"
                alt="logo-login"
            />
            <div className="auth-page__inner_password">
                {!toggle.passForgot
                    ? renderConnexion()
                    : renderForgotPassword()}
            </div>
            <FooterAuth />
        </div>
    );
};

export default withRouter(PasswordContainer);
