import React, { useState, useEffect } from 'react';
import { InputEvent, SelectOption, TSchemaProps } from 'Types';
import { TResponse } from 'Models';

import WordingConstant from '../../../utils/wording.json';
import {
    CustomInput,
    CustomButton,
    CustomSelect,
    CustomModal,
    CustomRadio,
    Group,
} from '../../../components';

import {
    isValidName,
    isValidString,
    isValidEmail,
    isEmptySelectOption,
    getSelectOptionValue,
    formatFirstNameString,
} from '../../../utils/helpers';
import { EResponseCode } from '../../../utils/enums';
import _ from 'lodash';

interface IPayloadUser {
    group: string;
    firstName: string;
    lastName: string;
    email: string;
    civility: string;
    userName?: string;
}

import accents from 'remove-accents';

const Wording = WordingConstant.UserAdministrationResult;

type Props = {
    retryFirstPaymentResponse: TResponse;
    resetRetryFirstPaymentResponse: () => void;
};

function getFirstNameError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    } else if (!isValidName(value, true)) {
        error = Wording.CreateUserModal.errors.lastNameValidity;
    }
    return error;
}

function getLastNameError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    } else if (!isValidName(value, true)) {
        error = Wording.CreateUserModal.errors.lastNameValidity;
    }
    return error;
}

function getMailAddressError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    } else if (!isValidEmail(value)) {
        error = Wording.CreateUserModal.errors.mailAddressValidity;
    }
    return error;
}

function getCivilityError(value: string | SelectOption<any>): string {
    let error = '';
    if (!isEmptySelectOption(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    }
    return error;
}

function getGroupError(value: string | SelectOption<any>): string {
    let error = '';
    if (!isEmptySelectOption(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    }
    return error;
}

function getUserNameError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    } else if (!isValidName(value, true)) {
        error = Wording.CreateUserModal.errors.userNameValidity;
    }
    return error;
}

function getClientNumberError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.clientNumberValidity;
    }
    return error;
}

const RetryFirstPaymentModal: React.FC<Props> = ({
    retryFirstPaymentResponse,
    resetRetryFirstPaymentResponse,
}) => {

    const { code, message } = retryFirstPaymentResponse;

    const elementMap = {

        btn_hide_modal: {
            title: Wording.hideModal,
            color: 'gray',
            small: true,
            onClick: () => {
                resetRetryFirstPaymentResponse();
            },
        },
    };

    const createUsermodalResponse = () => {
        const { code, message } = retryFirstPaymentResponse;

        return (
            <CustomModal
                show={code && message !== Wording.errorMessage ? true : false}
            >
                <span
                    className="btn-close"
                    onClick={() => {
                        resetRetryFirstPaymentResponse();
                    }}
                >
                    {'X'}
                </span>
                <div className="modal-response">
                    <p className="code">
                        {code === EResponseCode.SUCCESS
                            ? Wording.CreateGroupModal.succesMessage
                            : Wording.CreateGroupModal.failureMessage}
                    </p>

                    <p className="message">{message}</p>

                    <div className="search-rs-vl-btn">
                        <CustomButton {...elementMap.btn_hide_modal} />
                    </div>
                </div>
            </CustomModal>
        );
    };

    return (
        <>
            {code &&
                message !== Wording.errorMessage &&
                createUsermodalResponse()}
        </>
    );
};

export default RetryFirstPaymentModal;
