import React, { useState } from 'react';
import { CustomModal, CustomButton } from '../../../components';
import WordingConstant from '../../../utils/wording.json';
import { EResponseCode, EMode } from '../../../utils/enums';
import { TResponse } from 'Models';

import _ from 'lodash';

const Wording = WordingConstant.UserAdministrationResult;

interface IPayloadManageUser {
    mode: string;
    firstName: string;
    lastName: string;
    userName: string;
}

type Props = {
    manageUser: (props: IPayloadManageUser) => void;
    original: any;
    setAdminSuccessModal: (porps: boolean) => void;
    resetAdminClientSpaceResponse: () => void;
    resetManageUserResponse: () => void;
    manageUserResponse: TResponse;
    isAdminSpace: boolean;
};

const RemoveUserButton: React.FC<Props> = ({
    manageUser,
    original,
    setAdminSuccessModal,
    resetAdminClientSpaceResponse,
    manageUserResponse,
    resetManageUserResponse,
    isAdminSpace,
}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const { code, message } = manageUserResponse;

    const renderModal = () => {
        return (
            <div>
                <CustomModal show={isOpen}>
                    <span
                        className="btn-close"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        {'X'}
                    </span>
                    <div className="remove-user-modal">
                        <p className="warning-title">
                            {Wording.RemoveUser.warningTitle}
                        </p>
                        <p>{Wording.RemoveUser.message}</p>
                        <p className="response">
                            {Wording.RemoveUser.question}
                        </p>

                        <div className="remove-user-modal__buttons">
                            <CustomButton
                                onClick={() => {
                                    setOpen(false);
                                    setAdminSuccessModal(true);
                                }}
                                color="orange"
                                title="Retour"
                            />
                            <CustomButton
                                color="gray"
                                title="Continuer"
                                onClick={() => {
                                    if (isAdminSpace) {
                                        resetAdminClientSpaceResponse();
                                        setAdminSuccessModal(false);
                                    }
                                    setOpen(false);
                                    manageUser({
                                        mode: EMode.DELETE,
                                        firstName: _.get(
                                            original.original,
                                            'firstName',
                                            '',
                                        ),
                                        lastName: _.get(
                                            original.original,
                                            'lastName',
                                            '',
                                        ),
                                        userName: _.get(
                                            original.original,
                                            'userName',
                                            '',
                                        ),
                                    });
                                }}
                            />
                        </div>
                    </div>
                </CustomModal>
            </div>
        );
    };

    const deleteUserModal = () => {
        return (
            <CustomModal
                show={code && message !== Wording.errorMessage ? true : false}
            >
                <span
                    className="btn-close"
                    onClick={() => {
                        resetManageUserResponse();
                    }}
                >
                    {'X'}
                </span>
                <div className="enable-user-modal-response">
                    <p className="code">
                        {code === EResponseCode.SUCCESS
                            ? Wording.CreateGroupModal.succesMessage
                            : Wording.CreateGroupModal.failureMessage}
                    </p>

                    <p className="message">{message}</p>

                    <div className="search-rs-vl-btn">
                        <CustomButton
                            title={'Fermer'}
                            color={'orange'}
                            onClick={() => {
                                resetManageUserResponse();
                            }}
                        />
                    </div>
                </div>
            </CustomModal>
        );
    };

    return (
        <div className="remove-user">
            <p
                onClick={() => {
                    setOpen(true);
                }}
            >
                {Wording.RemoveUser.removeUser}
            </p>
            {renderModal()}
            {code && message && deleteUserModal()}
        </div>
    );
};

export default RemoveUserButton;
