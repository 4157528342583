import React from 'react';
import filter from 'lodash/filter';

import CustomSelect from '../../CustomSelect';
import { IProps } from '../../CustomSelect/CustomSelect';
import { TSchemaProps, SelectOption } from 'Types';

import {
    isFullAuthorization,
    isLimitedAuthorization,
    isMixedAuthorization,
    getInitialGroup,
    addSelectAllToSelect,
} from '../../../utils/helpers';
import { isArray } from 'lodash';

export interface IGroupProps extends IProps {
    withSelectAll?: boolean;
    schemaProps: TSchemaProps;
}

export type GroupOptions = Array<SelectOption<string>>;

const Group: React.FC<IGroupProps> = ({ options, schemaProps, ...props }) => {
    let newOption: GroupOptions = [];
    const propsSelect = { ...props, placeholder: ' ' };

    if (isFullAuthorization(schemaProps)) {
        newOption = options as GroupOptions;
    }

    if (isLimitedAuthorization(schemaProps)) {
        propsSelect.disabled = true;
        propsSelect.isMulti = false;
    }

    if (isMixedAuthorization(schemaProps)) {
        newOption = filter(
            options,
            ({ value }: SelectOption<string>) =>
                getInitialGroup(value) ===
                getInitialGroup(schemaProps.currentGroup),
        ) as GroupOptions;
    }

    if (propsSelect.withSelectAll) {
        newOption = addSelectAllToSelect(newOption);
    }

    return (
        <CustomSelect
            options={newOption}
            {...propsSelect}
            handleChangeSelect={(values: any) => {
                if (
                    isArray(values) &&
                    values[0] &&
                    values[0].value === 'select-all'
                ) {
                    propsSelect.handleChangeSelect(newOption);
                    return;
                }
                propsSelect.handleChangeSelect(values);
            }}
            value={
                propsSelect.value &&
                propsSelect.value.length === newOption.length
                    ? newOption[0]
                    : propsSelect.value
            }
        />
    );
};

export default Group;
