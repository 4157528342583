import resources from '../../utils/resources';
import React from 'react';
import WordingConstant from '../../utils/wording.json';
import CustomButtonNewGen from '../CustomButton/CustomButtonNewGen';
import Config from '../../utils/config';

interface ISlidingMenuProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const wording = WordingConstant.SlidingMenu;

enum WordingTitle {
    why = 'Pourquoi',
    individual = 'Particuliers',
    pro = 'Professionnels',
    green = 'Électricité verte',
    solar = 'Solar',
    blog = 'Blog',
}

const SlidingMenu = (props: ISlidingMenuProps) => {
    const { isOpen, setIsOpen } = props;
    const [isOpenIndividual, setIsOpenIndividual] = React.useState(false);

    return (
        <div className={`sliding-menu${isOpen ? ' visible' : ' hidden'}`}>
            <img
                src={resources['ic-arrow-open-menu']}
                onClick={() => {
                    setIsOpen(!isOpen);
                    setIsOpenIndividual(false);
                }}
                className={`sliding-menu__img${isOpen ? ' open-img' : ''}`}
                alt={'image open menu'}
            />
            <img
                className={'sliding-menu__logo'}
                src={resources['ic-wekiwi-new']}
                alt="logo-login"
                onClick={() => {
                    window.location.href = Config.HOME_PAGE_LINK as string;
                }}
            />
            {wording.data.map((item, index) => {
                if (
                    item.title.toLowerCase() ===
                    WordingTitle.individual.toLowerCase()
                ) {
                    return (
                        <>
                            <div
                                className={`${
                                    isOpenIndividual ? 'individualOpen' : ''
                                } sliding-menu__individual`}
                                onClick={() =>
                                    setIsOpenIndividual(!isOpenIndividual)
                                }
                                key={index}
                                tabIndex={0}
                                role={'button'}
                            >
                                {item.title}
                                <img
                                    className={isOpenIndividual ? 'open' : ''}
                                    src={resources['ic-arrow-down']}
                                    alt={'flèche vers le bas'}
                                />
                            </div>
                            <div
                                className={`sliding-menu__submenu${
                                    isOpenIndividual ? ' full' : ''
                                }`}
                                key={index}
                            >
                                {item.subMenu!.map((subItem, subIndex) => (
                                    <a
                                        key={subIndex}
                                        href={subItem.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {subItem.title}
                                    </a>
                                ))}
                            </div>
                        </>
                    );
                }
                return (
                    <div className={'sliding-menu__content'} key={index}>
                        <a href={item.link} target={'_blank'}>
                            {item.title}
                        </a>
                    </div>
                );
            })}
            <CustomButtonNewGen
                title={wording.button}
                color={'purple'}
                onClick={() => {
                    window.location.href = Config.SUBSCRIPTION_LINK as string;
                }}
                arrowRight={true}
            />
            <div className={'sliding-menu__footer'}>
                <p className={'sliding-menu__footer__title'}>
                    {wording.footer.title}
                </p>
                <p className={'sliding-menu__footer__subtitle'}>
                    {wording.footer.countries.map((item, index) => {
                        return (
                            <a
                                key={index}
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {index !== wording.footer.countries.length - 1
                                    ? `${item.title} - `
                                    : item.title}
                            </a>
                        );
                    })}
                </p>
            </div>
        </div>
    );
};

export default SlidingMenu;
