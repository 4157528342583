import React from 'react';

import ErrorScreen from './../../../components/ErrorScreen';

import WordingConstant from '../../../utils/wording.json';
const Wording = WordingConstant.NotFoundPage;

export type Props = {};

const NotFoundContainer: React.FC<Props> = props => {
    return <ErrorScreen details={Wording.details} message={Wording.message} />;
};

export default NotFoundContainer;
