import React, { useState, useEffect } from 'react';
import { CustomModal, CustomButton } from '../../../components';
import _ from 'lodash';
import { EResponseCode, EMode } from '../../../utils/enums';
import WordingConstant from '../../../utils/wording.json';
import { TResponse } from 'Models';
const Wording = WordingConstant.UserAdministrationResult;

interface IPayloadManageUser {
    mode: string;
    firstName: string;
    lastName: string;
    userName: string;
}

type Props = {
    status: string;
    manageUser: (props: IPayloadManageUser) => void;
    setAdminSuccessModal: (porps: boolean) => void;
    resetManageUserResponse: () => void;
    original: any;
    isAdminSpace: boolean;
    manageUserResponse: TResponse;
    resetAdminClientSpaceResponse: () => void;
};

const EnableOrDesableuserButton: React.FC<Props> = ({
    status,
    manageUser,
    original,
    manageUserResponse,
    resetManageUserResponse,
    resetAdminClientSpaceResponse,
    setAdminSuccessModal,
    isAdminSpace,
}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const { code, message } = manageUserResponse;

    const enableOrDesableUserModal = () => {
        return (
            <CustomModal
                show={code && message !== Wording.errorMessage ? true : false}
            >
                <span
                    className="btn-close"
                    onClick={() => {
                        resetManageUserResponse();
                    }}
                >
                    {'X'}
                </span>
                <div className="enable-user-modal-response">
                    <p className="code">
                        {code === EResponseCode.SUCCESS
                            ? Wording.CreateGroupModal.succesMessage
                            : Wording.CreateGroupModal.failureMessage}
                    </p>

                    <p className="message">{message}</p>

                    <div className="search-rs-vl-btn">
                        <CustomButton
                            title={'Fermer'}
                            color={'orange'}
                            onClick={() => {
                                resetManageUserResponse();
                            }}
                        />
                    </div>
                </div>
            </CustomModal>
        );
    };

    const renderModal = () => {
        return (
            <div>
                <CustomModal show={isOpen}>
                    <span
                        className="btn-close"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        {'X'}
                    </span>
                    {status === 'ENABLED' ? (
                        <div className="desable-user">
                            <p className="warning-title">
                                {Wording.DesableUser.warningTitle}
                            </p>
                            <p className="message">
                                {Wording.DesableUser.message}
                            </p>
                            <p className="question">
                                {Wording.DesableUser.question}
                            </p>

                            <div className="desable-user__buttons">
                                <CustomButton
                                    onClick={() => setOpen(false)}
                                    color="orange"
                                    title="Retour"
                                />
                                <CustomButton
                                    color="gray"
                                    title="Continuer"
                                    onClick={() => {
                                        if (isAdminSpace) {
                                            resetAdminClientSpaceResponse();
                                            setAdminSuccessModal(false);
                                        }
                                        setOpen(false);
                                        manageUser({
                                            mode: EMode.DISABLE,
                                            firstName: _.get(
                                                original.original,
                                                'firstName',
                                                '',
                                            ),
                                            lastName: _.get(
                                                original.original,
                                                'lastName',
                                                '',
                                            ),
                                            userName: _.get(
                                                original.original,
                                                'userName',
                                                '',
                                            ),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="enable-user">
                            <p className="warning-title">
                                {Wording.EnableUser.warningTitle}
                            </p>
                            <p className="message">
                                {Wording.EnableUser.message}
                            </p>
                            <p className="question">
                                {Wording.EnableUser.question}
                            </p>

                            <div className="enable-user__buttons">
                                <CustomButton
                                    onClick={() => setOpen(false)}
                                    color="orange"
                                    title="Retour"
                                />

                                <CustomButton
                                    color="gray"
                                    title="Continuer"
                                    onClick={() => {
                                        if (isAdminSpace) {
                                            resetAdminClientSpaceResponse();
                                            setAdminSuccessModal(false);
                                        }
                                        setOpen(false);
                                        manageUser({
                                            mode: EMode.ENABLE,
                                            firstName: _.get(
                                                original.original,
                                                'firstName',
                                                '',
                                            ),
                                            lastName: _.get(
                                                original.original,
                                                'lastName',
                                                '',
                                            ),
                                            userName: _.get(
                                                original.original,
                                                'userName',
                                                '',
                                            ),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </CustomModal>
            </div>
        );
    };

    return (
        <div className="enable-or-desable">
            {status === 'ENABLED' ? (
                <p
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    {Wording.RemoveUser.desableUser}
                </p>
            ) : (
                <p
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    {Wording.RemoveUser.enableUser}
                </p>
            )}
            {renderModal()}
            {/*code && message && enableOrDesableUserModal()*/}
        </div>
    );
};

export default EnableOrDesableuserButton;
