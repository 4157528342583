import React, { Fragment } from 'react';
import _ from 'lodash';
import 'react-day-picker/lib/style.css';
import { addDays } from 'date-fns';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { CustomSelect } from '../..';
import WordingConstant from '../../../utils/wording.json';
import {
    checkIsDisableSwitchSelect,
    getFormattedDate,
    prepareEffectiveStartDate,
} from '../../../utils/helpers';
import config from '../../../utils/config';
import { switchingSupplierOptions } from '../../../containers/admin/AdminSubscriptionContainer/sections/SituationSection/SituationSection';

const Wording = WordingConstant.Components.DateContainer;

const modifiersStyles = {
    highlighted: {
        color: 'white',
        backgroundColor: '#ffb40a',
    },

    outside: { color: '#8954ba', backgroundColor: 'white' },
};

class DateContainer extends React.Component {
    constructor(props) {
        super(props);
        const { dateContract } = props;
        this.state = {
            setting: {
                EL: {
                    hideDiv: false,
                    showSelect:
                        dateContract.EL && dateContract.EL.timeslot !== '',
                },
                NG: {
                    hideDiv: false,
                    showSelect:
                        dateContract.NG && dateContract.NG.timeslot !== '',
                },
            },
            isLockedSituationSelect: false,
            selectedDates: {
                EL: null,
                NG: null,
            },
        };
    }

    componentDidMount() {
        const { channel, handleDropdownContract } = this.props;

        if (checkIsDisableSwitchSelect({ channel })) {
            handleDropdownContract(switchingSupplierOptions[1]);
            this.setState({ isLockedSituationSelect: true });
        }
    }

    componentDidUpdate(prevProps) {
        const { dateContract } = this.props;
        const { setting } = this.state;
        const nextSetting = setting;
        if (prevProps !== this.props) {
            nextSetting.EL.showSelect =
                dateContract.EL && dateContract.EL.timeslot !== '';
            nextSetting.NG.showSelect =
                dateContract.NG && dateContract.NG.timeslot !== '';
            this.setState({ setting: nextSetting });
        }
    }
    renderInfosDiv = energy => {
        const { isSwitch } = this.props;
        return (
            <div
                className={`date-container__date__calendars__${energy}-calendar__infos`}
            >
                <div
                    className={`date-container__date__info date-container__date__calendars__${energy}-calendar__infos__options`}
                >
                    <div
                        className={`date-container__date__info date-container__date__calendars__${energy}-calendar__infos__circle_1`}
                    />
                    <p>{Wording.contractDate.selectedDate}</p>
                </div>

                {!isSwitch && (
                    <div
                        className={`date-container__date__info date-container__date__calendars__${energy}-calendar__infos__options`}
                    >
                        <div
                            className={`date-container__date__info date-container__date__calendars__${energy}-calendar__infos__circle_2`}
                        />
                        <p> {Wording.contractDate.dateExtraCoat} </p>
                    </div>
                )}
            </div>
        );
    };

    handleChangeBool = (energy, type) => {
        const { setting } = this.state;
        const updatedSetting = {
            ...setting,
            [energy]: {
                ...setting[energy],
                hideDiv: type === 'open',
            },
        };
        this.setState({ setting: updatedSetting });
    };

    handleChangeBoolSelect = energy => {
        const { setting } = this.state;
        const updatedSetting = {
            ...setting,
            [energy]: {
                ...setting[energy],
                showSelect: true,
            },
        };
        this.setState({ setting: updatedSetting });
    };

    renderTextWithCalendar = energy => {
        const { dateContract, isSwitch } = this.props;
        const shouldDisplayCBWarning =
            !isSwitch &&
            !!_.get(dateContract, `${energy}.firstPaymentCB`, false) &&
            !_.get(dateContract, `${energy}.isCyclicalProduct`, true);
        return (
            <div className={'warnings'}>
                {shouldDisplayCBWarning && (
                    <span className={'attention'}>
                        <span className="attention__title">Attention!</span>
                        {Wording.contractDate.attention}
                    </span>
                )}
            </div>
        );
    };

    renderTextSwitchByCalendar = (energy, classN) => {
        const { setting } = this.state;
        const { dropdownContractOptionSelection } = this.props;

        return (
            setting[energy].hideDiv && (
                <React.Fragment>
                    <p className={`${classN} attention-switch`}>
                        {dropdownContractOptionSelection !== null &&
                            dropdownContractOptionSelection.value === 'LATER' &&
                            `${
                                Wording.contractDate.renderDescription
                            } ${this.renderTheoreticalDate(energy)}`}
                    </p>
                </React.Fragment>
            )
        );
    };

    renderTheoreticalDate = energy => {
        const {
            switchType,
            isCyclicalProductGas,
            isCyclicalProductElec,
        } = this.props;
        const { selectedDates } = this.state;

        const currentDate = selectedDates[energy] || new Date();

        console.log(selectedDates);

        const nextDate = prepareEffectiveStartDate(
            currentDate,
            energy,
            switchType,
            isCyclicalProductGas || isCyclicalProductElec,
        );

        return getFormattedDate(nextDate);
    };

    renderTheoreticalDate21Days = energy => {
        const { selectedDates } = this.state;

        const currentDate = selectedDates[energy] || new Date();

        const nextDate = addDays(currentDate, 21);
        return getFormattedDate(nextDate);
    };

    handleDatePicker = (selectedDate, energy) => {
        //const { dateContract } = this.props;

        const updatedSelectedDates = {
            ...this.state.selectedDates,
            [energy]: selectedDate,
        };

        /*        const updatedDateContract = {
            ...dateContract,
            [energy]: {
                ...dateContract[energy],
                date: selectedDate,
            },
        };*/

        this.setState({
            selectedDates: updatedSelectedDates,
        });

        //this.updateDateContract(updatedDateContract);
    };

    renderCalendar = () => {
        const {
            energyTypes,
            handleDatePicker,
            handleDateCalendarOption,
            isSwitch,
            isCyclicalProductElec,
            isCyclicalProductGas,
            dropdownContractOptionSelection,
            channel,
        } = this.props;
        const { setting } = this.state;
        return (
            <div className="date-container__date">
                {energyTypes &&
                    energyTypes.map((energy, index) => {
                        // let currentDate = !!_.get(
                        //     dateContract,
                        //     `${energy}.date`,
                        // )
                        //     ? new Date(
                        //           dateContract[energy].year,
                        //           dateContract[energy].month,
                        //           dateContract[energy].date,
                        //       )
                        //     : undefined;
                        if (
                            config.ChannelsDoorToDoor.includes(channel) &&
                            isSwitch
                        ) {
                            //currentDate = addDays(currentDate, 7)
                        }
                        const renderTitle =
                            isCyclicalProductElec || isCyclicalProductGas
                                ? Wording.contractDate.title
                                : Wording.contractDate[
                                      dropdownContractOptionSelection !==
                                          null &&
                                      dropdownContractOptionSelection.value ===
                                          'LATER'
                                          ? 'titleSwitch'
                                          : 'title'
                                  ];

                        /*const isCyclicalBilling =
                            energy === 'EL'
                                ? isCyclicalProductElec
                                : isCyclicalProductGas;*/

                        return (
                            <>
                                <div
                                    key={index}
                                    className="date-container__date__calendars"
                                >
                                    <div
                                        className={`date-container__date__calendars__${energy}-calendar`}
                                    >
                                        <div
                                            className={`date-container__date__calendars__${energy}-calendar__title`}
                                        >
                                            <span>{renderTitle}</span>
                                            <span>
                                                {Wording.contractDate[energy]}
                                            </span>
                                        </div>

                                        {/*                                        {isCyclicalBilling && isSwitch && (
                                            <div className="cyclical-billing">
                                                <p>
                                                    {
                                                        Wording.contractDate
                                                            .cyclicalBillingText
                                                    }
                                                </p>
                                            </div>
                                        )}*/}

                                        {(isSwitch /*!isCyclicalBilling*/ ||
                                            !isSwitch) && (
                                            <React.Fragment>
                                                <div className="date_container_div">
                                                    {isSwitch &&
                                                        energyTypes.length ===
                                                            2 &&
                                                        energy === 'NG' &&
                                                        this.renderTextSwitchByCalendar(
                                                            energy,
                                                            'right',
                                                        )}
                                                    <DayPickerInput
                                                        placeholder="JJ/MM/AAAA"
                                                        format="DD/MM/YYYY"
                                                        formatDate={formatDate}
                                                        parseDate={parseDate}
                                                        dayPickerProps={{
                                                            className:
                                                                'selectable',
                                                            selectedDays: handleDateCalendarOption(
                                                                energy,
                                                                'selected',
                                                            ),
                                                            disabledDays: handleDateCalendarOption(
                                                                energy,
                                                                'disabled',
                                                            ),
                                                            locale:
                                                                Wording
                                                                    .contractDate
                                                                    .LOCAL,
                                                            months:
                                                                Wording
                                                                    .contractDate
                                                                    .MONTH[
                                                                    Wording
                                                                        .contractDate
                                                                        .LOCAL
                                                                ],
                                                            weekdaysShort:
                                                                Wording
                                                                    .contractDate
                                                                    .WEEKDAYS_SHORT[
                                                                    Wording
                                                                        .contractDate
                                                                        .LOCAL
                                                                ],
                                                            weekdaysLong:
                                                                Wording
                                                                    .contractDate
                                                                    .WEEKDAYS_LONG[
                                                                    Wording
                                                                        .contractDate
                                                                        .LOCAL
                                                                ],
                                                            firstDayOfWeek:
                                                                Wording
                                                                    .contractDate
                                                                    .FIRST_DAY_OF_WEEK[
                                                                    Wording
                                                                        .contractDate
                                                                        .LOCAL
                                                                ],
                                                            modifiers: {
                                                                highlighted:
                                                                    isSwitch &&
                                                                    config.ChannelsDoorToDoor.includes(
                                                                        channel,
                                                                    )
                                                                        ? addDays(
                                                                              -7,
                                                                          )
                                                                        : 0,
                                                                weekend: {
                                                                    daysOfWeek: [
                                                                        0,
                                                                        6,
                                                                    ],
                                                                },
                                                            },
                                                            modifiersStyles,
                                                            month: new Date(
                                                                new Date().getFullYear(),
                                                                new Date().getMonth(),
                                                            ),
                                                        }}
                                                        keepFocus
                                                        inputProps={{
                                                            readOnly: true,
                                                            onFocus: () => {
                                                                this.handleChangeBool(
                                                                    energy,
                                                                    'open',
                                                                );
                                                                this.handleChangeBoolSelect(
                                                                    energy,
                                                                );
                                                            },
                                                        }}
                                                        onDayChange={d => {
                                                            handleDatePicker(
                                                                d,
                                                                energy,
                                                            );
                                                            this.handleDatePicker(
                                                                d,
                                                                energy,
                                                            );
                                                        }}
                                                        hideOnDayClick={false}
                                                        onDayPickerShow={() => {
                                                            this.handleChangeBool(
                                                                energy,
                                                                'open',
                                                            );
                                                        }}
                                                        onDayPickerHide={() =>
                                                            this.handleChangeBool(
                                                                energy,
                                                                'close',
                                                            )
                                                        }
                                                        //value={currentDate}
                                                    />

                                                    {isSwitch &&
                                                        this.renderTextSwitchByCalendar(
                                                            energy,
                                                            energyTypes.length ===
                                                                2 &&
                                                                energy === 'NG'
                                                                ? 'right-hidden'
                                                                : 'right',
                                                        )}

                                                    {this.renderTextWithCalendar(
                                                        energy,
                                                    )}
                                                </div>
                                                {setting[energy].hideDiv &&
                                                    this.renderInfosDiv(energy)}
                                                {setting[energy].showSelect &&
                                                    !isSwitch &&
                                                    this.renderSelectTimeslot(
                                                        energy,
                                                    )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                                {/*                                <div>
                                    {dropdownContractOptionSelection && dropdownContractOptionSelection.value === "LATER" &&
                                        (
                                            <div>
                                                <span>{Wording.contractDate.renderDescription}</span>
                                                <span>{this.renderTheoreticalDate21Days(energy)}</span>
                                            </div>
                                        )
                                    }
                                </div>*/}
                            </>
                        );
                    })}
            </div>
        );
    };

    renderSelectTimeslot = energy => {
        const { handleChangeTimeslotSelect, dateContract } = this.props;
        const ts = _.get(dateContract, `${energy}.timeslots`);
        if (!ts) {
            return null;
        } else if (ts.length === 0) {
            return (
                <p
                    className={`date-container__date__calendars__${energy}-calendar__paragraph`}
                >
                    {Wording.contractDate.noTimeslotLabel}
                </p>
            );
        }
        _.remove(ts, 'NONE');
        const timeslotOptions = Wording.contractDate.timeslots.values.filter(
            element => ts.find(val => val === element.value) !== undefined,
        );
        const value =
            Wording.contractDate.timeslots.values.find(
                e => e.value === dateContract[energy].timeslot,
            ) || null;
        const containerClassName = `date-container__date__calendars__${energy}-calendar__container___paragraph`;
        const paragraphClassName = `date-container__date__calendars__${energy}-calendar__paragraph`;
        let energyStr = '';
        if (energy === 'EL')
            energyStr = Wording.contractDate.timeslotSelectorElectricity;
        else if (energy === 'NG')
            energyStr = Wording.contractDate.timeslotSelectorGas;
        return (
            <div className={containerClassName}>
                <div className={paragraphClassName}>
                    <span>{Wording.contractDate.timeslotSelectorTitle}</span>
                    <div>
                        <span>{`${Wording.contractDate.timeslotSelectorPrompt} :`}</span>
                        <span>{energyStr}</span>
                    </div>
                </div>
                <CustomSelect
                    id="idCreceau"
                    options={timeslotOptions}
                    value={value}
                    placeholder={Wording.contractDate.placeholderSelector}
                    handleChangeSelect={e => {
                        handleChangeTimeslotSelect(e, energy);
                    }}
                />
            </div>
        );
    };

    render() {
        const {
            dropdownContractOptions,
            dropdownContractOptionSelection,
            handleDropdownContract,
            situation,
            isCyclicalProductElec,
            isCyclicalProductGas,
        } = this.props;
        const { isLockedSituationSelect } = this.state;

        const Calender = (
            <Fragment>
                {this.renderCalendar()}
                {/* <div>
                    {dropdownContractOptionSelection && dropdownContractOptionSelection.value === "LATER" &&
                        (
                            <div>
                                <span>{Wording.contractDate.renderDescription}</span>
                                <span>{this.renderTheoreticalDate(energy)}</span>
                            </div>
                        )
                    }
                </div> */}
                <div className={'date-disclaimer'}>
                    <span>{Wording.contractDate.displayText}</span>
                </div>
            </Fragment>
        );
        return (
            <div className="date-container">
                {situation === Wording.situation.values.moved.value && Calender}
                {situation ===
                    Wording.situation.values.changeSupplier.value && (
                    <React.Fragment>
                        <CustomSelect
                            id="idChangeSupplierCustomSelect"
                            options={dropdownContractOptions}
                            handleChangeSelect={handleDropdownContract}
                            value={
                                isCyclicalProductElec || isCyclicalProductGas
                                    ? undefined
                                    : dropdownContractOptionSelection
                            }
                            placeholder={
                                isCyclicalProductElec || isCyclicalProductGas
                                    ? "L'offre cyclique ne vous permet pas de choisir un mode de paiement"
                                    : Wording.contractDate.placeholderSelector
                            }
                            disabled={
                                isCyclicalProductGas ||
                                isCyclicalProductElec ||
                                isLockedSituationSelect
                            }
                        />

                        {dropdownContractOptionSelection && Calender}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default DateContainer;
