import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as actions from '../_actions/auth.actions';
import * as contractActions from '../_actions/contract.actions';
import * as profileActions from '../_actions/profile.actions';
import * as eventActions from '../_actions/event.actions';
import * as consumptionActions from '../_actions/consumption.actions';
import * as fileActions from '../_actions/file.actions';
import * as paymentActions from '../_actions/payment.actions';
import * as packageActions from '../_actions/package.actions';
import * as customerContractActions from '../_actions/customerContract.actions';
import * as orderActions from '../_actions/searchOrder.actions';
import * as userGroupActions from '../_actions/searchUserGroup.actions';
import * as createGroupActions from '../_actions/createGroup.actions';
import * as getGroupActions from '../_actions/getGroup.actions';
import * as createUserActions from '../_actions/createUser.actions';
import * as manageUserActions from '../_actions/manageUser.actions';
import * as transferContractActions from '../_actions/transferContract.actions';
import * as adminClientSpaceActions from '../_actions/administrationClientSpace.actions';
import * as paymentCBActions from '../_actions/paymentCB.actions';
import * as createPasswordActions from '../_actions/createPassword.actions';
import * as caseResultActions from '../_actions/caseResult.action';

import { TScheme } from 'Models';

export type AuthState = Readonly<{
    loading: boolean;
    error?: string;
    message?: string;
    token?: string;
    userId?: string;
    unauthorized: boolean;
    autorizationsScheme: TScheme[];
    groupe?: string;
    reinit?: string;
}>;

const initialScheme: TScheme = {
    id: '',
    label: '',
    subMenu: [
        {
            id: '',
            label: '',
            root: '',
            groupMode: '',
            subGroupMode: '',
            actionMode: '',
        },
    ],
};

export const initialState: AuthState = {
    loading: false,
    error: undefined,
    token: undefined,
    message: undefined,
    userId: undefined,
    unauthorized: false,
    groupe: undefined,
    autorizationsScheme: [{ ...initialScheme }],
    reinit: undefined,
};

type Actions =
    | actions.AuthAction
    | contractActions.ContractAction
    | profileActions.ProfileAction
    | eventActions.EventAction
    | consumptionActions.ConsumptionAction
    | fileActions.FileActions
    | paymentActions.PiecesAction
    | packageActions.PackageAction
    | customerContractActions.CustomersContractAction
    | userGroupActions.searchUserGroupAction
    | createGroupActions.CreateGroupAction
    | getGroupActions.getGroupAction
    | createUserActions.CreateUserAction
    | manageUserActions.ManageUserAction
    | transferContractActions.TransferContractAction
    | orderActions.FetchOrderAction
    | paymentCBActions.PaymentCBAction
    | adminClientSpaceActions.AdminClientSpaceAction
    | createPasswordActions.CreatePasswordAction
    | caseResultActions.RetryFirstPaymentAction;

const authReducer: Reducer<AuthState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.resetAuthErrors):
            return {
                ...state,
                error: undefined,
                message: undefined,
                code: undefined,
            };
        case getType(actions.refreshSessionExpirationStatus):
            return {
                ...state,
                unauthorized: action.payload.valueOf(),
            };
        case getType(actions.removeReinitSubscriptionMessage):
            return {
                ...state,
                reinit: '',
            };
        case getType(actions.removeError):
            return {
                ...state,
                error: 'no errors occurred',
            };
        case getType(actions.fetchLoginAsync.request):
        case getType(actions.reinitSubscriptionEmailAsync.request):
        case getType(createPasswordActions.createPasswordAsync.request):
        case getType(caseResultActions.caseResultRetryFirstPaymentAsync.request):
        case getType(actions.resetPasswordAsync.request): {
            return {
                ...state,
                loading: true,
                error: undefined,
                message: undefined,
                code: undefined,
            };
        }

        case getType(createPasswordActions.createPasswordAsync.success):
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                error: undefined,
                code: action.payload.code,
            };
        case getType(caseResultActions.caseResultRetryFirstPaymentAsync.success):
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                error: undefined,
                code: action.payload.status,
            };
        case getType(caseResultActions.caseResultRetryFirstPaymentAsync.failure):
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                error: undefined,
                code: action.payload.code,
            };
        case getType(createPasswordActions.createPasswordAsync.failure):
            return {
                ...state,
                loading: false,
                error: action.payload.message,
                message: undefined,
                code: action.payload.code,
            };

        case getType(actions.fetchLoginAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        }

        case getType(actions.fetchLoginAsync.success): {
            return {
                ...state,
                loading: false,
                token: action.payload.token,
                userId: action.payload.userId,
                groupe: action.payload.groupe,
                autorizationsScheme:
                    action.payload.autorizationsScheme !== undefined
                        ? [...action.payload.autorizationsScheme]
                        : [{ ...initialScheme }],
            };
        }

        case getType(actions.resetPasswordAsync.failure): {
            return {
                ...state,
                loading: false,
                message: undefined,
                error: action.payload,
            };
        }

        case getType(actions.resetPasswordAsync.success): {
            return {
                ...state,
                loading: false,
                error: undefined,
                message: action.payload,
            };
        }

        case getType(actions.reinitSubscriptionEmailAsync.failure): {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case getType(actions.reinitSubscriptionEmailAsync.success): {
            return {
                ...state,
                loading: false,
                reinit: action.payload,
            };
        }
        case getType(paymentCBActions.createPaymentCBAsync.request):
        case getType(actions.casesExtractAsync.request):
        case getType(contractActions.fetchContractAsync.request):
        case getType(eventActions.createEventAsync.request):
        case getType(adminClientSpaceActions.adminClientSpaceAsync.request):
        case getType(eventActions.fetchEventAsync.request):
        case getType(orderActions.searchClientAsync.request):
        case getType(consumptionActions.fetchMeterConfigAsync.request):
        case getType(consumptionActions.fetchMeterReadingAsync.request):
        case getType(consumptionActions.createIndexAsync.request):
        case getType(profileActions.fetchContextAsync.request):
        case getType(profileActions.updatePasswordAsync.request):
        case getType(profileActions.updateCustomerAsync.request):
        case getType(profileActions.updateBillingAsync.request):
        case getType(fileActions.fetchFilesListAsync.request):
        case getType(paymentActions.createPaymentAsync.request):
        case getType(paymentActions.fetchPiecesAsync.request):
        case getType(paymentActions.fetchEPInvoicesAsync.request):
        case getType(profileActions.createSponsorAsync.request):
        case getType(packageActions.fetchPackageAsync.request):
        case getType(createGroupActions.createGroupAsync.request):
        case getType(getGroupActions.getGroupAsync.request):
        case getType(packageActions.fetchProductAsync.request):
        case getType(manageUserActions.manageUserAsync.request):
        case getType(createUserActions.createUserAsync.request):
        case getType(
            customerContractActions.fetchCustomerContractAsync.request,
        ):
        case getType(customerContractActions.uploadAmendmentsAsync.request):
        case getType(orderActions.fetchOrderAsync.request):
        case getType(userGroupActions.searchUserGroupAsync.request):
        case getType(transferContractActions.transferGetContractAsync.request):
        case getType(transferContractActions.transferContractAsync.request):
        case getType(packageActions.updatePackageAsync.request): {
            return {
                ...state,
                loading: true,
            };
        }
        case getType(paymentCBActions.createPaymentCBAsync.success):
        case getType(actions.casesExtractAsync.success):
        case getType(contractActions.fetchContractAsync.success):
        case getType(eventActions.fetchEventAsync.success):
        case getType(eventActions.createEventAsync.success):
        case getType(consumptionActions.fetchMeterConfigAsync.success):
        case getType(consumptionActions.fetchMeterReadingAsync.success):
        case getType(consumptionActions.createIndexAsync.success):
        case getType(profileActions.fetchContextAsync.success):
        case getType(profileActions.updatePasswordAsync.success):
        case getType(orderActions.searchClientAsync.success):
        case getType(profileActions.updateCustomerAsync.success):
        case getType(profileActions.updateBillingAsync.success):
        case getType(profileActions.createSponsorAsync.success):
        case getType(fileActions.fetchFilesListAsync.success):
        case getType(paymentActions.createPaymentAsync.success):
        case getType(paymentActions.fetchPiecesAsync.success):
        case getType(paymentActions.fetchEPInvoicesAsync.request):
        case getType(packageActions.fetchPackageAsync.success):
        case getType(packageActions.fetchProductAsync.success):
        case getType(userGroupActions.searchUserGroupAsync.success):
        case getType(getGroupActions.getGroupAsync.success):
        case getType(createUserActions.createUserAsync.success):
        case getType(manageUserActions.manageUserAsync.success):
        case getType(transferContractActions.transferGetContractAsync.success):
        case getType(transferContractActions.transferContractAsync.success):
        case getType(adminClientSpaceActions.adminClientSpaceAsync.success):
        case getType(createGroupActions.createGroupAsync.success):
        case getType(
            customerContractActions.fetchCustomerContractAsync.success,
        ):
        case getType(orderActions.fetchOrderAsync.success):
        case getType(actions.casesExtractAsync.failure):
        case getType(customerContractActions.uploadAmendmentsAsync.success):
        case getType(packageActions.updatePackageAsync.success): {
            return {
                ...state,
                loading: false,
            };
        }
        case getType(paymentCBActions.createPaymentCBAsync.failure):
        case getType(packageActions.fetchProductAsync.failure):
        case getType(orderActions.searchClientAsync.failure):
        case getType(packageActions.fetchPackageAsync.failure):
        case getType(packageActions.updatePackageAsync.failure):
        case getType(profileActions.updateCustomerAsync.failure):
        case getType(profileActions.updateBillingAsync.failure):
        case getType(profileActions.fetchContextAsync.failure):
        case getType(profileActions.updatePasswordAsync.failure):
        case getType(profileActions.createSponsorAsync.failure):
        case getType(paymentActions.createPaymentAsync.failure):
        case getType(paymentActions.fetchPiecesAsync.failure):
        case getType(paymentActions.fetchEPInvoicesAsync.request):
        case getType(eventActions.fetchEventAsync.failure):
        case getType(eventActions.createEventAsync.failure):
        case getType(contractActions.fetchContractAsync.failure):
        case getType(consumptionActions.createIndexAsync.failure):
        case getType(consumptionActions.fetchMeterConfigAsync.failure):
        case getType(consumptionActions.fetchMeterReadingAsync.failure):
        case getType(userGroupActions.searchUserGroupAsync.failure):
        case getType(getGroupActions.getGroupAsync.failure):
        case getType(createUserActions.createUserAsync.failure):
        case getType(manageUserActions.manageUserAsync.failure):
        case getType(adminClientSpaceActions.adminClientSpaceAsync.failure):
        case getType(transferContractActions.transferGetContractAsync.failure):
        case getType(transferContractActions.transferContractAsync.failure):
        case getType(createGroupActions.createGroupAsync.failure):
        case getType(customerContractActions.uploadAmendmentsAsync.failure):
        case getType(
            customerContractActions.fetchCustomerContractAsync.failure,
        ):
        case getType(orderActions.fetchOrderAsync.failure):
        case getType(fileActions.fetchFilesListAsync.failure): {
            const { code } = action.payload;
            return {
                ...state,
                loading: false,
                unauthorized: code === '401',
            };
        }

        default:
            return state;
    }
};

export default authReducer;
