import { Epic } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { mergeMap, catchError, filter, switchMap } from 'rxjs/operators';
import {
    createAsyncAction,
    createStandardAction,
    isActionOf,
    ActionType,
} from 'typesafe-actions';
import {
    TResponse,
    TInvoice,
    TPrepayment,
    TTransaction,
    TAdhoc,
    TEPInvoice,
} from 'Models';
import { RootAction, RootState, Services } from 'Types';
import Config from '../utils/config';
import {
    FETCH_PIECES_FAILURE,
    FETCH_PIECES_SUCCESS,
    FETCH_PIECES_REQUEST,
    CREATE_PAYMENT_REQUEST,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAILURE,
    CREATE_PAYMENT_RESET,
    FETCH_BALANCE_REQUEST,
    FETCH_BALANCE_SUCCESS,
    FETCH_BALANCE_FAILURE,
    GET_SUPPORTING_DOCUMENTS_REQUEST,
    GET_SUPPORTING_DOCUMENTS_FAILURE,
    GET_SUPPORTING_DOCUMENTS_SUCCESS,
    RESET_SUPPORTING_DOCUMENT,
    FETCH_EPINVOICES_REQUEST,
    FETCH_EPINVOICES_SUCCESS,
} from './actionTypes';
import { ProofPaymentProps } from '../components/PDFs/ProofOfPayment/type';

import { generateProofOfPayment } from '../components/PDFs/ProofOfPayment';
import { config } from 'process';
export type GetSupportingDocumentsRequest = Partial<{
    mode: 'proofOfResidence' | 'proofOfPayment';
    contractNbr: string;
    customerNbr: string;
    customerType: string;
    cunstomerName: {
        civility: string;
        firstName: string;
        lastName: string;
    };
    coholderName: {
        civility: string;
        firstName: string;
        lastName: string;
    };
    energyType: string;
    subscriptionDate: string;
    residenceAdress: {
        number: string;
        street: string;
        netArea: string;
        postalCode: string;
        country: string;
        townName: string;
    };
    pieceReference: string;
    pieceType: string;
    pointOfDelivery: string;

    actionProps: ProofPaymentProps;
}>;

interface IResubmitCollectionRequest {
    dueDate: string;
    piece: TEPInvoice;
}
interface IResubmitCollectionResponse {
    status: string;
}

interface IBalanceRequest {
    customerNbr: string;
    contractNbr: string;
    invoiceNbr?: string;
    mode?: string;
}

interface IBalanceResponse {
    customerBalance: string;
    contractBalance: string;
    invoiceBalance: string;
    code?: string;
    message?: string;
}

const resetUpdate = createStandardAction(CREATE_PAYMENT_RESET)();
const resetSupportingDocumentError = createStandardAction(
    RESET_SUPPORTING_DOCUMENT,
)();

interface IPieceRequest {
    contractNbr: string;
    invoiceId?: string;
}
interface IPieceResponse {
    invoices: TInvoice[];
    prepayments: TPrepayment[];
    adhocPieces: TAdhoc[];
    error?: TResponse;
}

interface IEPInvoiceRequest {
    customerNbr: string;
    contractNbr: string;
}

interface IEPInvoiceResponse {
    epInvoice: TEPInvoice[];
    error?: TResponse;
}

const fetchPiecesAsync = createAsyncAction(
    FETCH_PIECES_REQUEST,
    FETCH_PIECES_SUCCESS,
    FETCH_PIECES_FAILURE,
)<IPieceRequest, IPieceResponse, TResponse>();

const fetchEPInvoicesAsync = createAsyncAction(
    FETCH_EPINVOICES_REQUEST,
    FETCH_EPINVOICES_SUCCESS,
    FETCH_PIECES_FAILURE,
)<IEPInvoiceRequest, IEPInvoiceResponse, TResponse>();

const createPaymentAsync = createAsyncAction(
    CREATE_PAYMENT_REQUEST,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAILURE,
)<IResubmitCollectionRequest, IResubmitCollectionResponse, TResponse>();

const fetchBalanceAsync = createAsyncAction(
    FETCH_BALANCE_REQUEST,
    FETCH_BALANCE_SUCCESS,
    FETCH_BALANCE_FAILURE,
)<IBalanceRequest, IBalanceResponse, IBalanceResponse>();

const getSupportingDocumentsAsync = createAsyncAction(
    GET_SUPPORTING_DOCUMENTS_REQUEST,
    GET_SUPPORTING_DOCUMENTS_SUCCESS,
    GET_SUPPORTING_DOCUMENTS_FAILURE,
)<GetSupportingDocumentsRequest, any, string>();

export type PiecesAction =
    | ActionType<typeof createPaymentAsync>
    | ActionType<typeof fetchPiecesAsync>
    | ActionType<typeof fetchEPInvoicesAsync>
    | ActionType<typeof resetUpdate>
    | ActionType<typeof getSupportingDocumentsAsync>
    | ActionType<typeof resetSupportingDocumentError>
    | ActionType<typeof fetchBalanceAsync>;

const prepareBalancePayload = ({
    contractNbr,
    customerNbr,
}: {
    contractNbr: string;
    customerNbr: string;
}): IBalanceRequest => {
    return {
        contractNbr,
        customerNbr,
        mode: 'CONTRACT_BALANCE',
    };
};

const mapGetBalance = (action: RootAction, { apiRequest }: Services) => {
    const payload = prepareBalancePayload(action.payload);
    return apiRequest<IBalanceResponse>({
        path: '/getBalance',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IBalanceResponse) => {
            if (response) return of(fetchBalanceAsync.success(response));
            return throwError({ code: '200', message: response });
        }),
        catchError(error => of(fetchBalanceAsync.failure(error))),
    );
};

const fetchBalanceEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchBalanceAsync.request)),
        mergeMap(action => mapGetBalance(action, dependency)),
    );

const preparePiecesPayload = ({ contractNbr, invoiceId }: IPieceRequest) => {
    if (invoiceId) {
        return {
            invoiceId,
            contractId: contractNbr,
        };
    }
    return {
        contractId: contractNbr,
    };
};

const mapGetPiece = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePiecesPayload(action.payload);
    return apiRequest<IPieceResponse>({
        path: '/financialPieces',
        method: 'post',
        body: payload,
        isPay: true,
    }).pipe(
        mergeMap((response: IPieceResponse) => {
            if (response && response.error === undefined) {
                return of(fetchPiecesAsync.success(response));
            }
            let message = 'failure';
            if (response.error) {
                message = response.error.description || 'failure';
            }
            return of(fetchPiecesAsync.failure({ code: '500', message }));
        }),
        catchError(error => of(fetchPiecesAsync.failure(error))),
    );
};

const prepareEPInvoicesPayload = ({
    contractNbr,
    customerNbr,
}: IEPInvoiceRequest) => {
    return {
        jsonrpc: '2.0',
        id: 'EC01',
        params: {
            username: Config.EnergyPaid.username,
            password: Config.EnergyPaid.password,
            customer_ref: customerNbr,
            contract_ref: contractNbr,
        },
    };
};

const mapGetEPInvoice = (action: RootAction, { apiRequest }: Services) => {
    const payload = prepareEPInvoicesPayload(action.payload);
    return apiRequest<IEPInvoiceResponse>({
        path: '/ws_portal_get_customer_invoice_list/wsportal',
        method: 'post',
        body: payload,
        isPay: false,
        isPayEP: true,
    }).pipe(
        mergeMap((response: IEPInvoiceResponse) => {
            if (response && response.error === undefined) {
                return of(fetchEPInvoicesAsync.success(response));
            }
            let message = 'failure';
            if (response.error) {
                message = response.error.description || 'failure';
            }
            return of(fetchEPInvoicesAsync.failure({ code: '500', message }));
        }),
        catchError(error => of(fetchEPInvoicesAsync.failure(error))),
    );
};

const mapGetSupportingDocuments = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const {
        payload: { actionProps, ...apiPayload },
    }: any = action;

    return apiRequest<any>({
        path: '/getSupportingDocuments',
        method: 'post',
        body: apiPayload,
    }).pipe(
        mergeMap((response: any) => {
            if (response && typeof response === 'string') {
                generateProofOfPayment(actionProps);

                return of(getSupportingDocumentsAsync.success(response));
            }

            return of(getSupportingDocumentsAsync.failure(response.message));
        }),
        catchError(error =>
            of(getSupportingDocumentsAsync.failure(error.message)),
        ),
    );
};

const getSupportingDocumentsEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(getSupportingDocumentsAsync.request)),
        mergeMap(action => mapGetSupportingDocuments(action, dependency)),
    );

const fetchPiecesEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchPiecesAsync.request)),
        mergeMap(action => mapGetPiece(action, dependency)),
    );

const fetchEPInvoiceEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchEPInvoicesAsync.request)),
        mergeMap(action => mapGetEPInvoice(action, dependency)),
    );

const prepareCreatePaymentPayload = ({
    piece,
    dueDate,
}: IResubmitCollectionRequest) => {
    return {
        payment: piece,
        dueDate,
        mode: 'CREATE_PAYMENT',
        customerNbr: piece.partner_ref,
        contractNbr: piece.contract,
    };
};

const mapResubmit = (action: RootAction, { apiRequest }: Services) => {
    const payload = prepareCreatePaymentPayload(action.payload);
    return apiRequest<any>({
        path: '/getDocumentsList',
        method: 'post',
        body: payload,
    }).pipe(
        switchMap((response: any) => {
            if (response && response.financialPieces) {
                if (response.financialPieces.result.status === 'ok') {
                    return of(
                        createPaymentAsync.success({
                            status: 'ok',
                        }),
                    );
                }
                const message = response.error
                    ? response.description
                    : 'Erreur';
                return of(createPaymentAsync.failure({ code: '500', message }));
            }
            return of(
                createPaymentAsync.failure({ code: '500', message: 'Erreur' }),
            );
        }),
        catchError(error => {
            return of(
                createPaymentAsync.failure({
                    code: error.code,
                    message: error.message,
                }),
            );
        }),
    );
};

const createPaymentEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(createPaymentAsync.request)),
        mergeMap(action => mapResubmit(action, dependency)),
    );

export {
    mapGetPiece,
    mapGetEPInvoice,
    fetchPiecesAsync,
    fetchEPInvoicesAsync,
    fetchPiecesEpic,
    fetchEPInvoiceEpic,
    createPaymentEpic,
    createPaymentAsync,
    resetUpdate,
    mapGetBalance,
    fetchBalanceAsync,
    fetchBalanceEpic,
    getSupportingDocumentsEpic,
    getSupportingDocumentsAsync,
    resetSupportingDocumentError,
};
