import React, { FC } from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        padding: '8px 0px',
    },
    title: {
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 10,
    },

    value: {
        fontSize: 10,
    },
});

type Props = {
    title: string;
    data: {
        name: string;
        number_street: string;
        zip_code_city: string;
    };
};

const Adresse: FC<Props> = ({
    title,
    data: { name, number_street, zip_code_city },
}) => (
    <View style={styles.container}>
        <Text style={styles.title}> {title} : </Text>
        <Text style={styles.value}> {name} </Text>
        <Text style={styles.value}> {number_street} </Text>
        <Text style={styles.value}> {zip_code_city} </Text>
    </View>
);

export default Adresse;
