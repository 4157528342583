/**
 * @author Ivan Naluzhnyi | Nawin Kim
 * @email [ivan.naluzhnyi@gmail.com]
 * @email [nawin.kim@uppli.fr]
 * @create date 2019-09-16 16:44:55
 * @modify date 2019-09-16 16:50:47
 * @desc [client-space | Uppli]
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    RouteProps,
    RouteComponentProps,
} from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import store from './store';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { version } from '../package.json';

import { log, parseQueryParams } from './utils/helpers';
import AuthService from './services/auth-service';
import AuthPage from './pages/AuthPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ConnectedMain from './pages/Main';

let isFirstRedirect = true;
let initialSearchParams = new Map<string, string | number>();

interface IPrivateRouteProps extends RouteProps {
    component:
        | React.ComponentType<
              RouteComponentProps<any> & {
                  initialSearchParams: Map<string, string | number>;
              }
          >
        | React.ComponentType<any>;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
    component: Component,
    ...rest
}) => {
    const isSignIn = AuthService.isAuthenticated();
    return (
        <Route
            {...rest}
            render={props => {
                if (isFirstRedirect) {
                    isFirstRedirect = false;
                    initialSearchParams = parseQueryParams(
                        props.location,
                        window.location.host,
                        window.location.protocol,
                    );
                }

                if (!isSignIn && initialSearchParams.has('client')) {
                    initialSearchParams = new Map();
                }

                return isSignIn ? (
                    <Component
                        {...props}
                        initialSearchParams={initialSearchParams}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
};

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/login" component={AuthPage} />
                <Route
                    exact
                    path="/createPassword"
                    component={ResetPasswordPage}
                />
                <PrivateRoute path="/" component={ConnectedMain} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root'),
);

console.log('Version : ', version);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
