import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    ActionType,
    isActionOf,
    createStandardAction,
} from 'typesafe-actions';
import { ReducerError, RootAction, RootState, Services, TError } from 'Types';
import {
    RETRY_FIRST_PAYMENT_REQUEST,
    RETRY_FIRST_PAYMENT_SUCCESS,
    RETRY_FIRST_PAYMENT_FAILURE,
    RETRY_FIRST_PAYMENT_RESET_RESPONSE,
} from './actionTypes';
import { TUser } from 'Models';
import { Epic } from 'redux-observable';

export type TRetryPaymentPayload = Partial<{
    customerNbr: string;
    mode: string;
}>;

interface IResponseClient {
    status: string,
    message: string,
}

const caseResultRetryFirstPaymentAsync = createAsyncAction(
    RETRY_FIRST_PAYMENT_REQUEST,
    RETRY_FIRST_PAYMENT_SUCCESS,
    RETRY_FIRST_PAYMENT_FAILURE,
    RETRY_FIRST_PAYMENT_RESET_RESPONSE,
)<TRetryPaymentPayload, IResponseClient, ReducerError>();

const retryFirstPayment = createStandardAction(
    RETRY_FIRST_PAYMENT_RESET_RESPONSE,
)();

export type RetryFirstPaymentAction =
    | ActionType<typeof caseResultRetryFirstPaymentAsync>
    | ActionType<typeof retryFirstPayment>;

const preparePayloadClientSpace = (payload: TRetryPaymentPayload): TRetryPaymentPayload => {
    return { ...payload };
};

const mapGetRetryFirstPayment = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadClientSpace(action.payload);
    return apiRequest<IResponseClient>({
        path: '/retryFirstPayment',
        method: 'POST',
        body: payload,
        isSubscription: true,
    }).pipe(
        mergeMap((response: any & TError) => {
            if (
                response.code === 'MISSING_PARAMETER' ||
                response.code === 'NO_RESPONSE_FROM_PARTNER'
            ) {
                return of(
                    caseResultRetryFirstPaymentAsync.failure({
                        code: response.code,
                        message: response.description,
                    }),
                );
            }
            return of(caseResultRetryFirstPaymentAsync.success(response));
        }),
        catchError(error => {
            return of(
                caseResultRetryFirstPaymentAsync.failure({
                    code: error.code,
                    message: error.description,
                }),
            );
        }),
    );
};

const RetryFirstPaymentEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(caseResultRetryFirstPaymentAsync.request)),
        mergeMap(action => mapGetRetryFirstPayment(action, dependency)),
    );

export {
    caseResultRetryFirstPaymentAsync,
    RetryFirstPaymentEpic,
    mapGetRetryFirstPayment,
    retryFirstPayment,
};
