import { TEPInvoice } from 'Models';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { parseSrcIframe } from '../utils/helpers';

import * as actions from '../_actions/paymentCB.actions';

export type PaymentState = Readonly<
    {
        error?: string;
        requestProps?: {
            dueDate: string;
            payment: TEPInvoice;
        };
        responsePayload?: {
            errors: Array<{ code: string; description: string }>;
        };
    } & Partial<actions.TPaymentCBResponse>
>;

export const initialState: PaymentState = {
    iframe: undefined,
    stripe: undefined,
    adhocPaymentId: undefined,
    transactionId: undefined,
    error: undefined,
    responsePayload: { errors: [] },
};

type Actions = actions.PaymentCBAction;

const paymentReducer: Reducer<PaymentState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.createPaymentCBAsync.request): {
            return {
                ...initialState,
                requestProps: action.payload,
            };
        }

        case getType(actions.createPaymentCBAsync.success): {
            return {
                ...state,
                ...action.payload,
                iframe: parseSrcIframe(action.payload.iframe),
                error: undefined,
            };
        }

        case getType(actions.createPaymentCBAsync.failure): {
            return {
                ...initialState,
                requestProps: state.requestProps,
                responsePayload: action.payload,
                error: "Une erreur s'est produite",
            };
        }

        case getType(actions.resetModal): {
            return {
                ...initialState,
            };
        }
        default:
            return { ...state };
    }
};

export default paymentReducer;
