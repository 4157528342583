import React from 'react';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Footer;

const Footer: React.FC<{}> = ({}) => {
    return (
        <div className="footer">
            <div className="footer__articles">
                {Wording.articles.map((e, index) => {
                    const show = index < Wording.articles.length - 1;
                    return (
                        <React.Fragment key={index}>
                            <a
                                href={e.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {e.text}
                            </a>
                            {show && <p>|</p>}
                        </React.Fragment>
                    );
                })}
            </div>

            <div className="footer__social">
                <a
                    href={Wording.socialLink.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-linkedin']} alt="ic-linkedin" />
                </a>
                <a
                    href={Wording.socialLink.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-instagram']} alt="ic-instagram" />
                </a>
                <a
                    href={Wording.socialLink.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-facebook']} alt="ic-facebook" />
                </a>
                <a
                    href={Wording.socialLink.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-youtube']} alt="ic-youtube" />
                </a>
                <a
                    href={Wording.socialLink.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-twitter']} alt="ic-twitter" />
                </a>
            </div>
            <p className="footer__bottom_text"> {Wording.footerBottomText} </p>
        </div>
    );
};

export default Footer;
