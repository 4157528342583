import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';

import MoveContainer from '../../containers/MoveContainer';
import { TCustomer } from 'Models';
import { ECustomerStatus } from '../../utils/enums';
import { concatUrl } from '../../utils/helpers';

const mapStateToProps = (state: RootState) => ({
    profileState: state.profileReducer,
    contractState: state.contractReducer,
});

type Props = ReturnType<typeof mapStateToProps>;

class MovePage extends React.Component<Props> {
    handleClick = () => {
        const {
            profileState: {
                contact,
                customerCategoryCode,
                customerNumber,
                customerType,
            },
            contractState: { contracts },
        } = this.props;
        const customer: TCustomer = {
            customerCategoryCode,
            contact,
            customerNumber,
            customerStatus: ECustomerStatus.CUSTOMER,
            type: customerType,
        };
        const thirdParties = contracts[0].thirdParties;
        const link = concatUrl(customer, thirdParties);
        window.open(link);
    };

    render() {
        return <MoveContainer handleClick={this.handleClick} />;
    }
}

export default connect(mapStateToProps)(MovePage);
