import React, { FC } from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';

import ElecIcon from '../../../../../assets/icons/PDFs/ProofOfPayment/ic-elec.png';
import GasIcon from '../../../../../assets/icons/PDFs/ProofOfPayment/ic-gas.png';

import Wording from '../../wording.json';
import { useProof } from '../../lib';
import { getFormattedDate } from '../../../../../utils/helpers';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        backgroundColor: '#D5D5D5',
    },
    energyImg: {
        width: 30,
        height: 65,
        paddingBottom: 20,
        paddingTop: 5,
        margin: 'auto',
    },
    textContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoTitle: {
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 9,
    },
    infoValue: {
        fontSize: 9,
    },
    infoMargin: {
        marginBottom: 10,
    },
});

type InfoProps = {
    style?: any;
    title: string;
    value: string;
};

const Info: FC<InfoProps> = ({ title, value, style = {} }) => (
    <View style={{ ...styles.textContainer, ...style }}>
        <Text style={styles.infoTitle}>{title}: </Text>
        <Text style={styles.infoValue}>{value}</Text>
    </View>
);

const {
    customerInfo: { dateOfIssue, customerNbr, contractNbr, phone, mail },
} = Wording;

const CustomerInfo = () => {
    const { customer, contract } = useProof();

    const getImageSrcByEnergy = (energy: 'EL' | 'NG') =>
        energy === 'EL' ? ElecIcon : GasIcon;
    return (
        <View style={styles.container}>
            <Image style={styles.energyImg} src={getImageSrcByEnergy('EL')} />
            <Info
                style={styles.infoMargin}
                title={dateOfIssue}
                value={getFormattedDate(new Date()) || ''}
            />
            <Info title={customerNbr} value={customer.customerNbr} />
            <Info
                style={styles.infoMargin}
                title={contractNbr}
                value={contract.contractNumber}
            />

            <Info title={phone} value={customer.phone} />
            <Info title={mail} value={customer.email} />
        </View>
    );
};

export default CustomerInfo;
