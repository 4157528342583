import React from 'react';

import { View, StyleSheet, Text } from '@react-pdf/renderer';

import { bottom } from '../wording.json';
import { getFormattedDate } from '../../../../utils/helpers';
import { useProof } from '../lib';

const styles = StyleSheet.create({
    container: {},
    fullName: {
        padding: '10px 0',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 13,
    },

    text: {
        fontSize: 13,
    },
});

const BottomText = () => {
    const {
        piece,
        customer: { name },
    } = useProof();

    // const transactionDueDate =
    //    processusList && processusList[0] && processusList[0].transactions
    //        ? processusList[0].transactions[0].dueDate
    //        : undefined;

    return (
        <View style={styles.container}>
            <Text style={styles.text}>
                {bottom.attestDate}
                {piece.date || ''} :
            </Text>
            <Text
                style={styles.fullName}
            >{`${name.civility} ${name.firstName} ${name.lastName} `}</Text>

            <Text style={styles.text}>{bottom.text}</Text>
        </View>
    );
};

export default BottomText;
