import React, { useState } from 'react';
import { InputEvent } from 'Types';
import replace from 'lodash/replace';

import {
    CustomInput,
    CustomRadio,
    CustomButton,
    CustomToggle,
} from '../../components';
import { IFields, TPaymentMethodSection } from './types';

import { displayIban, isValidIban } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

const ModificationPayment: React.FC<{
    fields: IFields;
    showError: boolean;
    paymentMethodSection: TPaymentMethodSection;
    validateItems: {
        isValidIbanCode: boolean;
        isValidDebitDay: boolean;
    };
    handleCheckUpdate(section: string, value: boolean): void;
    handleInputChange(e: InputEvent, section: string, type: string): void;
    handleDebitDay(value: string): void;
    handlePaymentSection(section: TPaymentMethodSection): void;
    handleShowErrorMessage(section: string): void;
    handleShowModal(section: string): void;
}> = ({
    fields,
    showError,
    handleInputChange,
    handleDebitDay,
    handlePaymentSection,
    paymentMethodSection,
    handleShowErrorMessage,
    handleShowModal,
    validateItems,
    handleCheckUpdate,
}) => {
    const ibanCondition = paymentMethodSection === 'IBAN';
    const debitDayCondition = paymentMethodSection === 'DEBIT_DAY';
    return (
        <>
            <div className="profile-container__update-payment-method">
                <div
                    className={`profile-container__update-payment-method__iban ${
                        !ibanCondition ? '--off' : ''
                    }`}
                >
                    <div
                        className="tg-block"
                        onClick={() => handlePaymentSection('IBAN')}
                    >
                        <CustomToggle
                            checked={ibanCondition}
                            handleCheck={() => {}}
                            text={Wording.paymentMethod.IBAN}
                        />
                    </div>

                    <CustomInput
                        placeholder={Wording.paymentMethod.inputs.IBAN}
                        value={displayIban(fields.billing.iban)}
                        onInputChange={(e: InputEvent) =>
                            handleInputChange(e, 'billing', 'iban')
                        }
                        error={
                            !isValidIban(fields.billing.iban) &&
                            fields.billing.iban.length >= 15
                                ? Wording.error.billing.iban
                                : ''
                        }
                        disabled={!ibanCondition}
                    />
                    {ibanCondition && (
                        <CustomButton
                            title={Wording.buttons.validate}
                            disabled={!validateItems.isValidIbanCode}
                            onClick={() => {
                                if (validateItems.isValidIbanCode) {
                                    handleShowErrorMessage('iban');
                                    handleShowModal('iban');
                                    handleCheckUpdate('iban', false);
                                }
                            }}
                            color={
                                validateItems.isValidIbanCode
                                    ? 'orange'
                                    : 'gray'
                            }
                        />
                    )}
                </div>
                <div
                    className={`profile-container__update-payment-method__debit-day 
                ${!debitDayCondition ? '--off' : ''}
                
                `}
                >
                    <div
                        className="tg-block"
                        onClick={() => handlePaymentSection('DEBIT_DAY')}
                    >
                        <CustomToggle
                            checked={debitDayCondition}
                            handleCheck={() => {}}
                            text={Wording.paymentMethod.titleDebitDay}
                        />
                    </div>

                    <div className="profile-container__update-payment-method__debit-day__container">
                        {Wording.paymentMethod.debitDaysEnum.map(dd => (
                            <CustomRadio
                                key={dd}
                                checked={dd === fields.debitDay}
                                text={replace(
                                    Wording.paymentMethod.debitDayLabel,
                                    '{{ddValue}}',
                                    dd,
                                )}
                                value={dd}
                                handleCheck={() =>
                                    debitDayCondition && handleDebitDay(dd)
                                }
                            />
                        ))}
                    </div>

                    {debitDayCondition && (
                        <CustomButton
                            title={Wording.buttons.validate}
                            onClick={() => {
                                if (validateItems.isValidDebitDay) {
                                    handleShowErrorMessage('debitDay');
                                    handleShowModal('debitDay');
                                }
                            }}
                            color={
                                validateItems.isValidDebitDay
                                    ? 'orange'
                                    : 'gray'
                            }
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ModificationPayment;
