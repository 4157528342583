import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import * as actions from '../_actions/customerContract.actions';

import { TCustomer } from '../constants/key-system';

export type CustomersContractState = Readonly<{
    error?: string;
    customers: TCustomer[];
    transferContract?: TCustomer[];

    updateResponse: {
        code: string;
        message: string;
    };
}>;

const initialState = {
    customers: [],
    error: undefined,
    transferContract: [],

    updateResponse: {
        code: '',
        message: '',
    },
};

type Actions = actions.CustomersContractAction;

const customersContractReducer: Reducer<CustomersContractState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchCustomerContractAsync.success): {
            return {
                ...state,
                customers: action.payload.customers,
                error: undefined,
            };
        }

        case getType(actions.fetchCustomerContractAsync.failure):
            return {
                ...state,
                customers: [],
                error: action.payload.code,
            };

        case getType(actions.uploadAmendmentsAsync.request): {
            return {
                ...state,
                updateResponse: {
                    code: '0',
                    message: '',
                },
            };
        }

        case getType(actions.uploadAmendmentsAsync.success): {
            return {
                ...state,
                updateResponse: {
                    code: '200',
                    message: 'success',
                },
            };
        }

        case getType(actions.uploadAmendmentsAsync.failure): {
            return {
                ...state,
                updateResponse: {
                    code: '500',
                    message: 'failure',
                },
            };
        }

        case getType(actions.resetUploadAmendments): {
            return {
                ...state,
                updateResponse: {
                    code: '',
                    message: '',
                },
            };
        }

        default:
            return { ...state };
    }
};

export default customersContractReducer;
