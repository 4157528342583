import React, { FC } from 'react';

import Row, { TableRowProps } from './Row';

export const Header: FC<TableRowProps> = props => {
    const rowCells: any[] = React.Children.toArray(props.children);

    return (
        <Row {...props} key={'header'}>
            {rowCells.map((rc, columnIndex) =>
                React.cloneElement(rc, {
                    key: columnIndex,
                    isHeader: true,
                    fontSize: props.fontSize,
                    textAlign: props.textAlign,
                    style: {
                        ...(props.style || {}),
                        ...(rc.props.style || {}),
                    },
                }),
            )}
        </Row>
    );
};

export default Header;
