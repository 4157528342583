import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';

import config from '../utils/config';
import { log as consoleLog } from '../utils/helpers';

type TPayloadLogger = Partial<{
    tag: string;
    customerType: string;
    triggerButton: string;
    serviceCalled: string;
    statusAction: string;
    logMessage: string;
    returnCode: string;
    energyList: string;
    customerIdentificationKey: string;
    environnement: string;
    timestamp: string;
    app: string;
    channel: string;
}>;

type TRequestLogStreaming = {
    jsonLog: string;
};

type TPropsLogger = Partial<{
    tag: string;
    customerType: string;
    triggerButton: string;
    serviceCalled: string;
    statusAction: string;
    logMessage: string;
    returnCode: string;
    energyList: string[];
    customerIdentificationKey: string;
}>;

const prepareEnergyList = (arrEnergies: string[]) =>
    arrEnergies.length === 2 ? 'EL/NG' : arrEnergies[0];

const controlData = (dt: any, slDt: any, custom?: string) => {
    if (custom) {
        switch (custom) {
            case 'energyList':
                return dt && prepareEnergyList(dt);

            default:
                return undefined;
        }
    }

    if (dt !== undefined) return dt;
    if (slDt !== undefined) return slDt;

    return undefined;
};

const prepareObject = (props: TPropsLogger): TPayloadLogger => {
    const {
        tag,
        customerType,
        triggerButton,
        serviceCalled,
        statusAction,
        logMessage,
        returnCode,
        energyList,
        customerIdentificationKey,
    } = props;
    const dataLS = localStorage.getItem('log_streaming');
    const localStorageData = dataLS ? JSON.parse(dataLS) : {};

    const prepareData = {
        tag: controlData(tag, localStorageData.tag),
        customerType: controlData(customerType, localStorageData.customerType),
        triggerButton: controlData(
            triggerButton,
            localStorageData.triggerButton,
        ),
        serviceCalled: controlData(
            serviceCalled,
            localStorageData.serviceCalled,
        ),
        logMessage: controlData(logMessage, localStorageData.logMessage),
        // ( success => RAS, fail => stacktrace)$
        returnCode: controlData(returnCode, localStorageData.returnCode),
        statusAction: controlData(statusAction, localStorageData.statusAction),

        energyList: controlData(
            energyList,
            localStorageData.energyList,
            'energyList',
        ),

        customerIdentificationKey: controlData(
            customerIdentificationKey,
            localStorageData.customerIdentificationKey,
        ),

        environnement: config.ENV,
        timestamp: Date.now().toString(),
        app: 'CLIENT-SPACE',
        channel: 'WEB',
    };

    const channel = localStorage.getItem('channel');
    if (channel) {
        prepareData.channel = channel;
    }

    return prepareData;
};

const log = ({
    tag,
    customerType,
    triggerButton,
    serviceCalled,
    statusAction,
    logMessage,
    returnCode,
    energyList,
    customerIdentificationKey,
}: TPropsLogger) => {
    const prepareData = prepareObject({
        tag,
        customerType,
        triggerButton,
        serviceCalled,
        statusAction,
        logMessage,
        returnCode,
        energyList,
        customerIdentificationKey,
    });

    const payload: TRequestLogStreaming = {
        jsonLog: JSON.stringify(prepareData),
    };

    localStorage.setItem('log_streaming', payload.jsonLog);

    const settings = {
        method: 'POST',
        url: `${config.URL_SUBSCRIPTION}/getLogsStreaming`,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': config.ApiKey,
        },
        responseType: 'json',
        crossDomain: true,
        body: payload,
    };

    consoleLog(`Prepare Object /getLogsStreaming => `, prepareData);
    consoleLog(`FO Data: POST /getLogsStreaming ====> `, payload);

    const call = ajax(settings).pipe(
        catchError(({ message, response, status }) => {
            const error = {
                ajaxError: message,
                code: status,
                message,
            };
            if (response) {
                error.code = response.code;
                error.message = response.message;
            }

            return throwError(error);
        }),
        map(({ response }) => {
            consoleLog(`BO Response: POST /getLogsStreaming ====> `, response);
            return response;
        }),
    );

    const subscribe = call.subscribe(
        err =>
            err && consoleLog(`Error from API /getLogsStreaming ====> `, err),
        res =>
            res !== null &&
            consoleLog(`BO Response: POST /getLogsStreaming ====> `, res),
    );

    return subscribe;
};

export { log };
