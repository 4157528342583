import React, { FC } from 'react';
import { buildClassName as bcn } from '../../../../../utils/helpers';
import { CustomButton } from '../../../../../components';
import WordingConstant from '../../../../../utils/wording.json';
import { toColonHeading as tch } from '../../../../../utils/text';
import Icon from '../../../../../assets/icons/ic-logo-wekiwi.svg';

interface IProps {
    onFinalize: () => void;
    onRetry: () => void;
    ErrorMessage?: string;
}

const Wording =
    WordingConstant.AdminSubscriptionContainer.PaymentIframeModal.Retry;
const baseClassName: string = 'retry';
const logoClassName: string = bcn([baseClassName, 'logo']);
const buttonsClassName: string = bcn([baseClassName, 'buttons']);
const finalizeClassName: string = bcn([buttonsClassName, 'finalize']);

const Retry: FC<IProps> = (props: IProps) => {
    const { onFinalize, onRetry, ErrorMessage } = props;
    return (
        <div className={baseClassName}>
            <div className={logoClassName}>
                <img src={Icon} />
                <span>{Wording.title}</span>
            </div>
            <span>{ErrorMessage || Wording.refused}</span>
            <p>{Wording.contract}</p>
            <p>{tch(Wording.options)}</p>
            <div className={buttonsClassName}>
                <div className={finalizeClassName}>
                    <CustomButton
                        color={'gray'}
                        onClick={onFinalize}
                        title={Wording.finalize}
                    />
                    <p>{Wording.callback}</p>
                </div>
                <CustomButton
                    color={'orange'}
                    onClick={onRetry}
                    title={Wording.retry}
                />
            </div>
        </div>
    );
};

export default Retry;
