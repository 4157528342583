import {
    TContract,
    TCustomerFullName,
    TAddress,
    TContact,
    TFinance,
    TFile,
    TFiles,
    TAmountWithLabel,
    TUnitPriceWithLabel,
    TProduct,
    TPackage,
    TEPInvoice,
} from 'Models';
import {
    EAmountCategory,
    ERateOption,
    EEnergy,
    EContractStatus,
    ECivility,
    EFrequency,
    EEffectiveStartRange,
    EReadingFrequency,
    ETimeframe,
    EBillingMode,
    EProcessTypes,
    EAppointmentTimeslot,
    EInvoiceRoutingMode,
} from './enums';
import { getTodayDate } from './helpers';

export const initialCustomerFullName: TCustomerFullName = {
    civility: 'MR',
    firstName: '',
    lastName: '',
};

export const initialAddress: TAddress = {
    country: 'FR',
    netArea: '',
    number: '',
    postalCode: '',
    street: '',
    townName: '',
};

export const initialContact: TContact = {
    customerFullName: {
        civility: ECivility.MR,
        firstName: '',
        lastName: '',
    },
    birthdate: '',
    address: {
        number: '',
        street: '',
        netArea: '',
        postalCode: '',
        townName: '',
        country: 'FR',
    },
    email: '',
    phone: '',
    companyName: '',
    companyType: '',
    identificationNumber: '',
    activityCode: '',
    newsletter: false,
    marketing: false,
};

export const initialFinance: TFinance = {
    paymentMode: EBillingMode.PAYMENT_SCHEDULE,
    ibanCode: '',
    bicCode: '',
    bankName: '',
    accountOwnerFirstName: '',
    accountOwnerLastName: '',
    rumCode: '',
    invoiceRoutingMode: EInvoiceRoutingMode.EMAIL,
    invoiceEmail: '',
    debitDay: '',
    termCode: '',
};

export const initialFile: TFile = {
    documentsList: [],
    financialPieces: {
        id: '',
        result: {
            status: '',
            invoice_list: [],
            payment_list: [],
        },
    },
};
export const initialEPInvoice: TEPInvoice = {
    id: '',
    partner_name: '',
    partner_reference: '',
    invoice_type: '',
    invoice_number: '',
    invoice_date: '',
    invoice_date_due: '',
    invoice_amount_total_signed: '',
    invoice_amount_residual_signed: '',
    invoice_state: '',
    hgz_invoice_nature_code: '',
    hgz_invoice_nature_text: '',
    internal_invoice_nature_code: '',
    date: '',
    payment_method_code: '',
    payment_method_name: '',
    internal_invoice_nature_text: '',
    invoicing_period_end_date: '',
    invoicing_period_start_date: '',
    status: '',
    amount: '',
    contract: '',
    partner_ref: '',
    replacing_payment_count: '0',
    energy_paid_number_replays: '0',
};
export const initialFiles: TFiles = {
    documentDate: '',
    documentKey: '',
    documentName: '',
    documentType: '',
    contractNbr: '',
    energyType: '',
};

export const initialAmountWithLabel: TAmountWithLabel = {
    label: '',
    amount: '',
    unit: '',
    category: EAmountCategory.ADDITIONAL_RATE,
    unitPrices: [],
};

export const initialUnitPriceWithLabel: TUnitPriceWithLabel = {
    label: '',
    price: '',
    unit: '',
};

export const initialProduct: TProduct = {
    productCode: '',
    productType: '',
    label: '',
    description: '',
    energy: EEnergy.EL,
    default: true,
    rateOption: undefined,
    additionalRates: undefined,
};

const today = getTodayDate();

export const initialContract: TContract = {
    contractNumber: '',
    contractStatus: EContractStatus.EFFECTIVE,
    channel: 'WEB',
    effectiveStartRange: EEffectiveStartRange.NOW,
    effectiveStartDate: "",
    subscriptionDate: today,
    sponsorNumber: '',
    energy: EEnergy.EL,
    contractCategory: '',
    deliveryCategoryCode: '',
    deliveryPoint: {
        pointOfDelivery: '',
        deliveryPointCategory: '',
        grdCode: '',
        state: '',
        gridRate: '',
        deliveryStatus: '',
        address: {
            number: '',
            street: '',
            netArea: '',
            postalCode: '',
            townName: '',
            country: 'FR',
        },
        profile: '',
        meterNumber: '',
        meterType: '',
        smartMeterStatus: '',
        netArea: '',
        readingFrequency: EReadingFrequency.MONTHLY,
        contractualCapacity: 0,
        contractualCapacityUnit: '',
        process: {
            type: EProcessTypes.MOVE_IN,
            express: false,
            supplierCalendar: '',
            appointmentTimeslot: EAppointmentTimeslot.NONE,
            index: {
                type: '',
                date: '',
                registers: [
                    {
                        value: 0,
                        timeframe: ETimeframe.UNKNOWN,
                    },
                ],
            },
        },
    },
    billingMode: EBillingMode.PAYMENT_SCHEDULE,
    installmentFrequency: EFrequency.BIMONTHLY,
    settlementFrequency: EFrequency.BIANNUAL,
    finance: {
        paymentMode: EBillingMode.PAYMENT_SCHEDULE,
        ibanCode: '',
        bicCode: '',
        bankName: '',
        accountOwnerLastName: '',
        accountOwnerFirstName: '',
        billingContact: undefined,
        rumCode: '',
        invoiceRoutingMode: EInvoiceRoutingMode.EMAIL,
        invoiceEmail: '',
        debitDay: '',
        termCode: '',
    },
    thirdParties: [],
    rateOption: ERateOption.UNKNOWN,
    chosenProduct: initialProduct,
};

export const initialPackage: TPackage = {
    id: '',
    amount: '',
    energy: EEnergy.EL,
    currency: '',
    quantityMonthly: 0,
    packageQuantity: 0,
    recommended: false,
    timeframe: ETimeframe.UNKNOWN,
    authorizedPackages: undefined,
};
