import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Types';
import WordingConstant from '../../utils/wording.json';

import Icon from '../../assets/icons/ic-wekiwi-popup-orange.png';

const Wording = WordingConstant.PaymentCBModal;

import { LoadingSpinner } from '../../libraries/withLoader';
import { CustomButton, CustomModal } from '../../components';
import {
    createPaymentCBAsync,
    resetModal,
} from '../../_actions/paymentCB.actions';
import { TEPInvoice } from 'Models';
import StripeContainer from '../StripeFormContainer/StripeFormContainer';
import { fetchFilesListAsync } from '../../_actions/file.actions';

type PaymentCBModalProps = {
    targetPiece?: TEPInvoice;
    show: boolean;
    closeModal: () => void;
};

/**
 * stepIframeLoad => 0 -> mount / 1 -> display iframe / 2 -> finish modal
 */

const PaymentCBModal: React.FC<PaymentCBModalProps> = ({
    targetPiece,
    show,
    closeModal,
}: PaymentCBModalProps) => {
    const dispatch = useDispatch();
    const [stripeState, setStripeState] = useState({ err: '', success: false });
    const { error } = useSelector((state: RootState) => ({
        error: state.paymentCBReducer.error,
    }));

    const { stripe, selectedContractNumber, customerNumber } = useSelector(
        (state: RootState) => ({
            stripe: state.paymentCBReducer.stripe,
            selectedContractNumber:
                state.contractReducer.selectedContractNumber,
            customerNumber: state.profileReducer.customerNumber,
        }),
    );

    const handleClose = () => {
        dispatch(resetModal());
        closeModal();
        dispatch(
            fetchFilesListAsync.request({
                contractNbr: selectedContractNumber,
                customerNbr: customerNumber,
                mode: 'PAYMENT',
            }),
        );
    };

    const handleRetry = () => {
        if (targetPiece) {
            dispatch(resetModal());
            setStripeState({ err: '', success: false });
            dispatch(
                createPaymentCBAsync.request({
                    dueDate: new Date().toString(),
                    payment: targetPiece,
                }),
            );
        }
    };

    const renderError = (err: string) => (
        <div className="payment-cb-modal__error">
            <img
                src={Icon}
                alt="error-icon"
                style={{ width: '6rem', height: '6rem' }}
            />

            <p>{err}</p>
            <div className="errorContainer"></div>
            <CustomButton
                color={'orange'}
                onClick={handleRetry}
                title={Wording.retry}
            />
        </div>
    );

    const renderSuccess = () => (
        <div className="payment-cb-modal__success">
            <img
                src={Icon}
                alt="success-icon"
                style={{ width: '6rem', height: '6rem' }}
            />
            <p>{Wording.financialPieces.success}</p>
            <CustomButton
                color={'orange'}
                onClick={handleClose}
                title={Wording.button_validate}
            />
        </div>
    );

    const renderStripe = () => (
        <StripeContainer
            stripeInformations={stripe!}
            handleClose={handleClose}
            onFormValidate={(success, err) => {
                if (success) setStripeState({ err: '', success: true });
                else setStripeState({ err: err!, success: false });
            }}
        />
    );

    const renderContent = () => {
        if (!stripe && !error) {
            return (
                <div className="payment-cb-modal__loading">
                    <LoadingSpinner />
                </div>
            );
        }
        if (error || stripeState.err) {
            return renderError(error ? error : stripeState.err);
        }

        if (stripeState.success) {
            return renderSuccess();
        }

        return renderStripe();
    };

    return (
        <CustomModal
            className="payment-cb-modal"
            showCloseButton
            show={show}
            handleClick={handleClose}
        >
            <div>
                <h3>{Wording.title}</h3>

                {renderContent()}
            </div>
        </CustomModal>
    );
};

export default PaymentCBModal;
