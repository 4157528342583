import React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils, {
    parseDate,
    formatDate,
} from 'react-day-picker/moment';
import 'moment/locale/fr';

type Props = {
    text?: string;
    error?: string;
    selectedDay?: Date;
    isOpen?: boolean;
    deleteButton?: boolean;
    handleDayChange?: (selectedDay: Date) => void;
    deleteButtonOnClick?: () => void;
};

const CustomDate: React.FC<Props> = props => {
    const modifiers = {
        selectedDay: props.selectedDay,
    };
    const modifiersStyles = {
        selectedDay: {
            color: 'white',
            backgroundColor: '#7571f4',
        },
    };

    return (
        <div className="custom-date">
            {props.text && (
                <label htmlFor={`id${props.text}`}>{props.text}</label>
            )}

            {props.deleteButton && props.selectedDay !== undefined && (
                <span
                    className="custom-date__delete"
                    onClick={props.deleteButtonOnClick}
                >
                    <span>X</span>
                </span>
            )}

            <DayPickerInput
                formatDate={formatDate}
                parseDate={parseDate}
                format="DD/MM/YYYY"
                placeholder="JJ/MM/AAAA"
                value={!props.selectedDay ? '' : props.selectedDay}
                onDayChange={props.handleDayChange}
                dayPickerProps={{
                    modifiersStyles,
                    modifiers,
                    selectedDays: props.selectedDay,
                    locale: 'fr',
                    localeUtils: MomentLocaleUtils as any,
                }}
            />

            {props.error && props.error !== '' && (
                <p className="react-picker-error">{props.error}</p>
            )}
        </div>
    );
};
export default CustomDate;
