import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    ActionType,
    isActionOf,
    createStandardAction,
} from 'typesafe-actions';
import { ReducerError, RootAction, RootState, Services, TError } from 'Types';
import {
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    RESET_CREATE_USER_RESPONSE,
} from './actionTypes';
import { TResponse } from 'Models';
import { Epic } from 'redux-observable';

export type TCreateUserPayload = Partial<{
    group: string;
    firstName: string;
    lastName: string;
    email: string;
    civility: string;
    userName?: string;
}>;

const createUserAsync = createAsyncAction(
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
)<TCreateUserPayload, TResponse, ReducerError>();

const resetCreateUserResponse = createStandardAction(
    RESET_CREATE_USER_RESPONSE,
)();

export type CreateUserAction =
    | ActionType<typeof createUserAsync>
    | ActionType<typeof resetCreateUserResponse>;

const preparePayloadCreateUser = (
    payload: TCreateUserPayload,
): TCreateUserPayload => {
    return { ...payload };
};

const mapCreateUser = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadCreateUser(action.payload);
    return apiRequest<TResponse>({
        path: '/createUser',
        method: 'post',
        body: payload,
        isPay: false,
        isSubscription: false,
    }).pipe(
        mergeMap((response: any & TError) => {
            if (
                response.code === 'MISSING_PARAMETER' ||
                response.code === 'NO_RESPONSE_FROM_PARTNER'
            ) {
                return of(
                    createUserAsync.failure({
                        code: response.code,
                        message: response.description,
                    }),
                );
            }
            return of(createUserAsync.success(response));
        }),
        catchError(error => {
            return of(
                createUserAsync.failure({
                    code: error.code,
                    message: error.description,
                }),
            );
        }),
    );
};

const createUserEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(createUserAsync.request)),
        mergeMap(action => mapCreateUser(action, dependency)),
    );

export {
    createUserAsync,
    createUserEpic,
    mapCreateUser,
    resetCreateUserResponse,
};
