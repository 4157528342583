import React from 'react';
import { EActionMode, EGroups } from '../../../utils/enums';
import { CustomRadio, CustomCheckbox, CustomToggle } from '../..';

import WordingConstant from '../../../utils/wording.json';
import { IAccessItem, IItem, ISubMenuItem } from './AuthorizationSchemaGroup';

const Wording = WordingConstant.AuthorizationSchemaGroup;

export interface IAuthSchemaItemProps {
    item: IItem;
    handleSetSubMenu: any;
    handleSetActionMod: any;
    handleSetCheckbox: any;
    handleSetAccess: (props: IAccessItem) => void;
    itemValues: {
        isChecked: boolean;
        mode: string;
        subMenus: any;
        accessItem: any;
    };
}

const AuthSchemaItem: React.FC<IAuthSchemaItemProps> = ({
    item,
    handleSetCheckbox,
    handleSetActionMod,
    handleSetSubMenu,
    handleSetAccess,
    itemValues: { isChecked, mode, subMenus, accessItem },
}) => {
    const renderReadOnlyType = () => (
        <div className="authorization-schema-item__read-only">
            {Wording.accessItems.map(({ value, label }) => {
                const readOnlyItems = (Wording.subMenuItems as {
                    [key: string]: ISubMenuItem[];
                })[item.uId].filter((elm: any) => elm.readeOnly);

                const current = readOnlyItems[0];

                const isChecked =
                    accessItem[current.uId][value] === EGroups.FULL;

                return (
                    <CustomCheckbox
                        key={value}
                        text={label}
                        handleCheck={() => {
                            handleSetAccess({
                                uIdItem: item.uId,
                                uIdMenu: current.uId,
                                type: value,
                                value: isChecked
                                    ? EGroups.LIMITED
                                    : EGroups.FULL,
                            });
                        }}
                        checked={isChecked}
                    />
                );
            })}
        </div>
    );

    const renderByUIdOfItem = () => {
        return (
            <div className="authorization-schema-item__sub-menu-items">
                {(Wording.subMenuItems as { [key: string]: ISubMenuItem[] })[
                    item.uId
                ].map((subMenuItem, idx) => (
                    <div
                        key={idx}
                        className="authorization-schema-item__sub-menu-items__item-menu"
                    >
                        <CustomToggle
                            checked={
                                subMenus[subMenuItem.uId]
                                    ? subMenus[subMenuItem.uId] !== undefined
                                    : false
                            }
                            text={subMenuItem.label}
                            handleClick={() => {
                                handleSetSubMenu({
                                    uIdItem: item.uId,
                                    subMenuItem,
                                });
                            }}
                            handleCheck={() => {}}
                            value={subMenuItem.uId}
                        />

                        <div>
                            {Wording.accessItems.map(
                                ({ value, label, access }) => {
                                    const isChecked =
                                        accessItem[subMenuItem.uId][value] ===
                                        EGroups.FULL;

                                    const condition = access
                                        ? access.includes(subMenuItem.uId)
                                        : true;

                                    if (condition) {
                                        return (
                                            <CustomCheckbox
                                                key={value}
                                                text={label}
                                                handleCheck={() => {
                                                    handleSetAccess({
                                                        uIdItem: item.uId,
                                                        uIdMenu:
                                                            subMenuItem.uId,
                                                        type: value,
                                                        value: isChecked
                                                            ? EGroups.LIMITED
                                                            : EGroups.FULL,
                                                    });
                                                }}
                                                checked={isChecked}
                                            />
                                        );
                                    }

                                    return null;
                                },
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderByActionType = () => {
        switch (mode) {
            case EActionMode.FULL:
                return renderByUIdOfItem();

            case EActionMode.READ_ONLY:
                return renderReadOnlyType();

            default:
                break;
        }
    };

    return (
        <div className="authorization-schema-item">
            <CustomCheckbox
                text={item.label}
                handleCheck={() => handleSetCheckbox(item)}
                checked={isChecked}
            />

            {isChecked && (
                <div className="authorization-schema-item__modes">
                    {Wording.actionModes.map((aMod, idx) => (
                        <CustomRadio
                            key={idx}
                            text={aMod.label}
                            handleCheck={() =>
                                handleSetActionMod({
                                    uId: item.uId,
                                    value: aMod.value,
                                })
                            }
                            checked={mode === aMod.value}
                            value={aMod.value || EActionMode.FULL}
                        />
                    ))}
                </div>
            )}

            {isChecked && renderByActionType()}
        </div>
    );
};

export default AuthSchemaItem;
