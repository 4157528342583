import React, { Fragment, useEffect, useState } from 'react';
import {
    CustomButton,
    CustomInput,
    CustomModal,
    CustomSelect,
} from '../../../../../components';
import {
    TContract,
    TEstimate,
    EEnergyTypes,
    ERateOptions,
    ETimeframes,
} from '../../../../../utils/network/types';
import WordingConstant from '../../../../../utils/wording.json';
const Wording =
    WordingConstant.AdminSubscriptionContainer.Offer.clientConsumption;

interface IProps {
    open: boolean;
    contracts: TContract[] | null;
    electricityEnabled: boolean;
    gasEnabled: boolean;
    handleModalClick: () => void;
    handleEstimatesPackagesByClient: (
        estimates: TEstimate[],
        energies: EEnergyTypes[],
    ) => void;
}

type Rate = {
    id: number;
    value: ERateOptions;
    label: string;
};

interface IState {
    HIGH_LOW?: {
        EL: {
            HIGH: string;
            LOW: string;
        };
        NG: {
            HIGH: string;
            LOW: string;
        };
    };
    TOTAL_HOUR?: {
        EL: string;
        NG: string;
    };
    UNKNOWN?: {
        EL: string;
        NG: string;
    };

    [key: string]: any;
}

interface IBuildResponse {
    estimates: TEstimate[];
    energies: EEnergyTypes[];
}

const initState = {
    HIGH_LOW: { EL: { HIGH: '', LOW: '' }, NG: { LOW: '', HIGH: '' } },
    TOTAL_HOUR: { EL: '', NG: '' },
    UNKNOWN: { EL: '', NG: '' },
};

const ClientConsumption: React.FC<IProps> = ({
    open,
    handleModalClick,
    contracts,
    handleEstimatesPackagesByClient,
    electricityEnabled,
    gasEnabled,
}) => {
    const [state, setState] = useState<IState>(initState);
    const [isValide, setValide] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showEstimateErr, setEstimetesErr] = useState(false);

    const initStateValues = (contracts: TContract[]) => {
        const nextState = initState;

        const buildByEnergy = (energy: 'EL' | 'NG') => {
            const currContract = contracts.find(ctr => ctr.energy === energy);
            if (currContract) {
                const rate = buildRateOption(currContract);

                if (rate === ERateOptions.HIGH_LOW) {
                    const st = (currContract.estimates as TEstimate[]).reduce(
                        (acc, curr) => {
                            return {
                                ...acc,
                                [energy]: {
                                    // @ts-ignore
                                    ...acc[energy],
                                    [curr.timeframe as any]: curr.quantity,
                                },
                            };
                        },
                        {},
                    );

                    nextState.HIGH_LOW = {
                        ...nextState.HIGH_LOW,
                        ...st,
                    };
                }

                if (
                    rate === ERateOptions.TOTAL_HOUR ||
                    rate === ERateOptions.UNKNOWN
                ) {
                    if (currContract.estimates) {
                        const currEstimate = (currContract.estimates as TEstimate[])[0];
                        nextState[rate] = {
                            ...nextState[rate],
                            [energy]: currEstimate.quantity
                                ? currEstimate.quantity.toString()
                                : '',
                        };
                    }
                }
            }
        };

        if (electricityEnabled) {
            buildByEnergy('EL');
        }

        if (gasEnabled) {
            buildByEnergy('NG');
        }

        setState(nextState);
    };

    useEffect(() => {
        if (contracts) {
            initStateValues(contracts);
        }
    }, [contracts]);

    useEffect(() => {
        const checkByEnergy = (en: { HIGH: string; LOW: string }) =>
            en.HIGH !== '' && en.LOW !== '';

        const checkGas = (val: string) => (gasEnabled ? val !== '' : true);
        const checkElec = (val: string) =>
            electricityEnabled ? val !== '' : true;

        let valide = false;

        if (contracts) {
            contracts.forEach(ctr => {
                const rate = buildRateOption(ctr);

                switch (rate) {
                    case ERateOptions.TOTAL_HOUR:
                        valide = Boolean(
                            ctr.energy === 'EL'
                                ? state.TOTAL_HOUR &&
                                      checkElec(state.TOTAL_HOUR.EL)
                                : state.TOTAL_HOUR &&
                                      checkGas(state.TOTAL_HOUR.NG),
                        );

                        break;

                    case ERateOptions.UNKNOWN:
                        valide = Boolean(
                            ctr.energy === 'EL'
                                ? state.UNKNOWN && checkElec(state.UNKNOWN.EL)
                                : state.UNKNOWN && checkGas(state.UNKNOWN.NG),
                        );
                        break;

                    case ERateOptions.HIGH_LOW:
                        let hightLowCheck = true;

                        if (gasEnabled && ctr.energy === 'NG') {
                            hightLowCheck = Boolean(
                                hightLowCheck &&
                                    state.HIGH_LOW &&
                                    checkByEnergy(state.HIGH_LOW.NG),
                            );
                        }
                        if (electricityEnabled && ctr.energy === 'EL') {
                            hightLowCheck = Boolean(
                                hightLowCheck &&
                                    state.HIGH_LOW &&
                                    checkByEnergy(state.HIGH_LOW.EL),
                            );
                        }

                        valide = hightLowCheck;
                        break;

                    default:
                        break;
                }
            });
        }
        setValide(valide);
    }, [state]);

    if (!contracts) {
        return null;
    }

    const handleSetTotalHourAndUnknown = (
        rate: ERateOptions,
        energy: 'EL' | 'NG',
        value: string | number,
    ) => {
        setState({
            ...state,
            [rate]: {
                ...state[rate],
                [energy]: value,
            },
        });
    };

    const getInitHighLowState = (st?: {
        EL: {
            HIGH: string;
            LOW: string;
        };
        NG: {
            LOW: string;
            HIGH: string;
        };
    }) => (st ? st : initState.HIGH_LOW);

    const handleSetHighLow = (
        energy: 'EL' | 'NG',
        type: 'HIGH' | 'LOW',
        value: string | number,
    ) => {
        setState({
            ...state,
            HIGH_LOW: {
                ...initState.HIGH_LOW,
                ...state.HIGH_LOW,
                [energy]: {
                    ...getInitHighLowState(state.HIGH_LOW)[energy],
                    [type]: value,
                },
            },
        });
    };

    const buildResponse = (): IBuildResponse =>
        contracts.reduce(
            (acc, curr) => {
                const rate = buildRateOption(curr);
                if (rate && rate !== '') {
                    const nextEstimate: TEstimate[] = (() => {
                        if (rate === ERateOptions.HIGH_LOW) {
                            const nextState = state.HIGH_LOW as {
                                EL: {
                                    HIGH: string;
                                    LOW: string;
                                };
                                NG: {
                                    HIGH: string;
                                    LOW: string;
                                };
                            };

                            return [ETimeframes.HIGH, ETimeframes.LOW].map(
                                t => {
                                    const typeKey = t as 'HIGH' | 'LOW';
                                    return {
                                        energy: curr.energy,
                                        timeframe: t,
                                        quantity: parseInt(
                                            nextState[curr.energy][typeKey],
                                            10,
                                        ),
                                    };
                                },
                            );
                        }

                        const nextTimeframe =
                            rate === ERateOptions.TOTAL_HOUR
                                ? ETimeframes.TOTAL_HOUR
                                : ETimeframes.UNKNOWN;
                        return [
                            {
                                energy: curr.energy,
                                timeframe: nextTimeframe,
                                quantity: parseInt(
                                    (state[rate] as {
                                        EL: string;
                                        NG: string;
                                    })[curr.energy],
                                    10,
                                ),
                            },
                        ];
                    })();
                    return {
                        energies: [...acc.energies, curr.energy],
                        estimates: [...acc.estimates, ...nextEstimate],
                    };
                }

                return {
                    energies: [...acc.energies, curr.energy],
                    estimates: [...acc.estimates],
                };
            },
            { energies: [], estimates: [] } as IBuildResponse,
        );

    const handleCheckEstimates = () => {
        return contracts.reduce((acc, curr) => {
            const currRate = buildRateOption(curr);

            if (curr.estimates && currRate) {
                if (currRate === ERateOptions.HIGH_LOW) {
                    return curr.estimates.reduce(
                        (esAcc, esCurr) =>
                            Boolean(
                                state.HIGH_LOW &&
                                    checkQuantity(
                                        state.HIGH_LOW[curr.energy][
                                            esCurr.timeframe as 'HIGH' | 'LOW'
                                        ],
                                        esCurr.quantity as number,
                                    ),
                            ) && esAcc,
                        true,
                    );
                }

                const findCurrEstim = curr.estimates.find(
                    ({ timeframe }) => (timeframe as any) === currRate,
                );
                const rateState = state[currRate];

                return rateState && findCurrEstim && findCurrEstim.quantity
                    ? checkQuantity(
                          rateState[curr.energy] as string,
                          findCurrEstim.quantity,
                      ) && acc
                    : acc;
            }
            return acc;
        }, true);
    };

    const init = () => {
        setShowError(false);
        setEstimetesErr(false);
        setValide(false);

        overrideModalClick();
    };

    const handleValidate = () => {
        if (!isValide) {
            setShowError(true);
            return;
        }

        if (!handleCheckEstimates()) {
            setEstimetesErr(true);
            return;
        }
        if (showEstimateErr) {
            setEstimetesErr(false);
        }

        const { estimates, energies } = buildResponse();
        handleEstimatesPackagesByClient(estimates, energies);

        init();
    };

    const checkQuantity = (coming: string, estQuantity: number) =>
        parseInt(coming, 10) > 0.75 * estQuantity;

    const renderByRateOption = (
        rateProp: ERateOptions,
        energy: 'EL' | 'NG',
        estimates: TEstimate[],
    ) => {
        switch (rateProp) {
            case ERateOptions.TOTAL_HOUR:
            case ERateOptions.UNKNOWN:
                let currentEstimate = null;
                const value = state[rateProp]
                    ? (state[rateProp] as any)[energy]
                    : '';
                if (estimates) {
                    currentEstimate = estimates.find(
                        est => (est.timeframe as any) === rateProp,
                    );
                }

                const checkEstimate = Boolean(
                    currentEstimate &&
                        currentEstimate.quantity &&
                        checkQuantity(value, currentEstimate.quantity),
                );

                const error = (() => {
                    if (showError && value === '') {
                        return Wording.errMessage;
                    }

                    return showEstimateErr && !checkEstimate
                        ? `${Wording.estimateErr} ${
                              currentEstimate && currentEstimate.quantity
                                  ? `${0.75 * currentEstimate.quantity} kWh`
                                  : ''
                          }`
                        : undefined;
                })();

                return (
                    <>
                        <CustomInput
                            type="number"
                            text={
                                Wording[
                                    energy === 'EL' ? 'elecInput' : 'gasInput'
                                ]
                            }
                            value={value}
                            onInputChange={e =>
                                handleSetTotalHourAndUnknown(
                                    rateProp,
                                    energy,
                                    e.target.value,
                                )
                            }
                            error={error}
                        />

                        <p>
                            {Wording.initialConsumption}{' '}
                            {currentEstimate && currentEstimate.quantity
                                ? currentEstimate.quantity
                                : ''}{' '}
                            kWh
                        </p>
                    </>
                );

            case ERateOptions.HIGH_LOW:
                return (
                    <div className="client-consumption-content__high-low">
                        <h4>
                            {
                                Wording[
                                    energy === 'EL' ? 'elecInput' : 'gasInput'
                                ]
                            }
                        </h4>

                        <div>
                            {['HIGH', 'LOW'].map(t => {
                                const typeKey = t as 'HIGH' | 'LOW';
                                const value = state.HIGH_LOW
                                    ? state.HIGH_LOW[energy][typeKey]
                                    : '';

                                const currentEstimate = estimates.find(
                                    est =>
                                        (est.timeframe as any) ===
                                        (rateProp === ERateOptions.HIGH_LOW
                                            ? typeKey
                                            : rateProp),
                                );

                                const checkEstimate = Boolean(
                                    currentEstimate &&
                                        currentEstimate.quantity &&
                                        checkQuantity(
                                            value,
                                            currentEstimate.quantity,
                                        ),
                                );

                                const error = (() => {
                                    if (showError && value === '') {
                                        return Wording.errMessage;
                                    }

                                    return showEstimateErr && !checkEstimate
                                        ? `${Wording.estimateErr} ${
                                              currentEstimate &&
                                              currentEstimate.quantity
                                                  ? `${0.75 *
                                                        currentEstimate.quantity} kWh`
                                                  : ''
                                          }`
                                        : undefined;
                                })();

                                return (
                                    <div
                                        key={t}
                                        className="client-consumption-content__high-low__inputs"
                                    >
                                        <CustomInput
                                            key={t}
                                            type="number"
                                            text={Wording[typeKey]}
                                            value={value}
                                            onInputChange={e =>
                                                handleSetHighLow(
                                                    energy,
                                                    typeKey,
                                                    e.target.value,
                                                )
                                            }
                                            error={error}
                                        />

                                        <p>
                                            {Wording.initialConsumption}{' '}
                                            {currentEstimate &&
                                            currentEstimate.quantity
                                                ? currentEstimate.quantity
                                                : ''}{' '}
                                            kWh
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    const overrideModalClick = () => {
        handleModalClick();

        if (contracts) {
            initStateValues(contracts);
        }
    };

    const buildRateOption = (ctr: TContract) => {
        if (ctr.rateOption) {
            return ctr.rateOption;
        }
        let rt = '';

        if (ctr.estimates) {
            if (ctr.estimates.length === 2) {
                rt = ERateOptions.HIGH_LOW;
            } else {
                rt = ctr.estimates[0].timeframe || '';
            }
        }

        return rt;
    };

    return (
        <CustomModal
            show={open}
            showCloseButton
            handleClick={overrideModalClick}
            customStyle={{ height: 'fit-content', border: 'solid 4px black' }}
        >
            <div className="client-consumption-content">
                <h3>{Wording.title}</h3>

                {contracts.map((ctr, key) => {
                    if (
                        (electricityEnabled && ctr.energy === 'EL') ||
                        (gasEnabled && ctr.energy === 'NG')
                    ) {
                        const currRate = buildRateOption(ctr);
                        return (
                            <Fragment key={key}>
                                {renderByRateOption(
                                    currRate as ERateOptions,
                                    ctr.energy,
                                    ctr.estimates as TEstimate[],
                                )}
                            </Fragment>
                        );
                    }
                })}

                {showEstimateErr && (
                    <div className="client-consumption-content__estimates-error">
                        <p>{Wording.mainEstimateErr}</p>
                    </div>
                )}

                <div className="client-consumption-content__buttons">
                    <CustomButton
                        title={Wording.btnBack}
                        color={'gray'}
                        onClick={overrideModalClick}
                    />
                    <CustomButton
                        title={Wording.btnValidate}
                        color={'orange'}
                        onClick={handleValidate}
                    />
                </div>
            </div>
        </CustomModal>
    );
};

export default ClientConsumption;
