import React from 'react';

const baseClassName = 'section-separator';

const Separator = () => (
    <div className={baseClassName}>
        <div />
    </div>
);

export default Separator;
