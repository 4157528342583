import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { SelectOption } from 'Types';

export interface IStateElements {
    isMenuOpen: boolean;
}

export interface IProps {
    disabled?: boolean | undefined;
    id?: string;
    text?: string;
    value?: any;
    defaultValue?: any;
    options?: Array<SelectOption<any>>;
    placeholder?: string;
    isClearable?: boolean;
    handleChangeSelect: any;
    error?: string | undefined | SelectOption<any>;
    isMulti?: boolean;
    inputValue?: any;
    onInputChange?: (input: string) => void;
}

const indicatorSeparatorStyle = {
    alignSelf: 'stretch',
    // backgroundColor: colourOptions[2].color,
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

const IndicatorSeparator = ({ innerProps }: any) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

class CustomSelect extends React.Component<IProps> {
    state: IStateElements = {
        isMenuOpen: false,
    };

    handleChange(val: boolean): void {
        this.setState({ isMenuOpen: val });
    }

    render() {
        const {
            id,
            text,
            value,
            defaultValue,
            handleChangeSelect,
            options,
            placeholder,
            error,
            onInputChange,
            isClearable,
            isMulti,
            inputValue,
            disabled,
        } = this.props;
        const current: SelectOption<any> | undefined =
            options && options.find(option => option.value === defaultValue);
        const { isMenuOpen } = this.state;

        const renderClearable = () => (
            <CreatableSelect
                className={`custom-select__content__select ${
                    isMenuOpen ? 'custom-select__content__select--menuOpen' : ''
                }`}
                classNamePrefix="react-select"
                isClearable
                onChange={handleChangeSelect}
                onInputChange={onInputChange}
                inputValue={inputValue}
                options={options}
                isDisabled={!!disabled}
                placeholder={_.get(current, 'label') || placeholder}
                formatCreateLabel={(value: string) =>
                    `Créer : ${value.toUpperCase()}`
                }
            />
        );

        const renderDefault = () => (
            <Select
                className={`custom-select__content__select ${
                    isMenuOpen ? 'custom-select__content__select--menuOpen' : ''
                }`}
                classNamePrefix="react-select"
                value={value}
                placeholder={_.get(current, 'label') || placeholder}
                defaultValue={defaultValue}
                onChange={handleChangeSelect}
                onInputChange={onInputChange}
                options={options}
                onMenuOpen={() => this.handleChange(true)}
                onMenuClose={() => this.handleChange(false)}
                isDisabled={!!disabled}
            />
        );

        const renderMulti = () => (
            <Select
                className={`custom-select__content__select ${
                    isMenuOpen ? 'custom-select__content__select--menuOpen' : ''
                }`}
                classNamePrefix="react-select"
                value={value}
                placeholder={_.get(current, 'label') || placeholder}
                defaultValue={defaultValue}
                onChange={handleChangeSelect}
                options={options}
                isDisabled={!!disabled}
                isMulti
                closeMenuOnSelect={false}
                components={{ IndicatorSeparator }}
            />
        );

        const manageSelect = () => {
            if (isClearable) return renderClearable();
            if (isMulti) return renderMulti();

            return renderDefault();
        };

        return (
            <div className="custom-select">
                <div className="custom-select__content">
                    <label htmlFor={id}>{text}</label>

                    {manageSelect()}
                </div>

                {error && error !== '' && (
                    <p className="custom-select__error">{error}</p>
                )}
            </div>
        );
    }
}

export default CustomSelect;
