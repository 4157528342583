import React, { Fragment } from 'react';

import ElecIcon from '../../../../assets/icons/PDFs/ProofOfPayment/ic-elec.png';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { useTimeline } from '../lib';

import WordingConstant from '../wording.json';

const Wording = WordingConstant;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        display: 'flex',
        borderWidth: 2,
        borderColor: '#000000',
        borderRadius: '25',
        width: '80%',
        marginTop: 15,
        marginLeft: '10%',
        padding: 5,
    },
    subContainerImage: {
        width: '20%',
        display: 'flex',
        alignItems: 'center',
    },
    subContainerLeft: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '35%',
        fontFamily: 'Montserrat',
        marginRight: 0,
        fontSize: 11,
        fontWeight: 'bold',
    },
    subContainerRight: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '40%',
        marginLeft: 25,
        fontFamily: 'Montserrat',
        fontSize: 11,
        fontWeight: 100,
    },
    whiteBloc: {
        flexDirection: 'row',
    },
    energyImg: {
        width: 40,
        height: 60,
        marginTop: 20,
    },
    lastText: {
        marginTop: 15,
    },
});

const CustomerContainer = () => {
    const { customer, address, contract } = useTimeline();

    return (
        <Fragment>
            <View style={styles.container}>
                <View style={styles.whiteBloc}>
                    <View style={styles.subContainerImage}>
                        <Image style={styles.energyImg} src={ElecIcon} />
                    </View>
                    <View style={styles.subContainerLeft}>
                        <Text>{Wording.customerContainer.address}</Text>
                        <Text>{Wording.customerContainer.postalCode}</Text>
                        <Text>{Wording.customerContainer.townName}</Text>
                        <Text>{Wording.customerContainer.clientName}</Text>
                        <Text>{Wording.customerContainer.clientNumber}</Text>
                        <Text style={styles.lastText}>
                            {Wording.customerContainer.contractNumber}
                        </Text>
                    </View>
                    <View style={styles.subContainerRight}>
                        <Text>
                            {address.number} {address.street}
                        </Text>
                        <Text>{address.postalCode}</Text>
                        <Text>{address.townName}</Text>
                        <Text>
                            {customer.name.lastName} {customer.name.firstName}
                        </Text>
                        <Text>{customer.customerNbr}</Text>
                        <Text style={styles.lastText}>
                            {contract.contractNumber}
                        </Text>
                    </View>
                </View>
            </View>
        </Fragment>
    );
};

export default CustomerContainer;
