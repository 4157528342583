import React from 'react';

import { CSVLink } from 'react-csv';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import CustomButton from '../CustomButton';

export const LoadingComponent: React.FC<{ loading: boolean }> = ({
    loading,
}) => {
    if (loading) {
        return (
            <div className="custom-loading -loading -active">
                <div className="custom-loading-inner -loading-inner">
                    Chargement en cours...
                </div>
            </div>
        );
    }
    return null;
};

type Props = {
    columns: object[];
    data: any;
    title?: string;
    loading?: boolean;
    defaultPageSize?: number;
    pageSize?: number;
    noDataText?: string;
    sortable?: boolean;
    buttonTitle?: string;
    error?: string;
    SubComponent?: any;
    PaginationComponent?: any;
    showPagination?: boolean;
    showPaginationBottom?: boolean;
    downloadData?: any;
    downloadHeaders?: any;
    downloadFilename?: string;
    downloadButtonTitle?: string;
    className?: string;
    filterable?: boolean;
    handleButtonClick?(): void;
};

const CustomTable: React.FC<Props> = ({
    columns,
    data,
    title,
    loading,
    defaultPageSize,
    noDataText,
    sortable,
    buttonTitle,
    handleButtonClick,
    error,
    SubComponent,
    PaginationComponent,
    showPagination,
    showPaginationBottom,
    className,
    downloadHeaders,
    downloadData,
    downloadFilename,
    downloadButtonTitle,
    pageSize,
    filterable,
}) => (
    <div className="custom-table">
        {title && <h3>{title}</h3>}

        <ReactTable
            className={`custom-table__table ${className ? className : ''}`}
            columns={columns}
            data={data}
            pageSize={
                pageSize && !showPagination
                    ? pageSize
                    : !data
                    ? 2
                    : defaultPageSize
            }
            filterable={filterable}
            defaultPageSize={defaultPageSize}
            showPaginationBottom={showPaginationBottom === true}
            loading={loading}
            LoadingComponent={LoadingComponent}
            PaginationComponent={PaginationComponent}
            noDataText={noDataText}
            sortable={sortable}
            SubComponent={SubComponent}
            showPagination={showPagination}
            getTrProps={(state: any, rowInfo: any, column: any) => {
                if (rowInfo === undefined) {
                    return {};
                }
                return {
                    'data-qnt':
                        rowInfo.original
                            .SOME_VALUE_WITH_QUANTITY_OF_CHILDREN_ROWS,
                };
            }}
        />
        {!error && buttonTitle && (
            <CustomButton
                title={buttonTitle}
                color="orange"
                onClick={handleButtonClick}
            />
        )}
        {downloadButtonTitle && downloadData && (
            <CSVLink
                className="custom-table__downloadLink"
                data={downloadData}
                headers={downloadHeaders}
                filename={downloadFilename}
            >
                {downloadButtonTitle}
            </CSVLink>
        )}
    </div>
);

export default CustomTable;
