import React, { useState, useEffect, useRef } from 'react';
import { CustomModal, CustomButton } from '../../../components';
import WordingConstant from '../../../utils/wording.json';
import _ from 'lodash';
import { TUser, TResponse } from 'Models';
import CreateUserModal from '../UserAdministrationContainer/CreateUserModal';
import CustomTable from '../../../components/admin/CustomTable';
import { isEmpty } from '../../../utils/helpers';
import resources from '../../../utils/resources';
import RemoveUserButton from '../UserAdministrationContainer/RemoveUserButton';
import EnableOrDesableuserButton from '../UserAdministrationContainer/EnableOrDesableUserButton';
import useOutsideClick from '../../../libraries/useOutsideClick';
import { TSchemaProps } from 'Types';

const Wording = WordingConstant.UserAdministrationResult;

interface IPayloadUser {
    group: string;
    firstName: string;
    lastName: string;
    email: string;
    civility: string;
}
interface IPayloadManageUser {
    mode: string;
    firstName: string;
    lastName: string;
    userName: string;
}
interface IPayload {
    group: string;
}

type Props = {
    groupList: string[];
    createUser: (props: IPayloadUser) => void;
    createUserResponse: TResponse;
    resetCreateUserResponse: () => void;
    resetGroupListResponse: () => void;
    manageUser: (props: IPayloadManageUser) => void;
    administrationList: TUser[];
    resetAdminClientSpaceResponse: () => void;
    resetManageUserResponse: () => void;
    error: string;
    currentUser?: any;
    manageUserResponse: TResponse;
    schemaProps: TSchemaProps;
};

const AdministrationClientSpace: React.FC<Props> = ({
    groupList,
    createUser,
    createUserResponse,
    resetCreateUserResponse,
    administrationList,
    resetAdminClientSpaceResponse,
    error,
    currentUser,
    manageUser,
    manageUserResponse,
    resetManageUserResponse,
    schemaProps,
}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [openUserModal, setUserModal] = useState<boolean>(false);
    const [openAdminSuccessModal, setAdminSuccessModal] = useState<boolean>(
        false,
    );
    const [handleShowPopUp, setHandleShowPopUp] = useState<boolean>(false);

    useEffect(() => {
        administrationList.length === 0 ? setOpen(true) : setOpen(false);
        setAdminSuccessModal(administrationList.length > 0 ? true : false);
    }, [administrationList]);
    const divRef = useRef<HTMLDivElement>(null);

    useOutsideClick(divRef, () => {
        if (handleShowPopUp) setHandleShowPopUp(false);
    });

    const renderCreateUserModal = () => {
        return (
            <CustomModal show={isOpen}>
                <span
                    className="btn-close"
                    onClick={() => {
                        setOpen(false);
                        resetAdminClientSpaceResponse();
                    }}
                >
                    {'X'}
                </span>

                <div className="administration-user-modal">
                    <p className="warning-title">{Wording.modalTitle}</p>

                    <div className="administration-user-modal__buttons">
                        <CustomButton
                            onClick={() => {
                                setOpen(false);
                                resetAdminClientSpaceResponse();
                            }}
                            color="orange"
                            title="Retour"
                        />

                        <CustomButton
                            onClick={() => {
                                setOpen(false);
                                setUserModal(true);
                                resetAdminClientSpaceResponse();
                            }}
                            color="gray"
                            title="Créer un utilisateur"
                        />
                    </div>
                </div>
            </CustomModal>
        );
    };

    const prepareColumns = () => {
        return [
            {
                Header: Wording.group,
                accessor: 'userGroup',
                filterable: false,
            },
            {
                Header: Wording.userId,
                accessor: 'userName',
                filterable: false,
            },
            {
                Header: Wording.clientLastName,
                accessor: 'lastName',
                filterable: false,
            },
            {
                Header: Wording.clientFirstName,
                accessor: 'firstName',
                filterable: false,
            },
            {
                Header: Wording.clientEmail,
                accessor: 'email',
                filterable: false,
            },
            {
                Header: Wording.status,
                accessor: 'userStatus',
                filterable: false,
            },
            {
                Header: '',
                accessor: '',
                filterable: false,
                Cell: (original: any) => {
                    return (
                        <div>
                            <img
                                className="menu-style"
                                src={resources['ic-menu-h']}
                                alt="ic-menu"
                                onClick={() => {
                                    setHandleShowPopUp(!handleShowPopUp);
                                }}
                            />

                            {handleShowPopUp && (
                                <div ref={divRef} className="pop-up">
                                    <RemoveUserButton
                                        setAdminSuccessModal={
                                            setAdminSuccessModal
                                        }
                                        original={original}
                                        manageUser={manageUser}
                                        resetAdminClientSpaceResponse={
                                            resetAdminClientSpaceResponse
                                        }
                                        manageUserResponse={manageUserResponse}
                                        resetManageUserResponse={
                                            resetManageUserResponse
                                        }
                                        isAdminSpace={true}
                                    />

                                    <EnableOrDesableuserButton
                                        status={_.get(
                                            original.original,
                                            'userStatus',
                                            '',
                                        )}
                                        original={original}
                                        manageUser={manageUser}
                                        setAdminSuccessModal={
                                            setAdminSuccessModal
                                        }
                                        isAdminSpace={true}
                                        manageUserResponse={manageUserResponse}
                                        resetManageUserResponse={
                                            resetManageUserResponse
                                        }
                                        resetAdminClientSpaceResponse={
                                            resetAdminClientSpaceResponse
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    );
                },
            },
        ];
    };

    const administrationUserSuccess = () => {
        return (
            <CustomModal show={openAdminSuccessModal}>
                <span
                    className="btn-close"
                    onClick={() => {
                        setAdminSuccessModal(false);
                        resetAdminClientSpaceResponse();
                    }}
                >
                    {'X'}
                </span>

                <div className="administration-user-success-modal">
                    <p className="warning-title">
                        {'Administrer espace client'}
                    </p>
                    {isEmpty(administrationList) && (
                        <CustomTable
                            columns={prepareColumns()}
                            data={administrationList}
                            className="-striped -highlight"
                            showPagination={false}
                            filterable={false}
                            downloadData={administrationList}
                            downloadButtonTitle={''}
                        />
                    )}

                    <div className="administration-user-success-modal__buttons">
                        <CustomButton
                            onClick={() => {
                                setAdminSuccessModal(false);
                                resetAdminClientSpaceResponse();
                            }}
                            color="orange"
                            title="Retour"
                        />
                    </div>
                </div>
            </CustomModal>
        );
    };

    return (
        <div className="admin-user">
            {error === 'no matches found' && renderCreateUserModal()}
            {administrationList.length > 0 && administrationUserSuccess()}
            <CreateUserModal
                currentUser={currentUser}
                isCustomerAndContract={true}
                isOpen={openUserModal}
                setOpen={setUserModal}
                schemaProps={schemaProps}
                groupList={groupList}
                createUser={createUser}
                createUserResponse={createUserResponse}
                resetCreateUserResponse={resetCreateUserResponse}
            />
        </div>
    );
};

export default AdministrationClientSpace;
