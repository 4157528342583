import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { CustomSelect } from '../../components';
import SearchService from '../../utils/search';
import WordingConstant from '../../utils/wording.json';
import { TAddress } from '../../utils/network/types';
import { SelectOption } from 'Types';

const Prompts = WordingConstant.Common.prompts;

interface IProps {
    isDisabled?: boolean;
    initialAddress?: TAddress;
    text?: string;
    onSelection: (address: TAddress) => void;
}

interface IState {
    selection: SelectOption<TAddress> | null;
    options: Array<SelectOption<TAddress>>;
}

const throttleDelay = 1000;

function formatOption(
    address: TAddress,
    index: string,
): SelectOption<TAddress> {
    const city = _.get(address, 'townName', '');
    const code = _.get(address, 'postalCode', '');
    return {
        id: index,
        label: `${code} -- ${city}`,
        value: address,
    };
}

class PostalCodeSelect extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            options: [],
            selection: null,
        };
        (this as any).searchService = new SearchService().setRaw(true);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleInputChange = _.throttle(
            this.handleInputChange,
            throttleDelay,
        );
    }
    componentDidMount() {
        const { initialAddress } = this.props;
        if (!!initialAddress) {
            const initialSelection: SelectOption<TAddress> = formatOption(
                initialAddress,
                '-1',
            );
            const initialOptions: Array<SelectOption<TAddress>> = [
                initialSelection,
            ];
            this.setState({
                options: initialOptions,
                selection: initialSelection,
            });
        }
    }

    componentDidUpdate = (prevProps: any) => {
        if (!prevProps.isDisabled && this.props.isDisabled) {
            this.setState({ selection: null });
        }
    };
    componentWillUnmount = () => {
        (this as any).searchService.unsubscribe();
    };

    handleSelection(option: SelectOption<TAddress>) {
        const { onSelection } = this.props;
        onSelection(_.get(option, 'value') as TAddress);
        this.setState({ selection: option });
    }
    handleInputChange(value: string) {
        this.refreshPostalCodeOptions(value);
    }
    async refreshPostalCodeOptions(value: string) {
        const res = await (this as any).searchService
            .doSearch(value)
            .toPromise();
        const codes: Array<SelectOption<TAddress>> = _.map(res, (pc, i) =>
            formatOption(pc, i),
        );
        this.setState({ options: codes });
    }
    render() {
        const { text, isDisabled } = this.props;
        const { options, selection } = this.state;

        return (
            <Fragment>
                <CustomSelect
                    handleChangeSelect={this.handleSelection}
                    onInputChange={this.handleInputChange}
                    options={options}
                    placeholder={Prompts.selects.postalCode}
                    text={text}
                    value={selection}
                    disabled={isDisabled}
                />
            </Fragment>
        );
    }
}

export default PostalCodeSelect;
