import React from 'react';
import resources from '../../../utils/resources';

export type Props = {
    value?: string;
    onChange: any;
    allResultText: string;
    options: any[];
};

const compareSortSelect = (a: any, b: any) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0;

const FilterSelect: React.FC<Props> = ({
    allResultText,
    value,
    onChange,
    options,
}) => (
    <div className="filter-select">
        <img src={resources['ic-filter-symbol']} alt="ic-filter-symbol" />

        <select value={value} onChange={onChange}>
            <option value="all">{allResultText}</option>
            {options
                .sort(compareSortSelect)
                .map(({ value, label }, index: number) => (
                    <option key={index} value={value}>
                        {label}
                    </option>
                ))}
        </select>
    </div>
);

export default FilterSelect;
