import React, { FC } from 'react';
import { buildClassName as bcn } from '../../utils/helpers';

interface IProps {
    text: string;
    title: string;
}

const baseClassName: string = 'custom-error';
const titleClassName: string = bcn([baseClassName, 'title']);
const textClassName: string = bcn([baseClassName, 'text']);

const CustomError: FC<IProps> = (props: IProps) => {
    const { text, title } = props;
    return (
        <div className={baseClassName}>
            <span className={titleClassName}>{title}</span>
            <p className={textClassName}>{text}</p>
        </div>
    );
};

export default CustomError;
