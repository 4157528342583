const expKey: string = 'expires';

function hasExpiredSession(): boolean {
    const expires = Number(localStorage.getItem(expKey));
    const didExpired = isNaN(expires) || new Date().getTime() > expires;
    if (didExpired) localStorage.removeItem(expKey);
    return didExpired;
}

export { hasExpiredSession };
