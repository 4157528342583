import React, { useState, useEffect } from 'react';

import Config from '../../utils/config';

import {
    TContract,
    TProduct,
    TOffer,
    TPackage,
    TPrepaymentFrequency,
    TAdditionalRate,
} from 'Models';
import {
    EEnergy,
    EFrequency,
    EContractStatus,
    EAmountType,
    EBillingMode,
} from '../../utils/enums';
import { IPayloadUpdatePackage as PackagePayload } from '../../_actions/package.actions';
import { PlanElement, EnergyDetail, OptionElement } from '../../components';
import PackageSettingContainer from './PackageSettingContainer';

import {
    getFormattedDate,
    displayRateOption,
    displayFrequency,
    transformAuthorizedPackages,
    findBillingModeCode,
} from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
import { TCurrentActionMode } from '../../pages/PackagePage/PackagePage';

const Wording = WordingConstant.PackagePage;

export type Props = {
    loadingPackage: boolean;
    loadingOffer: boolean;
    contract: TContract;
    packages?: TPackage[];
    products: TProduct[];
    offer: TOffer;
    groupe?: string;
    prepaymentFrequencies: TPrepaymentFrequency[];
    handleFetchPackage(): void;
    handleSetPayload(payload: PackagePayload): void;
    handleShowModal(section: string): void;
    handleWordingModalMyMode(section: TCurrentActionMode): void;
};

const PackageContainer: React.FC<Props> = ({
    loadingPackage,
    loadingOffer,
    contract,
    products,
    offer,
    packages,
    prepaymentFrequencies,
    handleFetchPackage,
    groupe,
    handleSetPayload,
    handleShowModal,
    handleWordingModalMyMode,
}) => {
    const [localUserGroup, setLocalUserGroup] = useState<null | string>(null);

    const [adminChosenPackages, setAdminChosenPackages] = useState<
        TPackage[] | undefined
    >(undefined);
    const [showOption, setShowOption] = useState<boolean>(true);
    const options: TAdditionalRate[] = contract.chosenProduct.additionalRates
        ? contract.chosenProduct.additionalRates.filter(
              r => r.type === EAmountType.OPTION,
          )
        : [];
    const currentAdditionalOptions: string[] = options
        .filter(r => r.active)
        .map(r => r.label);

    const currentProductCodes: string[] = products
        .filter(p => p.productCode === contract.chosenProduct.productCode)
        .map(p => p.productCode);

    const chosenPackages = contract.chosenPackages;
    const packageID: string =
        chosenPackages && chosenPackages.length === 1
            ? chosenPackages[0].id
            : '';
    const prices: number[] = chosenPackages
        ? chosenPackages.map(p => Number(p.amount))
        : [];
    let ranges: number[][] = [[]];

    if (adminChosenPackages && adminChosenPackages.length === 1) {
        ranges = transformAuthorizedPackages(
            adminChosenPackages[0].authorizedPackages,
        );
    } else if (chosenPackages && chosenPackages.length === 1) {
        ranges = transformAuthorizedPackages(
            chosenPackages[0].authorizedPackages,
        );
    } else {
        if (packages) {
            const recommendPackage = packages.find(p => p.recommended);
            ranges = recommendPackage
                ? transformAuthorizedPackages(
                      recommendPackage.authorizedPackages,
                  )
                : [[]];
        }
    }

    useEffect(() => {
        const localGrp = localStorage.getItem('userGroup');
        setLocalUserGroup(localGrp);
    }, []);

    const current = {
        currentPackages: chosenPackages,
        packageID,
        prices,
        frequency: contract.installmentFrequency as EFrequency,
    };

    const renderDetail = () => {
        const deliveryPoint = contract.deliveryPoint;
        const details = [];
        let separateDetails = [];
        if (contract.energy === EEnergy.EL) {
            details.push({
                title: Wording.detailDescription.PDL,
                value: deliveryPoint.pointOfDelivery,
            });
        } else {
            details.push({
                title: Wording.detailDescription.PCE,
                value: deliveryPoint.pointOfDelivery,
            });
        }
        details.push({
            title: Wording.detailDescription.beginDate,
            value: getFormattedDate(contract.effectiveStartDate) || '',
        });
        if (contract.energy === EEnergy.EL) {
            const rateOption = displayRateOption(contract.rateOption);
            separateDetails = [
                {
                    title: Wording.detailDescription.contractualCapacityEL,
                    value: `${deliveryPoint.contractualCapacity} ${deliveryPoint.contractualCapacityUnit}`,
                },
                {
                    title: Wording.detailDescription.meterReading,
                    value: deliveryPoint.meterNumber,
                },
                {
                    title: Wording.detailDescription.priceOption,
                    value: rateOption,
                },
            ];
        } else {
            separateDetails = [
                {
                    title: Wording.detailDescription.profile,
                    value: deliveryPoint.profile,
                },
                {
                    title: Wording.detailDescription.meterReading,
                    value: deliveryPoint.meterNumber,
                },
                {
                    title: Wording.detailDescription.priceOption,
                    value: deliveryPoint.gridRate,
                },
            ];
            if (contract.estimates && contract.estimates.length > 0) {
                separateDetails.unshift({
                    title: Wording.detailDescription.contractualCapacityNG,
                    value: `${contract.estimates[0].quantity} ${Wording.detailDescription.contractualCapacityUnitNG}`,
                });
            }
        }
        const readingFrequency = displayFrequency(
            deliveryPoint.readingFrequency as EFrequency,
        );

        const installmentFrequency = displayFrequency(
            contract.installmentFrequency as EFrequency,
        );
        const settlementFrequency = displayFrequency(
            contract.settlementFrequency as EFrequency,
        );

        details.push(
            ...separateDetails,
            ...[
                {
                    title: Wording.detailDescription.readingFrequency,
                    value: readingFrequency,
                },
                {
                    title: Wording.detailDescription.installmentFrequency,
                    value: installmentFrequency,
                },
                {
                    title: Wording.detailDescription.settlementFrequency,
                    value: settlementFrequency,
                },
            ],
        );

        return <EnergyDetail details={details} />;
    };

    const handleSetAdminChosenPackages = (pkg: TPackage[]) => {
        setAdminChosenPackages(pkg);
    };

    const handleValidate = (section: string, payload: PackagePayload) => {
        handleWordingModalMyMode(section as TCurrentActionMode);
        handleShowModal(section);
        handleSetPayload(payload);
    };

    const renderContents = () => (
        <>
            <h1 className="package-container__subtitle">{Wording.subtitle}</h1>

            <div className="package-container__body">
                <PlanElement
                    loading={loadingOffer}
                    prices={prices}
                    offer={offer}
                    products={products}
                    additionalOptions={options}
                    currentProductCodes={currentProductCodes}
                    currentAdditionalOptions={currentAdditionalOptions}
                    loadingOption={false}
                    handleToggle={handleValidate}
                    contractProduct={contract.chosenProduct}
                />
                <div className="package-container__body__right">
                    {renderDetail()}
                    {showOption && options && options.length > 0 && (
                        <OptionElement
                            idToggle={contract.energy}
                            options={options}
                            currentOptions={currentAdditionalOptions}
                            handleToggle={handleValidate}
                        />
                    )}
                    {findBillingModeCode(contract) !==
                        EBillingMode.CYCLICAL_BILLING && (
                        <PackageSettingContainer
                            current={current}
                            loading={loadingPackage}
                            packages={packages}
                            ranges={ranges}
                            prepaymentFrequencies={prepaymentFrequencies}
                            handleSetShowOption={(value: boolean) => {
                                setShowOption(value);
                            }}
                            handleFetchPackage={handleFetchPackage}
                            handleValidate={handleValidate}
                            handleSetAdminChosenPackages={
                                handleSetAdminChosenPackages
                            }
                            adminChosenPackages={adminChosenPackages}
                        />
                    )}
                </div>
            </div>
        </>
    );

    /**
     * TODO: uncomment the block to reuse the contractStatus
     * If the contract is not yet activated, should only display messages
     */
    return (
        <div className="package-container">
            {contract.contractStatus !== EContractStatus.EFFECTIVE &&
            (localUserGroup === null || localUserGroup === '') ? (
                <div className="package-container__contractStatus">
                    <p>
                        {contract.contractStatus ===
                            EContractStatus.WAITING_FOURTEEN_DAY ||
                        contract.contractStatus === EContractStatus.RECOVERY ||
                        contract.contractStatus ===
                            EContractStatus.INITIALIZED ||
                        contract.contractStatus ===
                            EContractStatus.SENT_TO_MARKET
                            ? Wording.contractStatus.inProgress
                            : Wording.contractStatus.notAccessible}
                    </p>
                </div>
            ) : (
                renderContents()
            )}
        </div>
    );
};

export default PackageContainer;
