import React from 'react';

import { View, StyleSheet } from '@react-pdf/renderer';

import CustomerInfo from './CustomerInfo';
import BeneficiaryInfo from './BeneficiaryInfo';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        backgroundColor: '#D5D5D5',
        padding: 10,
        borderRadius: 15,
        height: '100%',
    },
});

const LeftColumn = () => {
    return (
        <View style={styles.container}>
            <CustomerInfo />

            <BeneficiaryInfo />
        </View>
    );
};

export default LeftColumn;
