import React from 'react';
import Slider from 'react-slick';

import { TPackage } from 'Models';

import resources from '../../utils/resources';

import { PackageElement, EnergyGauge, CustomSelect } from '../../components';

import WordingConstant from '../../utils/wording.json';
import {
    buildPackagesForSlider,
    canSelectAllPackage,
} from '../../utils/helpers';

const Wording = WordingConstant.PackagePage;
const CommonWording = WordingConstant.Common;

export type Props = {
    loading: boolean;
    packages?: TPackage[];
    ranges: number[][];
    showGaugeTab: boolean;
    prices: number[];
    packageID: string;
    handleSetAdminChosenPackages: (pkg: TPackage[]) => void;
    handleToggleGauge(gauge: boolean): void;
    handleChangePackageID(packageID: string): void;
    handleChangePrices(prices: number[]): void;
};

const SlickButtonFix = ({
    currentSlide,
    slideCount,
    children,
    ...props
}: any) => <span {...props}>{children}</span>;

const NextArrow = () => (
    <img src={resources['ic-arrow-next']} alt="ic-arrow-next" />
);
const BackArrow = () => (
    <img src={resources['ic-arrow-back']} alt="ic-arrow-back" />
);

const GaugeContainer: React.FC<Props> = ({
    loading,
    packages,
    ranges,
    prices,
    packageID,
    showGaugeTab,
    handleToggleGauge,
    handleChangePackageID,
    handleChangePrices,
    handleSetAdminChosenPackages,
}: Props) => {
    let initialProgress = 0;
    if (prices && prices.length > 1) {
        initialProgress = ranges.findIndex(
            r => r[0] === prices[0] && r[1] === prices[1],
        );
        initialProgress = initialProgress === -1 ? 0 : initialProgress;
    }
    const reducer = (accumulator: any, current: any) => {
        const max = current[0] > current[1] ? current[0] : current[1];
        return accumulator > max ? accumulator : max;
    };
    const gaugeMaxValue = ranges.reduce(reducer, 0);

    const isMultiple = packages && packages.length > 5;

    return (
        <div className="gauge-container">
            <p className="gauge-container__title">{Wording.plan.gauge.title}</p>

            <div className="gauge-container__gauge">
                <input
                    id="tab1"
                    type="radio"
                    checked={!showGaugeTab}
                    onChange={() => handleToggleGauge(false)}
                />
                <input
                    id="tab2"
                    type="radio"
                    checked={showGaugeTab}
                    onChange={() => handleToggleGauge(true)}
                />

                <ul>
                    <li className="tab1">
                        <label htmlFor="tab1">{Wording.plan.gauge.tab1}</label>
                    </li>
                    <li className="tab2">
                        <label htmlFor="tab2">{Wording.plan.gauge.tab2}</label>
                    </li>
                </ul>

                <section>
                    {!showGaugeTab ? (
                        <React.Fragment>
                            <div className="tab1">
                                {packages ? (
                                    <div className={`package-slider-carousel`}>
                                        <Slider
                                            speed={500}
                                            slidesToShow={
                                                isMultiple
                                                    ? 4
                                                    : (packages as any).length
                                            }
                                            slidesToScroll={4}
                                            arrows={isMultiple}
                                            nextArrow={
                                                <SlickButtonFix>
                                                    <NextArrow />
                                                </SlickButtonFix>
                                            }
                                            prevArrow={
                                                <SlickButtonFix>
                                                    <BackArrow />
                                                </SlickButtonFix>
                                            }
                                        >
                                            {(isMultiple
                                                ? buildPackagesForSlider(
                                                      packages as any,
                                                  )
                                                : packages
                                            ).map(
                                                (
                                                    { amount, id, recommended },
                                                    index,
                                                ) => (
                                                    <PackageElement
                                                        key={index}
                                                        price={amount}
                                                        chosen={
                                                            id === packageID
                                                        }
                                                        recommended={
                                                            recommended
                                                        }
                                                        onClick={() => {
                                                            handleChangePackageID(
                                                                id,
                                                            );
                                                        }}
                                                    />
                                                ),
                                            )}
                                        </Slider>
                                    </div>
                                ) : (
                                    <p>
                                        {loading
                                            ? CommonWording.isLoading
                                            : CommonWording.noData}
                                    </p>
                                )}
                            </div>

                            <div
                                className="modal"
                                onClick={() =>
                                    // handleToggle('modalPackage', !modalPackage)
                                    {}
                                }
                                tabIndex={0}
                                role="button"
                            >
                                {Wording.plan.popupChoosePackage}
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="tab2">
                            {packages && canSelectAllPackage() && (
                                <CustomSelect
                                    options={packages.map(pkg => ({
                                        id: `${pkg.id}`,
                                        label: `${pkg.id}${
                                            pkg.recommended
                                                ? ' (Recommandé)'
                                                : ''
                                        }`,
                                        value: pkg,
                                    }))}
                                    handleChangeSelect={(props: any) => {
                                        handleSetAdminChosenPackages([
                                            props.value,
                                        ] as TPackage[]);
                                    }}
                                />
                            )}
                            {ranges.length > 0 && ranges[0].length > 0 ? (
                                <>
                                    <EnergyGauge
                                        initial={initialProgress}
                                        maxValue={gaugeMaxValue}
                                        ranges={ranges}
                                        onChange={(p: number[]) => {
                                            handleChangePrices(p);
                                        }}
                                    />
                                </>
                            ) : (
                                <p>{CommonWording.noData}</p>
                            )}
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
};

export default GaugeContainer;
