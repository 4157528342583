import { Epic } from 'redux-observable';
import { of, forkJoin, throwError } from 'rxjs';
import {
    mergeMap,
    catchError,
    filter,
    switchMap,
    timeout,
} from 'rxjs/operators';
import {
    createAsyncAction,
    createStandardAction,
    isActionOf,
    ActionType,
    getType,
} from 'typesafe-actions';

import {
    mapCreateEvent,
    ICreateEventPayload,
    createEventAsync,
} from './event.actions';
import { RootAction, RootState, Services } from 'Types';
import {
    TCustomer,
    TContract,
    TCustomerFullName,
    TAddress,
    TThirdParty,
    TResponse,
} from 'Models';

import { getTodayDate } from '../utils/helpers';
import { EModeUpdateBilling } from '../utils/enums';

import {
    FETCH_CONTEXT_REQUEST,
    FETCH_CONTEXT_SUCCESS,
    FETCH_CONTEXT_FAILURE,
    UPDATE_CUSTOMER_REQUEST,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAILURE,
    UPDATE_CUSTOMER_RESET,
    UPDATE_BILLING_REQUEST,
    UPDATE_BILLING_SUCCESS,
    UPDATE_BILLING_FAILURE,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    CREATE_SPONSOR_REQUEST,
    CREATE_SPONSOR_SUCCESS,
    CREATE_SPONSOR_FAILURE,
} from './actionTypes';
import { fetchLoginAsync } from './auth.actions';
import { isEmpty } from 'lodash';

export interface IContext {
    customer: TCustomer;
    contracts: TContract[];
    message?: string;
    errorMessage?: string;
}

export interface IContextRequest {
    customerNbr: string;
}

const resetUpdate = createStandardAction(UPDATE_CUSTOMER_RESET)();

const fetchContextAsync = createAsyncAction(
    FETCH_CONTEXT_REQUEST,
    FETCH_CONTEXT_SUCCESS,
    FETCH_CONTEXT_FAILURE,
)<IContextRequest, IContext, TResponse>();

export interface IPassword {
    user: string;
    oldPassword: string;
    newPassword: string;
    token: string;
}

const updatePasswordAsync = createAsyncAction(
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
)<IPassword, string, TResponse>();

export interface ISponsor {
    sponsorId: string;
    sponsorFirstName: string;
    sponsorLastName: string;
    targetEmail: string;
}

const createSponsorAsync = createAsyncAction(
    CREATE_SPONSOR_REQUEST,
    CREATE_SPONSOR_SUCCESS,
    CREATE_SPONSOR_FAILURE,
)<ISponsor, string, TResponse>();

interface IRequestUpdateCoholder {
    mode: string;
    civility: string;
    firstName: string;
    customerNbr?: string;
    contractNbr: string;
}

interface IRequestUpdateCustomer {
    customerNbr: string;
    email: string;
    phone: string;
    marketing: boolean;
    newsletter: boolean;
}

export interface IRequestUpdateCustomerCoholderEvent {
    customer: IRequestUpdateCustomer;
    coholder?: IRequestUpdateCoholder;
    event: ICreateEventPayload;
}

interface IResponseUpdateCoholder {
    code: string;
    message: string;
    mode?: string;
    civility?: string;
    firstName?: string;
}
interface IResponseUpdateCustomer {
    code: string;
    message: string;
    email?: string;
    phone?: string;
    marketing?: boolean;
    newsletter?: boolean;
}

type IResponseUpdateCustomerCoholder = IResponseUpdateCoholder &
    IResponseUpdateCustomer;

const updateCustomerAsync = createAsyncAction(
    UPDATE_CUSTOMER_REQUEST,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAILURE,
)<
    IRequestUpdateCustomerCoholderEvent,
    IResponseUpdateCustomerCoholder,
    IResponseUpdateCustomerCoholder
>();

export interface IRequestUpdateBillingEvent {
    paymentMode: IRequestUpdateBilling;
    event: ICreateEventPayload;
}

interface IRequestUpdateBilling {
    mode: EModeUpdateBilling;
    customerNbr?: string;
    contractNbr?: string;
    ibanCode?: string;
    rumCode?: string;
    billingAddress?: TAddress;
    customerFullName?: TCustomerFullName;
    email?: string;
    debitDay?: string;
    routingMode?: string;
}

interface IResponseUpdateBilling {
    code: string;
    message: string;
    mode: EModeUpdateBilling;
    billingAddress?: TAddress;
    customerFullName?: TCustomerFullName;
    contractNbr?: string;
    ibanCode?: string;
    email?: string;
    debitDay?: string;
    routingMode?: string;
}

const updateBillingAsync = createAsyncAction(
    UPDATE_BILLING_REQUEST,
    UPDATE_BILLING_SUCCESS,
    UPDATE_BILLING_FAILURE,
)<IRequestUpdateBillingEvent, IResponseUpdateBilling, TResponse>();

export type ProfileAction =
    | ActionType<typeof fetchContextAsync>
    | ActionType<typeof updateCustomerAsync>
    | ActionType<typeof resetUpdate>
    | ActionType<typeof updateBillingAsync>
    | ActionType<typeof updatePasswordAsync>
    | ActionType<typeof createSponsorAsync>;

const preparePayloadGetContext = ({ customerNbr }: { customerNbr: string }) => {
    return { customerNbr };
};

const mapGetContext = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadGetContext(action.payload);
    return apiRequest<IContext>({
        path: '/getContext',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IContext) => {
            const userId = sessionStorage.getItem('currentClient');
            if (response && response.customer && !isEmpty(response.contracts)) {
                if (userId) {
                    return of(
                        fetchLoginAsync.success({
                            userId,
                        }),
                        fetchContextAsync.success(response),
                    );
                }
                return of(fetchContextAsync.success(response));
            }
            const message =
                response.message ||
                response.errorMessage ||
                'Une erreur est survenue';
            return of(
                fetchContextAsync.failure({
                    code: userId ? '505' : '500',
                    message,
                }),
            );
        }),
        catchError(error => of(fetchContextAsync.failure(error))),
    );
};

const preparePayloadUpdateCustomer = ({
    customerNbr,
    email,
    phone,
    marketing,
    newsletter,
}: IRequestUpdateCustomer) => {
    return {
        customerNbr,
        email,
        phone,
        marketing,
        newsletter,
    };
};

const mapUpdateCustomer = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadUpdateCustomer(action.payload.customer);
    return apiRequest<IResponseUpdateCustomer>({
        path: '/updateCustomer',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IResponseUpdateCustomer) => {
            const prepareToStore = {
                code: response ? response.code : '500',
                message: response ? response.message : 'failure',
                email: payload.email,
                phone: payload.phone,
                marketing: payload.marketing,
                newsletter: payload.newsletter,
            };
            return of(prepareToStore);
        }),
        catchError(error => of(error)),
    );
};

const preparePayloadUpdateCoholder = ({
    mode,
    contractNbr,
    civility,
    firstName,
}: {
    mode: string;
    civility: string;
    firstName: string;
    contractNbr: string;
}) => {
    const prepareObject: TThirdParty = {
        civility,
        firstName,
        role: 'COHOLDER',
        effectiveDate: getTodayDate(),
        code: '',
        lastName: '',
        contractNbr: '', 
    };
    return {
        mode,
        contractNbr,
        coholder: [prepareObject],
    };
};

const mapUpdateCoholder = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadUpdateCoholder(action.payload.coholder);
    return apiRequest<IResponseUpdateCoholder>({
        path: '/manageCoholder',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IResponseUpdateCoholder) => {
            return of({
                code: response ? response.code : '500',
                message: response ? response.message : 'failure',
                mode: action.payload.coholder.mode,
                civility: action.payload.coholder.civility,
                firstName: action.payload.coholder.firstName,
            });
        }),
        catchError(error => of(error)),
    );
};

const fetchContextEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchContextAsync.request)),
        mergeMap(action => mapGetContext(action, dependency)),
    );

const updateCustomerEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(updateCustomerAsync.request)),
        switchMap(
            (action: RootAction) => mapUpdateCustomer(action, dependency),
            (action: RootAction, r: IResponseUpdateCustomerCoholder) => [
                action,
                r,
            ],
        ),
        switchMap(([action, customerResponse]) => {
            if (action.payload.coholder && customerResponse.code === '200') {
                return forkJoin(
                    of(action),
                    of(customerResponse),
                    mapUpdateCoholder(action, dependency),
                );
            }

            return forkJoin(of(action), of(customerResponse));
        }),
        switchMap(([action, customerResponse, coholderResponse]) => {
            if (customerResponse.code !== '200') {
                return throwError(customerResponse);
            }

            const modifiedAction = {
                type: getType(createEventAsync.request),
                payload: {
                    ...action.payload.event,
                },
            };
            if (coholderResponse) {
                return forkJoin(
                    of(customerResponse),
                    of(coholderResponse),
                    mapCreateEvent(modifiedAction, dependency),
                );
            }
            return forkJoin(
                of(customerResponse),
                mapCreateEvent(modifiedAction, dependency),
            );
        }),

        switchMap(([customerResponse, coholderResponse, eventResponse]) => {
            if (coholderResponse) {
                return of(
                    updateCustomerAsync.success({
                        ...customerResponse,
                        ...coholderResponse,
                    }),
                );
            }

            return of(updateCustomerAsync.success(customerResponse));
        }),
        catchError(error => {
            return of(
                updateCustomerAsync.failure({
                    code: error.code,
                    message: error.message,
                }),
            );
        }),
    );

const preparePayloadUpdateBilling = ({
    customerNbr,
    contractNbr,
    mode,
    ibanCode,
    rumCode,
    billingAddress,
    customerFullName,
    email,
    debitDay,
    routingMode,
}: IRequestUpdateBilling) => {
    switch (mode) {
        case EModeUpdateBilling.IBAN:
            return {
                mode,
                customerNbr,
                ibanCode,
                rumCode,
            };

        case EModeUpdateBilling.DEBIT_DAY: {
            return {
                mode,
                contractNbr,
                debitDay,
            };
        }

        case EModeUpdateBilling.ROUTING_MODE: {
            return {
                mode,
                contractNbr,
                routingMode,
            };
        }

        default:
            return {
                mode,
                customerNbr,
                contractNbr,
                customerFullName,
                email,
                billingAddress,
            };
    }
};

const mapUpdateBilling = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadUpdateBilling(action.payload.paymentMode);
    return apiRequest<IResponseUpdateBilling>({
        path: '/updateBillingData',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IResponseUpdateBilling) => {
            if (response && response.code === '200') {
                return of({
                    code: response.code,
                    message: response.message,
                    mode: payload.mode,
                    customerNbr: payload.customerNbr,
                    ibanCode: payload.ibanCode,
                    billingAddress: payload.billingAddress,
                    customerFullName: payload.customerFullName,
                    email: payload.email,
                    debitDay: payload.debitDay,
                    routingMode: payload.routingMode,
                });
            }
            return of({
                code: '500',
                message: 'failure',
            });
        }),
        catchError(error => of(error)),
    );
};

const updateBillingEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(updateBillingAsync.request)),
        switchMap(
            (action: RootAction) => mapUpdateBilling(action, dependency),
            (action: RootAction, r: IResponseUpdateBilling) => [action, r],
        ),
        switchMap(([action, billingResponse]) => {
            if (billingResponse.code !== '200') {
                return forkJoin([of(billingResponse), of(undefined)]);
            }
            const modifiedAction = {
                type: getType(createEventAsync.request),
                payload: {
                    ...action.payload.event,
                },
            };
            return forkJoin([
                of(billingResponse),
                mapCreateEvent(modifiedAction, dependency),
            ]);
        }),
        switchMap(([billingResponse, eventResponse]) => {
            if (billingResponse.code !== '200') {
                return of(
                    updateBillingAsync.failure({
                        code: billingResponse.code,
                        message: billingResponse.message,
                    }),
                );
            }
            return of(updateBillingAsync.success(billingResponse));
        }),
        catchError(error => {
            return of(
                updateBillingAsync.failure({
                    code: error.code,
                    message: error.message,
                }),
            );
        }),
    );

const preparePayloadUpdatePassword = ({
    user,
    oldPassword,
    newPassword,
    token,
}: IPassword) => {
    return {
        user,
        oldPassword,
        newPassword,
        token,
    };
};

const mapUpdatePassword = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadUpdatePassword(action.payload);
    return apiRequest<TResponse>({
        path: '/changePassword',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: TResponse) => {
            return of(updatePasswordAsync.success(''));
        }),
        catchError(error => of(updatePasswordAsync.failure(error))),
    );
};

const updatePasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(updatePasswordAsync.request)),
        mergeMap(action => mapUpdatePassword(action, dependency)),
    );

const preparePayloadCreateSponsor = ({
    sponsorId,
    sponsorFirstName,
    sponsorLastName,
    targetEmail,
}: ISponsor) => {
    return {
        sponsorId,
        sponsorFirstName,
        sponsorLastName,
        targetEmail,
    };
};

const mapCreateSponsor = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadCreateSponsor(action.payload);
    return apiRequest<TResponse>({
        path: '/sponsoring',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: TResponse) => {
            return of(createSponsorAsync.success(''));
        }),
        catchError(error => of(createSponsorAsync.failure(error))),
    );
};

const createSponsorEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(createSponsorAsync.request)),
        mergeMap(action => mapCreateSponsor(action, dependency)),
    );

export {
    fetchContextEpic,
    fetchContextAsync,
    mapGetContext,
    updateCustomerAsync,
    updateCustomerEpic,
    mapUpdateCustomer,
    resetUpdate,
    updateBillingEpic,
    updateBillingAsync,
    mapUpdateBilling,
    mapUpdateCoholder,
    updatePasswordAsync,
    updatePasswordEpic,
    createSponsorAsync,
    createSponsorEpic,
};
