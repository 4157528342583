import React, { FC } from 'react';
import Row, { TableRowProps } from './Row';

export type TableBodyProps = {
    data?: any[];
} & TableRowProps;

export const Body: FC<TableBodyProps> = props => {
    const rowCells: any[] = React.Children.toArray(props.children);

    const dataRows = props.data || [];

    return dataRows.map((data: any, rowIndex: any) => (
        <Row {...props} key={rowIndex} data={data}>
            {rowCells}
        </Row>
    ));
};

export default Body;
