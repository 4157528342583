function toIsc(str: string = '') {
    return str.replace(' ', String.fromCharCode(0x00a0));
}

function toColonHeading(str: string = '') {
    return toIsc(`${str} :`);
}

function removeSpaces(str: string = '') {
    return str.replace(/[ ]/g, '');
}

function removeWhitespaces(str: string = '') {
    return str.replace(/[\s]/g, '');
}

export { removeSpaces, removeWhitespaces, toColonHeading, toIsc };
