import React, { FC } from 'react';

type Props = {
    onClick: () => void;
};

const baseClassName = 'close-button';
const greyZone = 'grey-area';
const closeText = 'X';

const CloseButton: FC<Props> = (props: Props) => {
    const { onClick } = props;
    return (
        <span className={baseClassName}>
            <div
                className={greyZone}
                onClick={() => {
                    onClick();
                }}
            >
                {closeText}
            </div>
        </span>
    );
};

export default CloseButton;
