import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';

export type TableRowProps = {
    fontSize?: number | string;
    textAlign?: 'left' | 'center' | 'right';
    data?: any;
    style?: any;
};

export const Row: FC<TableRowProps> = props => {
    const rowCells: any[] = React.Children.toArray(props.children);

    let remainingWeighting = 1;
    let numberOfWeightingsDefined = 0;
    rowCells.forEach((i: any) => {
        if (i.props.weighting !== undefined) {
            remainingWeighting -= i.props.weighting;
            // tslint:disable-next-line:no-increment-decrement
            numberOfWeightingsDefined++;
        }
    });

    const weightingsPerNotSpecified = Math.ceil(
        remainingWeighting / (rowCells.length - numberOfWeightingsDefined),
    );
    return (
        <View
            // @ts-ignore
            style={{
                borderBottom: '1pt solid black',
                borderRight: '1pt solid black',
                borderLeft: '1pt solid black',
                borderTop: '1pt solid black',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'stretch',
            }}
        >
            {rowCells.map((rc, columnIndex) =>
                React.cloneElement(rc, {
                    weighting: rc.props.weighting || weightingsPerNotSpecified,
                    data: props.data,
                    key: columnIndex,
                    fontSize: props.fontSize,
                    textAlign: rc.props.textAlign || props.textAlign,
                    includeLeftBorder: columnIndex === 0,
                    includeRightBorder: columnIndex !== rowCells.length - 1,
                    style: rc.props.style || props.style,
                }),
            )}
        </View>
    );
};

export default Row;
