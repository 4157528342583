import _ from 'lodash';
import { getFormattedDateToBackEnd } from '../../helpers';
import { EBillingModes, TContract } from '../types';
import { Energy } from '../builders';
import { isCyclicalProduct } from '../helpers';

function buildFinalContracts(
    electricity?: Energy | Partial<Energy> | null,
    gas?: Energy | Partial<Energy> | null,
): TContract[] {
    const finalContracts: TContract[] = [];
    if (!!electricity && electricity.contract) {
        const elecPrepare: any = {
            ...electricity.contract,
        };

        if (
            electricity.contract.deliveryPoint !== undefined &&
            electricity.contract.deliveryPoint.pointOfDelivery === undefined &&
            electricity.pointOfDelivery !== undefined
        ) {
            elecPrepare.deliveryPoint.pointOfDelivery = electricity.pointOfDelivery.replace(
                / /g,
                '',
            );
        }

        if (isCyclicalProduct(electricity.chosenProduct)) {
            elecPrepare.chosenProduct = electricity.chosenProduct;
        } else {
            elecPrepare.chosenPackages = [...electricity.chosenPackages];
            elecPrepare.chosenProduct = electricity.chosenProduct;
            elecPrepare.installmentFrequency = electricity.selectedFrequency;
        }

        finalContracts.push(elecPrepare);
    }
    if (!!gas && !!gas.contract) {
        const gasPrepare: any = {
            ...gas.contract,
        };

        if (
            gas.contract.deliveryPoint !== undefined &&
            gas.contract.deliveryPoint.pointOfDelivery === undefined &&
            gas.pointOfDelivery !== undefined
        ) {
            gasPrepare.deliveryPoint.pointOfDelivery = gas.pointOfDelivery.replace(
                / /g,
                '',
            );
        }

        if (isCyclicalProduct(gas.chosenProduct)) {
            gasPrepare.chosenProduct = gas.chosenProduct;
        } else {
            gasPrepare.chosenPackages = [...gas.chosenPackages];
            gasPrepare.chosenProduct = gas.chosenProduct;
            gasPrepare.installmentFrequency = gas.selectedFrequency;
        }

        finalContracts.push(gasPrepare);
    }
    return finalContracts.map(
        (contract: TContract): TContract => {
            const isCyclical = isCyclicalProduct(
                contract.chosenProduct || null,
            );
            const fmtDate = getFormattedDateToBackEnd(new Date(Date.now()));
            return {
                ...contract,
                billingModeCode: isCyclical
                    ? EBillingModes.CYCLICAL_BILLING
                    : EBillingModes.PAYMENT_SCHEDULE,
                effectiveStartDate: fmtDate,
                subscriptionDate: fmtDate,
            };
        },
    );
}

export { buildFinalContracts };
