import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    ActionType,
    isActionOf,
    createStandardAction,
} from 'typesafe-actions';
import { ReducerError, RootAction, RootState, Services, TError } from 'Types';
import {
    TRANSFER_GET_CONTRACT_REQUEST,
    TRANSFER_GET_CONTRACT_SUCCESS,
    TRANSFER_GET_CONTRACT_FAILURE,
    REMOVE_GET_TRANSFER_CONTRACT_MESSAGE,
    TRANSFER_CONTRACT_REQUEST,
    TRANSFER_CONTRACT_SUCCESS,
    TRANSFER_CONTRACT_FAILURE,
    REMOVE_TRANSFER_CONTRACT_MESSAGE,
} from './actionTypes';
import { Epic } from 'redux-observable';

import { TCustomer } from '../constants/key-system';
import config from '../utils/config';
import { TOrderPayload } from './searchOrder.actions';
import { TOrder } from 'Models';
import { RequestError } from '../utils/network/errors';

export type TCustomerContractPayload = Partial<{
    customerId: string;
    contractId: string;
    clientFirstName: string;
    clientLastName: string;
    companyName: string;
    fromDate: string;
    toDate: string;
}>;

export interface ITransferContract {
    customerNbrOutdated: string;
    customerNbrTargeted: string;
    contractNbr: string;
    userName: string;
}

interface IResponseClient {
    customers: TCustomer[];
}

const transferGetContractAsync = createAsyncAction(
    TRANSFER_GET_CONTRACT_REQUEST,
    TRANSFER_GET_CONTRACT_SUCCESS,
    TRANSFER_GET_CONTRACT_FAILURE,
)<TCustomerContractPayload, TOrder[], ReducerError>();

const transferContractAsync = createAsyncAction(
    TRANSFER_CONTRACT_REQUEST,
    TRANSFER_CONTRACT_SUCCESS,
    TRANSFER_CONTRACT_FAILURE,
)<ITransferContract, any, ReducerError>();

const resetResponse = createStandardAction(
    REMOVE_GET_TRANSFER_CONTRACT_MESSAGE,
)();

const resetResponseTransfer = createStandardAction(
    REMOVE_TRANSFER_CONTRACT_MESSAGE,
)();

export type TransferContractAction =
    | ActionType<typeof transferGetContractAsync>
    | ActionType<typeof transferContractAsync>
    | ActionType<typeof resetResponseTransfer>
    | ActionType<typeof resetResponse>;

const preparePayloadGetTransferContract = (
    payload: TCustomerContractPayload,
): TCustomerContractPayload => {
    return { ...payload };
};

const preparePayloadOrder = (customerNumber: string): TOrderPayload => {
    return {
        fromDate: '',
        toDate: '',
        email: '',
        orderNumber: customerNumber,
        firstName: '',
        lastName: '',
        processType: '',
        phone: '',
        externalReference: '',
        channel: '',
        createdBy: '',
    };
};

const mapGetTransferContract = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadOrder(action.payload.customerId);

    return apiRequest<TOrder[]>({
        isSubscription: true,
        method: 'POST',
        path: '/searchOrder',
        body: payload,
        lambdaFunctionName: config.FunctionNames.searchOrder,
    }).pipe(
        mergeMap((response: any | RequestError) => {
            if (
                response instanceof RequestError &&
                response.code !== undefined
            ) {
                return of(
                    transferGetContractAsync.failure({
                        code: response.code as string,
                        message: response.message,
                    }),
                );
            }

            if (response) {
                try {
                    const res = JSON.parse(JSON.stringify(response));
                    return of(transferGetContractAsync.success(res));
                } catch (e) {
                    return of(
                        transferGetContractAsync.failure({
                            code: '500',
                            message: 'erreur interne',
                        }),
                    );
                }
            }
            return of(transferGetContractAsync.success(response));
        }),
        catchError(error => {
            return of(
                transferGetContractAsync.failure({
                    code: '400',
                    message: 'Données indisponibles',
                }),
            );
        }),
    );
};
const preparePayloadTransferContract = (
    payload: ITransferContract,
): ITransferContract => {
    return { ...payload };
};
const mapTransferContract = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadTransferContract(action.payload);
    return apiRequest<any>({
        path: '/transferContract',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: any & TError) => {
            if (
                response.code === 'MISSING_PARAMETER' ||
                response.code === '400' ||
                response.code === 'NO_RESPONSE_FROM_PARTNER'
            ) {
                return of(
                    transferContractAsync.failure({
                        code: response.code,
                        message: response.description || response.message,
                    }),
                );
            }
            return of(transferContractAsync.success(response));
        }),
        catchError(error => {
            return of(
                transferContractAsync.failure({
                    code: error.code,
                    message: error.description || error.message,
                }),
            );
        }),
    );
};

const transferGetContractEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(transferGetContractAsync.request)),
        mergeMap(action => mapGetTransferContract(action, dependency)),
    );

const transferContractEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(transferContractAsync.request)),
        mergeMap(action => mapTransferContract(action, dependency)),
    );

export {
    transferGetContractAsync,
    transferGetContractEpic,
    mapGetTransferContract,
    resetResponse,
    mapTransferContract,
    transferContractAsync,
    resetResponseTransfer,
    transferContractEpic,
};
