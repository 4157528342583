import React, { FC } from 'react';
import { CustomButton } from '../../../../../components';
import WordingConstant from '../../../../../utils/wording.json';
import { buildClassName as bcn } from '../../../../../utils/helpers';

interface IProps {
    onBack: () => void;
    onConfirm: () => void;
}

const Wording = WordingConstant.AdminSubscriptionContainer.ResetModal;
const baseClassName = 'reset-modal';
const buttonsClassName = bcn([baseClassName, 'buttons']);

const ResetModal: FC<IProps> = (props: IProps) => {
    const { onBack, onConfirm } = props;
    return (
        <div className={baseClassName}>
            <span>{Wording.prompt}</span>
            <div className={buttonsClassName}>
                <CustomButton
                    color={'gray'}
                    onClick={onBack}
                    title={Wording.back}
                />
                <CustomButton
                    color={'orange'}
                    onClick={() => {
                        onConfirm();
                        (document as any).location = window.location.href.split(
                            '?',
                        )[0];
                    }}
                    title={Wording.confirm}
                />
            </div>
        </div>
    );
};

export default ResetModal;
