import React, { FC } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        padding: 20,
        flex: 1,
        flexDirection: 'column',
    },
});

const Container: FC = ({ children }) => (
    <View style={styles.container}>{children}</View>
);

export default Container;
