import React, { FC } from 'react';

import Header from './Header';
import Body from './Body';
import { View } from '@react-pdf/renderer';

type TableProps = {
    data?: any[];
};

export const Table: FC<TableProps> = props => {
    let tableHeader: any = null;
    let tableBody: any = null;

    React.Children.forEach(props.children, (c: any) => {
        if (c.type === Header) {
            tableHeader = c;
        } else if (c.type === Body) {
            tableBody = React.cloneElement(c, {
                data: c.props.data || props.data || [],
            });
        }
    });
    return (
        <View
            style={{
                width: '100%',
            }}
        >
            {tableHeader}
            {tableBody}
        </View>
    );
};

export default Table;
