import React from 'react';

import { View, StyleSheet } from '@react-pdf/renderer';

import Wording from '../../wording.json';

import TextValue from './TextValue';
import { useProof } from '../../lib';

const styles = StyleSheet.create({
    container: {
        padding: '10px 0px',
    },
});

type Frequencies =
    | 'MONTHLY'
    | 'BIMONTHLY'
    | 'QUARTERLY'
    | 'FOURMONTHLY'
    | 'BIANNUAL'
    | 'UNKNOWN';

const {
    sections: {
        offer: { type, frequency, modulation, EL, NG, frequencyMapping },
    },
} = Wording;

const mappingEnergy = (energy: 'EL' | 'NG') => (energy === 'EL' ? EL : NG);
const modulationCheck = (chosenPackages: any[] | undefined) => {
    if (!chosenPackages) return '';
    return chosenPackages.length > 1 ? 'Oui' : 'Non';
};

const Offer = () => {
    const {
        contract: { energy, installmentFrequency, chosenPackages },
    } = useProof();

    return (
        <View style={styles.container}>
            <TextValue title={type} value={mappingEnergy(energy)} />
            <TextValue
                title={frequency}
                value={frequencyMapping[installmentFrequency as Frequencies]}
            />
            <TextValue
                title={modulation}
                value={modulationCheck(chosenPackages)}
            />
        </View>
    );
};

export default Offer;
