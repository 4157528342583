import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { StripeForm } from '../../components';
import config from '../../utils/config';

const stripeProm = loadStripe(config.Stripe.publicKey || '');

type StripeContainerProps = {
    stripeInformations: {
        clientSecret: string;
        billName: string;
        amount: string;
    };
    onFormValidate: (sucess: boolean, errMsg?: string) => void;
    handleClose: () => void;
};

const StripeContainer = ({
    stripeInformations,
    onFormValidate,
    handleClose,
}: StripeContainerProps) => {
    if (!stripeInformations || !stripeInformations.clientSecret) {
        return <div>Stripe loaded with invalid argument</div>;
    }
    const { clientSecret, billName, amount } = stripeInformations;

    return (
        <Elements stripe={stripeProm} options={{ clientSecret }}>
            <StripeForm
                handleClose={handleClose}
                onFormValidate={onFormValidate}
                clientSecret={clientSecret}
                clientName={billName}
                amount={amount}
            />
        </Elements>
    );
};

export default StripeContainer;
