import React from 'react';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/fr';

import CustomInput from '../CustomInput';

const modifiersStyles = {
    highlighted: {
        color: 'white',
        backgroundColor: '#ffb40a',
    },

    outside: { color: '#8954ba', backgroundColor: 'white' },
};

type Props = {
    dateHighlighted?: any;
    handleChangeDay?: any;
    selectedDays?: any[];
    disabledDays?: any[];
};

const CustomDayPicker: React.FC<Props> = props => {
    const modifiers = {
        highlighted: props.dateHighlighted,
        weekend: { daysOfWeek: [0, 6] },
    };

    return (
        <div className="custom-daypicker">
            <CustomInput
                placeholder="JJ-MM-AAAA"
                disabled={true}
                value={
                    props.dateHighlighted !== null &&
                    props.dateHighlighted !== undefined
                        ? props.dateHighlighted.toLocaleDateString()
                        : ''
                }
            />
            <DayPicker
                locale="fr"
                localeUtils={MomentLocaleUtils as any}
                selectedDays={props.selectedDays}
                modifiers={modifiers}
                modifiersStyles={modifiersStyles}
                onDayClick={props.handleChangeDay}
                disabledDays={props.disabledDays}
            />
        </div>
    );
};

export default CustomDayPicker;
