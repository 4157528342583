import WordingConstant from '../wording.json';

const Wording = WordingConstant.Errors;
const defaultMessage = Wording.default;

// Generics

export class RequestError extends Error {
    public code: number | null | string;
    public data: any | null;
    constructor(
        message: string = defaultMessage,
        code: number | null | string = null,
        data: any | null = null,
    ) {
        super(message);
        this.code = code;
        this.data = data;
    }
}

export class ParsingError extends RequestError {
    constructor() {
        super(Wording.parsing, null, null);
    }
}

export class FunctionNameError extends RequestError {
    constructor() {
        super(Wording.fnName, null, null);
    }
}

export function parseError(res: any) {
    // TODO
    return new RequestError(defaultMessage);
}

// Specifics

export class ExternalError extends RequestError {
    constructor(message: string) {
        super(message);
    }
}

export class NoDaysListError extends RequestError {
    constructor(message?: string) {
        super(message || Wording.noDaysList);
    }
}

export class NoPackageListError extends RequestError {
    constructor(message?: string) {
        super(message || Wording.noPackageList);
    }
}

export class NoMatchingPackagesError extends RequestError {
    constructor(message?: string) {
        super(message || Wording.noMatchingPackages);
    }
}

export class NoEnergySelectionError extends RequestError {
    constructor(message?: string) {
        super(message || Wording.noEnergySelection);
    }
}

export class SessionExpiredError extends RequestError {
    constructor(message?: string) {
        super(message || Wording.sessionExpired);
    }
}
