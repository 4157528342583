import React, { useState } from 'react';
import { InputEvent, SelectOption, TSchemaProps } from 'Types';
import {
    CustomInput,
    CustomButton,
    CustomModal,
    Group,
    CustomSelect,
} from '../../../components';
import CustomDate from '../../../components/admin/CustomDate';
import WordingConstant from '../../../utils/wording.json';
import {
    getDateFormatted,
    getHost,
    getSelectOptionValue,
    isHaveActionsPermission,
    isLimitedAuthorization,
    isMixedAuthorization,
} from '../../../utils/helpers';
import _, { isObject } from 'lodash';
import { searchClientAsync } from '../../../_actions/searchOrder.actions';
import { useDispatch } from 'react-redux';
import CustomUpload from '../../../components/CustomUpload/CustomUpload';

const Wording = WordingConstant.SearchCaseContainer;

const situationStatus: Array<SelectOption<any>> = [
    { id: '0', label: 'CHF', value: 'SWITCH' },
    { id: '1', label: 'MES', value: 'MOVE_IN' },
];

export type Props = {
    // fetchOrder: (props: TOrderPayload) => void;
    groupList: string[];
    schemaProps: TSchemaProps;
    userId: string | undefined;
    userLimited: string | undefined;
    handleShowModal: (f: any) => void;
};

const mapStateToProps = () => ({});
export const dispatchProps = {
    searchClientRequest: searchClientAsync.request,
    searchClientSuccess: searchClientAsync.success,
    searchClientFailure: searchClientAsync.failure,
};

const CustomerContracSearch: React.FC<Props> = ({
    groupList,
    schemaProps,
    userId,
    userLimited,
    handleShowModal,
}) => {
    const dispatch = useDispatch();
    const isDisabledActionBtn = isLimitedAuthorization(schemaProps);
    const isMixed = isMixedAuthorization(schemaProps);
    const isLimited = userLimited ? userLimited === 'LIMITED' : false;
    const renderDefaultGroup = () => {
        const defaultVal = {
            id: 0,
            label: schemaProps.currentGroup,
            value: schemaProps.currentGroup,
        };

        if (isMixed) {
            return [defaultVal];
        }

        return isDisabledActionBtn ? defaultVal : '';
    };

    const [clientLastName, setclientLastName] = useState<string | null>('');
    const [clientFirstName, setClientFirstName] = useState<string | null>('');
    const [customerPhone, setCustomerPhone] = useState<string | null>('');
    const [customerEmail, setCustomerEmail] = useState<string | null>('');
    const [clientId, setClientId] = useState<string>('');
    const [fromDate, setFrom] = useState<undefined | Date>(undefined);
    const [showGroupErrorMesage, setShowErroGroup] = useState(false);
    const [toDate, setTo] = useState<undefined | Date>(undefined);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [caseId, setCaseId] = useState<string | null>(null);
    const [refExt, setRefExt] = useState<string | null>(null);
    const [createdBy, setCreatedBy] = useState<string | null>(
        isLimited ? userId || '' : null || '',
    );
    const [customerGroup, setCustomerGroup] = useState<
        '' | SelectOption<string> | Array<SelectOption<string>>
    >(renderDefaultGroup());

    const [customerSituation, setCustomerSituation] = useState<
        '' | SelectOption<string>
    >('');

    const renderValueGroup = () => {
        if (isDisabledActionBtn) {
            return (customerGroup as SelectOption<string>).value;
        }

        if (customerGroup !== '' && isObject(customerGroup)) {
            return (customerGroup as Array<SelectOption<string>>).map(
                (obj: SelectOption<string>) => obj.value,
            );
        }

        return '';
    };

    const groupeValue = renderValueGroup();

    const groupeCondition = groupeValue !== '' && groupeValue.length !== 0;

    const searchCondition =
        caseId !== '' ||
        clientFirstName !== '' ||
        clientLastName !== '' ||
        refExt !== '' ||
        createdBy !== '' ||
        fromDate !== undefined ||
        toDate !== undefined ||
        customerEmail !== '' ||
        customerSituation !== '' ||
        customerPhone !== '';

    const mainCondition = isMixed
        ? groupeCondition
        : searchCondition || groupeCondition;

    const elementMap = {
        caseId: {
            text: Wording.num_case,
            value: caseId || '',
            onInputChange: (e: InputEvent) => setCaseId(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setCaseId(''),
        },

        to: {
            text: '',
            selectedDay: toDate,
            handleDayChange: (date: Date) => setTo(date),
            deleteButton: true,
            deleteButtonOnClick: () => setTo(undefined),
        },

        from: {
            text: '',
            selectedDay: fromDate,
            handleDayChange: (date: Date) => setFrom(date),
            deleteButton: true,
            deleteButtonOnClick: () => setFrom(undefined),
        },

        customerGroup: {
            id: 'group',
            options: _.map(groupList, (group: string, id: number) => ({
                id,
                label: group,
                value: group,
            })),

            withSelectAll: true,

            handleChangeSelect: (group: any) => {
                setCustomerGroup(group);
                if (isMixed) setShowErroGroup(false);
            },
            error:
                isMixed && !groupeCondition && showGroupErrorMesage
                    ? 'Veuillez sélectionner un Groupe'
                    : undefined,
            value: customerGroup,
            text: Wording.group,
            placeholder: ' ',
            isMulti: true,
            schemaProps,
        },

        clientFirstName: {
            text: Wording.clientFirstName,
            value: clientFirstName,
            onInputChange: (e: InputEvent) =>
                setClientFirstName(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setClientFirstName(''),
        },

        clientLastName: {
            text: Wording.clientLastName,
            value: clientLastName,
            onInputChange: (e: InputEvent) =>
                setclientLastName(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setclientLastName(''),
        },

        customerSituation: {
            id: 'situation',
            defaultValue: '',
            placeholder: 'Sélectionner',
            options: situationStatus,
            handleChangeSelect: (situation: SelectOption<string>) => {
                setCustomerSituation(situation);
            },
            value: customerSituation,
            text: Wording.situation,
        },

        refExt: {
            text: Wording.client_ref,
            value: refExt || '',
            onInputChange: (e: InputEvent) => setRefExt(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setRefExt(''),
        },

        customerPhone: {
            text: Wording.clientPhoneNbr,
            value: customerPhone,
            onInputChange: (e: InputEvent) =>
                setCustomerPhone(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setCustomerPhone(''),
        },

        customerEmail: {
            text: Wording.email,
            value: customerEmail,
            onInputChange: (e: InputEvent) =>
                setCustomerEmail(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setCustomerEmail(''),
        },
        createdBy: {
            text: Wording.createdBy,
            value: createdBy,
            onInputChange: (e: InputEvent) =>
                setCreatedBy(e.currentTarget.value),
            isLocked: isLimited,
            deleteButton: !isLimited,
            deleteButtonOnClick: () => setCreatedBy(''),
        },

        btn_validate: {
            title: Wording.btn_validate,
            color: mainCondition ? 'orange' : 'gray',
            small: true,
            onClick: () => {
                if (mainCondition) {
                    dispatch(
                        dispatchProps.searchClientRequest({
                            fromDate: getDateFormatted(fromDate),
                            toDate: getDateFormatted(toDate),
                            email: customerEmail,
                            orderNumber: caseId,
                            firstName: clientFirstName,
                            lastName: clientLastName,
                            externalReference: refExt,
                            processType: getSelectOptionValue(
                                customerSituation as SelectOption<string>,
                            ),
                            channel: groupeValue,
                            phone: customerPhone,
                            createdBy,
                        }),
                    );

                    setShowErroGroup(false);
                    return;
                }

                if (isMixed && !groupeCondition) {
                    setShowErroGroup(true);
                }
            },
        },

        btn_reset: {
            title: Wording.button_reset,
            small: true,
            color: 'gray',
            onClick: () => {
                setCaseId('');
                setclientLastName('');
                setClientFirstName('');
                setRefExt('');
                setFrom(undefined);
                setTo(undefined);
                setCustomerPhone('');
                setCustomerEmail('');
                setCustomerSituation('');
                setCreatedBy('');
                setShowErroGroup(false);

                if (!isDisabledActionBtn) {
                    setCustomerGroup('');
                }
            },
        },
    };

    const renderEndorsementGeneration = () => {
        const { upload_csv } = WordingConstant.CustomerContracSearch;

        return (
            <div className="upload-endorsements">
                <p className="upload-endorsements__title">
                    {upload_csv.title}{' '}
                </p>

                <CustomUpload
                    title={upload_csv.btn}
                    onChange={(files: any) => {
                        handleShowModal(files);
                    }}
                    accept=".csv"
                />
            </div>
        );
    };

    const clientAccessButton = {
        title: WordingConstant.CustomerContracSearch.inputs.buttonAccessClient,
        small: true,
        color: 'orange',
        onClick: () => {
            if (clientId !== '') {
                window.open(getHost(new Map([['client', clientId]])));
            }
        },
    };

    const clientAccessInput = {
        text: '',
        value: clientId,
        onInputChange: (e: InputEvent) => setClientId(e.currentTarget.value.trim()),
    };

    return (
        <div className="search-case-container">
            <div className="top-row">
                {isHaveActionsPermission(schemaProps) && (
                    <div className="access-client">
                        <CustomInput {...clientAccessInput} />
                        <CustomButton {...clientAccessButton} />
                    </div>
                )}

                {renderEndorsementGeneration()}
            </div>

            <div className="search-case-container__container">
                <div className="search-case-container__block">
                    <CustomInput {...elementMap.caseId} />
                    <label>{Wording.from}</label>
                    <CustomDate {...elementMap.from} />
                    <Group {...elementMap.customerGroup} />
                </div>
                <div className="search-case-container__block">
                    <CustomInput {...elementMap.clientLastName} />
                    <label>{Wording.to}</label>
                    <CustomDate {...elementMap.to} />
                    <CustomSelect {...elementMap.customerSituation} />
                </div>

                <div className="search-case-container__block">
                    <CustomInput {...elementMap.clientFirstName} />
                    <CustomInput {...elementMap.refExt} />
                    <CustomInput {...elementMap.createdBy} />
                </div>

                <div className="search-case-container__block">
                    <CustomInput {...elementMap.customerPhone} />
                    <CustomInput {...elementMap.customerEmail} />
                    <div className="search-rs-vl-btn">
                        <CustomButton {...elementMap.btn_reset} />

                        <CustomButton {...elementMap.btn_validate} />
                    </div>
                    <CustomModal show={showErrorModal}>
                        <span
                            className="btn-close"
                            onClick={() => {
                                setShowErrorModal(false);
                            }}
                        >
                            {'X'}
                        </span>
                        <div className="modal-response">
                            <p className="code">
                                {WordingConstant.Errors.default}
                            </p>

                            <p className="message">Veuillez reessayer</p>
                            <div className="search-rs-vl-btn">
                                <CustomButton
                                    title={'Fermer'}
                                    onClick={() => {
                                        setShowErrorModal(false);
                                    }}
                                    color={'orange'}
                                />
                            </div>
                        </div>
                    </CustomModal>
                </div>
            </div>
        </div>
    );
};

export default CustomerContracSearch;
