import React from 'react';
import resources from '../../utils/resources';
import { ECustomerType } from '../../utils/enums';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.Components.TimelineStatus;

export type DisplayColor =
    | 'yellow'
    | 'green'
    | 'red'
    | 'orange'
    | 'grey'
    | 'red-pending';

export type SegmentsColors = Array<{
    [key: string]: DisplayColor | undefined;
}>;

export type SegmentsLabels = {
    [key: string]: string[];
};

const getColor = (status: string, isPro: boolean, segmentIndx: number) => {
    const defaultColor = 'grey';

    const typeProp = !isPro ? 'part' : 'pro';

    const colors = Wording[typeProp].colors as SegmentsColors;
    return !!colors[segmentIndx] && !!colors[segmentIndx][status]
        ? colors[segmentIndx][status]
        : defaultColor;
};

const getLabels = (status: string, isPro: boolean) => {
    const typeProp = !isPro ? 'part' : 'pro';

    const labels = Wording[typeProp].labels as SegmentsLabels;
    return labels[status];
};

export const getDataSegment = (status: string, isPro: boolean): any[] => {
    const prepare: any[] = [];
    const loopArr = !isPro ? [0, 1, 2] : [0, 1];

    const lastColor = getColor(status, isPro, loopArr[loopArr.length - 1]);

    if (lastColor === 'green' || lastColor === 'orange') {
        loopArr.forEach(idx => {
            if (idx === loopArr.length - 1) prepare[idx] = lastColor;
            else prepare[idx] = 'green';
        });
    } else {
        loopArr.forEach(idx => {
            prepare[idx] =
                getColor(status, isPro, idx + 1) === 'green'
                    ? 'green'
                    : (getColor(status, isPro, idx) as DisplayColor);
        });
    }

    prepare.push(getLabels(status, isPro));
    return prepare;
};

const getImageByColor = (color: DisplayColor) => {
    switch (color) {
        case 'green':
            return resources['ic-timeline-status-ok'];

        case 'orange':
            return resources['ic-timeline-status-error'];

        case 'red':
            return resources['ic-timeline-status-abandonned'];

        case 'yellow':
            return resources['ic-timeline-status-waiting'];

        default:
            return null;
    }
};

const SegmentIcon: React.FC<{ color: DisplayColor }> = ({ color }) => {
    const cur = getImageByColor(color);

    return cur ? <img src={cur} alt={color} /> : null;
};

export const Segment: React.FC<{
    color: string | DisplayColor;
    borderRadius?: boolean;
    img?: string;
    radius?: 'start' | 'end';
}> = ({ color, radius, borderRadius }) => (
    <div className={`segment ${radius ? `segment-radius-${radius}` : ''}`}>
        <div className="icon">
            <SegmentIcon color={color as DisplayColor} />
        </div>
        <div
            className={`color color-${color} ${
                borderRadius ? 'color-border' : ''
            }`}
        >
            {radius && <div className={`radius-${radius} color-${color}`} />}
        </div>

        {color === 'green' && radius === 'end' && (
            <div className="success-icon">
                <span />
                <img src={resources['ic-timeline-status-logo']} alt="logo" />
                <p>{Wording.welcome}</p>
            </div>
        )}
    </div>
);

export const Step: React.FC<{
    label: string;
    date?: string;
    isLast?: boolean;
}> = ({ label, date, isLast }) => (
    <div className="step">
        {date && (
            <p className={`step__date ${isLast ? 'step__date-last' : ''}`}>
                {date}
            </p>
        )}
        <p className="step__label">{label}</p>
        <span />
    </div>
);
