import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import _ from 'lodash';
import WordingConstant from '../../../../../utils/wording.json';
import { buildClassName as bcn } from '../../../../../utils/helpers';
import {
    ERebateCodeTypes,
    EEnergyTypes,
    TOffer,
    TContract,
} from '../../../../../utils/network/types';
import RecapGroup from './RecapGroup';
import { SelectOption } from 'Types';
import {
    CustomError,
    CustomInput,
    CustomSelect,
    CustomToggle,
} from '../../../../../components';
import { RequestError } from '../../../../../utils/network/errors';
import Data from './Data';

const Wording = WordingConstant.AdminSubscriptionContainer.Recap;
const baseClassName = 'recap-section';
const groupsClassName = bcn([baseClassName, 'groups']);
const codeClassName = 'code';
const radioToggleClassName = bcn([codeClassName, 'radios']);
const codeInputClassName = bcn([codeClassName, 'inputs']);
const codeOptions: Array<SelectOption<ERebateCodeTypes>> = [
    { id: '0', label: Wording.code.sponsor, value: ERebateCodeTypes.SPONSOR },
    {
        id: '1',
        label: Wording.code.promotional,
        value: ERebateCodeTypes.PROMOTION,
    },
    { id: '2', label: Wording.code.cashback, value: ERebateCodeTypes.CASHBACK },
];

type GaugeProps = {
    initial: number;
    ranges: number[][];
    maxValue: number;
};

interface ISectionProps {
    initialParams?: Data;
    offers: TOffer[] | null;
    finalContracts: TContract[];
    gaugeProps: {
        electricityGaugeProps: GaugeProps | null;
        gasGaugeProps: GaugeProps | null;
    };
    onCodeValidation: (
        validated: boolean,
        code: string,
        type?: ERebateCodeTypes,
    ) => void;
    validateSponsorCode: (code: string) => Promise<boolean | RequestError>;
}

function getCodeHint(code: ERebateCodeTypes | null) {
    switch (code) {
        case ERebateCodeTypes.SPONSOR:
            return Wording.code.sponsorHint;
        case ERebateCodeTypes.CASHBACK:
            return Wording.code.cashbackHint;
        default:
            return '';
    }
}

function getCodePlaceholder(code: ERebateCodeTypes | null) {
    switch (code) {
        case ERebateCodeTypes.SPONSOR:
            return Wording.code.sponsorPlaceholder;
        case ERebateCodeTypes.PROMOTION:
            return Wording.code.promotionalPlaceholder;
        case ERebateCodeTypes.CASHBACK:
            return Wording.code.cashbackPlaceholder;
        default:
            return '';
    }
}

const RecapSection: FC<ISectionProps> = (props: ISectionProps) => {
    const {
        initialParams,
        offers,
        onCodeValidation,
        validateSponsorCode,
        finalContracts,
        gaugeProps,
    } = props;
    const codeSelectionDefault: SelectOption<ERebateCodeTypes> | null =
        !!initialParams && !!initialParams.codeType
            ? _.find(codeOptions, (option: SelectOption<ERebateCodeTypes>) => {
                  return option.value === initialParams.codeType;
              }) || null
            : null;

    const [codeSelection, setCodeSelection] = useState<SelectOption<
        ERebateCodeTypes
    > | null>(codeSelectionDefault);

    const [codeInputToggled, toggleCodeInput] = useState<boolean>(
        !!initialParams ? initialParams.codeInputToggled : false,
    );

    const [code, setCode] = useState<string>(
        !!initialParams ? initialParams.code : '',
    );

    const [codeError, setCodeError] = useState<string>('');
    const selectedCode = _.get(codeSelection, 'value', null);
    const codeInputHint = getCodeHint(selectedCode);
    const codeInputPlaceholder = getCodePlaceholder(selectedCode);

    const handleCodeInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (codeSelection === null) {
            setCodeError(Wording.code.missingSelection);
            return;
        }
        setCodeError('');
        const nextValue = _.get(e, 'target.value', '');

        setCode(nextValue);

        onCodeValidation(true, nextValue, _.get(codeSelection, 'value'));
    };
    const handleCodeSelection = (
        opt: SelectOption<ERebateCodeTypes> | null,
    ) => {
        setCodeError('');
        setCode('');
        setCodeSelection(opt);
    };
    const handleCodeToggle = (e: ChangeEvent<HTMLInputElement>) => {
        handleCodeSelection(null);
        toggleCodeInput(!codeInputToggled);
    };

    const offerArr: TOffer[] = !!initialParams
        ? initialParams.offers
        : offers || [];
    const groupClassNameSuffix = offerArr.length > 1 ? 'bi' : 'mono';
    return (
        <section className={baseClassName}>
            <h2>{Wording.title}</h2>
            {offerArr.length > 0 ? (
                <div className={`${groupsClassName}-${groupClassNameSuffix}`}>
                    {offerArr.map((offer: TOffer, idx: number) => {
                        let title = '-';
                        if (offer.energy === EEnergyTypes.ELECTRICTY) {
                            title = Wording.electricity;
                        } else if (offer.energy === EEnergyTypes.GAS) {
                            title = Wording.gas;
                        }

                        const currentContract = finalContracts.find(
                            cntr => cntr.energy === offer.energy,
                        );

                        return (
                            <RecapGroup
                                chosenPackages={
                                    currentContract
                                        ? currentContract.chosenPackages || []
                                        : []
                                }
                                key={idx}
                                offer={offer}
                                title={title}
                                gaugeProps={gaugeProps}
                                installmentFrequency={
                                    currentContract
                                        ? currentContract.installmentFrequency
                                        : undefined
                                }
                            />
                        );
                    })}
                </div>
            ) : (
                <CustomError
                    text={
                        WordingConstant.AdminSubscriptionContainer.errors
                            .getOffers
                    }
                    title={
                        WordingConstant.AdminSubscriptionContainer.errors
                            .defaultTitle
                    }
                />
            )}
            <div className={codeClassName}>
                <div className={radioToggleClassName}>
                    <CustomToggle
                        checked={codeInputToggled}
                        handleCheck={handleCodeToggle}
                        id={'code-toggle'}
                        text={Wording.code.prompt}
                    />
                </div>
                {codeInputToggled && (
                    <div className={codeInputClassName}>
                        <CustomSelect
                            handleChangeSelect={handleCodeSelection}
                            options={codeOptions}
                            value={codeSelection}
                        />
                        <CustomInput
                            error={codeError}
                            onInputChange={handleCodeInputChange}
                            placeholder={codeInputPlaceholder}
                            value={code}
                        />
                    </div>
                )}
                {codeInputToggled && !!codeInputHint && <p>{codeInputHint}</p>}
            </div>
        </section>
    );
};

export default RecapSection;
