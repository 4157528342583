import React, { Component, FC, useState } from 'react';
import _ from 'lodash';
import { Omit } from 'Types';
import { buildClassName as bcn, sleep } from '../../../../../utils/helpers';
import { CloseButton } from '../../../../../components';
import {
    EOrderStatuses,
    TOrder,
    TGetOrderStatusResponse,
    TPaymentCBResponse,
    TSignOrderResponse,
    EFirstPaymentCBStatuses,
} from '../../../../../utils/network/types';
import { RequestError } from '../../../../../utils/network/errors';
import Success from './Success';
import Retry from './Retry';
import { LoadingSpinner } from '../../../../../libraries/withLoader';
import WordingConstant from '../../../../../utils/wording.json';
import StripeContainer from '../../../../StripeFormContainer/StripeFormContainer';

const Wording = WordingConstant.AdminSubscriptionContainer.PaymentIframeModal;

enum Steps {
    BILLING = 0,
    PAYMENT = 1,
    RETRY_PAYMENT = 2,
    SUCCESS = 3,
}

type PendingOrder = {
    data: TOrder;
    number: string;
    validation: TSignOrderResponse | null;
};
type PendingPayment = TPaymentCBResponse;

interface IProps {
    orderReference: string | null;
    checkOrderStatus: (
        orderNumber: string,
    ) => Promise<TGetOrderStatusResponse | RequestError>;
    onClose: (
        validated: EFirstPaymentCBStatuses,
        error?: { title: string; text: string },
    ) => void;
    order: PendingOrder | null;
    previousOrder: TOrder | null;
    pay: (order: TOrder) => Promise<TPaymentCBResponse | RequestError>;
    setLoading: (loading: boolean) => void;
}

interface IState {
    billingIframeSrc: string | null;
    firstBillingLock: boolean;
    firstPaymentLock: boolean;
    order: PendingOrder | null;
    payment: PendingPayment | null;
    paymentIframeSrc: string | null;
    steps: [Steps, Steps];
    stripe: Pick<TPaymentCBResponse, 'stripe'> | undefined;
    stripeErrorMessage: string;
}

const initialState: IState = {
    billingIframeSrc: null,
    firstBillingLock: true,
    firstPaymentLock: true,
    order: null,
    payment: null,
    paymentIframeSrc: null,
    steps: [Steps.BILLING, Steps.BILLING],
    stripe: undefined,
    stripeErrorMessage: '',
};

const baseClassName = 'payment-iframe-modal';
const iframeClassName = bcn([baseClassName, 'iframe']);
const iframeRegex: RegExp = /<iframe.*?src="(.*?)"/;

function parseIframeSrc(srcStr: string = ''): string {
    const src: RegExpExecArray | null = iframeRegex.exec(srcStr || '');
    return src ? src[1] : '';
}

class PaymentIframeModal extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.handleBillingOnLoad = this.handleBillingOnLoad.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleFinalize = this.handleFinalize.bind(this);
        this.handlePaymentOnLoad = this.handlePaymentOnLoad.bind(this);
        this.attemptPayment = this.attemptPayment.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.closeModalButton = this.closeModalButton.bind(this);
        this.state = { ...initialState };
    }
    componentDidMount() {
        const { order, previousOrder } = this.props;
        const { steps } = this.state;
        let target: PendingOrder | null = order;
        if (!target && !!previousOrder) {
            target = {
                data: previousOrder,
                number: _.get(previousOrder, 'orderReference', ''),
                validation: null,
            };
            if (!target.number) {
                console.error('Could not retrieve previous order number');
                return;
            }
        }
        if (!target) return;
        const requiresPayment =
            target.data.firstPaymentCBStatus ===
                EFirstPaymentCBStatuses.PENDING ||
            target.data.firstPaymentCBStatus ===
                EFirstPaymentCBStatuses.IN_FAILURE ||
            target.data.firstPaymentCBStatus ===
                EFirstPaymentCBStatuses.CANCELED;
        const hasCompletedOrder =
            target.data.orderStatus === EOrderStatuses.FINALIZED ||
            target.data.orderStatus === EOrderStatuses.FINALIZED_WITHOUT_DOC;
        const hasCompletedPayment =
            target.data.firstPaymentCBStatus ===
            EFirstPaymentCBStatuses.FINALIZED;
        const payOnly =
            hasCompletedOrder && requiresPayment && !hasCompletedPayment;
        if (hasCompletedPayment) {
            this.setState({ steps: [steps[1], Steps.SUCCESS] });
        } else if (payOnly) {
            this.setState(
                {
                    order: target,
                    steps: [Steps.PAYMENT, Steps.RETRY_PAYMENT],
                },
                () => {
                    this.attemptPayment();
                },
            );
        } else {
            this.setState({
                billingIframeSrc: !!target
                    ? parseIframeSrc(_.get(target, 'validation.iframe', ''))
                    : null,
                order: target,
            });
        }
    }
    componentDidUpdate(prevProps: IProps) {
        const { order: prevOrder } = prevProps;
        const { order } = this.props;
        if (!_.isEqual(prevOrder, order)) {
            this.setState({
                billingIframeSrc: !!order
                    ? parseIframeSrc(_.get(order, 'validation.iframe', ''))
                    : null,
                order,
            });
        }
    }
    async attemptPayment() {
        this.setState({ payment: null, paymentIframeSrc: null });
        const { pay } = this.props;
        const { order, steps } = this.state;
        if (!order) {
            console.error('Cannot load payment: missing order data');
            this.setState({ steps: [steps[1], Steps.RETRY_PAYMENT] });
            return;
        }
        const res: TPaymentCBResponse | RequestError = await this.load(
            async () => {
                return await pay({
                    ...order.data,
                    externalReference: _.get(
                        order,
                        'validation.orderExternalReference',
                        '',
                    ),
                    orderReference: order.number,
                });
            },
        );
        if (res instanceof RequestError) {
            console.error('Could not load payment');
            this.setState({ steps: [steps[1], Steps.RETRY_PAYMENT] });
            return;
        }
        this.setState({
            payment: res,
            paymentIframeSrc: parseIframeSrc(res.iframe),
            steps: [steps[1], Steps.PAYMENT],
        });
    }
    async handleBillingOnLoad() {
        const { checkOrderStatus } = this.props;
        const { firstBillingLock, order } = this.state;
        if (!order) {
            this.handleCancel(EFirstPaymentCBStatuses.IN_FAILURE, {
                title: Wording.Errors.signatureTitle,
                text: Wording.Errors.missingOrderNumber,
            });
            return;
        } else if (firstBillingLock) {
            this.setState({ firstBillingLock: false });
            return;
        }
        const res: TGetOrderStatusResponse | RequestError = await this.load(
            async () => {
                return await checkOrderStatus(order.number);
            },
        );
        if (res instanceof RequestError) {
            this.handleCancel(EFirstPaymentCBStatuses.IN_FAILURE, {
                title: Wording.Errors.signatureTitle,
                text: Wording.Errors.orderStatusFetch,
            });
            return;
        }
        const isSuccess =
            res.orderStatus === EOrderStatuses.FINALIZED ||
            res.orderStatus === EOrderStatuses.FINALIZED_WITHOUT_DOC;
        const isIdle = res.orderStatus === EOrderStatuses.IN_PROGRESS;
        const isFailure =
            res.orderStatus === EOrderStatuses.CANCELED ||
            res.orderStatus === EOrderStatuses.IN_FAILURE;
        const requiresPayment =
            res.firstPaymentCBStatus === EFirstPaymentCBStatuses.PENDING;
        if (isSuccess && requiresPayment) {
            this.attemptPayment();
        } else if (isSuccess && !requiresPayment) {
            this.handleSuccess();
        } else if (!isIdle || isFailure) {
            this.handleCancel(res.firstPaymentCBStatus, {
                title: Wording.Errors.signatureTitle,
                text: Wording.Errors.signatureError,
            });
        } else if (!isIdle) {
            console.error('Signature: Unexpected status');
            this.handleCancel(res.firstPaymentCBStatus, {
                title: Wording.Errors.signatureTitle,
                text: Wording.Errors.unexpected,
            });
        }
    }
    handleCancel(
        paymentStatus: EFirstPaymentCBStatuses,
        error?: { title: string; text: string },
    ) {
        const { onClose } = this.props;
        onClose(paymentStatus, error);
    }
    handleFinalize() {
        const { onClose } = this.props;
        onClose(EFirstPaymentCBStatuses.PENDING);
    }
    async handlePaymentOnLoad(recursionCount: number = 0) {
        const { checkOrderStatus } = this.props;
        const { firstPaymentLock, order, steps } = this.state;
        if (!order) {
            this.handleCancel(EFirstPaymentCBStatuses.IN_FAILURE, {
                title: Wording.Errors.paymentTitle,
                text: Wording.Errors.missingOrderNumber,
            });
            return;
        } else if (firstPaymentLock) {
            this.setState({ firstPaymentLock: false });
            return;
        } else if (
            steps[1] === Steps.PAYMENT &&
            steps[0] === Steps.RETRY_PAYMENT
        ) {
            this.setState({ steps: [Steps.PAYMENT, Steps.PAYMENT] });
            return;
        }
        const res: TGetOrderStatusResponse | RequestError = await this.load(
            async () => {
                return await checkOrderStatus(order.number);
            },
        );
        if (res instanceof RequestError) {
            console.error('Could not get order status');
            return;
        }
        const status: EFirstPaymentCBStatuses | undefined = _.get(
            res,
            'firstPaymentCBStatus',
        );
        if (status === EFirstPaymentCBStatuses.FINALIZED) {
            this.handleSuccess();
        } else if (status === EFirstPaymentCBStatuses.PENDING) {
            console.log('Order payment pending');
        } else if (status === EFirstPaymentCBStatuses.IN_FAILURE) {
            if (recursionCount < 2) {
                console.log('Order payment in progress');
                await sleep(1000);
                await this.handlePaymentOnLoad(recursionCount + 1);
            } else {
                this.handleCancel(status, {
                    title: Wording.Errors.paymentTitle,
                    text: Wording.Errors.paymentStatusRefresh,
                });
            }
        } else if (status) {
            this.setState({ steps: [steps[1], Steps.RETRY_PAYMENT] });
        } else {
            console.error('Payment: Unexpected status');
            this.handleCancel(status, {
                title: Wording.Errors.paymentTitle,
                text: Wording.Errors.unexpected,
            });
        }
    }
    async handleSuccess() {
        const { onClose } = this.props;
        const { steps } = this.state;
        this.setState({
            steps: [steps[1], Steps.SUCCESS],
            stripeErrorMessage: '',
        });
        await sleep(5000);
        onClose(EFirstPaymentCBStatuses.FINALIZED);
    }
    async load<T>(fn: () => Promise<T>): Promise<T> {
        const { setLoading } = this.props;
        setLoading(true);
        const res = await fn();
        setLoading(false);
        return res;
    }
    closeModalButton() {
        const { onClose } = this.props;
        if (this.state.steps[1] === Steps.SUCCESS) {
            onClose(EFirstPaymentCBStatuses.FINALIZED);
        } else {
            onClose(EFirstPaymentCBStatuses.PENDING);
        }
    }
    render() {
        const {
            billingIframeSrc,
            steps,
            payment,
            stripeErrorMessage,
        } = this.state;
        const currentStep = steps[1];
        const { orderReference } = this.props;
        return (
            <div className={baseClassName}>
                <CloseButton onClick={this.closeModalButton} />
                {currentStep === Steps.BILLING && (
                    <iframe
                        className={iframeClassName}
                        title="payment-modal"
                        src={billingIframeSrc || ''}
                        id="payment-modal"
                        onLoad={() => {
                            this.handleBillingOnLoad();
                        }}
                    />
                )}
                {currentStep === Steps.PAYMENT && payment && payment.stripe && (
                    <StripeContainer
                        stripeInformations={payment.stripe}
                        handleClose={() =>
                            this.handleCancel(EFirstPaymentCBStatuses.PENDING)
                        }
                        onFormValidate={(isSuccess, errMsg) => {
                            if (isSuccess) this.handleSuccess();
                            else {
                                this.setState({
                                    steps: [steps[1], Steps.RETRY_PAYMENT],
                                    stripeErrorMessage: errMsg || '',
                                });
                            }
                        }}
                    />
                )}
                {currentStep === Steps.RETRY_PAYMENT && (
                    <Retry
                        onFinalize={this.handleFinalize}
                        onRetry={this.attemptPayment}
                        ErrorMessage={stripeErrorMessage}
                    />
                )}
                {currentStep === Steps.SUCCESS && (
                    <Success orderReference={orderReference} />
                )}
            </div>
        );
    }
}

type ILoaderProps = Omit<IProps, 'setLoading'>;

const PaymentWithLoader: FC<ILoaderProps> = (props: ILoaderProps) => {
    const [loading, setLoading] = useState(false);
    const toggleLoading = (toggled: boolean) => setLoading(toggled);
    return (
        <div className="payment-iframe">
            {loading && (
                <div className="payment-loader">
                    <LoadingSpinner />
                </div>
            )}
            <PaymentIframeModal {...props} setLoading={toggleLoading} />
        </div>
    );
};

export default PaymentWithLoader;
