import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { TContact, TContract } from 'Models';
import { EModeUpdateBilling, EEnergy } from '../utils/enums';
import { initialContract, initialContact } from '../utils/initialState';

import * as actions from '../_actions/contract.actions';
import * as profileActions from '../_actions/profile.actions';
import * as packageActions from '../_actions/package.actions';
import { isEmpty } from 'lodash';

// TODO: remove currentContract
export type ContractState = Readonly<{
    loading: boolean;
    error?: string;
    contracts: TContract[];
    selectedContractNumber: string;
    currentContract: {
        index: number;
        id: string;
    };
}>;

export const initialState = {
    loading: false,
    error: undefined,
    contracts: [
        {
            ...initialContract,
        },
    ],
    selectedContractNumber: '',
    currentContract: {
        index: 0,
        id: '',
    },
};

type Actions =
    | actions.ContractAction
    | profileActions.ProfileAction
    | packageActions.PackageAction;

const contractReducer: Reducer<ContractState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.resetContractState): {
            return {
                ...state,
                loading: false,
                error: undefined,
            };
        }

        case getType(actions.fetchContractAsync.request): {
            return {
                ...state,
                loading: true,
            };
        }

        case getType(actions.fetchContractAsync.failure): {
            return {
                ...state,
                loading: false,
                error: 'failure',
            };
        }

        case getType(actions.fetchContractAsync.success): {
            const selectedContractNumber = action.payload.contractNbr;
            const contracts = state.contracts.map(c => {
                const { contract } = action.payload;
                if (contract && c.contractNumber === selectedContractNumber) {
                    // Attach offer if getContract does not return it
                    const isFetched = true;
                    const offer = c.offer ? c.offer : contract.offer;
                    if (
                        contract.deliveryPoint.deliveryPointCategory &&
                        contract.deliveryPoint.deliveryPointCategory !== ''
                    ) {
                        return {
                            ...contract,
                            isFetched,
                            offer,
                            contractNumber: selectedContractNumber,
                        };
                    }
                    const deliveryPointCategory =
                        contract.energy === EEnergy.EL
                            ? 'C5'
                            : contract.deliveryPoint.gridRate;
                    return {
                        ...contract,
                        isFetched,
                        offer,
                        contractNumber: selectedContractNumber,
                        deliveryPoint: {
                            ...contract.deliveryPoint,
                            deliveryPointCategory,
                        },
                    };
                }
                return c;
            });
            return {
                ...state,
                selectedContractNumber,
                contracts,
                loading: false,
                currentContract: {
                    index: contracts.length - 1,
                    id: action.payload.contractNbr,
                },
                error: undefined,
            };
        }

        case getType(profileActions.fetchContextAsync.success): {
            const { contracts } = action.payload;
            const selectedContractNumber = contracts[0].contractNumber;
            const modifiedContracts = contracts.map(contract => {
                if (selectedContractNumber === contract.contractNumber) {
                    if (contract.deliveryPoint.deliveryPointCategory) {
                        return {
                            ...contract,
                            isFetched: true,
                        };
                    }
                    const deliveryPointCategory =
                        contract.energy === EEnergy.EL
                            ? 'C5'
                            : contract.deliveryPoint.gridRate;
                    return {
                        ...contract,
                        isFetched: true,
                        deliveryPoint: {
                            ...contract.deliveryPoint,
                            deliveryPointCategory,
                        },
                    };
                }
                return contract;
            });
            return {
                ...state,
                selectedContractNumber,
                contracts: modifiedContracts,
                currentContract: {
                    index: 0,
                    id: selectedContractNumber,
                },
            };
        }

        case getType(profileActions.updateCustomerAsync.success): {
            const { civility, firstName, mode } = action.payload;

            const contracts = state.contracts.map(contract => {
                if (contract.contractNumber !== state.selectedContractNumber) {
                    return contract;
                }
                if (mode && mode !== 'delete') {
                    const thirdParties = contract.thirdParties.map(p => {
                        if (p.role !== 'COHOLDER') {
                            return p;
                        }
                        return {
                            ...p,
                            civility,
                            firstName,
                        };
                    });
                    return {
                        ...contract,
                        thirdParties:
                            thirdParties.length > 0
                                ? thirdParties
                                : [
                                      {
                                          role: 'COHOLDER',
                                          civility,
                                          firstName,
                                      },
                                  ],
                    };
                }
                return {
                    ...contract,
                    thirdParties: contract.thirdParties.filter(
                        p => p.role !== 'COHOLDER',
                    ),
                };
            });

            return {
                ...state,
                contracts,
            };
        }

        case getType(profileActions.updateBillingAsync.success): {
            const {
                billingAddress,
                ibanCode,
                email,
                customerFullName,
                mode,
                debitDay,
                routingMode,
            } = action.payload;
            const contracts = state.contracts.map(contract => {
                if (contract.contractNumber !== state.selectedContractNumber) {
                    return contract;
                }
                if (mode === EModeUpdateBilling.IBAN && ibanCode) {
                    return {
                        ...contract,
                        finance: {
                            ...contract.finance,
                            ibanCode,
                        },
                    };
                }

                if (mode === EModeUpdateBilling.DEBIT_DAY && debitDay) {
                    return {
                        ...contract,
                        finance: {
                            ...contract.finance,
                            debitDay: debitDay || contract.finance.debitDay,
                        },
                    };
                }

                if (mode === EModeUpdateBilling.ROUTING_MODE && routingMode) {
                    return {
                        ...contract,
                        finance: {
                            ...contract.finance,
                            invoiceRoutingMode:
                                routingMode ||
                                contract.finance.invoiceRoutingMode,
                        },
                    };
                }

                if (contract.finance) {
                    let billingContact: TContact = { ...initialContact };
                    if (contract.finance.billingContact) {
                        billingContact = {
                            ...contract.finance.billingContact,
                        };
                    }
                    billingContact = {
                        ...billingContact,
                        customerFullName:
                            customerFullName || billingContact.customerFullName,
                        address: billingAddress || billingContact.address,
                        email: email || billingContact.email,
                    };
                    contract.finance = {
                        ...contract.finance,
                        billingContact,
                    };
                }
                return contract;
            });
            return {
                ...state,
                contracts,
            };
        }

        case getType(packageActions.updatePackageAsync.success): {
            const { contract } = action.payload;
            if (contract === undefined) {
                return {
                    ...state,
                };
            }
            const contracts = state.contracts.map(c => {
                if (c.contractNumber === contract.contractNumber) {
                    return contract;
                }
                return c;
            });
            return {
                ...state,
                contracts,
            };
        }

        // Use the the product from the productsList
        // Only if the current chosenProduct is empty (contains only productCode)
        case getType(packageActions.fetchOfferAsync.success): {
            const { offers, products, contractNbr } = action.payload;
            const contracts = state.contracts.map(contract => {
                const c = { ...contract };
                if (
                    offers &&
                    c.offer === undefined &&
                    c.energy === offers[0].energy
                ) {
                    c.offer = offers[0];
                }
                if (
                    c.contractNumber !== contractNbr ||
                    c.chosenProduct.additionalRates ||
                    offers === undefined ||
                    products === undefined
                ) {
                    return c;
                }
                const { productCode } = offers[0].product;
                const chosenProduct = products.find(
                    p => p.productCode === productCode,
                );

                if (chosenProduct) {
                    return {
                        ...c,
                        chosenProduct,
                    };
                }
                return c;
            });
            return {
                ...state,
                contracts,
            };
        }

        case getType(actions.setContract): {
            const { contract } = action.payload;
            const contracts = state.contracts.map(c => {
                if (contract.contractNumber === c.contractNumber) {
                    return contract;
                }
                return c;
            });
            return {
                ...state,
                selectedContractNumber: contract.contractNumber,
                contracts,
            };
        }

        default:
            return state;
    }
};

export default contractReducer;
