import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';

import { fetchApiRequestBlob } from '../../services/api-service';
import {
    fetchContractAsync,
    setContract,
} from '../../_actions/contract.actions';
import {
    fetchEPInvoicesAsync,
    fetchPiecesAsync,
} from '../../_actions/payment.actions';

import BillContainer, {
    Props as ContainerProps,
} from '../../containers/BillContainer/BillContainer';
import withBanner, {
    ExternalProps as ExternalPropsBanner,
} from '../../libraries/withBanner';
import withModal, {
    ExternalProps as ExternalPropsModal,
    InjectedProps as InjectedPropsModal,
} from '../../libraries/withModal';

import { findContractByNumber, getTodayDate } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';
import {
    EInvoiceRoutingMode,
    EModeUpdateBilling,
    EPieceType,
} from '../../utils/enums';
import { TEPInvoice, TInvoice } from 'Models';
import {
    IRequestUpdateBillingEvent,
    resetUpdate,
    updateBillingAsync,
} from '../../_actions/profile.actions';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';
import { fetchFilesListAsync } from '../../_actions/file.actions';

const Wording = WordingConstant.BillPage;

const mapStateToProps = (state: RootState) => ({
    profileState: state.profileReducer,
    contractState: state.contractReducer,
    fileState: state.fileReducer,
});

const dispatchProps = {
    contractDispatch: fetchContractAsync.request,
    // fetchPiecesDispatch: fetchPiecesAsync.request,
    filesListDispatch: fetchFilesListAsync.request,
    setContractDispatch: setContract,
    updateBillingDispatch: updateBillingAsync.request,
    resetBillingUpdateDispatch: resetUpdate,
};

import { IFinance } from '../../containers/ProfileContainer/types';

type State = {
    finance: IFinance;
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const WrappedBill = compose<
    ContainerProps,
    Subtract<ContainerProps, InjectedPropsModal> &
        ExternalPropsBanner &
        ExternalPropsModal
>(
    withModal(),
    withBanner(),
)(BillContainer);

class BillPage extends React.Component<Props, State, {}> {
    constructor(props: Props) {
        super(props);
        const { contractState } = this.props;
        const contract = findContractByNumber(
            contractState.selectedContractNumber,
            contractState.contracts,
        );
        const finance = {
            invoiceRoutingMode:
                contract.finance.invoiceRoutingMode ||
                EInvoiceRoutingMode.EMAIL,
            checkUpdateInvoiceRouting: false,
        };

        this.state = {
            finance,
        };
    }

    componentDidMount() {
        const {
            contractState: { selectedContractNumber },
            profileState: { customerNumber },
            // fetchPiecesDispatch,
            filesListDispatch,
        }: Props = this.props;

        // fetchPiecesDispatch({
        //    contractNbr: selectedContractNumber,
        // });
        filesListDispatch({
            contractNbr: selectedContractNumber,
            customerNbr: customerNumber,
            mode: 'INVOICE',
        });
    }

    handleChangeBanner = (contractNumber: string) => {
        const {
            contractState: { contracts },
            contractDispatch,
            profileState: { customerNumber },
            // fetchPiecesDispatch,
            setContractDispatch,
            filesListDispatch,
        } = this.props;
        const contract = findContractByNumber(contractNumber, contracts);
        if (contract.isFetched) {
            setContractDispatch({ contract });
        } else {
            contractDispatch({
                customerNbr: customerNumber,
                contractNbr: contractNumber,
            });
        }
        // fetchPiecesDispatch({
        //    contractNbr: contractNumber,
        // });
        filesListDispatch({
            contractNbr: contractNumber,
            customerNbr: customerNumber,
            mode: 'INVOICE',
        });
    };

    handleDownloadBill = async (key: string) => {
        const payload = {
            documentId: key,
            documentType: EPieceType.INVOICE,
        };
        const path = '/getInvoicePdf';
        const blob = await fetchApiRequestBlob(payload, path);
        if (blob) {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
        }
    };

    handleUpdate = () => {
        const {
            profileState,
            updateBillingDispatch,
            contractState,
        }: Props = this.props;
        const { finance } = this.state;
        const payload: IRequestUpdateBillingEvent = {
            paymentMode: {
                mode: EModeUpdateBilling.ROUTING_MODE,
                contractNbr: contractState.selectedContractNumber,
                routingMode: finance.invoiceRoutingMode,
            },
            event: {
                customerNbr: profileState.customerNumber,
                contractNbr: contractState.selectedContractNumber,
                category: 'PERSONAL_DATA',
                date: getTodayDate(),
                subCategory: 'INVOICE_CONTACT_CHANGE',
                message: Wording.routingMode.invoiceRoutingChange.label,
            },
        };
        updateBillingDispatch(payload);
    };

    handleRoutingMode = (value: string) => {
        const { finance } = this.state;
        finance.invoiceRoutingMode = value as EInvoiceRoutingMode;
        this.setState({ finance });
    };

    getContract = () => {
        const {
            contractState: { contracts, selectedContractNumber },
        }: Props = this.props;
        return findContractByNumber(selectedContractNumber, contracts);
    };

    render() {
        const {
            profileState: { contact, customerType, updateResponse },
            contractState: { contracts, selectedContractNumber },
            fileState: { files },
            resetBillingUpdateDispatch,
        }: Props = this.props;
        const { finance } = this.state;
        const invoices = files.financialPieces
            ? files.financialPieces.result.invoice_list
            : [];
        const setCheckUpdateInvoiceRouting = (value: boolean) => {
            this.setState(prevState => ({
                finance: {
                    ...prevState.finance,
                    checkUpdateInvoiceRouting: value,
                },
            }));
        };

        const handleReset = () => {
            resetBillingUpdateDispatch();
            this.setState(prevState => ({
                finance: {
                    ...prevState.finance,
                    checkUpdateInvoiceRouting: false,
                },
            }));
        };

        return (
            <WrappedBill
                data={invoices as TEPInvoice[]}
                handleDownloadBill={this.handleDownloadBill}
                handleRoutingMode={this.handleRoutingMode}
                finance={finance}
                contract={this.getContract()}
                setCheckUpdateInvoiceRouting={setCheckUpdateInvoiceRouting}
                // Banner
                title={Wording.title}
                contracts={contracts}
                contact={contact}
                customerType={customerType}
                handleChangeBanner={this.handleChangeBanner}
                selectedContractNumber={selectedContractNumber}
                // Modal
                modalMessage={Wording.routingMode.popup}
                updateResponse={updateResponse}
                handleValidate={this.handleUpdate}
                handleReset={handleReset}
            />
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(BillPage);
