import IconLogoSideBar from '../assets/icons/ic-logo-sidebar.svg';
import IconLogoLogin from '../assets/icons/ic-logo-login.svg';
import IconElecOff from '../assets/icons/ic-elec-off.svg';
import IconElecOn from '../assets/icons/ic-elec-on.svg';
import IconElecGreen from '../assets/icons/ic-elec-green.svg';
import IconGasOff from '../assets/icons/ic-gas-off.svg';
import IconGasOn from '../assets/icons/ic-gas-on.svg';
import IconLogOut from '../assets/icons/ic-logout-sidebar.svg';
import IconWekiwi from '../assets/icons/ic-wekiwi-popup.png';
import IconWekiwiOrange from '../assets/icons/ic-wekiwi-popup-orange.png';
import IconWekiwiNew from '../assets/icons/logo-wekiwi-new.png';
import IconAvatar from '../assets/icons/ic-avatar.svg';
import IconLinkedin from '../assets/icons/ic-linkedin.svg';
import IconInstagram from '../assets/icons/ic-instagram.svg';
import IconFacebook from '../assets/icons/ic-facebook.svg';
import IconYoutube from '../assets/icons/ic-youtube.svg';
import IconTwitter from '../assets/icons/ic-twitter.svg';
import IconCertification from '../assets/icons/ic-certification.svg';
import IcMenu from '../assets/icons/ic-menu.svg';
import IcMenuH from '../assets/icons/ic-menu-h.svg';
import IcTriangle from '../assets/icons/ic-triangle.svg';
import IcCross from '../assets/icons/ic-cross.svg';
import IcCheckValidate from '../assets/icons/ic-check-validate.svg';
import IcFilterSymbol from '../assets/icons/filter-symbol.svg';
import IcArrowDown from '../assets/icons/arrow-down.png';
import IcArrowSubscription from '../assets/icons/arrow-subscription.svg';

import IcMenuOrange from '../assets/icons/ic-menu-orange.svg';
import IcMenuOrangeCreu from '../assets/icons/ic-menu-orange-creu.svg';

import IconArrowBack from '../assets/icons/ic-arrow-back.svg';
import IconArrowNext from '../assets/icons/ic-arrow-next.svg';
import IconAccueilNewWekiwi from '../assets/icons/ic-image-background-accueil.png';
import IconArrowOpenMenu from '../assets/icons/icon-arrow-open-menu.png';

// timline status
import IconTimelineLogo from '../assets/icons/timeline-status/logo.png';
import IconTimelineOk from '../assets/icons/timeline-status/ok.png';
import IconTimelineError from '../assets/icons/timeline-status/error.png';
import IconTimelineWaiting from '../assets/icons/timeline-status/waiting.png';
import IconTimelineAbandonned from '../assets/icons/timeline-status/abandonned.png';

import IconElecCounters from '../assets/icons/ic-elec-counters.png';
import IconGasCounters from '../assets/icons/ic-gas-counters.png';

import IconCopyClipboard from '../assets/icons/ic-copy-clipboard.svg';
import IconLoader from '../assets/loader.svg';

export default {
    'ic-elec-counters': IconElecCounters,
    'ic-gas-counters': IconGasCounters,
    'ic-logo-sidebar': IconLogoSideBar,
    'ic-timeline-status-ok': IconTimelineOk,
    'ic-timeline-status-logo': IconTimelineLogo,
    'ic-timeline-status-error': IconTimelineError,
    'ic-timeline-status-waiting': IconTimelineWaiting,
    'ic-timeline-status-abandonned': IconTimelineAbandonned,
    'ic-logo-login': IconLogoLogin,
    'ic-elec-off': IconElecOff,
    'ic-elec-on': IconElecOn,
    'ic-elec-green': IconElecGreen,
    'ic-gas-on': IconGasOn,
    'ic-gas-off': IconGasOff,
    'ic-logout-sidebar': IconLogOut,
    'ic-wekiwi-popup': IconWekiwi,
    'ic-wekiwi-popup-orange': IconWekiwiOrange,
    'ic-avatar': IconAvatar,
    'ic-linkedin': IconLinkedin,
    'ic-instagram': IconInstagram,
    'ic-facebook': IconFacebook,
    'ic-youtube': IconYoutube,
    'ic-twitter': IconTwitter,
    'ic-certification': IconCertification,
    'ic-menu': IcMenu,
    'ic-menu-h': IcMenuH,
    'ic-menu-orange': IcMenuOrange,
    'ic-menu-orange-creu': IcMenuOrangeCreu,
    'ic-triangle': IcTriangle,
    'ic-cross': IcCross,
    'ic-check-validate': IcCheckValidate,
    'ic-filter-symbol': IcFilterSymbol,
    'ic-arrow-back': IconArrowBack,
    'ic-arrow-next': IconArrowNext,
    'ic-loader': IconLoader,
    'ic-copy-clipboard': IconCopyClipboard,
    'ic-wekiwi-new': IconWekiwiNew,
    'ic-new-logo-accueil-background': IconAccueilNewWekiwi,
    'ic-arrow-open-menu': IconArrowOpenMenu,
    'ic-arrow-down': IcArrowDown,
    'ic-arrow-subscribtion': IcArrowSubscription,
};
