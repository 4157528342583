import React from 'react';

import { TFinance } from 'Models';
import { getLabelPaymentMode } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

const Payment: React.FC<{
    finance: TFinance;
}> = ({ finance }) => {
    const f = finance || {};
    return (
        <React.Fragment>
            <h3 className="profile-container__payment-method__title">
                {Wording.paymentMethod.title}
            </h3>
            <div className="profile-container__payment-method">
                <div className="profile-container__payment-method__texts">
                    <p>{Wording.paymentMethod.paymentMode}</p>
                    <p>{Wording.paymentMethod.samplingDate}</p>
                    <p>{Wording.paymentMethod.IBAN}</p>
                </div>
                <div className="profile-container__payment-method__data">
                    <p>{getLabelPaymentMode(f.paymentMode)}</p>
                    <p>
                        {' '}
                        {f.debitDay} {WordingConstant.Common.debitDaySuffix}
                    </p>
                    <p>{f.ibanCode}</p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Payment;
