import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { CheckboxTile } from '../../../../../components';
import { buildClassName as bcn } from '../../../../../utils/helpers';
import WordingConstant from '../../../../../utils/wording.json';
import { TAdditionalRate } from '../../../../../utils/network/types';

interface IProps {
    electricityOptions: TAdditionalRate[];
    electricityOptionSelection: boolean[];
    gasOptions: TAdditionalRate[];
    gasOptionSelection: boolean[];
    onSelection: (
        electricitySelection: boolean[],
        gasSelection: boolean[],
    ) => void;
}

const baseClassName = 'additional';
const optionsClassName = bcn([baseClassName, 'options']);
const groupClassName = 'option-group';
const Wording = WordingConstant.AdminSubscriptionContainer.Recap;

class Options extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            electricityOptions: [],
            gasOptions: [],
        };
        this.toggleOption = this.toggleOption.bind(this);
    }
    toggleOption(type: 'EL' | 'NG', index: number, value: boolean) {
        const { onSelection } = this.props;
        const { electricityOptionSelection, gasOptionSelection } = this.props;
        if (type === 'EL') electricityOptionSelection[index] = value;
        else if (type === 'NG') gasOptionSelection[index] = value;
        onSelection(electricityOptionSelection, gasOptionSelection);
    }
    render() {
        const {
            electricityOptions,
            electricityOptionSelection,
            gasOptions,
            gasOptionSelection,
        } = this.props;
        return (
            <div className={baseClassName}>
                <div className={optionsClassName}>
                    {electricityOptions.length > 0 && (
                        <Fragment>
                            <h5>{Wording.options.electricity.title}</h5>
                            <div className={groupClassName}>
                                {electricityOptions.map(
                                    (opt: TAdditionalRate, i: number) => {
                                        const checked =
                                            electricityOptionSelection.length >=
                                                i &&
                                            electricityOptionSelection[i];
                                        return (
                                            <CheckboxTile
                                                key={i}
                                                checked={checked}
                                                onToggle={() => {
                                                    this.toggleOption(
                                                        'EL',
                                                        i,
                                                        !checked,
                                                    );
                                                }}
                                                text={opt.description}
                                                title={opt.label}
                                                titleHint={[
                                                    _.get(
                                                        opt,
                                                        'detail.price',
                                                        '',
                                                    ),
                                                    _.get(
                                                        opt,
                                                        'detail.unit',
                                                        '',
                                                    ),
                                                ].join(' ')}
                                            />
                                        );
                                    },
                                )}
                            </div>
                        </Fragment>
                    )}
                    {gasOptions.length > 0 && (
                        <Fragment>
                            <h5>{Wording.options.gas.title}</h5>
                            <div className={groupClassName}>
                                {gasOptions.map(
                                    (opt: TAdditionalRate, i: number) => {
                                        const checked =
                                            gasOptionSelection.length >= i &&
                                            gasOptionSelection[i];
                                        return (
                                            <CheckboxTile
                                                key={i}
                                                checked={checked}
                                                onToggle={() => {
                                                    this.toggleOption(
                                                        'NG',
                                                        i,
                                                        !checked,
                                                    );
                                                }}
                                                text={opt.description}
                                                title={opt.label}
                                                titleHint={
                                                    !!opt.detail &&
                                                    !!opt.detail.price &&
                                                    !!opt.detail.unit
                                                        ? `${opt.detail.price} ${opt.detail.unit}`
                                                        : ''
                                                }
                                            />
                                        );
                                    },
                                )}
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default Options;
