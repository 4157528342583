import { pdf } from '@react-pdf/renderer';
import React from 'react';
import Timeline from './Timeline';
import { TimelineProps } from './type';

export default function(props: TimelineProps) {
    pdf(<Timeline {...props} />)
        .toBlob()
        .then(blob => {
            window.open(URL.createObjectURL(blob));
        });
}
