import React from 'react';

import { StyleSheet, Text } from '@react-pdf/renderer';

import Wording from '../../wording.json';

const styles = StyleSheet.create({
    text: {
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 15,
        marginBottom: 10,
    },
});

const ObjectTitle = () => {
    return <Text style={styles.text}>{Wording.object}</Text>;
};

export default ObjectTitle;
