import React from 'react';
import { connect } from 'react-redux';
import { RootState, TSchemaProps } from 'Types';
import SearchContainer from '../../../containers/admin/SearchContainer';
import CustomerContractContainer from '../../../containers/admin/CustomerContractContainer';
import { manageUserAsync } from '../../../_actions/manageUser.actions';
import { searchUserGroupAsync } from '../../../_actions/searchUserGroup.actions';
import { createUserAsync } from '../../../_actions/createUser.actions';
import { resetGroupResponse } from '../../../_actions/createGroup.actions';
import { getGroupAsync } from '../../../_actions/getGroup.actions';

const mapStateToProps = (state: RootState) => ({
    groupListState: state.userGroupReducer.groupList,
    manageUserState: state.userGroupReducer.createUserResponse,
    orders: state.orderReducer.searchClient.order,
    schema: state.authReducer.autorizationsScheme,
});

const dispatchProps = {
    manageUserAsyncDispatch: manageUserAsync.request,
    searchUserGroupAsyncDispatch: searchUserGroupAsync.request,
    createUserAsyncDispatch: createUserAsync.request,
    getGroupAsyncDispatch: getGroupAsync.request,
    resetGroupResponse,
};

type Props = {
    schemaProps: TSchemaProps;
} & ReturnType<typeof mapStateToProps> &
    typeof dispatchProps;

const CustomerContracterPage: React.FC<Props> = ({
    groupListState,
    schemaProps,
    manageUserAsyncDispatch,
    searchUserGroupAsyncDispatch,
    createUserAsyncDispatch,
    manageUserState,
}) => {
    return (
        <div className="search-container">
            <SearchContainer
                schemaProps={schemaProps}
                groupList={groupListState}
            />

            <CustomerContractContainer
                manageUser={manageUserAsyncDispatch}
                searchUserGroup={searchUserGroupAsyncDispatch}
                groupList={groupListState}
                createUser={createUserAsyncDispatch}
                createUserResponse={manageUserState}
                schemaProps={schemaProps}
                resetGroupResponse={resetGroupResponse}
            />
        </div>
    );
};

export default connect(mapStateToProps, dispatchProps)(CustomerContracterPage);
