import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { TFile, TFiles, TEPInvoice } from 'Models';

import * as actions from '../_actions/file.actions';
import {
    initialFile,
    initialEPInvoice,
    initialFiles,
} from '../utils/initialState';

export type FileState = Readonly<{
    files: TFile;
    documents: TFiles[];
    invoices: TEPInvoice[];
    payments: TEPInvoice[];
}>;

export const initialState = {
    files: initialFile,
    documents: [],
    invoices: [],
    payments: [],
};

type Actions = actions.FileActions;

const fileReducer: Reducer<FileState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchFilesListAsync.request):
            return { ...state };

        case getType(actions.fetchFilesListAsync.failure):
            return { ...state };

        case getType(actions.fetchFilesListAsync.success): {
            return {
                ...state,
                files: action.payload,
                documents: action.payload.documentsList,
                invoices: action.payload.financialPieces
                    ? action.payload.financialPieces.result.invoice_list
                    : [],
                payments: action.payload.financialPieces
                    ? action.payload.financialPieces.result.payment_list
                    : [],
            };
        }
        default:
            return { ...state };
    }
};

export default fileReducer;
