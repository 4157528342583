import React, { FC } from 'react';

import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    textValueContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 10,
    },
    value: {
        fontSize: 10,
    },
});

export const TextValue: FC<{ title: string; value: string }> = ({
    title,
    value,
}) => (
    <View style={styles.textValueContainer}>
        <Text style={styles.title}>{title} : </Text>
        <Text style={styles.value}>{value}</Text>
    </View>
);

export default TextValue;
