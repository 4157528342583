import React, { Component } from 'react';
import cs from 'classnames';

const defaultButton = (props: any) => (
    <button type="button" {...props} className="-btn">
        {props.children}
    </button>
);

type State = {
    page: any;
};

const defaultRowsNumber = [5, 10, 20, 50];

class TablePagination extends Component<any, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            page: props.page,
        };
    }

    componentWillReceiveProps(nextProps: { page: any }) {
        this.setState({ page: nextProps.page });
    }

    getSafePage = (page: number) => {
        if (isNaN(page)) {
            // tslint:disable-next-line:no-parameter-reassignment
            page = this.props.page;
        }
        if (this.props.canNextFromData) return page;

        return Math.min(Math.max(page, 0), this.props.pages - 1);
    };

    changePage = (page: any) => {
        // tslint:disable-next-line:no-parameter-reassignment
        page = this.getSafePage(page);
        this.setState({ page });
        if (this.props.page !== page) {
            this.props.onPageChange(page);
        }
    };

    applyPage = (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        const page = this.state.page;
        this.changePage(page === '' ? this.props.page : page);
    };

    render() {
        const {
            t,
            // Computed
            pages,
            // Props
            page,
            showPageSizeOptions,
            pageSize,
            data,
            showPageJump,
            canPrevious,
            onPageSizeChange,
            className,
            PreviousComponent = defaultButton,
            NextComponent = defaultButton,
            showTotalPages = true,
        } = this.props;
        const dataLength = data.length;

        const canNext = this.props.canNextFromData
            ? data && dataLength === pageSize
            : this.props.canNext;

        const dataLengthCheck = dataLength < 10;

        return (
            <div
                className={cs(
                    'table-pagination -pagination',
                    { 'table-pagination-visibility': dataLengthCheck },
                    className,
                )}
                style={this.props.style}
            >
                <div className="table-pagination-previous -previous">
                    <PreviousComponent
                        onClick={() => {
                            if (!canPrevious) return;
                            this.changePage(page - 1);
                        }}
                        disabled={!canPrevious}
                    >
                        {'<'}
                    </PreviousComponent>
                </div>

                <div className="center">
                    <span className="-pageInfo">
                        {this.props.pageText}{' '}
                        {showPageJump ? (
                            <div className="-pageJump">
                                <input
                                    type={
                                        this.state.page === ''
                                            ? 'text'
                                            : 'number'
                                    }
                                    onChange={e => {
                                        const val = e.target.value;
                                        const page = Number(val) - 1;
                                        if (val === '') {
                                            return this.setState({ page: val });
                                        }
                                        this.setState({
                                            page: this.getSafePage(page),
                                        });
                                    }}
                                    value={
                                        this.state.page === ''
                                            ? ''
                                            : this.state.page + 1
                                    }
                                    onBlur={this.applyPage}
                                    onKeyPress={e => {
                                        if (
                                            e.which === 13 ||
                                            e.keyCode === 13
                                        ) {
                                            this.applyPage();
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            <span className="-currentPage">{page + 1}</span>
                        )}{' '}
                        {showTotalPages ? (
                            <React.Fragment>
                                {'sur'}{' '}
                                <span className="-totalPages">
                                    {pages || 1}
                                </span>
                            </React.Fragment>
                        ) : null}
                    </span>

                    <div className="table-pagination-next -next">
                        <NextComponent
                            onClick={() => {
                                if (!canNext) return;
                                this.changePage(page + 1);
                            }}
                            disabled={!canNext}
                        >
                            {'>'}
                        </NextComponent>
                    </div>
                    {showPageSizeOptions && (
                        <span className="select-wrap -pageSizeOptions">
                            <select
                                onChange={e =>
                                    onPageSizeChange(Number(e.target.value))
                                }
                                value={pageSize}
                            >
                                {defaultRowsNumber.map((option, idx) => (
                                    <option key={idx} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </span>
                    )}

                    <span className="data-length">
                        <p>
                            Résultat{dataLength > 1 ? 's' : ''} :{' '}
                            <span>{dataLength}</span>
                        </p>
                    </span>
                </div>
            </div>
        );
    }
}

export default TablePagination;
