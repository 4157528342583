import React from 'react';

import { Image, View, StyleSheet } from '@react-pdf/renderer';

import Logo from '../../../../assets/icons/PDFs/ProofOfPayment/ic-logo-sidebar.png';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        backgroundColor: '#ffb40a',
        alignItems: 'center',
    },
    img: {
        width: '110px',
        height: '55px',
        padding: '10px 0px',
    },
});

export default () => (
    <View style={styles.container}>
        <Image style={styles.img} src={Logo} />
    </View>
);
