import React from 'react';

type InputSetting = {
    isLocked?: boolean;
    text?: string;
    value?: any;
    type?: string;
    error?: string;
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
    onToggleModal?: any;
    minLength?: number;
    maxLength?: number;
    isInterrogationVisible?: boolean;
    defaultValue?: any;
    placeholder?: string;
    disabled?: boolean;
    onBlur?: any;
    onKeyPress?: any;
    deleteButton?: boolean;
    deleteButtonOnClick?: () => void;
};

const CustomInput: React.FC<InputSetting> = setting => (
    <div className="custom-input">
        {setting.text && (
            <label htmlFor={`id${setting.text}`}>
                {setting.text}
                {setting.isInterrogationVisible && (
                    <span
                        className="custom-input__layer"
                        onClick={setting.onToggleModal}
                        onKeyDown={setting.onToggleModal}
                        role="button"
                        tabIndex={0}
                    >
                        ?
                    </span>
                )}
            </label>
        )}

        {setting.deleteButton && setting.value !== '' && (
            <span
                className="custom-input__delete"
                onClick={setting.deleteButtonOnClick}
                role="button"
                tabIndex={0}
            >
                <span role="button" tabIndex={0}>
                    X
                </span>
            </span>
        )}

        <input
            className={`custom-input__input${
                setting.isLocked ? ' custom-input__input--locked' : ''
            }`}
            onChange={setting.onInputChange}
            type={setting.type}
            value={setting.value}
            minLength={setting.minLength}
            maxLength={setting.maxLength}
            defaultValue={setting.defaultValue}
            placeholder={setting.placeholder}
            disabled={setting.disabled}
            onBlur={setting.onBlur}
            onKeyPress={setting.onKeyPress}
        />
        {setting.error && setting.error !== '' && (
            <p className="custom-input__error">{setting.error}</p>
        )}
    </div>
);
export default CustomInput;
