import React from 'react';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Common;

type Props = {
    show?: boolean;
    showCloseButton?: boolean;
    handleClick?: any;
    className?: string;
    children: JSX.Element[] | JSX.Element;
    customStyle?: React.CSSProperties;
};

const CustomModal: React.FC<Props> = ({
    show,
    showCloseButton,
    handleClick,
    className,
    children,
    customStyle,
}) => (
    <div
        role="button"
        tabIndex={0}
        className={`custom-modal ${show ? ' custom-modal--show-modal' : ''}  `}
    >
        <div
            className={` custom-modal__content ${
                className !== '' ? className : ''
            }`}
            role="button"
            tabIndex={0}
            style={customStyle}
        >
            {showCloseButton && (
                <span
                    className="custom-modal__content__close-button"
                    onClick={handleClick}
                    role="button"
                    tabIndex={0}
                >
                    {Wording.popup.closeButton}
                </span>
            )}
            {children}
        </div>
    </div>
);

export default CustomModal;
