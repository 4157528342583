import React, { FC } from 'react';

import { Document, Page, StyleSheet } from '@react-pdf/renderer';

import './fonts';

import Header from './components/Header';
import CustomerContainer from './components/CustomerContainer';
import ContractContainer from './components/ContractContainer';
import TimelineTable from './components/TimelineTable';
import Informations from './components/Informations';
import Footer from './components/Footer';

import { TimelineProps } from './type';
import { TimelineProvider } from './lib';

const media: any = {
    '@media max-width: 400': {
        flexDirection: 'column',
    },
};

const styles = StyleSheet.create({
    page: {},
});

const Timeline: FC<TimelineProps> = props => {
    return (
        <TimelineProvider value={{ ...props }}>
            <Document author="Wekiwi SAS" title="ECHEANCIER">
                <Page size="A4" style={styles.page}>
                    <Header />
                    <CustomerContainer />
                    <ContractContainer />
                    <TimelineTable />
                    <Informations />
                    <Footer />
                </Page>
            </Document>
        </TimelineProvider>
    );
};

export default Timeline;
