import React from 'react';

type Props = {
    text: string;
    value: string;
    id?: string;
    checked: boolean;
    handleCheck: any;
    description?: string;
};

const CustomRadio: React.FC<Props> = ({
    text,
    value,
    id,
    checked,
    handleCheck,
    description,
}) => (
    <label htmlFor={id} className="custom-radio">
        <input
            type="checkbox"
            id={id}
            checked={checked}
            onChange={handleCheck}
            value={value}
        />
        <span className="custom-radio__checkmark" />
        {text}
        {description && (
            <p className="custom-radio__description">{description}</p>
        )}
    </label>
);

export default CustomRadio;
