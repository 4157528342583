import React, { FC } from 'react';

import { Document, Page, View, StyleSheet } from '@react-pdf/renderer';

import './fonts';

import Header from './components/Header';
import Container from './components/Container';
import PageNumber from './components/PageNumber';

import MainTitle from './components/titles/Main';

import LeftColumn from './components/LeftColumn';
import RightColumn from './components/RightColumn';

import BottomText from './components/BottomText';

import Footer from './components/Footer';

import { ProofPaymentProps } from './type';
import { ProofOfPaymentProvider } from './lib';

const media: any = {
    '@media max-width: 400': {
        flexDirection: 'column',
    },
};

const styles = StyleSheet.create({
    page: {},
    container: {
        padding: 20,
        flex: 1,
        flexDirection: 'column',
        ...media,
    },
    columnsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 40,
        marginBottom: 20,
    },
});

const ProofOfPayment: FC<ProofPaymentProps> = props => {
    return (
        <ProofOfPaymentProvider value={{ ...props }}>
            <Document author="Wekiwi SAS" title="JUSTIFICATIF-DE-PAIEMENT">
                <Page size="A4" style={styles.page}>
                    <Header />

                    <Container>
                        <MainTitle />

                        <View style={styles.columnsContainer}>
                            <LeftColumn />
                            <RightColumn />
                        </View>

                        <BottomText />

                        <Footer />
                    </Container>

                    <PageNumber />
                </Page>
            </Document>
        </ProofOfPaymentProvider>
    );
};

export default ProofOfPayment;
