/**
 * @author Ivan Naluzhnyi | Nawin Kim
 * @email [ivan.naluzhnyi@gmail.com]
 * @email [nawin.kim@uppli.fr]
 * @create date 2019-09-16 16:44:55
 * @modify date 2019-09-16 16:50:47
 * @desc [client-space | Uppli]
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { RootState, RouteProps, SubRouteProps } from 'Types';
import { SideBar, Footer, Modal } from '../components';
import routes from './routes';
import { resetReduxStore } from '../_actions/auth.actions';
import withLoader from '../libraries/withLoader';
import AuthService from '../services/auth-service';

import Wording from '../utils/wording.json';
import { resetUpdate } from '../_actions/profile.actions';
const WordingCommon = Wording.Common;

const Content = () => (
    <div className="content" id="content">
        {routes.map((route: RouteProps) =>
            route.links.map((subRoute: SubRouteProps, index: number) => (
                <Route
                    key={index}
                    path={route.path + subRoute.path}
                    exact={subRoute.exact}
                    component={subRoute.main}
                />
            )),
        )}
    </div>
);

const WrappedContent = withLoader()(Content);

const mapStateToProps = (state: RootState) => ({
    authState: state.authReducer,
    profileState: state.profileReducer,
});

const dispatchProps = {
    resetReduxStoreDispatch: resetReduxStore,
    resetUpdateDispatch: resetUpdate,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

class Main extends React.Component<Props, {}> {
    render() {
        const {
            profileState: { contact, customerNumber, updateResponse },
            authState: { loading, unauthorized },
            resetReduxStoreDispatch,
            resetUpdateDispatch,
        }: Props = this.props;

        const props = {
            routes,
            firstName: contact.customerFullName.firstName,
            lastName: contact.customerFullName.lastName,
            customerNbr: customerNumber,
            handleLogout: () => resetReduxStoreDispatch(),
        };
        const isSignIn = AuthService.isAuthenticated();
        if (!isSignIn) {
            return <Redirect to={{ pathname: '/login' }} />;
        }

        return (
            <div className="main-page">
                <div className="main-page__content">
                    <SideBar {...props} />
                    <WrappedContent
                        loading={loading}
                        unauthorized={unauthorized}
                        handleCloseModal={() => {
                            // this.props.history.push('')
                            AuthService.logout();
                            props.handleLogout();
                        }}
                    />

                    <Modal
                        title={WordingCommon.contextModal.title}
                        description={
                            updateResponse.message ||
                            WordingCommon.contextModal.description
                        }
                        state={1}
                        show={
                            updateResponse.code === '505' ||
                            updateResponse.code === '0'
                        }
                        handleCancel={() => {
                            window.close();
                            resetUpdateDispatch();
                        }}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(Main);
