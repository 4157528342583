export enum EEnergy {
    EL = 'EL',
    NG = 'NG',
}

export enum ECustomerType {
    INDIVIDUAL = 'INDIVIDUAL',
    PROFESSIONAL = 'PROFESSIONAL',
}

export enum ECustomerStatus {
    PROSPECT = 'PROSPECT',
    CUSTOMER = 'CUSTOMER',
}

export enum ECustomerCategoryCode {
    CUSTOMERB2B = 'customerB2B',
    CUSTOMERB2C = 'customerB2C',
}

export enum EBillingMode {
    PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE',
    CYCLICAL_BILLING = 'CYCLICAL_BILLING',
}

export enum EProfile {
    RES1 = 'RES1',
    RES11 = 'RES11',
    RES2 = 'RES2',
    P011 = 'P011',
    P012 = 'P012',
    PRO1 = 'PRO1',
    PRO2 = 'PRO2',
    RES3 = 'RES3',
    RES4 = 'RES4',
    RES5 = 'RES5',
}

export enum ECivility {
    MR = 'MR',
    MS = 'MS',
    MRS = 'MRS',
}

export enum EAppointmentTimeslot {
    MORNING = 'MORNING',
    AFTERNOON = 'AFTERNOON',
    DAY = 'DAY',
    NONE = 'NONE',
}

export enum EProcessTypes {
    MOVE_IN = 'MOVE_IN',
    SWITCH = 'SWITCH',
}

export enum EFrequency {
    BIMONTHLY = 'BIMONTHLY',
    QUARTERLY = 'QUARTERLY',
    FOURMONTHLY = 'FOURMONTHLY',
    BIANNUAL = 'BIANNUAL',
}

export enum EReadingFrequency {
    MONTHLY = 'MONTHLY',
    BIMONTHLY = 'BIMONTHLY',
    BIANNUAL = 'BIANNUAL',
    UNKNOWN = 'UNKNOWN',
}

export enum ETimeframe {
    TOTAL_HOUR = 'TOTAL_HOUR',
    LOW = 'LOW',
    HIGH = 'HIGH',
    WINTER = 'WINTER',
    SUMMER = 'SUMMER',
    UNKNOWN = 'UNKNOWN',
}

export enum ERateOption {
    TOTAL_HOUR = 'TOTAL_HOUR',
    HIGH_LOW = 'HIGH_LOW',
    UNKNOWN = 'UNKNOWN',
}

export enum EIntermediate {
    SLIMPAY = 'slimpay',
    BANK = 'bank',
    BALANCE = 'balance',
}

export enum ETransactionStatus {
    INITIALIZED = 'initialized',
    TRANSMITTED = 'transmitted',
    TRANSFER_FAILURE = 'transferFailure',
    TRANSACTION_FAILURE = 'transactionFailure',
    DETECTED_ANOMALY = 'detectedAnomaly',
    SUCCESS = 'success',
    CANCELLED = 'cancelled',
    DISPUTED = 'disputed',
}

export enum EEffectiveStartRange {
    NOW = 'NOW',
    LATER = 'LATER',
}

export enum EtypePayment {
    SDD = 'SDD',
    OTHER = 'OTHER',
}

export enum EInvoiceRoutingMode {
    EMAIL = 'EMAIL',
    MAIL = 'MAIL',
    BOTH = 'BOTH',
}

export enum EPaymentMode {
    CHECK = 'CHECK',
    DIRECT_DEBIT = 'DIRECT_DEBIT',
    BANK_TRANSFER = 'BANK_TRANSFER',
}

export enum EPaymentModeKey {
    DIRECT_DEBIT = 'directDebit',
    CHECK = 'check',
    BANK_TRANSFER = 'bankTransfer',
    BANK_CARD = 'bankCard',
    CASH = 'cash',
}

export enum EModeUpdateBilling {
    BILLING_ADDRESS = 'BILLING_ADDRESS',
    IBAN = 'IBAN',
    DEBIT_DAY = 'DEBIT_DAY',
    ROUTING_MODE = 'ROUTING_MODE',
}

export enum EModeManageOffer {
    INSTALLMENT_FREQUENCY = 'INSTALLEMENT_FREQUENCY',
    PRODUCT = 'PRODUCT',
    PACKAGE = 'PACKAGE',
    OPTIONS = 'OPTIONS',
}

export enum EAmountCategory {
    TOTAL_AMOUNT = 'TOTAL_AMOUNT',
    ADDITIONAL_RATE = 'ADDITIONAL_RATE',
    TOTAL_BUDGET = 'TOTAL_BUDGET',
    ECONOMY = 'ECONOMY',
}

export enum EAmountType {
    FEE = 'FEE',
    OPTION = 'OPTION',
    DISCOUNT = 'DISCOUNT',
}

export enum CreatePasswordMode {
    RESET = 'RESET',
    VALIDATION = 'VALIDATION',
}

export enum EContractStatus {
    WAITING = 'waiting',
    RECOVERY = 'recovery',
    INITIALIZED = 'initialized',
    CANCELLED = 'canceled',
    ABONDONED = 'abandonned',
    REJECTED = 'rejected',
    SENT_TO_MARKET = 'sent_to_market',
    EFFECTIVE = 'effective',
    TERMINATED = 'terminated',
    VALIDATION_REJECTED = 'terminated',
    WAITING_FOURTEEN_DAY = 'waiting-14d',
    ACCEPTED = 'accepted',
    PAYMENT_FAILURE = 'paymentFailure',
}

export enum EConsumptionStatus {
    ACTUAL = 'ACTUAL',
    RECTIFIED = 'RECTIFIED',
}

export enum EMeterReadFrequency {
    HALF_YEAR = 'HALF_YEAR',
    MONTHLY = 'MONTHLY',
    BIMONTHLY = 'BIMONTHLY',
}

export enum EPieceType {
    INVOICE = 'in',
    PREPAYMENT = 'pp',
    ADHOC = 'adhocPieces',
}
/**
 * List of all enums for kiwi pay api
 */

export enum EPAYPaymentMode {
    CHECK = 'check',
    DIRECT_DEBIT = 'directDebit',
    BANK_TRANSFER = 'bankTransfer',
    BANK_CARD = 'bankCard',
    CASH = 'cash',
}

export enum EPAYTransactionStatus {
    INITIALIZED = 'initialized',
    TRANSMITTED = 'transmitted',
    TRANSFER_FAILURE = 'transferFailure',
    TRANSACTION_FAILURE = 'transactionFailure',
    DETECTED_ANOMALY = 'detectedAnomaly',
    SUCCESS = 'success',
    CANCELLED = 'cancelled',
    DISPUTED = 'disputed',
}

export enum EPAYInvoiceStatus {
    NOT_SETTLED = 'notSettled',
    SETTLED = 'settled',
    CANCELLED = 'cancelled',
    RECOVERY_ONE = 'recovery1',
    RECOVERY_TWO = 'recovery2',
    LATE_PAYMENT = 'latePayment',
}

export enum EPAYPrepaymentStatus {
    NOT_SETTLED = 'notSettled',
    SETTLED = 'settled',
    DROPPED = 'dropped',
    INCIDENT = 'incident',
}

export enum EPAYContractStatus {
    WAITING_FOURTEEN_DAY = 'waiting-14d',
    RECOVERY = 'recovery',
    INITIALIZED = 'initialized',
    WAITING = 'waiting',
    CANCELLED = 'cancelled',
    ABONDONED = 'abandoned',
    VALIDATION_REJECTED = 'validationReject',
    REJECTED = 'rejected',
    SEND_TO_MARKET = 'sendToMkt',
    ACCEPTED = 'accepted',
    EFFECTIVE = 'effective',
    TERMINATED = 'terminated',
}

export enum EPAYProcessStatus {
    PROCESSING = 'processing',
    DROPPED = 'dropped',
    TERMINATED = 'terminated',
}

export enum EPAYProcessNature {
    IN = 'IN',
    PP = 'PP',
    ENERGY_CHECK = 'ENERCGYCHECK',
    REFUNDABLE_OVERCHARGE = 'REFUNDABLEOVERCHARGE',
    NONREFUNDABLE_OVERCHARGE = 'NONREFUNDABLEOVERCHARGE',
    RECOVERY = 'RECOVRY',
}

export enum EPAYAccountingType {
    DEBIT = 'debit',
    CREDIT = 'credit',
    BALANCE = 'balance',
}

export enum EPAYIntermediate {
    SLIMPAY = 'slimpay',
    BANK = 'bank',
}

export enum EErrorsType {
    CUSTOMER_CONTRACT = 'CUSTOMER_CONTRACT',
}

export enum EResponseCode {
    SUCCESS = '200',
}

export enum EMode {
    DELETE = 'delete',
    ENABLE = 'enable',
    DISABLE = 'disable',
}

export enum EGroups {
    FULL = 'FULL',
    LIMITED = 'LIMITED',
}

export enum EActionMode {
    READ_ONLY = 'READ_ONLY',
    FULL = 'FULL',
}

export enum EMenuLabels {
    ADMINISTRATION = 'Administration des utilisateurs',
    CASE = 'Affaires',
    CLIENT_CONTRACT = 'Client/contrat',
}

export enum ESubMenuAdministrationLabel {
    CREATE_USER = 'Créer un utilisateur',
    CREATE_GROUP = 'Créer un groupe',
    MANAGE_USERS = 'Gérer les utilisateurs',
}

export enum ESubMenuCaseLabel {
    CREATE_CASE = 'Créer une affaire',
    SEARCH_CASE = 'Rechercher une affaire',
}

export enum EConsumptionContext {
    PERIODIC_METERING = 'PERIODIC_METERING',
}

export enum EFileName {
    CONTRACT = 'CONTRACT',
    SEPA_MANDATE = 'SEPA_MANDATE',
    CONTRACT_AMENDMENT = 'CONTRACT_AMENDMENT',
    EL = 'EL',
    NG = 'NG',
    EXPLANATIONS_OFFER_CAPACITY = 'EXPLANATIONS_OFFER_CAPACITY',
}

export enum ENature {
    INVOICE = 'in',
    PREPAYMENT = 'pp',
    BALANCE = 'balance',
    RECOVERY = 'recovery',
    ENERGY_CHEQUE = 'energyCheck',
    ACTIVATION = 'contractActivation',
    SECURITY_DEPOSIT = 'securityDeposit',
    EXTERNAL_RECOVERY_INVOICE = 'externalRecoveryOnIn',
    EXTERNAL_RECOVERY_PREPAYMENT = 'externalRecoveryOnPP',
}
