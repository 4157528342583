import React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import moment from 'moment';

import MomentLocaleUtils, {
    parseDate,
    formatDate,
} from 'react-day-picker/moment';
import 'moment/locale/fr';

const currentYear = new Date().getFullYear();

// const fromMonth_ = new Date(currentYear - 70, 0);
// const toMonth_ = new Date(currentYear - 18, 11);

const FROM_MONTH = 120;
const TO_MONTH = 18;
const DEFAULT = currentYear - 1975;
const fromMonth_ = new Date(currentYear - FROM_MONTH, 0);
const toMonth_ = new Date(currentYear - TO_MONTH, 11);

export const renderValueDate = (date: any) => {
    const pDate = new Date(date);
    return pDate.getFullYear() === currentYear - FROM_MONTH
        ? new Date(currentYear - DEFAULT, pDate.getMonth(), pDate.getDate())
        : pDate;
};

export const YearMonthForm = ({ date, onChange }: any) => {
    moment.locale('fr');
    const months = moment.months();
    const years = [];
    for (
        let i = fromMonth_.getFullYear();
        i <= toMonth_.getFullYear();
        i += 1
    ) {
        years.push(i);
    }

    const handleChange_ = (e: any) => {
        const { year, month } = e.target.form;
        onChange(new Date(year.value, month.value));
    };

    return (
        <form className="DayPicker-Caption">
            <select
                name="month"
                onChange={handleChange_}
                value={date.getMonth()}
            >
                {months.map((month: any, i: any) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            <select
                name="year"
                onChange={handleChange_}
                value={date.getFullYear()}
            >
                {years.map(year => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </form>
    );
};

type Props = {
    value: any;
    onDateChange?: any;
    maxDate?: string;
    error?: string;
};

type State = {
    month_: any;
};

const modifiersStyles = {
    highlighted: {
        color: 'white',
        backgroundColor: '#ffb40a',
    },

    outside: { color: '#8954ba', backgroundColor: 'white' },
};

class CustomDate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
        this.state = {
            month_: this.renderStateFromMonth(),
        };
    }

    /**
     * Function to set month onChange
     * @param {Date} month_ The month to display in the calendar.
     */
    handleYearMonthChange(month: any) {
        this.setState({ month_: month });
    }

    renderStateFromMonth = () =>
        fromMonth_.getFullYear() === currentYear - 80 // 1939
            ? new Date(currentYear - DEFAULT, 1, 1)
            : fromMonth_;

    renderDate = (date: any, value: any) =>
        (value === '' || value === undefined) &&
        date.getFullYear() === currentYear - FROM_MONTH
            ? new Date(currentYear - DEFAULT, 0, 1)
            : date;

    render() {
        const { onDateChange, value, maxDate, error } = this.props;
        const { month_ } = this.state;

        return (
            <React.Fragment>
                <DayPickerInput
                    keepFocus={false}
                    onDayChange={dt => {
                        onDateChange(renderValueDate(dt));
                    }}
                    placeholder="JJ/MM/AAAA"
                    format="DD/MM/YYYY"
                    value={
                        value !== '' && value !== undefined
                            ? renderValueDate(value)
                            : undefined
                    }
                    parseDate={parseDate}
                    formatDate={formatDate}
                    dayPickerProps={{
                        locale: 'fr',
                        modifiers: {
                            highlighted: new Date(value),
                        },
                        modifiersStyles,
                        month: month_,
                        fromMonth: fromMonth_,
                        toMonth: toMonth_,
                        localeUtils: MomentLocaleUtils as any,
                        disabledDays: {
                            after: new Date(maxDate ? maxDate : ''),
                        },

                        captionElement: ({ date }: any) => (
                            <YearMonthForm
                                date={this.renderDate(date, value)}
                                locale="fr"
                                onChange={this.handleYearMonthChange}
                            />
                        ),
                    }}
                />
                {error && error !== '' && (
                    <p className="react-picker-error">{error}</p>
                )}
            </React.Fragment>
        );
    }
}

export { fromMonth_, toMonth_ };
export default CustomDate;
