import React, { FC } from 'react';

import Cell, { TableCellProps } from './Cell';

export type DataTableCellProps = {
    data?: any;
    Cell: (data: any) => React.ReactNode | JSX.Element | string | number;
} & TableCellProps;

export const DataCell: FC<DataTableCellProps> = props => {
    return (
        <Cell
            {...props}
            style={{
                ...props.style,
                ...(props.data.style || {}),
            }}
        >
            {props.Cell(props.data)}
        </Cell>
    );
};

export default DataCell;
