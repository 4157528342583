import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';

import NotFoundContainer from '../../../containers/defaults/NotFoundContainer';

const mapStateToProps = (state: RootState) => ({});
const dispatchProps = {};

export default connect(
    mapStateToProps,
    dispatchProps,
)(NotFoundContainer);
