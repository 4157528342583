import React from 'react';

const EnergyDetail: React.FC<{
    details: Array<{
        title: string;
        value: string;
    }>;
}> = ({ details }) => (
    <div className="energy-detail">
        {details &&
            details.map(({ title, value }, index) => (
                <span key={index}>
                    <p>{title}: </p>
                    <p>{value}</p>
                </span>
            ))}
    </div>
);

export default EnergyDetail;
