import { Epic } from 'redux-observable';
import { of, forkJoin } from 'rxjs';
import { mergeMap, catchError, filter, switchMap } from 'rxjs/operators';
import {
    createAsyncAction,
    createStandardAction,
    isActionOf,
    ActionType,
    getType,
} from 'typesafe-actions';
import { RootAction, RootState, Services } from 'Types';

import { mapGetContext, fetchContextAsync } from './profile.actions';

import {
    FETCH_LOGIN_REQUEST,
    FETCH_LOGIN_SUCCESS,
    FETCH_LOGIN_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    REDUX_RESET_STATE,
    REMOVE_ERROR,
    REINIT_SUBSCRIPTION_EMAIL_REQUEST,
    REINIT_SUBSCRIPTION_EMAIL_SUCCESS,
    REINIT_SUBSCRIPTION_EMAIL_FAILURE,
    REMOVE_REINIT_SUBSCRIPTION_MESSAGE,
    REFRESH_SESSION_EXPIRATION_STATUS,
    CASES_EXTRACT_REQUEST,
    CASES_EXTRACT_SUCCESS,
    CASES_EXTRACT_FAILURE,
    RESET_AUTH_ERRORS,
} from './actionTypes';
import Config from '../utils/config';
import { log } from '../services/logger';
import { TScheme } from 'Models';

interface IPayload {
    user?: string;
    password?: string;
    email?: string;
    userId?: string;
    token?: string;
    accessToken?: string;
    error?: string;
    message?: string;
    groupe?: string;
    autorizationsScheme?: TScheme[];
    code?: string;
    reinit?: string;
    validated?: string;
}

interface IPayloadReinitSubscription {
    id: string;
    email: string;
    message?: string;
    code?: string;
}

const fetchLoginAsync = createAsyncAction(
    FETCH_LOGIN_REQUEST,
    FETCH_LOGIN_SUCCESS,
    FETCH_LOGIN_FAILURE,
)<IPayload, IPayload, IPayload>();

const casesExtractAsync = createAsyncAction(
    CASES_EXTRACT_REQUEST,
    CASES_EXTRACT_SUCCESS,
    CASES_EXTRACT_FAILURE,
)<null, null, null>();

const resetPasswordAsync = createAsyncAction(
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
)<IPayload, string, string>();

const reinitSubscriptionEmailAsync = createAsyncAction(
    REINIT_SUBSCRIPTION_EMAIL_REQUEST,
    REINIT_SUBSCRIPTION_EMAIL_SUCCESS,
    REINIT_SUBSCRIPTION_EMAIL_FAILURE,
)<IPayloadReinitSubscription, string, string>();

const resetReduxStore = createStandardAction(REDUX_RESET_STATE)();
const removeError = createStandardAction(REMOVE_ERROR)();
const resetAuthErrors = createStandardAction(RESET_AUTH_ERRORS)();
const removeReinitSubscriptionMessage = createStandardAction(
    REMOVE_REINIT_SUBSCRIPTION_MESSAGE,
)();

const refreshSessionExpirationStatus = createStandardAction(
    REFRESH_SESSION_EXPIRATION_STATUS,
)<boolean>();

export type AuthAction =
    | ActionType<typeof fetchLoginAsync>
    | ActionType<typeof resetPasswordAsync>
    | ActionType<typeof resetReduxStore>
    | ActionType<typeof removeError>
    | ActionType<typeof reinitSubscriptionEmailAsync>
    | ActionType<typeof removeReinitSubscriptionMessage>
    | ActionType<typeof refreshSessionExpirationStatus>
    | ActionType<typeof resetAuthErrors>;

const preparePayloadLogin = ({ user, password }: IPayload) => {
    return {
        user,
        password,
    };
};

const mapPostLogin = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadLogin(action.payload);
    return apiRequest<IPayload>({
        path: '/login',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IPayload) => {
            if (response && response.token) {
                const currentUser = (payload.user as string).replace(
                    'ADMIN:',
                    '',
                );
                if (
                    response.email &&
                    response.email !== currentUser &&
                    response.user !== currentUser
                ) {
                    return of({
                        error: 'Erreur de connexion: Veuillez réessayer',
                    });
                }
                if (!response.email && response.user !== currentUser) {
                    return of({
                        error: 'Erreur de connexion: Veuillez réessayer',
                    });
                }
                const user = {
                    userId: response.user,
                    token: response.token,
                    accessToken: response.accessToken,
                    groupe: response.groupe,
                    autorizationsScheme: response.autorizationsScheme,
                };
                if (user.token && user.accessToken) {
                    localStorage.setItem('token', user.token);

                    localStorage.setItem('accessToken', user.accessToken);
                    const dt = String(
                        new Date().getTime() +
                            1000 * Config.ExpiredSessionTimeout,
                    );
                    localStorage.setItem('expires', dt);
                }
                return of(user);
            }
            const user = {
                userId: response.user,
                validated: response.validated,
            };
            if (user.validated) {
                localStorage.setItem('isValidated', String(user.validated));
            }
            if (user.userId) {
                localStorage.setItem(
                    'userIdCreatePassword',
                    String(user.userId),
                );
            }

            const message = response.message || 'Une erreur est survenue';
            return of({ error: message });
        }),
        catchError(error => {
            return of({ error: error.message });
        }),
    );
};

const fetchLoginEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchLoginAsync.request)),
        switchMap(
            (action: RootAction) => mapPostLogin(action, dependency),
            (action: RootAction, r: IPayload) => [action, r],
        ),
        switchMap(([action, loginResponse]) => {
            if (loginResponse.error) {
                return forkJoin(
                    of(loginResponse),
                    of(
                        fetchContextAsync.failure({
                            code: '500',
                            message: 'Erreur de connexion: Veuillez réessayer',
                        }),
                    ),
                );
            }
            const modifiedAction = {
                ...action,
                payload: {
                    ...action.payload,
                    customerNbr: loginResponse.userId,
                },
            };
            return forkJoin(
                of(loginResponse),
                mapGetContext(modifiedAction, dependency),
            );
        }),
        switchMap(([loginResponse, contextResponse]) => {
            if (
                loginResponse &&
                loginResponse.groupe !== undefined &&
                loginResponse.autorizationsScheme !== undefined
            ) {
                if (loginResponse.token && loginResponse.userId) {
                    localStorage.setItem('userId', loginResponse.userId);
                }

                if (loginResponse.token && loginResponse.groupe) {
                    localStorage.setItem('userGroup', loginResponse.groupe);
                }
                return of(fetchLoginAsync.success(loginResponse));
            }
            if (
                loginResponse &&
                loginResponse.token &&
                contextResponse.type === getType(fetchContextAsync.success)
            ) {
                if (loginResponse.token && loginResponse.userId) {
                    localStorage.setItem('userId', loginResponse.userId);
                }

                log({
                    triggerButton: 'validate-button',
                    tag: 'auth-connexion-page',
                    customerIdentificationKey: loginResponse.userId,
                    serviceCalled: '/login',
                    returnCode: '200',
                    statusAction: 'SUCCESS',
                });
                return of(
                    fetchLoginAsync.success(loginResponse),
                    fetchContextAsync.success(contextResponse.payload),
                );
            }
            const message = loginResponse.error || 'Une erreur est survenue';
            const errResponse = loginResponse.error
                ? { code: 'Error', message: loginResponse.error }
                : { code: 'Error', message: 'Une erreur est survenue' };

            log({
                triggerButton: 'validate-button',
                tag: 'auth-connexion-page',
                serviceCalled: '/login',
                returnCode: errResponse.code,
                logMessage: errResponse.message,
                statusAction: 'Failure',
            });
            return of(
                fetchLoginAsync.failure({
                    error: message,
                }),
            );
        }),
        catchError(error => {
            log({
                triggerButton: 'validate-button',
                tag: 'auth-connexion-page',
                serviceCalled: '/login',
                returnCode: '500',
                logMessage: 'FAILURE',
                statusAction: 'Failure',
            });
            return of(
                fetchLoginAsync.failure({
                    error: error.message,
                }),
            );
        }),
    );

const preparePayloadResetPassword = ({ email }: IPayload) => {
    return {
        customerNbr: email,
        mode: 'RESET',
    };
};

const mapPostResetPassword = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadResetPassword(action.payload);
    return apiRequest<IPayload>({
        path: '/resetPassword',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IPayload) => {
            if (
                response.code &&
                (response.code === 'MISSING_PARAMETER' ||
                    response.code === 'UNEXPECTED_ERROR' ||
                    response.code === 'NO_RESPONSE_FROM_PARTNER')
            ) {
                return of(
                    resetPasswordAsync.failure(
                        response.message || 'Une erreur est survenue',
                    ),
                );
            }
            return of(resetPasswordAsync.success(response.message || ''));
        }),
        catchError(error => {
            return of(resetPasswordAsync.failure(error.message));
        }),
    );
};

const resetPasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(resetPasswordAsync.request)),
        mergeMap(action => mapPostResetPassword(action, dependency)),
    );

const preparePayloadReinitSubscriptionEmail = ({
    id,
    email,
}: IPayloadReinitSubscription) => {
    return {
        customerNbr: id,
        email,
    };
};

const mapReinitSubscriptionEmail = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadReinitSubscriptionEmail(action.payload);
    return apiRequest<IPayloadReinitSubscription>({
        path: '/reinitSubscriptionEmail',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IPayloadReinitSubscription) => {
            if (response.code !== '500') {
                return of(reinitSubscriptionEmailAsync.success('OK'));
            }
            return of(
                reinitSubscriptionEmailAsync.failure(
                    response.message as string,
                ),
            );
        }),
        catchError(error => {
            return of(reinitSubscriptionEmailAsync.failure(error.message));
        }),
    );
};

const reinitSubscriptionEmailEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(reinitSubscriptionEmailAsync.request)),
        mergeMap(action => mapReinitSubscriptionEmail(action, dependency)),
    );

export {
    fetchLoginEpic,
    fetchLoginAsync,
    resetPasswordAsync,
    resetPasswordEpic,
    resetReduxStore,
    removeError,
    reinitSubscriptionEmailEpic,
    reinitSubscriptionEmailAsync,
    removeReinitSubscriptionMessage,
    refreshSessionExpirationStatus,
    casesExtractAsync,
    resetAuthErrors,
};
