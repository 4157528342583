import { TEPInvoice } from 'Models';
import { Epic } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';

import { RootAction, RootState, Services } from 'Types';

import {
    createAsyncAction,
    isActionOf,
    ActionType,
    createAction,
} from 'typesafe-actions';
import {
    PAYMENT_CB_FAILURE,
    PAYMENT_CB_REQUEST,
    PAYMENT_CB_SUCCESS,
    RESET_PAYMENT_CB_MODAL,
} from './actionTypes';

export type TPaymentCBRequest = {
    dueDate: string;
    payment: TEPInvoice;
};

export type TPaymentCBResponse = {
    adhocPaymentId: string;
    stripe: {
        billName: string;
        amount: string;
        clientSecret: string;
    };
    iframe: string;
    transactionId: string;
};

const createPaymentCBAsync = createAsyncAction(
    PAYMENT_CB_REQUEST,
    PAYMENT_CB_SUCCESS,
    PAYMENT_CB_FAILURE,
)<TPaymentCBRequest, TPaymentCBResponse, any>();

const resetModal = createAction(RESET_PAYMENT_CB_MODAL);

export type PaymentCBAction =
    | ActionType<typeof createPaymentCBAsync>
    | ActionType<typeof resetModal>;

const prepareCreatePaymentPayload = ({
    payment,
    dueDate,
}: TPaymentCBRequest) => {
    return {
        payment,
        dueDate,
        mode: 'CREATE_PAYMENT',
        customerNbr: payment.partner_ref,
        contractNbr: payment.contract,
    };
};

const mapCreateCBPayment = (action: RootAction, { apiRequest }: Services) => {
    const payload = prepareCreatePaymentPayload(action.payload);
    return apiRequest<TPaymentCBResponse>({
        path: '/paymentCB',
        method: 'post',
        body: payload,
        isSubscription: true,
    }).pipe(
        mergeMap((response: TPaymentCBResponse) => {
            if (response) return of(createPaymentCBAsync.success(response));
            return throwError({ code: '400', message: response });
        }),
        catchError(error => {
            return of(createPaymentCBAsync.failure(error));
        }),
    );
};

const createCBPaymentEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(createPaymentCBAsync.request)),
        mergeMap(action => mapCreateCBPayment(action, dependency)),
    );

export {
    createCBPaymentEpic,
    mapCreateCBPayment,
    createPaymentCBAsync,
    resetModal,
};
