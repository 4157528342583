const base64ToArrayBuffer = (_base64Str: string) => {
    const binaryString = window.atob(_base64Str);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    // tslint:disable-next-line:no-increment-decrement
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

const showDocument = (_base64Str: string, _contentType: string) => {
    const byte = base64ToArrayBuffer(_base64Str);
    const blob = new Blob([byte], { type: _contentType });
    window.open(URL.createObjectURL(blob), '_blank');
};

export { showDocument };
