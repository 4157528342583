import React, { Component } from 'react';
import { RootState, TSchemaProps } from 'Types';
import { connect } from 'react-redux';
import { BackgroundElement, CustomButton } from '../../../components';
import CustomTable from '../../../components/admin/CustomTable';
import WordingConstant from '../../../utils/wording.json';
import { TOrder, TResponse } from 'Models';
import {
    getHost,
    isEmpty,
    isHaveActionsPermission,
    getFormattedDate,
} from '../../../utils/helpers';
import SubComponent from './SubComponent';
import _ from 'lodash';
import { resetGroupListResponse } from '../../../_actions/getGroup.actions';
import ErrorElement from '../../../components/ErrorElement';
import { withTriangle } from '../../../components/admin/CustomTable/CustomTable';
import Status from '../../../components/admin/Status';
import FilterSelect from '../../../components/admin/FilterSelect';
import MessageCollapse from '../../../components/admin/MessageCollapse';
import { EEnergy } from '../../../utils/enums';
import {
    TRetryPaymentPayload,
    retryFirstPayment,
} from '../../../_actions/caseResult.action';
import RetryFirstPaymentModal from './RetryFirstPaymentModal';
import { saveOrder } from '../../../utils/network/request';
import { RequestError } from '../../../utils/network/errors';

const Wording = WordingConstant.Caseresult;
const WordingMapping = WordingConstant.mapping;

const mapStateToProps = (state: RootState) => ({
    authState: state.authReducer,
    errorMessage: state.orderReducer.message,
});

const dispatchProps = { resetGroupListResponse };

type Props = {
    order: Array<{ channel: string; order: TOrder }>;
    schemaProps: TSchemaProps;
    resetGroupListResponse: () => void;
    getGroup: () => void;
    retryFirstPayment: (props: TRetryPaymentPayload) => void;
    retryFirstPaymentState: string;
    retryFirstPaymentResponse: TResponse;
    resetFirstPayment: any;
} & ReturnType<typeof mapStateToProps> &
    typeof dispatchProps;

type State = {
    emailSubscriptionResent: boolean;
    message: string;
    orderNumber: string;
};

const headers = [
    { label: 'Groupe', key: 'channel' },
    { label: 'N° de contrat', key: 'contractNumber' },
    { label: 'N° affaire', key: 'orderReference' },
    { label: 'code agent', key: 'createdBy' },
    { label: 'status', key: 'orderStatus' },
    { label: 'Paiement CB', key: 'firstPaymentCBStatus' },
    // { label: 'Code erreur', key: 'errorCode' },
    { label: 'Nom sociéte', key: 'companyName' },
    { label: 'Nom', key: 'lastName' },
    { label: 'Prénom', key: 'firstName' },
    { label: 'Mail', key: 'email' },
    { label: 'Rue', key: 'address.street' },
    { label: 'Ville', key: 'address.townName' },
    { label: 'Code postal', key: 'address.postalCode' },
    { label: 'N° téléphone', key: 'phone' },
    { label: 'Type de contrat', key: 'contractType' },
    { label: 'Date de soucription', key: 'subscriptionDate' },
    { label: 'Date théorique de contrat', key: 'effectiveStartDate' },
    { label: 'Situation', key: 'situation' },
    { label: 'Capacité', key: 'contractualCapacity' },
    { label: 'Puissance', key: 'contractualCapacityUnit' },
    { label: 'Catégorie Gaz', key: 'contractCategory' },
];

class CaseResult extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            emailSubscriptionResent: false,
            message: '',
            orderNumber: '',
        };
    }

    prepareColumns = (order: any) => {
        const { schemaProps } = this.props;
        const yourCondition = true;
        const selectedWording = yourCondition
            ? Wording.firstPaymentCBStatus
            : Wording.firstPaymentSlimCollectStatus;
        return [
            {
                Header: withTriangle(Wording.group),
                accessor: 'channel',

                filterMethod: (filter: any, row: any) => {
                    return row.channel
                        ? row.channel.includes(filter.value)
                        : false;
                },
            },
            {
                Header: withTriangle(Wording.caseNbr),
                accessor: 'order.orderReference',
                filterable: false,
            },
            {
                Header: withTriangle(Wording.agentCode),
                accessor: 'order.createdBy',

                filterMethod: (filter: any, row: any) => {
                    return row['order.createdBy']
                        ? row['order.createdBy'].includes(filter.value)
                        : false;
                },
            },
            {
                Header: withTriangle(Wording.souscriptionCode),
                accessor: 'order.contracts[0].subscriptionDate',
                Cell: ({ value }: any) => getFormattedDate(value),
                filterMethod: (filter: any, row: any) => {
                    return row['order.contracts[0].subscriptionDate']
                        ? row['order.contracts[0].subscriptionDate'].includes(
                              filter.value,
                          )
                        : false;
                },
            },
            {
                Header: withTriangle(Wording.status),
                accessor: 'order.orderStatus',
                Cell: ({ value }: any) => {
                    return value !== undefined && value !== '' ? (
                        <Status status={value} type="orderStatus" />
                    ) : null;
                },

                filterMethod: (filter: any, row: any) => {
                    if (filter.value === 'all') {
                        return true;
                    }
                    return row['order.orderStatus'] === filter.value;
                },

                Filter: ({ filter, onChange }: any) => (
                    <FilterSelect
                        allResultText={'Tous'}
                        onChange={(event: any) =>
                            onChange(event.currentTarget.value)
                        }
                        value={filter ? filter.value : 'all'}
                        options={WordingMapping.orderStatus}
                    />
                ),
            },

            {
                Header: withTriangle(selectedWording),
                accessor: 'order',
                Cell: ({ value }: any) => {
                    if (!value) {
                        return null;
                    }
                    if (value.orderStatus !== 'FINALIZED') {
                        return null;
                    }
                    if (value.customer.typePayment === 'SDD') {
                        return <Status 
                                type={'paymentSDD'}
                                status={'PAYMENT_SDD'}
                               />
                    }
                    if (value.firstPaymentCBStatus) {
                        return (
                            <Status
                                status={value.firstPaymentCBStatus}
                                type={'firstPaymentCBStatus'}
                            />
                        );
                    } else if (value.firstPaymentSlimCollectStatus) {
                        return (
                            <Status
                                status={value.firstPaymentSlimCollectStatus}
                                type={'firstPaymentSlimCollectStatus'}
                            />
                        );
                    } else {
                        return null;
                    }
                },
                Filter: ({ filter, onChange }: any) => (
                    <FilterSelect
                        allResultText={'Tous'}
                        onChange={(event: any) =>
                            onChange(event.currentTarget.value)
                        }
                        value={filter ? filter.value : 'all'}
                        options={WordingMapping.firstPaymentSlimCollectStatus}
                    />
                ),
            },
            {
                Header: withTriangle(Wording.clientLastName),
                accessor: 'order.customer.contact.lastName',
                filterable: false,
            },
            {
                Header: withTriangle(Wording.clientFirstName),
                accessor: 'order.customer.contact.firstName',
                filterable: false,
            },
            // {
            //     Header: withTriangle(Wording.address),
            //     accessor: 'order.customer.contact.address',
            //     filterable: false,
            //     Cell: ({ value }: any) => {
            //         return value !== undefined ? (
            //             <MessageCollapse
            //                 initialLength={12}
            //                 message={`${value.street} ${value.postalCode} ${value.townName}`}
            //             />
            //         ) : null;
            //     },
            // },
            {
                Header: withTriangle(Wording.clientPhoneNbr),
                accessor: 'order.customer.contact.phone',
                filterable: false,
            },
            {
                accessor: 'voir client',
                filterable: false,
                show: isHaveActionsPermission(schemaProps),
                width: 95,
                Cell: ({ original }: any) => (
                    <CustomButton
                        color={'black'}
                        title={Wording.seeCustomer}
                        onClick={() => {
                            const orderNumber: string | undefined = _.get(
                                original as TOrder,
                                'order.orderReference',
                            );
                            if (!!orderNumber) {
                                const host = getHost(
                                    new Map([['orderNumber', orderNumber]]),
                                    ['case'],
                                );
                                window.open(host);
                            }
                        }}
                    />
                ),
            },
            {
                Header: Wording.sendActivationTitle,
                filterable: false,
                filterMethod: (filter: any, row: any) => {
                    this.hasFailedPayment(row);
                    return row['order.firstPaymentCBStatus']
                        ? row['order.firstPaymentCBStatus'].includes(
                              filter.value,
                          )
                        : false;
                },
                width: 150,
                Cell: ({ original }: any) => {
                    if (original.order.orderStatus === 'IN_PROGRESS') {
                        return (
                            <>
                                <CustomButton
                                    color={'black'}
                                    title={Wording.sendFinalizeOrder}
                                    onClick={async () => {
                                        const res = await saveOrder(
                                            original.channel,
                                            original.order,
                                        );
                                        if (res instanceof RequestError) {
                                            this.setState({
                                                message: res.message,
                                                emailSubscriptionResent: true,
                                                orderNumber:
                                                    original.order
                                                        .orderReference,
                                            });
                                            setTimeout(() => {
                                                this.setState({
                                                    emailSubscriptionResent: false,
                                                });
                                            }, 10000);
                                        } else {
                                            this.setState({
                                                message: `mail envoyé de nouveau`,
                                                emailSubscriptionResent: true,
                                                orderNumber: res.orderNumber,
                                            });
                                            setTimeout(() => {
                                                this.setState({
                                                    emailSubscriptionResent: false,
                                                });
                                            }, 10000);
                                        }
                                    }}
                                />
                                <br />
                                {this.state.emailSubscriptionResent &&
                                    this.state.orderNumber ===
                                        original.order.orderReference && (
                                        <span
                                            style={{
                                                fontSize: '10px',
                                                wordWrap: 'normal',
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            {this.state.message}
                                        </span>
                                    )}
                            </>
                        );
                    }
                    if (
                        original.order.orderStatus === 'FINALIZED' ||
                        original.order.orderStatus === 'FINALIZED_WITHOUT_DOC'
                    ) {
                        if (!this.hasFailedPayment(original.order)) {
                            return null;
                        }
                        if(original.order.customer.typePayment === 'SDD') {
                            return null;
                        }
                        return (
                            <CustomButton
                                color={'black'}
                                title={Wording.sendActivation}
                                onClick={() => {
                                    const orderNumber:
                                        | string
                                        | undefined = _.get(
                                        original as TOrder,
                                        'order.orderReference',
                                    );
                                    if (!!orderNumber) {
                                        const payload: TRetryPaymentPayload = {
                                            customerNbr: orderNumber,
                                            mode: 'EMAIL',
                                        };
                                        this.props.retryFirstPayment(payload);
                                    }
                                }}
                            />
                        );
                    }
                    return null;
                },
            },
        ];
    };

    hasFailedPayment(order: any) {
        if (order) {
            if (
                !order.firstPaymentSlimCollectStatus &&
                !order.firstPaymentCBStatus
            ) {
                return false;
            }
            if (
                order.firstPaymentCBStatus === 'PENDING' ||
                order.firstPaymentCBStatus === 'IN_FAILURE' ||
                order.firstPaymentSlimCollectStatus === 'IN_FAILURE' ||
                order.firstPaymentSlimCollectStatus === 'PENDING'
            ) {
                return true;
            }
        }
        return false;
    }

    prepareOrderToDownloadCSV = () => {
        const { order } = this.props;

        const parseEnergy = (energy: any) => (energy === 'EL' ? 'Elec' : 'Gaz');

        return order
            .map(({ channel, order }) => {
                const {
                    contracts,
                    customer: {
                        contact: {
                            companyName,
                            firstName,
                            lastName,
                            address,
                            phone,
                            email,
                        },
                    },
                    orderError,
                    orderReference,
                    orderStatus,
                    createdBy,
                    firstPaymentCBStatus,
                } = order;

                const prepare = {
                    channel,
                    orderReference,
                    createdBy,
                    orderStatus,
                    firstPaymentCBStatus,
                    companyName,
                    firstName,
                    lastName,
                    address,
                    phone,
                    email,
                };

                if (!contracts) {
                    return prepare;
                }

                return contracts.map(contract => {
                    const {
                        contractNumber,
                        energy,
                        subscriptionDate,
                        effectiveStartDate,
                        deliveryPoint: {
                            process: { type: situation },
                            contractualCapacity,
                            contractualCapacityUnit,
                        },
                        contractCategory,
                    } = contract;

                    const contractType =
                        energy === 'EL' ? 'Electricité' : 'Gaz';

                    return {
                        ...prepare,
                        contractType,
                        subscriptionDate,
                        effectiveStartDate,
                        situation,
                        contractualCapacity,
                        contractualCapacityUnit,
                        contractCategory,
                        contractNumber,
                    };
                });
            })
            .flat();
    };

    downloadDataTable = () => {
        const { schemaProps } = this.props;
        return isHaveActionsPermission(schemaProps)
            ? {
                  downloadData: this.prepareOrderToDownloadCSV(),
                  downloadHeaders: headers,
                  downloadButtonTitle: 'TÉLÉCHARGER CSV',
                  downloadFilename: 'customer-contracts.csv',
              }
            : {};
    };

    render() {
        const { order } = this.props;

        return (
            <div className="search-case-result-container">
                <ErrorElement error={this.props.errorMessage} />

                {isEmpty(order) && (
                    <BackgroundElement>
                        <CustomTable
                            columns={this.prepareColumns(this.props.order)}
                            data={order}
                            className="-striped -highlight"
                            showPagination={true}
                            filterable={true}
                            SubComponent={({ original, index }: any) => (
                                <SubComponent index={index} order={original} />
                            )}
                            {...this.downloadDataTable()}
                        />
                    </BackgroundElement>
                )}
                <RetryFirstPaymentModal
                    retryFirstPaymentResponse={
                        this.props.retryFirstPaymentResponse
                    }
                    resetRetryFirstPaymentResponse={
                        this.props.resetFirstPayment
                    }
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(CaseResult);
