import React, { useState } from 'react';
import { InputEvent, SelectOption, TSchemaProps } from 'Types';
import WordingConstant from '../../../utils/wording.json';
import {
    CustomInput,
    CustomButton,
    CustomSelect,
    Group,
} from '../../../components';
import { TUserPayload } from '../../../_actions/searchUserGroup.actions';
import {
    getSelectOptionValue,
    isLimitedAuthorization,
} from '../../../utils/helpers';
import CreateUserModal from '../UserAdministrationContainer/CreateUserModal';
import CreateGroupModal from '../UserAdministrationContainer/CreateGroupModal';
import { TResponse } from 'Models';

import _ from 'lodash';
import { TGroupPayload } from '../../../_actions/createGroup.actions';

const Wording = WordingConstant.UserAdministrationContainer;

const statusOption: Array<SelectOption<string>> = [
    { id: '0', label: 'Actif', value: 'ENABLED' },
    { id: '1', label: 'Inactif', value: 'DISABLED' },
];
interface IPayloadUser {
    group: string;
    firstName: string;
    lastName: string;
    email: string;
    civility: string;
}
type Props = {
    schemaProps: TSchemaProps;
    groupList: string[];
    createGroupResponse: TResponse;
    createUserResponse: TResponse;
    currentUser?: any;
    checkCreateGroupPermission: boolean;
    checkCreateUserPermission: boolean;
    resetGroupResponse: () => void;
    resetCreateUserResponse: () => void;
    createGroup: (props: TGroupPayload) => void;
    createUser: (props: IPayloadUser) => void;
    resetAdminClientSpaceResponse: () => void;
    searchUserGroup: (props: TUserPayload) => void;
};

const UserAdministrationContainer: React.FC<Props> = ({
    searchUserGroup,
    groupList,
    checkCreateGroupPermission,
    checkCreateUserPermission,
    createUser,
    createGroupResponse,
    createUserResponse,
    resetGroupResponse,
    resetCreateUserResponse,
    createGroup,
    currentUser,
    schemaProps,
}) => {
    const isDisabledActionBtn = isLimitedAuthorization(schemaProps);

    const defaultGroup = isDisabledActionBtn
        ? {
              id: 0,
              label: schemaProps.currentGroup,
              value: schemaProps.currentGroup,
          }
        : '';

    const [groupId, setGroupId] = useState<string | SelectOption<string>>(
        defaultGroup,
    );
    const [userId, setUserId] = useState<string>('');
    const [clientLastName, setclientLastName] = useState<string>('');
    const [clientFirstName, setClientFirstName] = useState<string>('');
    const [customerPhone, setCustomerPhone] = useState<string>('');
    const [customerEmail, setCustomerEmail] = useState<string>('');
    const [status, setStatus] = useState<'' | SelectOption<string>>('');
    const [openUserModal, setUserModal] = useState<boolean>(false);
    const [openGroupModal, setGroupModal] = useState<boolean>(false);
    const [isLocked, setLocked] = useState<boolean>(!isDisabledActionBtn);

    const searchCondition =
        groupId !== '' ||
        userId !== '' ||
        clientFirstName !== '' ||
        clientLastName !== '' ||
        customerEmail !== '' ||
        status !== '' ||
        customerPhone !== '';
    const elementMap = {
        groupId: {
            id: 'groupe',
            options: _.map(groupList, (group: string, id: number) => ({
                id,
                label: group,
                value: group,
            })),
            handleChangeSelect: (situation: SelectOption<string>) => {
                setLocked(false);
                setGroupId(situation);
            },
            value: groupId,
            text: Wording.group,
            schemaProps,
        },
        customerPhone: {
            text: Wording.clientPhoneNbr,
            value: customerPhone,
            onInputChange: (e: InputEvent) =>
                setCustomerPhone(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setCustomerPhone(''),
        },

        userId: {
            text: Wording.userId,
            value: userId,
            onInputChange: (e: InputEvent) => setUserId(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setUserId(''),
        },
        customerEmail: {
            text: Wording.clientEmail,
            value: customerEmail,
            onInputChange: (e: InputEvent) =>
                setCustomerEmail(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setCustomerEmail(''),
        },

        clientFirstName: {
            text: Wording.clientFirstName,
            value: clientFirstName,
            onInputChange: (e: InputEvent) =>
                setClientFirstName(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setClientFirstName(''),
            isLocked,
        },

        clientLastName: {
            text: Wording.clientLastName,
            value: clientLastName,
            onInputChange: (e: InputEvent) =>
                setclientLastName(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setclientLastName(''),
            isLocked,
        },

        status: {
            id: 'statut',
            defaultValue: '',
            options: statusOption,
            handleChangeSelect: (status: SelectOption<string>) => {
                setStatus(status);
            },
            value: status,
            placeholder: 'Sélectionner',
            text: Wording.status,
        },

        btn_validate: {
            title: Wording.btnValidate,
            color: searchCondition ? 'orange' : 'gray',
            small: true,
            onClick: () => {
                if (searchCondition) {
                    searchUserGroup({
                        userName: userId.trim(),
                        email: customerEmail.trim(),
                        firstName: clientFirstName.trim(),
                        lastName: clientLastName.trim(),
                        group: getSelectOptionValue(
                            groupId as SelectOption<string>,
                        ),
                        userStatus: getSelectOptionValue(
                            status as SelectOption<string>,
                        ),
                    });
                }
            },
        },

        btn_reset: {
            title: Wording.buttonReset,
            small: true,
            color: 'gray',
            onClick: () => {
                setGroupId('');
                setUserId('');
                setclientLastName('');
                setClientFirstName('');
                setCustomerPhone('');
                setCustomerEmail('');
                setStatus('');
                setLocked(true);
            },
        },

        btn_create_group: {
            title: Wording.createGroup,
            small: true,
            color: 'orange',
            disabled: checkCreateGroupPermission,
            onClick: () => {
                setGroupModal(true);
            },
        },

        btn_create_user: {
            title: Wording.createUser,
            disabled: checkCreateUserPermission,
            small: true,
            color: 'orange',
            onClick: () => {
                setUserModal(true);
            },
        },
    };

    return (
        <div className="user-administration-container">
            <div className="search-rs-vl-btn actions-btns">
                <CustomButton {...elementMap.btn_create_group} />
                <CustomButton {...elementMap.btn_create_user} />
            </div>

            <div className="user-administration-container__container">
                <div className="user-administration-container__container__row">
                    <div className="user-administration-container__container__row__block">
                        <CustomInput {...elementMap.userId} />
                        <Group {...elementMap.groupId} />
                    </div>

                    <div className="user-administration-container__container__row__block">
                        <CustomSelect {...elementMap.status} />
                        <CustomInput {...elementMap.clientFirstName} />
                        <p className="search-group-text">
                            {
                                'La recherche par Prénom ou Nom nécessite de sélectionner au préalable un groupe'
                            }
                        </p>
                    </div>

                    <div className="user-administration-container__container__row__block">
                        <CustomInput {...elementMap.customerEmail} />
                        <CustomInput {...elementMap.clientLastName} />
                    </div>
                </div>

                <div className="search-rs-vl-btn ">
                    <CustomButton {...elementMap.btn_reset} />
                    <CustomButton {...elementMap.btn_validate} />
                </div>
            </div>

            <CreateUserModal
                currentUser={currentUser}
                isCustomerAndContract={false}
                isOpen={openUserModal}
                setOpen={setUserModal}
                groupList={groupList}
                schemaProps={schemaProps}
                createUser={createUser}
                createUserResponse={createUserResponse}
                resetCreateUserResponse={resetCreateUserResponse}
            />

            <CreateGroupModal
                isOpen={openGroupModal}
                setOpen={setGroupModal}
                schemaProps={schemaProps}
                createGroup={createGroup}
                groupList={groupList}
                createGroupResponse={createGroupResponse}
                resetGroupResponse={resetGroupResponse}
            />
        </div>
    );
};

export default UserAdministrationContainer;
