import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    ActionType,
    isActionOf,
    createStandardAction,
} from 'typesafe-actions';
import { ReducerError, RootAction, RootState, Services, TError } from 'Types';
import {
    GET_GROUP_REQUEST,
    GET_GROUP_SUCCESS,
    GET_GROUP_FAILURE,
    RESET_USER_GROUP_RESPONSE,
} from './actionTypes';
import { Epic } from 'redux-observable';

interface IResponseClient {
    groupList: [];
}

export type TGetGroupPayload = Partial<{
    group: string;
}>;

const getGroupAsync = createAsyncAction(
    GET_GROUP_REQUEST,
    GET_GROUP_SUCCESS,
    GET_GROUP_FAILURE,
    RESET_USER_GROUP_RESPONSE,
)<undefined, IResponseClient, ReducerError>();

const resetGroupListResponse = createStandardAction(
    RESET_USER_GROUP_RESPONSE,
)();

export type getGroupAction =
    | ActionType<typeof getGroupAsync>
    | ActionType<typeof resetGroupListResponse>;

const preparePayloadGetGroup = (payload: string): string => {
    return payload;
};

const mapGetGroup = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadGetGroup(action.payload);
    return apiRequest<IResponseClient>({
        path: '/getGroup',
        method: 'post',
        body: 'group',
        isPay: false,
        isSubscription: false,
    }).pipe(
        mergeMap((response: any & TError) => {
            if (
                response.code === 'MISSING_PARAMETER' ||
                response.code === 'NO_RESPONSE_FROM_PARTNER'
            ) {
                return of(
                    getGroupAsync.failure({
                        code: response.code,
                        message: response.description,
                    }),
                );
            }
            return of(getGroupAsync.success(response));
        }),
        catchError(error => {
            return of(
                getGroupAsync.failure({
                    code: error.code,
                    message: error.description,
                }),
            );
        }),
    );
};

const getGroupEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(getGroupAsync.request)),
        mergeMap(action => mapGetGroup(action, dependency)),
    );

export { getGroupAsync, getGroupEpic, mapGetGroup, resetGroupListResponse };
