import React from 'react';
import { List } from 'react-content-loader';
import {
    TOffer,
    TProduct,
    TAdditionalRate,
    TAmountWithLabel,
    TUnitPriceWithLabel,
} from 'Models';

import CustomToggle from '../CustomToggle';
import { transformPriceLabel } from '../../utils/helpers';
import { initialUnitPriceWithLabel } from '../../utils/initialState';
import WordingConstant from '../../utils/wording.json';
import { InputEvent } from 'Types';
import { EModeManageOffer } from '../../utils/enums';

const Wording = WordingConstant.PackagePage;
const CommonWording = WordingConstant.Common;
const PriceWording = Wording.price;
const DetailWording = Wording.detail;

type Props = {
    loading: boolean;
    prices: number[];
    offer: TOffer;
    products: TProduct[];
    additionalOptions: TAdditionalRate[];
    currentProductCodes: string[];
    currentAdditionalOptions: string[];
    loadingOption: boolean;
    handleToggle: any;
    contractProduct: any;
};

const CommonUnit: { [key: string]: string } = CommonWording.unit;

const PlanElement: React.FC<Props> = ({
    loading,
    prices,
    offer,
    products,
    additionalOptions,
    currentProductCodes,
    currentAdditionalOptions,
    handleToggle,
    loadingOption,
    contractProduct,
}) => {
    const renderDetail = () => {
        if (loading) {
            return (
                <p className="plan-element__noOffer">
                    {CommonWording.isLoading}
                </p>
            );
        }
        if (!offer) {
            return (
                <p className="plan-element__noOffer">{CommonWording.noData}</p>
            );
        }

        const details: TAmountWithLabel[] = offer.details || [];
        const totalAmount = details.find(
            d => d.category === DetailWording.category.totalAmount,
        );
        const subscription =
            totalAmount && totalAmount.unitPrices
                ? totalAmount.unitPrices.find(
                      p => p.label === PriceWording.subscription.label,
                  )
                : { ...initialUnitPriceWithLabel };
        const additionalRates = details.filter(
            d => d.category === DetailWording.category.additionalRate,
        );
        const totalBudget = details.find(
            d => d.category === DetailWording.category.totalBudget,
        );
        const discountRates = details.find(
            d => d.category === DetailWording.category.discountRate,
        );

        const renderPrice = (
            { label, price, unit }: TUnitPriceWithLabel,
            index: number,
            fix_part?: boolean,
        ) => {
            const displayedUnit = CommonUnit[unit];
            const display = transformPriceLabel(label);
            if (display === '') return null;
            const formattedPrice = fix_part
                ? parseFloat(price).toFixed(2)
                : price;
            return (
                <p key={index}>
                    {display} :{' '}
                    <span>
                        {formattedPrice} {displayedUnit}
                    </span>
                </p>
            );
        };
        return (
            <>
                <div
                    className={`plan-element__description ${
                        prices.length === 0
                            ? `plan-element__description--cyclicBill`
                            : ''
                    }`}
                >
                    {offer && loadingOption ? (
                        <List />
                    ) : (
                        <React.Fragment>
                            <div className="plan-element__description__subscription">
                                <p className="plan-element__description__title">
                                    {Wording.detail.title.fix}
                                </p>
                                {subscription &&
                                    renderPrice(subscription, 0, true)}
                                <div className="plan-element__description__separator" />
                            </div>
                            <p className="plan-element__description__title">
                                {Wording.detail.title.consumption}
                            </p>
                            <p className="plan-element__description__subtitle">
                                {Wording.detail.title.grossPrice}
                            </p>
                            {totalAmount &&
                                totalAmount.unitPrices &&
                                totalAmount.unitPrices
                                    .filter(
                                        p =>
                                            p.label !==
                                            PriceWording.subscription.label,
                                    )
                                    .map((e, index) => renderPrice(e, index))}
                            <br className="plan-element__description__margin" />
                            {additionalRates.map(
                                (rate, idx1) =>
                                    rate &&
                                    rate.unitPrices &&
                                    rate.unitPrices.map((e, idx2) =>
                                        renderPrice(e, idx1 + idx2),
                                    ),
                            )}
                            {totalBudget &&
                                totalBudget.unitPrices &&
                                totalBudget.unitPrices.map((e, index) =>
                                    renderPrice(e, index),
                                )}
                            <br className="plan-element__description__margin" />
                            <p className="plan-element__description__subtitle">
                                {Wording.detail.title.discountPrice}
                            </p>
                            {discountRates &&
                                discountRates.unitPrices &&
                                discountRates.unitPrices.map((e, index) =>
                                    renderPrice(e, index),
                                )}
                            {additionalOptions &&
                                currentAdditionalOptions &&
                                currentAdditionalOptions.length > 0 && (
                                    <React.Fragment>
                                        <div className="plan-element__description__separator" />
                                        <p className="plan-element__description__title">
                                            {Wording.detail.title.option}
                                        </p>
                                        {additionalOptions.map((e, index) => {
                                            if (
                                                currentAdditionalOptions &&
                                                currentAdditionalOptions.includes(
                                                    e.label,
                                                ) &&
                                                e.detail
                                            ) {
                                                return renderPrice(
                                                    e.detail,
                                                    index,
                                                );
                                            }
                                            return null;
                                        })}
                                    </React.Fragment>
                                )}
                            <div className="plan-element__description__separator" />
                            {offer.product && offer.product.description && (
                                <p>{offer.product.description}</p>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </>
        );
    };

    const containerClassName = `plan-element${
        prices.length === 0 ? ` plan-element--cyclicBill` : ''
    }`;

    const priceClassName = `plan-element__prices ${
        prices.length > 1 ? 'plan-element__prices--larger' : ''
    }${offer ? '' : ' plan-element__prices--noOffer'}`;

    return (
        <div className={containerClassName}>
            {prices.length !== 0 && (
                <div className={priceClassName}>
                    {prices &&
                        prices.map((p, index) => (
                            <div
                                key={index}
                                className={`plan-element__prices__label ${
                                    index === 0 && prices.length > 1
                                        ? 'plan-element__prices__label--border'
                                        : ''
                                }`}
                            >
                                {prices.length > 1 && (
                                    <span className="plan-element__prices__label__timeframe">
                                        {CommonWording.timeframe[index]}
                                    </span>
                                )}
                                <label
                                    htmlFor="idPrice"
                                    className="plan-element__prices__label__priceDescription"
                                >
                                    {p}
                                    <p>{WordingConstant.Common.priceUnit}</p>
                                </label>
                                <span className="plan-element__prices__label__month">
                                    {WordingConstant.Common.priceCyclic}
                                </span>
                            </div>
                        ))}
                </div>
            )}

            {renderDetail()}

            <div
                className={`plan-element__toggle ${
                    prices.length === 0
                        ? `plan-element__toggle--cyclicBill`
                        : ''
                }`}
            >
                {products && products.length > 1 && (
                    <div className="plan-element__toggle__productOptions">
                        <p className="plan-element__toggle__productOptions__title">
                            {Wording.detail.title.fixPrice}
                        </p>

                        {products.map((product, index) => {
                            if (product.default) {
                                return false;
                            }
                            return (
                                <CustomToggle
                                    key={index}
                                    id={`idFixPrice__${index}`}
                                    text={
                                        Wording.detail.title.fixPriceDescription
                                    }
                                    value={product.productCode}
                                    checked={currentProductCodes.includes(
                                        product.productCode,
                                    )}
                                    handleCheck={(e: InputEvent) => {
                                        // Send the product (FIX) if the toggle is checked
                                        // Otherwise use default product
                                        let pro: TProduct | undefined = product;
                                        if (e.currentTarget.checked) {
                                            pro = product;
                                        } else {
                                            pro = products.find(p => p.default);
                                        }

                                        handleToggle('product', {
                                            product: pro,
                                            mode: EModeManageOffer.PRODUCT,
                                        });
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PlanElement;
