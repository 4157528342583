import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import _ from 'lodash';
import {
    CustomButton,
    CustomError,
    CustomInput,
} from '../../../../../components';
import { formatSiteString } from '../../../../../utils/helpers';
import WordingConstant from '../../../../../utils/wording.json';
import { removeWhitespaces as rw, toIsc } from '../../../../../utils/text';

interface IProps {
    onValidate: (PCE?: string, PDL?: string) => void;
    requiresPCE: boolean;
    requiresPDL: boolean;
    isPackageValid: boolean;
    PDLRef: React.MutableRefObject<string | null>;
    PCERef: React.MutableRefObject<string | null>;
}

const Wording = WordingConstant.AdminSubscriptionContainer.MissingInformations;
const baseClassName = 'missing-informations-section';

const MissingInformationSection: FC<IProps> = (props: IProps) => {
    const {
        onValidate,
        requiresPCE,
        requiresPDL,
        isPackageValid,
        PDLRef,
        PCERef,
    } = props;
    const [PCEError, setPCEError] = useState<string>('');
    const [PCEValue, setPCEValue] = useState<string>('');
    const [PDLError, setPDLError] = useState<string>('');
    const [PDLValue, setPDLValue] = useState<string>('');
    const promptText = toIsc(
        requiresPCE && requiresPDL ? Wording.prompts : Wording.prompt,
    );

    const handleClick = () => {
        if (
            (requiresPCE && PCEValue.length > 0) ||
            (requiresPDL && PDLValue.length > 0)
        ) {
            onValidate(rw(PCEValue), rw(PDLValue));
        } else {
            if (requiresPCE) setPCEError(Wording.length);
            if (requiresPDL) setPDLError(Wording.length);
        }
    };
    const handlePCEInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPCEError('');
        const value: string = _.get(e, 'target.value', '');
        PCERef.current = PCEValue.toString();
        setPCEValue(formatSiteString(value));
    };
    const handlePDLInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPDLError('');
        const value: string = _.get(e, 'target.value', '');
        PDLRef.current = PDLValue.toString();
        setPDLValue(formatSiteString(value));
    };

    useEffect(() => {}, [PDLValue, PDLRef, PCERef]);

    return (
        <section className={baseClassName}>
            <span>{promptText}</span>
            {!!requiresPDL && (
                <CustomInput
                    error={PDLError}
                    onInputChange={handlePDLInputChange}
                    text={Wording.PDL}
                    value={PDLValue}
                />
            )}

            {requiresPCE && (
                <CustomInput
                    error={PCEError}
                    onInputChange={handlePCEInputChange}
                    text={Wording.PCE}
                    value={PCEValue}
                />
            )}
            {!isPackageValid && (
                <CustomError
                    title={Wording.packageValid.title}
                    text={Wording.packageValid.message}
                />
            )}
            <CustomButton
                color={'orange'}
                onClick={handleClick}
                title={Wording.validate}
            />
        </section>
    );
};

export default MissingInformationSection;
