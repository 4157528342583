import React, { Fragment } from 'react';
import { TOrder } from 'Models';
import {
    getFormattedDate,
    getLabelByType,
    isOdd,
} from '../../../utils/helpers';
import WordingConstant from '../../../utils/wording.json';
import _ from 'lodash';

const Wording = WordingConstant.Caseresult.SubComponent;

type Props = {
    order: TOrder;
    index: number;
};

enum EProcessType {
    MOVE_IN = 'MES',
    SWITCH = 'CHF',
}

const SubComponent: React.FC<Props> = ({ order, index }) => {
    return (
        <div className={`sub-component ${isOdd(index) ? '-odd' : ''}`}>
            <table className="sub-component__table">
                <thead>
                    <tr>
                        {[
                            Wording.contractNbr,
                            Wording.startCase,
                            Wording.directDebitDate,
                            // Wording.subscriptionDate,
                            Wording.deliveryPoint,
                            Wording.energyType,
                            Wording.process,
                            Wording.address,
                        ].map((el: string, index: number) => (
                            <th key={index}>{el}</th>
                        ))}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {_.get(order, 'order.contracts', '') ? (
                        _.get(order, 'order.contracts', '').map(
                            (contract: any, index: number) => {
                                return (
                                    <Fragment key={index}>
                                        <tr className="transaction-status">
                                            <td>
                                                {_.get(
                                                    contract,
                                                    'contractNumber',
                                                    '',
                                                )}
                                            </td>
                                            <td>
                                                {_.get(
                                                    contract,
                                                    'effectiveStartDate',
                                                    '',
                                                ) !== undefined
                                                    ? getFormattedDate(
                                                        _.get(
                                                            contract,
                                                            'effectiveStartDate',
                                                            '',
                                                        ),
                                                    )
                                                    : ''}
                                            </td>
                                            <td>
                                                {_.get(
                                                    contract,
                                                    'dueDate',
                                                    '',
                                                ) !== undefined
                                                    ? getFormattedDate(
                                                        _.get(
                                                            contract,
                                                            'dueDate',
                                                            '',
                                                        ),
                                                    )
                                                    : ''}
                                            </td>
                                            <td>
                                                {_.get(
                                                    contract,
                                                    'deliveryPoint.pointOfDelivery',
                                                    '',
                                                )}
                                            </td>
                                            <td>
                                                {getLabelByType(
                                                    _.get(
                                                        contract,
                                                        'energy',
                                                        '',
                                                    ),
                                                    'energyTypes',
                                                )}
                                            </td>
                                            <td>
                                                {_.get(
                                                    contract,
                                                    'deliveryPoint.process.type',
                                                    '',
                                                ) === 'MOVE_IN'
                                                    ? EProcessType.MOVE_IN
                                                    : EProcessType.SWITCH}
                                            </td>
                                            <td>
                                                {_.get(
                                                    contract,
                                                    'deliveryPoint.address.number',
                                                    '',
                                                )
                                                    ? `${_.get(
                                                        contract,
                                                        'deliveryPoint.address.number',
                                                        '',
                                                    )} `
                                                    : ''}
                                                {_.get(
                                                    contract,
                                                    'deliveryPoint.address.street',
                                                    '',
                                                )}
                                                &nbsp;
                                                {_.get(
                                                    contract,
                                                    'deliveryPoint.address.postalCode',
                                                    '',
                                                )}
                                                &nbsp;
                                                {_.get(
                                                    contract,
                                                    'deliveryPoint.address.townName',
                                                    '',
                                                )}
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            },
                        )
                    ) : (
                        <tr>
                            <td>{Wording.no_contract}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default SubComponent;
