import React, { FC, useState } from 'react';
import { buildClassName as bcn } from '../../utils/helpers';

interface IProps {
    active: boolean;
    highlighted?: boolean;
    onToggle?: (nextToggleState: boolean) => void;
    text?: string;
    title?: string;
    titleHint?: string;
    wrapped?: boolean;
}

const tileClassName = 'tile';
const activeTileClassName = `${tileClassName}-active`;
const highlightedTileClassName = `${tileClassName}-highlighted`;
const titleRowClassName = 'title-row';
const bodyClassName = 'body';
const textClassName = 'text';
const wrappedTextClassName = bcn([bodyClassName, `${textClassName}-wrapped`]);
const unwrappedTextClassName = bcn([
    bodyClassName,
    `${textClassName}-unwrapped`,
]);
const wrapToggleClassName = bcn([bodyClassName, 'toggle']);

const Tile: FC<IProps> = (props: IProps) => {
    const { active, highlighted, onToggle, text, title, titleHint } = props;
    const [wrapped, setWrapped] = useState(true);
    let mainClassName = tileClassName;
    if (active) mainClassName = activeTileClassName;
    else if (!!highlighted) mainClassName = highlightedTileClassName;
    let textStatusClassName = wrappedTextClassName;
    if (!wrapped) textStatusClassName = unwrappedTextClassName;
    const wrapToggleStr = !!text ? (!!wrapped ? '+' : '-') : '';
    const cn = (...names: string[]) => {
        return bcn([mainClassName, ...names]);
    };
    const onClick = () => {
        if (!!onToggle) onToggle(!active);
    };
    const onWrap = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setWrapped(!wrapped);
        event.stopPropagation();
    };
    return (
        <div className={mainClassName} onClick={onClick}>
            <div className={cn(titleRowClassName)}>
                <span>{title || ''}</span>
                {!!titleHint && <span>{titleHint}</span>}
            </div>
            <div className={cn(bodyClassName)}>
                {!!text && (
                    <div className={cn(textStatusClassName)}>
                        <span>{text}</span>
                    </div>
                )}
                <div className={cn(wrapToggleClassName)} onClick={onWrap}>
                    {wrapToggleStr}
                </div>
            </div>
        </div>
    );
};

export default Tile;
