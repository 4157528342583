import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, ActionType, isActionOf } from 'typesafe-actions';
import { ReducerError, RootAction, RootState, Services } from 'Types';
import {
    FETCH_ORDER_REQUEST,
    FETCH_ORDER_SUCCESS,
    FETCH_ORDER_FAILURE,
    SEARCH_USER_REQUEST,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILURE,
} from './actionTypes';
import { TContract, TFinance, TOrder } from 'Models';
import { Epic } from 'redux-observable';

import { RequestError } from '../utils/network/errors';

import config from '../utils/config';
import {
    EFirstPaymentCBStatuses,
    EOrderStatuses,
    TAddress,
    TBirthTown,
} from '../utils/network/types';
import { ECivility, ECustomerType } from '../utils/enums';
import { log } from '../utils/helpers';

export type TOrderPayload = Partial<{
    fromDate: string | null;
    toDate: string | null;
    email: string | null;
    orderNumber: string | null;
    firstName: string | null;
    lastName: string | null;
    processType: string | null;
    phone: string | null;
    externalReference: string | null;
    channel: string | null | string[];
    createdBy: string | null;
}>;

export type TOrderResponse = {
    order: {
        orderReference: string;
        externalReference: string;
        orderStatus: EOrderStatuses;
        firstPaymentCBStatus: EFirstPaymentCBStatuses;
        customer: {
            customerNumber: string;
            type: ECustomerType;
            contact: {
                civility: ECivility;
                firstName: string;
                lastName: string;
                birthdate: string;
                address: TAddress;
                birthTown: Partial<TBirthTown> &
                    Required<Pick<TBirthTown, 'townName' | 'country'>>;
                email: string;
                phone: string;
                companyName: string;
            };
            finance: Partial<TFinance>;
        };
        contracts: TContract[];
    };
    channel: string;
};

const fetchOrderAsync = createAsyncAction(
    FETCH_ORDER_REQUEST,
    FETCH_ORDER_SUCCESS,
    FETCH_ORDER_FAILURE,
)<TOrderPayload, TOrder[], ReducerError>();

const searchClientAsync = createAsyncAction(
    SEARCH_USER_REQUEST,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILURE,
)<TOrderPayload, TOrderResponse[], ReducerError>();

export type FetchOrderAction = ActionType<
    typeof fetchOrderAsync | typeof searchClientAsync
>;

const preparePayloadOrder = (payload: TOrderPayload): TOrderPayload => {
    return {
        fromDate: payload.fromDate || null,
        toDate: payload.toDate || null,
        email: payload.email || null,
        orderNumber: payload.orderNumber || null,
        firstName: payload.firstName || null,
        lastName: payload.lastName || null,
        processType: payload.processType || null,
        phone: payload.phone || null,
        externalReference: payload.externalReference || null,
        channel: payload.channel || null,
        createdBy: payload.createdBy || null,
    };
};

const mapGetOrder = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadOrder(action.payload);

    return apiRequest<TOrder[]>({
        isSubscription: true,
        method: 'POST',
        path: '/searchOrder',
        body: payload,
        lambdaFunctionName: config.FunctionNames.searchOrder,
    }).pipe(
        mergeMap((response: any | RequestError) => {
            if (
                response instanceof RequestError &&
                response.code !== undefined
            ) {
                return of(
                    fetchOrderAsync.failure({
                        code: response.code as string,
                        message: response.message,
                    }),
                );
            }

            if (response) {
                try {
                    const res = JSON.parse(JSON.stringify(response));
                    return of(fetchOrderAsync.success(res));
                } catch (e) {
                    return of(
                        fetchOrderAsync.failure({
                            code: '400',
                            message: 'Données indisponibles',
                        }),
                    );
                }
            }

            return of(
                fetchOrderAsync.failure({
                    code: '400',
                    message: 'Données indisponibles',
                }),
            );
        }),
        catchError(error => {
            return of(
                fetchOrderAsync.failure({
                    code: error.code,
                    message:
                        error.code === '0'
                            ? error.message
                            : JSON.parse(error.message).message,
                }),
            );
        }),
    );
};

const orderEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchOrderAsync.request)),
        mergeMap(action => mapGetOrder(action, dependency)),
    );

const mapGetSearchedClients = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadOrder(action.payload);

    return apiRequest<TOrder[]>({
        isSubscription: true,
        method: 'POST',
        path: '/searchOrder',
        body: payload,
        lambdaFunctionName: config.FunctionNames.searchOrder,
    }).pipe(
        mergeMap((response: any | RequestError) => {
            if (
                response instanceof RequestError &&
                response.code !== undefined
            ) {
                return of(
                    searchClientAsync.failure({
                        code: response.code as string,
                        message: response.message,
                    }),
                );
            }

            if (response) {
                try {
                    const res = JSON.parse(JSON.stringify(response));

                    return of(searchClientAsync.success(res));
                } catch (e) {
                    return of(
                        searchClientAsync.failure({
                            code: '400',
                            message: 'Données indisponibles',
                        }),
                    );
                }
            }

            return of(
                searchClientAsync.failure({
                    code: '400',
                    message: 'Données indisponibles',
                }),
            );
        }),
        catchError(error => {
            return of(
                searchClientAsync.failure({
                    code: error.code,
                    message:
                        error.code === '0'
                            ? error.message
                            : JSON.parse(error.message).message,
                }),
            );
        }),
    );
};

const searchClientEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(searchClientAsync.request)),
        mergeMap(action => mapGetSearchedClients(action, dependency)),
    );

export {
    fetchOrderAsync,
    searchClientAsync,
    orderEpic,
    mapGetOrder,
    searchClientEpic,
};
