import React from 'react';
import { getLabelByType } from '../../../utils/helpers';

export type StatusType =
    | 'contractStatus'
    | 'orderStatus'
    | 'firstPaymentCBStatus'
    | 'firstPaymentSlimCollectStatus'
    | 'paymentSDD';

const Status: React.FC<{ status: string; type: StatusType }> = ({
    status,
    type,
}) => {
    return (
        <div className={`status status-${mappingStatus(status)}`}>
            <div className="circle" />
            <span className="label">{getLabelByType(status, type)}</span>
        </div>
    );
};

export const mappingStatus = (status: string) => {
    switch (status) {
        case 'success':
        case 'overPayment':
        case 'effective':
        case 'processed':
        case 'validated':
        case 'lettered':
        case 'COMPLETED':
        case 'terminated':
        case 'allocated':
        case 'OK':
        case 'FINALIZED':
        case 'FINALIZED_WITHOUT_DOC':
            return 'success';

        case 'detectedAnomaly':
        case 'transferFailure':
        case 'recovery1':
        case 'recovery2':
        case 'latePayment':
        case 'incident':
        case 'transactionFailure':
        case 'errorDetected':
        case 'toBeLetter':
        case 'SUSPENDED':
        case 'IN_FAILURE':
        case 'inFaillure':
        case 'inFailure':
        case 'KO':
        case 'NOK':
        case 'PENDING':
            return 'danger';

        case 'transmitted':
        case 'notSettled':
        case 'externalRecovery':
        case 'toProcess':
        case 'initialized':
        case 'pending':
        case 'toAllocate':
        case 'waiting':
        case 'toBeQualified':
        case 'toValidate':
        case 'inProposal':
        case 'PENDING':
        case 'IN_PROGRESS':
        case 'suspended':
        case 'inProgress':
        case 'disputed':
            return 'warning';

        case 'dropped':
        case 'cancelled':
        case 'canceled':
        case 'CANCELLED':
        case 'CANCELED':
        case 'discarded':
            return 'secondary';

        case 'PAYMENT_SDD':
            return 'purple'

        default:
            return 'undefined';
    }
};

export default Status;
