import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';
import { TEPInvoice } from 'Models';
import {
    fetchContractAsync,
    resetContractState,
    setContract,
} from '../../_actions/contract.actions';
import {
    fetchPiecesAsync,
    fetchEPInvoicesAsync,
    createPaymentAsync,
    resetUpdate,
} from '../../_actions/payment.actions';
import { fetchMeterReadingAsync } from '../../_actions/consumption.actions';
import withBanner, {
    ExternalProps as ExternalPropsBanner,
} from '../../libraries/withBanner';
import withModal, {
    ExternalProps as ExternalPropsModal,
    InjectedProps as InjectedPropsModal,
} from '../../libraries/withModal';
import HomeContainer, {
    Props as ContainerProps,
} from '../../containers/HomeContainer/HomeContainer';
import { ChangePiece } from '../../containers/FailPieceContainer/FailPieceContainer';
import { fetchContextAsync } from '../../_actions/profile.actions';
import {
    findContractByNumber,
    sortedConsumptionList,
    getTodayDate,
} from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
import { fetchFilesListAsync } from '../../_actions/file.actions';
import { initialEPInvoice } from '../../utils/initialState';

const Wording = WordingConstant.HomePage;

const WrappedHome = compose<
    ContainerProps,
    Subtract<ContainerProps, InjectedPropsModal> &
        ExternalPropsBanner &
        ExternalPropsModal
>(
    withBanner(),
    withModal(),
)(HomeContainer);

const mapStateToProps = (state: RootState) => ({
    profileState: state.profileReducer,
    contractState: state.contractReducer,
    paymentState: state.paymentReducer,
    consumptionState: state.consumptionReducer,
    fileState: state.fileReducer,
});

const dispatchProps = {
    contractDispatch: fetchContractAsync.request,
    filesListDispatch: fetchFilesListAsync.request,
    createPaymentDispatch: createPaymentAsync.request,
    fetchMeterReadingDispatch: fetchMeterReadingAsync.request,
    resetCreatePaymentDispatch: resetUpdate,
    resetContractDispatch: resetContractState,
    setContractDispatch: setContract,
    fetchContextDispatch: fetchContextAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

type State = {
    fields: ChangePiece;
};

class HomePage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            fields: {
                dueDate: '',
                piece: initialEPInvoice,
            },
        };
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        const userId = url.searchParams.get('client');
        if (userId) {
            sessionStorage.setItem('currentClient', userId);
            const { fetchContextDispatch } = this.props;
            fetchContextDispatch({ customerNbr: userId });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.contractState.selectedContractNumber !==
            this.props.contractState.selectedContractNumber
        ) {
            const {
                contractState: { selectedContractNumber, contracts },
                profileState: { customerNumber },
                // fetchPiecesDispatch,
                fetchMeterReadingDispatch,
                filesListDispatch,
            }: Props = this.props;

            const contract = findContractByNumber(
                selectedContractNumber,
                contracts,
            );

            // fetchPiecesDispatch({
            //    contractNbr: selectedContractNumber,
            // });
            fetchMeterReadingDispatch({
                contractNbr: selectedContractNumber,
                pointOfDelivery: contract.deliveryPoint.pointOfDelivery,
                readingDate: getTodayDate(),
                energyType: contract.energy,
            });
            filesListDispatch({
                contractNbr: selectedContractNumber,
                customerNbr: customerNumber,
                mode: 'PAYMENT',
            });
        }
    }

    handleChangeBanner = (contractNumber: string) => {
        const {
            contractState: { contracts },
            contractDispatch,
            profileState: { customerNumber },
            setContractDispatch,
            filesListDispatch,
        } = this.props;
        const payload = {
            customerNbr: customerNumber,
            contractNbr: contractNumber,
        };
        const contract = findContractByNumber(contractNumber, contracts);
        if (contract.isFetched) {
            setContractDispatch({ contract });
        } else {
            contractDispatch(payload);
        }
        filesListDispatch({
            contractNbr: contractNumber,
            customerNbr: customerNumber,
            mode: 'PAYMENT',
        });
    };

    handleChangeFailPiece = (ff: TEPInvoice, dd: string) => {
        const { fields } = this.state;
        fields.dueDate = dd;
        fields.piece = ff;
        this.setState({ fields });
    };

    handleUpdate = () => {
        const { fields } = this.state;
        const { createPaymentDispatch } = this.props;
        createPaymentDispatch(fields);
        fields.dueDate = '';
        this.setState({ fields });
    };

    handleReset = () => {
        const {
            filesListDispatch,
            resetCreatePaymentDispatch,
            profileState: { customerNumber },
            contractState: { selectedContractNumber },
        } = this.props;

        resetCreatePaymentDispatch();
        filesListDispatch({
            contractNbr: selectedContractNumber,
            customerNbr: customerNumber,
            mode: 'PAYMENT',
        });
    };

    render() {
        const {
            profileState: { contact, customerType, customerNumber },
            contractState: { contracts, selectedContractNumber, error },
            paymentState: { updateResponse },
            fileState: { payments },
            consumptionState: { meters },
            resetContractDispatch,
        }: Props = this.props;

        const contract = findContractByNumber(
            selectedContractNumber,
            contracts,
        );

        return (
            <WrappedHome
                contract={contract}
                customerNumber={customerNumber}
                meters={sortedConsumptionList(meters)}
                pieces={payments}
                handleChangeFailPiece={this.handleChangeFailPiece}
                // Banner
                title={Wording.title}
                bannerError={error}
                handleCloseModalBanner={() => resetContractDispatch()}
                selectedContractNumber={selectedContractNumber}
                contracts={contracts}
                contact={contact}
                customerType={customerType}
                handleChangeBanner={this.handleChangeBanner}
                // Modal
                modalMessage={Wording.popup}
                updateResponse={updateResponse}
                handleValidate={this.handleUpdate}
                handleReset={this.handleReset}
            />
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(HomePage);
