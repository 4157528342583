import React, { useState, useEffect } from 'react';
import keys from 'lodash/keys';
import WordingConstant from '../../../utils/wording.json';
import CustomCheckbox from '../../CustomCheckbox';

import { EActionMode, EGroups } from '../../../utils/enums';
import AuthSchemaItem from './AuthSchemaItem';
import { TAuthorizationSchemaSubMenu } from '../../../_actions/createGroup.actions';

const Wording = WordingConstant.AuthorizationSchemaGroup;

const sortingAlg = (a: any, b: any) => (a.rang > b.rang ? 1 : -1);

interface IAuthSchemaMenuObject {
    id: string;
    label: string;
    root: string;
    groupMode: string;
    subGroupMode: string;
    actionMode: string;
    userLimited?: string;
    rang?: number;
}

export interface IAuthSchemaObject {
    rang?: number;
    id: string;
    label: string;
    subMenu: IAuthSchemaMenuObject[];
}

export interface IItem {
    label: string;
    uId: string;
    rang: number;
}

interface IActionModesChange {
    uId: string;
    value: string;
}

interface ISubMenusChange {
    uIdItem: string;
    subMenuItem: ISubMenuItem;
}

export interface IAccessItem {
    uIdItem: string;
    uIdMenu: string;
    type: string;
    value: string;
}

export interface ISubMenuItem extends IItem {
    root: string;
}

export interface IAuthorizationSchemaGroupProps {
    getAuthorizationSchema: React.Dispatch<any>;
}

interface IItemStateObject {
    rang: number;
    label: string;
}

interface IItemState {
    [key: string]: IItemStateObject[];
}

interface IActionModeState {
    [key: string]: string;
}

interface ISubMenuState {
    [key: string]: { [key: string]: ISubMenuItem };
}

interface IAccessStateObject {
    groupMode: string;
    subGroupMode: string;
    userLimited?: string;
}

interface IAccessState {
    [key: string]: { [key: string]: IAccessStateObject };
}

const AuthorizationSchemaGroup: React.FC<IAuthorizationSchemaGroupProps> = ({
    getAuthorizationSchema,
}) => {
    const [isSelectedAll, setSelectAll] = useState<boolean>(false);

    const [itemsState, setItemValue] = useState<IItemState>({});
    const [actionsModesState, setModesValue] = useState<IActionModeState>({});
    const [subMenuState, setSubMenuValue] = useState<ISubMenuState>({});
    const [accessState, setAccessState] = useState<IAccessState>({});

    useEffect(() => {
        const actModes: IActionModeState = {};
        const subMn: ISubMenuState = {};
        const access: IAccessState = {};

        Wording.renderItems.forEach(({ uId }) => {
            actModes[uId] = EActionMode.FULL;
            subMn[uId] = {};
            access[uId] = {};

            (Wording.subMenuItems as { [key: string]: ISubMenuItem[] })[
                uId
            ].forEach(elm => {
                const prepareAccess: any = {};

                Wording.accessItems.forEach(ai => {
                    if (ai.access) {
                        if (ai.access.includes(elm.uId)) {
                            prepareAccess[ai.value] = EGroups.LIMITED;
                        }
                    } else {
                        prepareAccess[ai.value] = EGroups.LIMITED;
                    }
                });

                access[uId][elm.uId] = prepareAccess;
            });
        });

        setModesValue(actModes);
        setSubMenuValue(subMn);
        setAccessState(access);
    }, []);

    useEffect(() => {
        if (keys(itemsState).length !== 0) {
            const build = buildAuthorizationSchema();
            getAuthorizationSchema(build);
        }
    }, [itemsState, subMenuState, accessState, actionsModesState]);

    const buildAuthorizationSchema = () => {
        const prepareArr: any = [];
        keys(itemsState).forEach(itemKey => {
            const ItemElm: any = itemsState[itemKey];
            const prepareObject: IAuthSchemaObject = {
                rang: ItemElm.rang,
                id: '',
                label: ItemElm.label,
                subMenu: [],
            };

            keys(subMenuState[itemKey]).forEach(menuKey => {
                const MenuItem = subMenuState[itemKey][menuKey];
                const AccessItem = accessState[itemKey][menuKey];
                const ActionItem = actionsModesState[itemKey];

                const prepareMenuObject: IAuthSchemaMenuObject = {
                    rang: MenuItem.rang,
                    id: '',
                    label: MenuItem.label,
                    root: MenuItem.root,
                    groupMode: AccessItem.groupMode,
                    subGroupMode: AccessItem.subGroupMode,
                    actionMode: ActionItem,
                };

                if (AccessItem.userLimited) {
                    prepareMenuObject.userLimited = AccessItem.userLimited;
                }

                prepareObject.subMenu.push(prepareMenuObject);
            });

            prepareArr.push({
                ...prepareObject,
                subMenu: prepareObject.subMenu
                    .sort(sortingAlg)
                    .map((elm, idx) => {
                        const prepare: TAuthorizationSchemaSubMenu = {
                            id: (idx + 1).toString(),
                            label: elm.label,
                            root: elm.root,
                            groupMode: elm.groupMode,
                            subGroupMode: elm.subGroupMode,
                            actionMode: elm.actionMode,
                        };

                        if (elm.userLimited) {
                            prepare.userLimited = elm.userLimited;
                        }

                        return prepare;
                    }),
            });
        });

        return prepareArr.sort(sortingAlg).map((elm: any, idx: number) => ({
            id: idx + 1,
            label: elm.label,
            subMenu: elm.subMenu,
        }));
    };

    const handleSetItemValue = ({ uId, label, rang }: IItem) => {
        if (itemsState[uId]) {
            const newValues: any = {};
            keys(itemsState).forEach((key: string) => {
                if (key !== uId) {
                    newValues[key] = {
                        ...itemsState[key],
                    };
                }
            });
            setItemValue(newValues);
        } else {
            setItemValue({
                ...itemsState,

                [uId]: {
                    rang,
                    label,
                },
            } as IItemState);
        }
    };

    const handleSetActionMod = ({ uId, value }: IActionModesChange) => {
        setModesValue({
            ...actionsModesState,
            [uId]: value,
        });

        if (value === EActionMode.READ_ONLY) {
            const newMenus: any = {};

            const readOnlyItems = (Wording.subMenuItems as {
                [key: string]: ISubMenuItem[];
            })[uId].filter((elm: any) => elm.readeOnly);

            if (readOnlyItems) {
                readOnlyItems.forEach(elm => {
                    newMenus[elm.uId] = {
                        root: elm.root,
                        label: elm.label,
                        rang: elm.rang,
                    };
                });
            }

            setSubMenuValue({
                ...subMenuState,
                [uId]: newMenus,
            });
        }

        if (
            value === EActionMode.FULL &&
            keys(subMenuState[uId]).length !== 0
        ) {
            setSubMenuValue({
                ...subMenuState,
                [uId]: {},
            });
        }
    };

    const handleSetSubMenu = ({ subMenuItem, uIdItem }: ISubMenusChange) => {
        const { label, root, uId, rang } = subMenuItem;

        const currentMenusByItem = subMenuState[uIdItem];

        if (currentMenusByItem[uId]) {
            const newMenuValues: any = {};

            keys(currentMenusByItem).forEach((key: string) => {
                if (key !== uId) {
                    newMenuValues[key] = {
                        ...currentMenusByItem[key],
                    };
                }
            });

            setSubMenuValue({
                ...subMenuState,
                [uIdItem]: newMenuValues,
            });
        } else {
            setSubMenuValue({
                ...subMenuState,
                [uIdItem]: {
                    ...currentMenusByItem,
                    [uId]: {
                        label,
                        root,
                        rang,
                        uId,
                    },
                },
            });
        }
    };

    const handleSetAccess = ({
        uIdItem,
        value,
        type,
        uIdMenu,
    }: IAccessItem) => {
        const objBuild = {
            ...accessState[uIdItem][uIdMenu],
            [type]: value,
        };

        if (type === 'groupMode' && value === 'FULL') {
            objBuild.subGroupMode = 'FULL';
        }

        if (
            !(
                type === 'subGroupMode' &&
                accessState[uIdItem][uIdMenu].groupMode === 'FULL'
            )
        ) {
            setAccessState({
                ...accessState,
                [uIdItem]: {
                    ...accessState[uIdItem],
                    [uIdMenu]: {
                        ...objBuild,
                    },
                },
            });
        }
    };

    const selectAllItems = () => {
        setSelectAll(!isSelectedAll);

        if (!isSelectedAll) {
            const actModes: any = {};
            const subMn: any = {};
            const access: any = {};
            const mods: any = {};

            Wording.renderItems.forEach(({ uId, label, rang }) => {
                actModes[uId] = EActionMode.FULL;
                subMn[uId] = {};
                access[uId] = {};

                mods[uId] = {
                    rang,
                    label,
                };

                (Wording.subMenuItems as { [key: string]: ISubMenuItem[] })[
                    uId
                ].forEach(elm => {
                    const prepareAccess: any = {};

                    Wording.accessItems.forEach(ai => {
                        if (ai.access) {
                            if (ai.access.includes(elm.uId)) {
                                prepareAccess[ai.value] = EGroups.FULL;
                            }
                        } else {
                            prepareAccess[ai.value] = EGroups.FULL;
                        }
                    });

                    access[uId][elm.uId] = prepareAccess;
                    subMn[uId][elm.uId] = {
                        label: elm.label,
                        root: elm.root,
                        rang: elm.rang,
                    };
                });
            });

            setItemValue(mods);
            setModesValue(actModes);
            setSubMenuValue(subMn);
            setAccessState(access);
        }
    };

    return (
        <div className="authorization-schema-group">
            <div className="authorization-schema-group__select-all">
                <CustomCheckbox
                    checked={isSelectedAll}
                    handleCheck={selectAllItems}
                    text={Wording.selectAll}
                />
            </div>

            <div className="authorization-schema-group__items-auth">
                {Wording.renderItems.map((obj: IItem) => {
                    const { uId } = obj;
                    return (
                        <AuthSchemaItem
                            key={uId}
                            item={obj}
                            handleSetCheckbox={handleSetItemValue}
                            handleSetAccess={handleSetAccess}
                            handleSetActionMod={handleSetActionMod}
                            handleSetSubMenu={handleSetSubMenu}
                            itemValues={{
                                isChecked: itemsState[uId] !== undefined,
                                mode: actionsModesState[uId],
                                subMenus: subMenuState[uId],
                                accessItem: accessState[uId],
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default AuthorizationSchemaGroup;
