import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { TMeterRead } from 'Models';
import { ERateOption } from '../utils/enums';

import * as actions from '../_actions/consumption.actions';

import WordingConstant from '../utils/wording.json';

const Wording = WordingConstant.Common;

export type ConsumptionState = Readonly<{
    rateOption: ERateOption;
    digitSize: number;
    meterNumber: string;
    meters: TMeterRead[];
    updateResponse: {
        code: string;
        message: string;
    };
    errorMeterConfig?: string;
    errorMeterReading?: string;
}>;

export const initialState = {
    rateOption: ERateOption.UNKNOWN,
    digitSize: 0,
    meterNumber: '',
    meters: [],
    updateResponse: {
        code: '',
        message: '',
    },
    errorMeterConfig: undefined,
    errorMeterReading: undefined,
};

type Actions = actions.ConsumptionAction;

const consumptionReducer: Reducer<ConsumptionState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchMeterConfigAsync.request): {
            return {
                ...state,
            };
        }
        case getType(actions.fetchMeterReadingAsync.request): {
            return {
                ...state,
            };
        }

        case getType(actions.fetchMeterConfigAsync.failure): {
            return {
                ...state,
                errorMeterConfig: Wording.noData,
            };
        }

        case getType(actions.fetchMeterReadingAsync.failure): {
            return {
                ...state,
                errorMeterReading: Wording.noData,
                meters: [],
                meterNumber: '',
                digitSize: 0,
            };
        }

        case getType(actions.fetchMeterConfigAsync.success): {
            return {
                ...state,
                rateOption: action.payload.rateOption,
                digitSize: Number(action.payload.digitSize),
                meterNumber: action.payload.meterNumber,
                errorMeterConfig: undefined,
            };
        }

        case getType(actions.fetchMeterReadingAsync.success): {
            return {
                ...state,
                meters: action.payload || [],
                errorMeterReading: undefined,
            };
        }

        case getType(actions.createIndexAsync.failure): {
            return {
                ...state,
                updateResponse: {
                    code: action.payload.code || '500',
                    message: action.payload.message || 'failure',
                },
            };
        }

        case getType(actions.createIndexAsync.success): {
            return {
                ...state,
                updateResponse: {
                    code: '200',
                    message: 'success',
                },
            };
        }

        case getType(actions.resetCreateIndex):
            return {
                ...state,
                updateResponse: {
                    code: '',
                    message: '',
                },
            };

        default:
            return { ...state };
    }
};

export default consumptionReducer;
