import React from 'react';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PaymentPage.balance;

type Props = {
    contractBalance?: string;
    customerBalance?: string;
    invoiceBalance?: string;
};

const Balance: React.FC<Props> = ({ contractBalance }) => {
    return (
        <div className="balance-container">
            <div className="balance-container__block">
                {contractBalance ? (
                    <div className="balance-container__block__head">
                        <p>{Wording.title}</p>
                        <h3>{`${contractBalance} €`}</h3>
                    </div>
                ) : (
                    <p className="balance-container__block__error">
                        {Wording.error}
                    </p>
                )}
            </div>
        </div>
    );
};

export default Balance;
