import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    ActionType,
    isActionOf,
    createStandardAction,
} from 'typesafe-actions';
import { ReducerError, RootAction, RootState, Services, TError } from 'Types';
import {
    FETCH_CUSTOMER_CONTRACT_FAILURE,
    FETCH_CUSTOMER_CONTRACT_REQUEST,
    FETCH_CUSTOMER_CONTRACT_SUCCESS,
    RESET_UPLOAD_AMENDMENTS,
    UPLOAD_AMENDMENTS_FAILURE,
    UPLOAD_AMENDMENTS_REQUEST,
    UPLOAD_AMENDMENTS_SUCCESS,
} from './actionTypes';
import { Epic } from 'redux-observable';

import { TCustomer } from '../constants/key-system';

export type TCustomerContractPayload = Partial<{
    customerId: string;
    contractId: string;
    clientFirstName: string;
    clientLastName: string;
    companyName: string;
    fromDate: string;
    toDate: string;
}>;

export type TUploadAmendmentsPayload = {
    riders: Array<{
        contractNbr: string;
        customerNbr: string;
        riderEffectiveStartDate: string;
        productCode: string;
        productType: string;
        refDateForFixedPrice: string;
    }>;
};
interface IResponseClient {
    customers: TCustomer[];
}

const fetchCustomerContractAsync = createAsyncAction(
    FETCH_CUSTOMER_CONTRACT_REQUEST,
    FETCH_CUSTOMER_CONTRACT_SUCCESS,
    FETCH_CUSTOMER_CONTRACT_FAILURE,
)<TCustomerContractPayload, IResponseClient, ReducerError>();

const uploadAmendmentsAsync = createAsyncAction(
    UPLOAD_AMENDMENTS_REQUEST,
    UPLOAD_AMENDMENTS_SUCCESS,
    UPLOAD_AMENDMENTS_FAILURE,
)<TUploadAmendmentsPayload, any, ReducerError>();

const resetUploadAmendments = createStandardAction(RESET_UPLOAD_AMENDMENTS)();

export type CustomersContractAction =
    | ActionType<typeof fetchCustomerContractAsync>
    | ActionType<typeof resetUploadAmendments>
    | ActionType<typeof uploadAmendmentsAsync>;

const preparePayloadCustomerContract = (
    payload: TCustomerContractPayload,
): TCustomerContractPayload => {
    return { ...payload };
};

const mapGetCustomerContract = (
    action: RootAction,
    { apiRequest }: Services,
) => {
    const payload = preparePayloadCustomerContract(action.payload);
    return apiRequest<IResponseClient>({
        path: '/customerAndContract',
        method: 'post',
        body: payload,
        isPay: true,
    }).pipe(
        mergeMap((response: any & TError) => {
            if (
                response.code === 'MISSING_PARAMETER' ||
                response.code === 'NO_RESPONSE_FROM_PARTNER'
            ) {
                return of(
                    fetchCustomerContractAsync.failure({
                        code: response.code,
                        message: response.description,
                    }),
                );
            }
            return of(fetchCustomerContractAsync.success(response));
        }),
        catchError(error => {
            return of(
                fetchCustomerContractAsync.failure({
                    code: error.code,
                    message: error.description,
                }),
            );
        }),
    );
};

const mapUploadAmendments = (action: RootAction, { apiRequest }: Services) => {
    return apiRequest<any>({
        path: '/uploadAmendments',
        method: 'post',
        body: action.payload,
    }).pipe(
        mergeMap((response: any & TError) => {
            if (
                response.code === 'MISSING_PARAMETER' ||
                response.code === 'NO_RESPONSE_FROM_PARTNER'
            ) {
                return of(
                    uploadAmendmentsAsync.failure({
                        code: response.code,
                        message: response.description,
                    }),
                );
            }
            return of(uploadAmendmentsAsync.success(response));
        }),
        catchError(error => {
            return of(
                uploadAmendmentsAsync.failure({
                    code: error.code,
                    message: error.description,
                }),
            );
        }),
    );
};

const uploadAmendmentsEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(uploadAmendmentsAsync.request)),
        mergeMap(action => mapUploadAmendments(action, dependency)),
    );

const customerContractEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(fetchCustomerContractAsync.request)),
        mergeMap(action => mapGetCustomerContract(action, dependency)),
    );

export {
    fetchCustomerContractAsync,
    customerContractEpic,
    mapGetCustomerContract,
    uploadAmendmentsAsync,
    uploadAmendmentsEpic,
    resetUploadAmendments,
};
