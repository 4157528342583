import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { CoordsInputs, CustomInput, CustomRadio, PostalCodeSelect } from '..';
import WordingConstant from '../../utils/wording.json';
import { TAddress, ECivilities } from '../../utils/network/types';
import { getAddressStreetLabel } from '../../utils/network/helpers';
import {
    buildClassName as bcn,
    formatAddressString,
} from '../../utils/helpers';
import { toIsc } from '../../utils/text';

type Coords = {
    civility: ECivilities | null;
    firstName: string;
    lastName: string;
};

type CoordsErrors = Partial<{
    civility: string;
    firstName: string;
    lastName: string;
}>;

interface IProps {
    addressError?: string;
    initialAddress?: TAddress;
    initialCoords?: Coords;
    onAddressInputChange?: (address: string) => void;
    onCoordsChange?: (coords: Coords) => void;
    onIdenticalAddressToggle?: (isIdentical: boolean) => void;
    onPostalCodeSelection: (address: TAddress) => void;
    text: string;
}

const Wording = WordingConstant.Components.AddressSelect;
const baseClassName = 'address-select';
const checkboxesClassName = bcn([baseClassName, 'checkboxes']);
const inputsClassName = bcn([baseClassName, 'inputs']);
const addressClassName = bcn([inputsClassName, 'address']);

const AddressSelect: FC<IProps> = (props: IProps) => {
    const {
        addressError,
        initialCoords,
        onAddressInputChange,
        onCoordsChange,
        onIdenticalAddressToggle,
        onPostalCodeSelection,
        text,
    } = props;
    const [initialAddress, setInitialAddress] = useState<TAddress | null>(null);
    const [address, setAddress] = useState<string>('');
    const [city, setCity] = useState<string | null>(null);
    const [isIdentical, setIdentical] = useState<boolean>(true);

    const [coords, setCoords] = useState<Coords | null>(null);

    const [coordsErrors, setCoordsErrors] = useState<CoordsErrors | null>(null);

    useEffect(() => {
        if (props.initialAddress) {
            setInitialAddress(props.initialAddress);
            setAddress(getAddressStreetLabel(props.initialAddress));

            if (props.initialAddress.townName) {
                setCity(props.initialAddress.townName);
            }
        }

        setIdentical(
            !(
                props.initialAddress !== undefined &&
                props.initialAddress !== null
            ),
        );

        if (!!onCoordsChange) {
            setCoords(
                initialCoords || {
                    civility: ECivilities.MR,
                    firstName: '',
                    lastName: '',
                },
            );

            setCoordsErrors({});
        }
    }, []);

    const onAddressInput = (e: ChangeEvent<HTMLInputElement>) => {
        const nextInput = _.get(e, 'target.value', '');
        setAddress(formatAddressString(nextInput));
        if (!!onAddressInputChange) onAddressInputChange(nextInput);
    };
    const onToggle = (toggled: boolean) => {
        setIdentical(toggled);
        if (!toggled) setInitialAddress(null);
        if (!!onIdenticalAddressToggle) onIdenticalAddressToggle(toggled);
        setAddress('');
        setCity(null);
    };
    const onCoordsInput = (input: Coords) => {
        const nextErrors: CoordsErrors = {};
        const firstNameChanged: boolean =
            !coords || !_.isEqual(coords.firstName, input.firstName);
        const lastNameChanged: boolean =
            !coords || !_.isEqual(coords.lastName, input.lastName);
        if (firstNameChanged && input.firstName.length <= 1) {
            nextErrors.firstName =
                WordingConstant.AdminSubscriptionContainer.ClientData.errors.firstNameValidity;
        }
        if (lastNameChanged && input.lastName.length <= 1) {
            nextErrors.lastName =
                WordingConstant.AdminSubscriptionContainer.ClientData.errors.lastNameValidity;
        }
        setCoords(input);
        setCoordsErrors(nextErrors);
        if (!!onCoordsChange) onCoordsChange(input);
    };
    return (
        <div className={baseClassName}>
            <span>{toIsc(text)}</span>
            <div className={checkboxesClassName}>
                <CustomRadio
                    checked={isIdentical}
                    handleCheck={() => {
                        onToggle(true);
                    }}
                    text={Wording.yes}
                    value={Wording.yes}
                />
                <CustomRadio
                    checked={!isIdentical}
                    handleCheck={() => {
                        onToggle(false);
                    }}
                    text={Wording.no}
                    value={Wording.no}
                />
            </div>
            {!isIdentical && (
                <div className={inputsClassName}>
                    {!!coords && (
                        <CoordsInputs
                            errors={coordsErrors || undefined}
                            onInputChange={onCoordsInput}
                            values={coords}
                        />
                    )}
                    <div className={addressClassName}>
                        <CustomInput
                            error={addressError}
                            onInputChange={onAddressInput}
                            text={Wording.address}
                            value={address}
                        />
                        <PostalCodeSelect
                            initialAddress={
                                !!initialAddress ? initialAddress : undefined
                            }
                            onSelection={(address: TAddress) => {
                                setCity(address.townName || null);
                                onPostalCodeSelection(address);
                            }}
                            text={Wording.postalCode}
                        />
                        <CustomInput
                            disabled={true}
                            text={Wording.city}
                            value={city || ''}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddressSelect;
