import React, { useState } from 'react';

import ModificationButtons from '../../containers/ProfileContainer/ModificationButtons';

import { TInvoice, TContract, TEPInvoice } from 'Models';
import { CustomTable, CustomSelect } from '../../components';
import {
    getFormattedDate,
    getLabelByValue,
    getLabelInvoiceRouting,
} from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';
import { IFinance } from '../ProfileContainer/types';
import { EInvoiceRoutingMode } from '../../utils/enums';

const Wording = WordingConstant.BillPage;
const HeaderWording = WordingConstant.TableHeader.bill;

export type Props = {
    // data: TInvoice[];
    data: TEPInvoice[];
    finance: IFinance;
    contract: TContract;
    handleRoutingMode: any;
    handleDownloadBill: (key: string) => Promise<void>;
    setCheckUpdateInvoiceRouting: any;
    handleShowModal(section: string): void;
};

const BillContainer: React.FC<Props> = ({
    data,
    finance,
    contract,
    handleRoutingMode,
    handleDownloadBill,
    handleShowModal,
    setCheckUpdateInvoiceRouting,
}) => {
    const columnsTable = [
        {
            Header: HeaderWording.id,
            accessor: 'invoice_number',
            Cell: (propsCell: any) => <span>{propsCell.value}</span>,
            width: 150,
        },
        {
            Header: HeaderWording.date,
            accessor: 'invoice_date',
            Cell: (propsCell: any) => <span>{propsCell.value}</span>,
            width: 100,
        },
        {
            Header: HeaderWording.dueDate,
            accessor: 'invoice_date_due',
            Cell: (propsCell: any) => <span>{propsCell.value}</span>,
            width: 200,
        },
        {
            Header: HeaderWording.type,
            accessor: 'hgz_internal_invoice_nature_text',
            Cell: (propsCell: any) => <span>{propsCell.value}</span>,
            width: 120,
        },
        {
            Header: HeaderWording.totalAmount,
            accessor: 'invoice_amount_total_signed',
            Cell: ({ original }: any) => {
                if (original.invoice_type === "out_refund") {
                    return (<span>- {original.invoice_amount_total_signed} €</span>)
                }
                return (<span>{original.invoice_amount_total_signed} €</span>)
            },
            width: 180,
        },
        {
            Header: HeaderWording.totalAmountDu,
            accessor: 'invoice_amount_residual_signed',
            Cell: (propsCell: any) => <span>{propsCell.value} €</span>,
            width: 180,
        },
        {
            Header: HeaderWording.status,
            accessor: 'invoice_amount_residual_signed',
            // Cell: ({ value }: any) => (
            //    <span>
            //        {value && getLabelByValue(value, Wording.invoiceStatus)}
            //    </span>
            // ),
            Cell: (propsCell: any) => {
                if (parseInt(propsCell.value, 10) === 0) {
                    return <span>Payée</span>;
                }
                if (parseInt(propsCell.value, 10) > 0) {
                    return <span>À payer</span>;
                }
                if (parseInt(propsCell.value, 10) < 0) {
                    return <span>Trop perçu</span>;
                }
            },

            width: 150,
        },
        {
            Header: HeaderWording.download,
            accessor: 'invoice_number',
            Cell: (propsCell: any) => {
                return (
                    <a
                        href={'#'}
                        onClick={() => handleDownloadBill(propsCell.value)}
                    >
                        Fichier
                    </a>
                );
            },
            width: 150,
        },
    ];

    return (
        <div className="bill-container">
            <h2 className="bill-container__amount">{Wording.amount}</h2>
            <CustomTable
                columns={columnsTable}
                data={data}
                defaultPageSize={data && data.length}
                sortable={true}
                noDataText={Wording.noDataText}
            />

            <div className="invoice-routing">
                <h2 className="invoice-routing__title">
                    {Wording.routingMode.title}
                </h2>
                <div
                    className={`invoice-routing__bloc ${
                        !finance.checkUpdateInvoiceRouting
                            ? 'inactive'
                            : 'active'
                    }`}
                >
                    <span className="invoice-routing__label">
                        {Wording.routingMode.label}
                    </span>

                    {!finance.checkUpdateInvoiceRouting ? (
                        <p className="invoice-routing__value">
                            {getLabelInvoiceRouting(
                                contract.finance
                                    .invoiceRoutingMode as EInvoiceRoutingMode,
                            )}
                        </p>
                    ) : (
                        <CustomSelect
                            id="invoice-routing"
                            defaultValue={finance.invoiceRoutingMode}
                            options={Wording.routingMode.values}
                            handleChangeSelect={(e: any) =>
                                handleRoutingMode(e.value)
                            }
                            value={finance.invoiceRoutingMode}
                        />
                    )}

                    <ModificationButtons
                        showButton={!finance.checkUpdateInvoiceRouting}
                        condition={true}
                        disabledValidate={false}
                        handleButtonClick={() =>
                            setCheckUpdateInvoiceRouting(true)
                        }
                        handleButtonCancel={() =>
                            setCheckUpdateInvoiceRouting(false)
                        }
                        handleButtonValidate={() => {
                            handleShowModal('billing');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default BillContainer;
