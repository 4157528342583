import React from 'react';
import { Route } from 'react-router-dom';
import AdminMain from './admin/root';
import UserMain from './root';
import { connect } from 'react-redux';
import { resetReduxStore } from '../_actions/auth.actions';
import { fetchContextAsync } from '../_actions/profile.actions';
import { getGroupAsync } from '../_actions/getGroup.actions';
import { RootState } from 'Types';

const mapStateToProps = (state: RootState) => ({
    authState: state.authReducer,
});

const dispatchProps = {
    resetReduxStoreDispatch: resetReduxStore,
    getGroupAsyncDispatch: getGroupAsync.request,
};

type IProps = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        initialSearchParams: Map<string, string | number>;
    };
type IState = {
    isAdmin: boolean;
};

class Main extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isAdmin: false,
        };
    }
    componentDidMount() {
        const { groupe } = this.props.authState;
        const { initialSearchParams: isp, getGroupAsyncDispatch } = this.props;

        if (groupe && isp.get('client') === undefined) {
            getGroupAsyncDispatch();
            this.setState({ isAdmin: true });
        } else if (groupe && isp.get('client') !== undefined) {
            this.setState({ isAdmin: true });
        } else {
            this.setState({ isAdmin: false });
        }
    }

    componentWillUnmount() {
        const { groupe } = this.props.authState;
        if (groupe === undefined) {
            this.setState({ isAdmin: false });
        }
    }

    render() {
        const { initialSearchParams: isp } = this.props;
        const { isAdmin } = this.state;
        const isUserAlias = isp.has('client') && !!isp.get('client');

        return (
            <Route
                path="/"
                component={isAdmin && !isUserAlias ? AdminMain : UserMain}
            />
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(Main);
