import React, { useMemo } from 'react';

import { TPiece, TAdhoc, TContract, TEPInvoice } from 'Models';

import FailPieceContainer, {
    BaseProps,
} from '../FailPieceContainer/FailPieceContainer';
import PaymentStatusContainer from '../PaymentStatusContainer';

import { getLabelByValue } from '../../utils/helpers';
import { CustomTable, Balance, CustomButton } from '../../components';

import { EPieceType } from '../../utils/enums';
import WordingConstant from '../../utils/wording.json';
import {
    GenerateProofProps,
    GenerateTimelineProps,
} from '../../pages/PaymentPage/PaymentPage';
import { PaymentSupportingDocuments } from '../../_reducers/payment.reducer';
import resources from '../../utils/resources';

import SupportingDocumentErrorModal from './SupportingDocumentErrorModal';

const Wording = WordingConstant.PaymentPage;
const HeaderWording = WordingConstant.TableHeader.payment;

const humanizeLabel = (piece: TEPInvoice) => {
    const status = piece.status;

    let isInvoiceRunNumberMIGR = false;
    if (piece.invoicing_run_number) {
        const value = piece.invoicing_run_number.slice(0, 4);
        isInvoiceRunNumberMIGR = value === 'MIGR';
    }

    switch (status) {
        case 'sent':
            return 'Transmis';
        case 'reconciled':
            return 'Soldé';
        case 'sent-cancelled':
            return 'Rejeté';
        case 'posted':
            if (piece.payment_type === 'outbound') return 'Remboursé';
            if (isInvoiceRunNumberMIGR) return 'Soldé';
            if (piece.payment_method_code === 'sdd') return 'Transmis';
            return 'Soldé';
        default:
            return '';
    }
};

export type Props = BaseProps & {
    pieces: TEPInvoice[];
    contract: TContract;
    customerNumber: string;
    supportingDocument: PaymentSupportingDocuments;
    generateProof: (props: GenerateProofProps) => void;
    generateTimeline: (props: GenerateTimelineProps) => void;
};

const PaymentContainer: React.FC<Props> = ({
    pieces,
    contract,
    handleChangeFailPiece,
    handleShowModal,
    customerNumber,
    supportingDocument,
    generateProof,
    generateTimeline,
}) => {
    const filteredPieces = useMemo(() => {
        if (!pieces) return [];
        return (
            pieces.filter(el => {
                return (
                    humanizeLabel(el) !== '' &&
                    !(
                        (el.replacing_payment_count &&
                            parseInt(el.replacing_payment_count, 10) > 0) ||
                        (el.energy_paid_number_replays &&
                            parseInt(el.energy_paid_number_replays, 10) > 0)
                    )
                );
            }) || []
        );
    }, [pieces]);

    const columnsTable = [
        {
            Header: HeaderWording.date,
            accessor: 'date',
            // Cell: (propsCell: any) => getFormattedDate(propsCell.value),
            Cell: ({ value }: any) => <span>{value}</span>,
            width: 150,
        },
        {
            Header: HeaderWording.id,
            accessor: 'communication',
            Cell: ({ value }: any) => <span>{value}</span>,
            width: 200,
        },
        {
            Header: HeaderWording.type,
            accessor: 'internal_invoice_nature_text',
            Cell: ({ value }: any) => (
                // getLabelByValue(value, Wording.typePieces),
                <span>{value}</span>
            ),
            width: 150,
        },
        /*{
            Header: HeaderWording.dueDate,
            accessor: 'dueDate',
            Cell: (propsCell: any) => getFormattedDate(propsCell.value),
            width: 180,
        },
        */
        {
            Header: HeaderWording.totalAmount,
            accessor: 'amount',
            Cell: ({ original }: any) => {
                if (original.payment_type === "outbound") {
                    return (<span>- {original.amount} €</span>)
                }
                return (<span>{original.amount} €</span>)
            },
            width: 150,
        },
        /*{
            Header: HeaderWording.amountToPay,
            accessor: 'amountToPay',
            width: 250,
            Cell: (propsCell: any) => <span>{propsCell.value} €</span>,
        },*/
        {
            Header: HeaderWording.status,
            accessor: 'status',
            Cell: (propsCell: { original: any }) =>
                humanizeLabel(propsCell.original),
            // Cell: ({ value }: any) =>
            //    <span>{value}</span>,

            width: 200,
        },

        {
            Header: () => {
                return (
                    <div className="proof-header">
                        {HeaderWording.proof}{' '}
                        {supportingDocument.loading && (
                            <img
                                className="proof-header-loader"
                                src={resources['ic-loader']}
                            />
                        )}
                    </div>
                );
            },
            accessor: 'status',
            Cell: ({ original }: any) => {
                const invoice: TEPInvoice = original;
                if (humanizeLabel(invoice) === 'Soldé') {
                    return (
                        <CustomButton
                            color="orange"
                            title="Générer le justificatif"
                            disabled={supportingDocument.loading}
                            onClick={() =>
                                generateProof({
                                    contract,
                                    piece: invoice,
                                })
                            }
                        />
                    );
                }

                return null;
            },

            width: 200,
        },
    ];

    // const getBalanceValue = (pieces: TPiece[]) => {
    //     const filteredPieces = pieces.filter(
    //         (p: TPiece) => p.type === EPieceType.INVOICE,
    //     );
    //     const reducer = (acc: number, current: TPiece) =>
    //         acc + parseFloat((current as any).amountToPay);

    //     return filteredPieces.reduce(reducer, 0).toFixed(2);
    // };

    const isDataAvailableForTimeline = () => {
        // if (!findLastValidPiece(pieces).lastValid) return false;
        // const data = findLastValidPiece(pieces);
        // const dataTrans = findLastValidTransaction(
        //     (data.lastValid as TPiece).processusList[0].transactions,
        //  );
        // if (!!dataTrans) return false;
        // else
        if (contract && !contract.installmentFrequency) return false;
        return contract && contract.chosenPackages ? true : false;
    };

    // const contractBalance = getBalanceValue(pieces);
    // const piecesToDisplay = controlPieces(pieces);

    return (
        <div className="payment-container">
            <div className="balanceTimeline">
                <div className="balance">
                    <h2>{Wording.balanceTitle}</h2>
                    <Balance>Non disponible</Balance>
                </div>
                <div className="timeline">
                    <CustomButton
                        title={Wording.timeline.title}
                        color="orange"
                        disabled={!isDataAvailableForTimeline()}
                        onClick={() => {
                            generateTimeline({
                                contract,
                                pieces: filteredPieces,
                            });
                        }}
                    ></CustomButton>
                </div>
            </div>

            <h2> {Wording.title} </h2>
            <CustomTable
                columns={columnsTable}
                data={filteredPieces}
                defaultPageSize={filteredPieces ? filteredPieces.length : 0}
                sortable={true}
                noDataText={Wording.noDataText}
            />

            <PaymentStatusContainer pieces={filteredPieces} />

            <FailPieceContainer
                contract={contract}
                customerNumber={customerNumber}
                pieces={filteredPieces}
                handleChangeFailPiece={handleChangeFailPiece}
                handleShowModal={handleShowModal}
            />

            <SupportingDocumentErrorModal
                errorMessage={supportingDocument.errorMessage}
            />
        </div>
    );
};

export default PaymentContainer;
