import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    ActionType,
    isActionOf,
    createStandardAction,
} from 'typesafe-actions';
import { ReducerError, RootAction, RootState, Services, TError } from 'Types';
import {
    ADMIN_CLIENT_SPACE_REQUEST,
    ADMIN_CLIENT_SPACE_SUCCESS,
    ADMIN_CLIENT_SPACE_FAILURE,
    ADMIN_CLIENT_SPACE_RESET_RESPONSE,
} from './actionTypes';
import { TUser } from 'Models';
import { Epic } from 'redux-observable';

export type TUserPayload = Partial<{
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    group: string;
    userStatus: string;
}>;

interface IResponseClient {
    userList: TUser[];
}

const adminClientSpaceAsync = createAsyncAction(
    ADMIN_CLIENT_SPACE_REQUEST,
    ADMIN_CLIENT_SPACE_SUCCESS,
    ADMIN_CLIENT_SPACE_FAILURE,
    ADMIN_CLIENT_SPACE_RESET_RESPONSE,
)<TUserPayload, IResponseClient, ReducerError>();

const resetAdminClientSpaceResponse = createStandardAction(
    ADMIN_CLIENT_SPACE_RESET_RESPONSE,
)();

export type AdminClientSpaceAction =
    | ActionType<typeof adminClientSpaceAsync>
    | ActionType<typeof resetAdminClientSpaceResponse>;

const preparePayloadClientSpace = (payload: TUserPayload): TUserPayload => {
    return { ...payload };
};

const mapGetClientSpace = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadClientSpace(action.payload);
    return apiRequest<IResponseClient>({
        path: '/searchUserGroup',
        method: 'post',
        body: payload,
        isPay: false,
        isSubscription: false,
    }).pipe(
        mergeMap((response: any & TError) => {
            if (
                response.code === 'MISSING_PARAMETER' ||
                response.code === 'NO_RESPONSE_FROM_PARTNER'
            ) {
                return of(
                    adminClientSpaceAsync.failure({
                        code: response.code,
                        message: response.description,
                    }),
                );
            }
            return of(adminClientSpaceAsync.success(response));
        }),
        catchError(error => {
            return of(
                adminClientSpaceAsync.failure({
                    code: error.code,
                    message: error.description,
                }),
            );
        }),
    );
};

const adminClientSpaceEpic: Epic<
    RootAction,
    RootAction,
    RootState,
    Services
> = (action$, state$, dependency) =>
    action$.pipe(
        filter(isActionOf(adminClientSpaceAsync.request)),
        mergeMap(action => mapGetClientSpace(action, dependency)),
    );

export {
    adminClientSpaceAsync,
    adminClientSpaceEpic,
    mapGetClientSpace,
    resetAdminClientSpaceResponse,
};
