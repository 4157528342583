import { View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

import { Enums } from '../../../../../utils/wording.json';
import { useProof } from '../../lib';

import { Table, Header, Cell, DataCell, Body } from '../Table';

const styles = StyleSheet.create({
    header: {
        backgroundColor: '#ffb40a',
        padding: '5px 0',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 11,
    },

    body: {
        padding: '3px 10px',
    },

    rowStyle: {
        backgroundColor: '#D5D5D5',
        fontFamily: 'Montserrat',
        fontWeight: 800,
    },
});

const CustomTable = () => {
    const { piece }: any = useProof();

    const { amount, internal_invoice_nature_text } = piece;
    /*
    const tableAmount =
        piece.type === 'pp'
            ? parseFloat((piece as any).totalAmount) -
              parseFloat(piece.amountToPay as any)
            : piece.amountPaid;

    const process =
        processusList && processusList[0] && processusList[0].transactions
            ? processusList[0]
            : undefined;

    const mappedProcessNature =
        process && process.nature
            ? Enums.processNature.find(pr => pr.value === process.nature)
            : { label: '' };
*/
    const dataToDisplay = [
        {
            transactionName: internal_invoice_nature_text,
            amount: `${amount}€`,
        },

        {
            style: styles.rowStyle,

            transactionName: 'Total',
            amount: `${amount}€`,
        },
    ];
    return (
        <View>
            <Table data={dataToDisplay}>
                <Header textAlign="center" style={styles.header}>
                    <Cell>Type de transaction</Cell>
                    <Cell>Montant</Cell>
                </Header>
                <Body style={styles.body}>
                    <DataCell Cell={r => r.transactionName} />
                    <DataCell Cell={r => r.amount} textAlign="right" />
                </Body>
            </Table>
        </View>
    );
};

export default CustomTable;
