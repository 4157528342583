import React from 'react';

import ArticleVideoContainer from '../../containers/ArticleVideoContainer';
import PaymentStatusContainer from '../../containers/PaymentStatusContainer';
import { TimelineStatus } from '../../components';
import FailPieceContainer, {
    ContainerProps,
} from '../FailPieceContainer/FailPieceContainer';
import articles from '../../assets/articles.json';
import WordingConstant from '../../utils/wording.json';
import { TMeterRead, TContract, TAdhoc, TEPInvoice } from 'Models';
import { Graph } from '../ConsumptionContainer/ConsumptionContainer';
import {
    ECustomerType,
    EPAYInvoiceStatus,
    EPieceType,
} from '../../utils/enums';
import { controlPieces } from '../../utils/helpers/payment-functions';

const Wording = WordingConstant.HomePage;

export type Props = ContainerProps & {
    customerNumber: string;
    meters: TMeterRead[];
    contract: TContract;
    customerType: ECustomerType;
    pieces: TEPInvoice[];
};

const HomeContainer: React.FC<Props> = ({
    meters,
    contract,
    pieces,
    handleChangeFailPiece,
    handleShowModal,
    customerType,
    customerNumber,
}) => {
    const currentPieceForTimeline =
        pieces &&
        pieces.find(
            p =>
                p.internal_invoice_nature_code === 'instalment' &&
                p.status === 'reconcilied',
        );

    // const piecesToDisplay = controlPieces(pieces);

    return (
        <div className="home-container">
            <h2 className="home-container__subTitle">{Wording.subTitle}</h2>

            <TimelineStatus
                contract={contract}
                customerType={customerType}
                piece={currentPieceForTimeline as TEPInvoice | undefined}
            />

            <PaymentStatusContainer pieces={pieces} />

            <FailPieceContainer
                customerNumber={customerNumber}
                pieces={pieces}
                contract={contract}
                handleChangeFailPiece={handleChangeFailPiece}
                handleShowModal={handleShowModal}
            />

            <Graph
                contract={contract}
                meters={meters}
                showProgressBar={false}
            />

            <h2>{Wording.articleVideoText}</h2>
            <ArticleVideoContainer
                articles={articles.articles}
                videos={articles.videos}
            />
        </div>
    );
};

export default HomeContainer;
