import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '../../components';
import { resetSupportingDocumentError } from '../../_actions/payment.actions';

type Props = {
    errorMessage?: string;
};

const SupportingDocumentErrorModal: FC<Props> = ({ errorMessage }) => {
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        dispatch(resetSupportingDocumentError());
    }, []);

    return (
        <Modal
            state={1}
            show={errorMessage !== undefined}
            title={'Votre justificatif n’est pas disponible'}
            description={errorMessage || ''}
            showCloseButton
            handleCancel={handleClose}
        />
    );
};

export default SupportingDocumentErrorModal;
