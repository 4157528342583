import React from 'react';
import { CustomModal } from '../../components';
import WordingConstant from '../../utils/wording.json';
import resources from '../../utils/resources';

const Wording = WordingConstant.ConsumptionPage.SelfWithdrawalModal;

type Props = {
    energy: 'EL' | 'NG';
    open: boolean;
    handleModal: () => void;
};

const SelfWithdrawalModal: React.FC<Props> = ({
    open,
    handleModal,
    energy,
}) => {
    const WordingCounters = Wording.counters[energy];
    const WordingData = Wording.data[energy];
    const WordingBottom = Wording.bottom[energy];

    const imgSrc =
        energy === 'EL'
            ? resources['ic-elec-counters']
            : resources['ic-gas-counters'];

    const span = (i: number) => (
        <span className={i === 0 ? 'first-span' : ''}>{i + 1}.</span>
    );

    if (!open) {
        return null;
    }
    return (
        <CustomModal
            className="self-withdrawal-modal"
            show={open}
            showCloseButton
            handleClick={handleModal}
        >
            <>
                <h3 className="self-withdrawal-modal__title">
                    {Wording.titles[energy]}
                </h3>

                <div className="self-withdrawal-modal__counters">
                    <p>{WordingCounters.title}</p>

                    <ul>
                        {WordingCounters.list.map((p, i) => (
                            <li key={i}>
                                {span(i)}
                                {p}
                            </li>
                        ))}
                    </ul>

                    <img src={imgSrc} alt="counters" />
                </div>

                <div className="self-withdrawal-modal__data">
                    <ul>
                        {WordingData.map(({ title, list }, iMain) => (
                            <li key={iMain}>
                                <p>
                                    {span(iMain)} {title}
                                </p>
                                <ul>
                                    {list.map((t, idx) => (
                                        <li key={idx}>{t}</li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="self-withdrawal-modal__bottom">
                    <p>
                        {WordingBottom.main}{' '}
                        <a href={WordingBottom.link}>
                            {WordingBottom.linkText}
                        </a>{' '}
                        {WordingBottom.end}
                    </p>
                </div>
            </>
        </CustomModal>
    );
};

export default SelfWithdrawalModal;
