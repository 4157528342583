import React, { Component, ChangeEvent } from 'react';
import { RootState, TSchemaProps } from 'Types';
import { connect } from 'react-redux';
import SubComponent from './SubComponent';
import {
    BackgroundElement,
    CustomButton,
    CustomModal,
} from '../../../components';
import CustomTable from '../../../components/admin/CustomTable';
import {
    isEmpty,
    getHost,
    isHaveActionsPermission,
} from '../../../utils/helpers';
import WordingConstant from '../../../utils/wording.json';
import resources from '../../../utils/resources';
import ResetPasswordButton from './ResetPasswordButton';
import ReinitSubscriptionEmailButton from './ReinitSubscriptionEmailButton';
import {
    resetPasswordAsync,
    removeError,
    reinitSubscriptionEmailAsync,
    removeReinitSubscriptionMessage,
} from '../../../_actions/auth.actions';
import AdministrationClientSpace from './AdministrationClientSpace';
import { TUserPayload } from '../../../_actions/searchUserGroup.actions';
import { TUser, TResponse } from 'Models';
import {
    transferGetContractAsync,
    resetResponse,
    transferContractAsync,
    resetResponseTransfer,
} from '../../../_actions/transferContract.actions';
import { resetGroupListResponse } from '../../../_actions/getGroup.actions';
import {
    adminClientSpaceAsync,
    resetAdminClientSpaceResponse,
} from '../../../_actions/administrationClientSpace.actions';
import { resetManageUserResponse } from '../../../_actions/manageUser.actions';
import { EResponseCode, ECustomerStatus } from '../../../utils/enums';
import { resetCreateUserResponse } from '../../../_actions/createUser.actions';

import _ from 'lodash';
import ErrorElement from '../../../components/ErrorElement';
import OutsideClickClass from '../../../libraries/OutsideClickClass';
import { withTriangle } from '../../../components/admin/CustomTable/CustomTable';

import {
    TCustomer,
    TAddress,
    TCustomerIndividual,
} from '../../../constants/key-system';
import MessageCollapse from '../../../components/admin/MessageCollapse';

const Wording = WordingConstant.CustomerContractContainer;

interface IPayloadUser {
    group: string;
    firstName: string;
    lastName: string;
    email: string;
    civility: string;
}

const headers = [
    { label: 'N°client', key: 'id' },
    { label: 'Nom de société', key: 'company.companyName' },
    { label: 'Nom', key: 'individual.lastName' },
    { label: 'Prénom', key: 'individual.firstName' },
    { label: 'Adresse', key: 'address.street' },
    { label: 'N° Téléphone', key: 'contact.phone1' },
    { label: 'Email', key: 'contact.email' },
    { label: 'N° Contrat', key: 'contracts[0].contractNumber' },
    { label: 'Energie', key: 'contracts[0].energy' },
    { label: 'Date de début', key: 'contracts[0].effectiveStartDate' },
    { label: 'status', key: 'contracts[0].status' },
];

const mapStateToProps = (state: RootState) => ({
    customerContractState: state.customersContractReducer,
    authState: state.authReducer,
    userList: state.userGroupReducer.userList,
    transferState: state.transferContractReducer,
    administrationList: state.userGroupReducer.administrationList,
    administrationError: state.userGroupReducer.error as string,
    manageUserState: state.userGroupReducer.manageUserResponse,
    orders: state.orderReducer.searchClient,
});

const dispatchProps = {
    resetPasswordDispatch: resetPasswordAsync.request,
    removeErrorDispatch: removeError,
    reinitSubscriptionEmailDispatch: reinitSubscriptionEmailAsync.request,
    removeReinitSubscriptionMessageDispatch: removeReinitSubscriptionMessage,
    transferGetContractDispatch: transferGetContractAsync.request,
    transferContractDispatch: transferContractAsync.request,
    resetResponse,
    resetResponseTransfer,
    resetGroupListResponse,
    adminClientSpaceAsyncDispatch: adminClientSpaceAsync.request,
    resetAdminClientSpaceResponse,
    resetManageUserResponse,
    resetCreateUserResponse,
};

type Props = {
    searchUserGroup: (props: TUserPayload) => void;
    manageUser: (props: IPayloadManageUser) => void;
    handleReset?: () => void;
    userList: TUser[];
    groupList: string[];
    createUser: (props: IPayloadUser) => void;
    createUserResponse: TResponse;
    resetCreateUserResponse: () => void;
    resetGroupResponse: () => void;
    resetGroupListResponse: () => void;
    adminClientSpaceAsyncDispatch: (props: TUserPayload) => void;
    administrationList: TUser[];
    resetAdminClientSpaceResponse: () => void;
    administrationError: string;
    schemaProps: TSchemaProps;
} & ReturnType<typeof mapStateToProps> &
    typeof dispatchProps;

type State = {
    showDropdown: boolean;
    currentItem: number;
    currentEmail: string;
    idClient: string;
    currentUser: any;
    subscriptionSendEmail: string;
};

interface IPayloadManageUser {
    mode: string;
    firstName: string;
    lastName: string;
    userName: string;
}

class CustomerContractContainer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDropdown: false,
            currentItem: -1,
            currentEmail: '',
            idClient: '',
            currentUser: '',
            subscriptionSendEmail: '',
        };
        this.openClient = this.openClient.bind(this);
    }

    componentDidMount() {
        window.onscroll = () => onScroll();

        const header = document.getElementsByClassName('rt-thead')[0];

        const onScroll = () => {
            if (window.pageYOffset > 280) {
                if (header) header.classList.add('sticky');
            } else {
                if (header) header.classList.remove('sticky');
            }
        };
    }

    prepareColumns = () => {
        const { schemaProps } = this.props;
        return [
            {
                Header: withTriangle(Wording.customerId),
                accessor: 'id',
                filterable: false,
                width: 150,
            },
            {
                Header: withTriangle(Wording.companyName),
                accessor: 'company.companyName',
                filterable: false,
            },
            {
                Header: withTriangle(Wording.clientFirstName),
                accessor: 'individual',
                filterable: false,
                Cell: ({ value }: any) => {
                    if (value) {
                        const {
                            firstName,
                            lastName,
                        } = value as TCustomerIndividual;
                        return (
                            <MessageCollapse
                                message={`${firstName} ${lastName}`}
                                initialLength={17}
                            />
                        );
                    }
                    return '';
                },
            },
            {
                Header: withTriangle(Wording.address),
                accessor: 'address',
                filterable: false,
                Cell: ({ value }: any) => {
                    if (value) {
                        const {
                            // tslint:disable-next-line:variable-name
                            number,
                            street,
                            city,
                        } = value as TAddress;

                        return (
                            <MessageCollapse
                                message={`${number} ${street} ${city}`}
                                initialLength={19}
                            />
                        );
                    }
                    return '';
                },
            },
            {
                Header: withTriangle(Wording.invoiceId),
                accessor: 'contact.phone1',
                filterable: false,
            },
            {
                Header: withTriangle(Wording.email),
                accessor: 'contact.email',
                filterable: false,
            },
            {
                Header: Wording.see_customer,
                accessor: 'voir client',
                filterable: false,
                show: isHaveActionsPermission(schemaProps),
                width: 95,
                Cell: ({ original }: any) => (
                    <CustomButton
                        color="black"
                        title={Wording.see_customer}
                        onClick={() => this.openClient(original)}
                    />
                ),
            },
            {
                Header: '',
                accessor: '',
                filterable: false,
                show: isHaveActionsPermission(schemaProps),
                width: 40,
                Cell: (original: any) => {
                    return (
                        <div>
                            <img
                                className="menu-style"
                                src={resources['ic-menu-orange']}
                                alt="ic-menu"
                                onClick={() => {
                                    this.setCurrentEmail(
                                        _.get(
                                            original.original,
                                            'contact.email',
                                            '',
                                        ),
                                    );
                                    this.handleButtonDropdown(
                                        original.viewIndex,
                                        original,
                                    );
                                }}
                            />
                            {this.state.showDropdown &&
                                this.state.currentItem ===
                                    original.viewIndex && (
                                    <OutsideClickClass
                                        callback={() =>
                                            this.setState({
                                                showDropdown: false,
                                            })
                                        }
                                    >
                                        <div className="pop-up">
                                            <ResetPasswordButton
                                                email={_.get(
                                                    original.original,
                                                    'contact.email',
                                                    '',
                                                )}
                                                resetPasswordDispatch={
                                                    this.props
                                                        .resetPasswordDispatch
                                                }
                                            />

                                            <ReinitSubscriptionEmailButton
                                                customerNbr={_.get(
                                                    original,
                                                    'original.id',
                                                    '',
                                                )}
                                                defaultEmail={_.get(
                                                    original.original,
                                                    'contact.email',
                                                    '',
                                                )}
                                                handleSubscriptionSendEmail={
                                                    this
                                                        .handleSubscriptionSendEmail
                                                }
                                                reinitSubscriptionEmailDispatch={
                                                    this.props
                                                        .reinitSubscriptionEmailDispatch
                                                }
                                            />
                                            <p
                                                onClick={() => {
                                                    this.props.adminClientSpaceAsyncDispatch(
                                                        {
                                                            userName: _.get(
                                                                original,
                                                                'original.id',
                                                                '',
                                                            ),
                                                            email: '',
                                                            firstName: '',
                                                            lastName: '',
                                                            group: '',
                                                            userStatus: '',
                                                        },
                                                    );
                                                }}
                                            >
                                                {Wording.admin_client_space}
                                            </p>
                                        </div>
                                    </OutsideClickClass>
                                )}
                        </div>
                    );
                },
            },
        ];
    };

    setCurrentEmail = (email: string) => {
        this.setState({ currentEmail: email });
    };

    openClient(original: { id: string } | string) {
        let clientId: string = '';
        if (typeof original === 'string') {
            clientId = original;
        } else {
            clientId = _.get(original, 'id', '');
        }
        const queryParams = new Map([['client', clientId]]);
        window.open(getHost(queryParams));
    }

    handleSubscriptionSendEmail = (subscriptionSendEmail: string) => {
        this.setState({ subscriptionSendEmail });
    };
    handleIdClientInput = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({ idClient: e.target.value });
    };

    renderResetPasswordModal = () => {
        const {
            authState: { error },
        } = this.props;
        if (error !== undefined && error !== Wording.no_error && error !== '') {
            return (
                <CustomModal
                    show={error !== undefined && error !== Wording.no_error}
                >
                    <span
                        className="btn-close"
                        onClick={() => {
                            this.props.removeErrorDispatch();
                        }}
                    >
                        {'X'}
                    </span>

                    <div className="reset-modal">
                        <p className="warning-title">{Wording.error_message}</p>
                        <p className="message">{Wording.try_again_message}</p>
                        <div className="reset-modal__buttons">
                            <CustomButton
                                onClick={() => {
                                    this.props.removeErrorDispatch();
                                }}
                                color="orange"
                                title="Retour"
                            />
                            <CustomButton
                                color="gray"
                                title="Réessayer"
                                onClick={() => {
                                    this.props.resetPasswordDispatch({
                                        email: this.state.currentEmail,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </CustomModal>
            );
        }

        if (error === '') {
            return (
                <CustomModal show={error === ''}>
                    <span
                        className="btn-close"
                        onClick={() => {
                            this.props.removeErrorDispatch();
                        }}
                    >
                        {'X'}
                    </span>
                    <div className="reset-modal">
                        <div className="warning-title">
                            {'Réinitialisation de mot de passe'}
                        </div>
                        <span>
                            <p>{Wording.success_message}</p>
                        </span>

                        <div className="reset-modal__buttons">
                            <CustomButton
                                onClick={() => {
                                    this.props.removeErrorDispatch();
                                }}
                                color="orange"
                                title="Fermer"
                            />
                        </div>
                    </div>
                </CustomModal>
            );
        }
    };

    renderReinitSubscriptionEmailModal = () => {
        const {
            authState: { reinit, error },
        } = this.props;

        if (error === Wording.error_code) {
            return (
                <CustomModal show={error === Wording.error_code}>
                    <div className="reinit-modal">
                        <p className="success_message">{error}</p>
                        <div className="reinit-modal__buttons">
                            <CustomButton
                                onClick={() => {
                                    this.props.removeErrorDispatch();
                                }}
                                color="orange"
                                title="Fermer"
                            />
                        </div>
                    </div>
                </CustomModal>
            );
        }
        if (reinit === Wording.success_code) {
            return (
                <CustomModal show={reinit === Wording.success_code}>
                    <span
                        className="btn-close"
                        onClick={() => {
                            this.props.removeReinitSubscriptionMessageDispatch();
                        }}
                    >
                        {'X'}
                    </span>

                    <div className="reinit-modal">
                        <div className="warning-title">
                            {'Renvoyer un mail de bienvenue'}
                        </div>
                        <p className="success_message">
                            {Wording.reinit_success_message}
                        </p>
                        <p className="email">
                            {this.state.subscriptionSendEmail}
                        </p>
                        <p className="advice">{Wording.advice_message}</p>
                        <div className="reinit-modal__buttons">
                            <CustomButton
                                onClick={() => {
                                    this.props.removeReinitSubscriptionMessageDispatch();
                                }}
                                color="orange"
                                title="Fermer"
                            />
                        </div>
                    </div>
                </CustomModal>
            );
        }
    };

    handleButtonDropdown = (original: number, currentUser: any) => {
        this.setState({
            showDropdown: !this.state.showDropdown,
            currentItem: original,
            currentUser,
        });
    };

    enableOrDessableUserResponseModal = (code: string, message: string) => {
        return (
            <CustomModal
                show={code && message !== Wording.errorMessage ? true : false}
            >
                <span
                    className="btn-close"
                    onClick={() => {
                        this.props.resetManageUserResponse();
                    }}
                >
                    {'X'}
                </span>
                <div className="enable-user-modal-response">
                    <p className="code">
                        {code === EResponseCode.SUCCESS
                            ? Wording.succesMessage
                            : Wording.failureMessage}
                    </p>

                    <p className="message">{message}</p>

                    <div className="search-rs-vl-btn">
                        <CustomButton
                            title={'Fermer'}
                            color={'orange'}
                            onClick={() => {
                                this.props.resetManageUserResponse();
                            }}
                        />
                    </div>
                </div>
            </CustomModal>
        );
    };

    downloadDataTable = (allCustomerContract: any[]) => {
        const { schemaProps } = this.props;

        return isHaveActionsPermission(schemaProps)
            ? {
                  downloadData: allCustomerContract,
                  downloadHeaders: headers,
                  downloadButtonTitle: Wording.download_csv,
              }
            : {};
    };

    render() {
        const {
            orders,
            transferState: { transferContract, transferContractResponse },
            transferContractDispatch,
            authState: { userId },
            resetResponseTransfer,
            schemaProps,
        } = this.props;
        const { code, message } = this.props.manageUserState;

        const customers: TCustomer[] = [
            ...orders.order.map(it => {
                const {
                    order: { customer },
                } = it;
                return {
                    address: {
                        box: '',
                        city: customer.contact.address.townName || '',
                        countryIso: '',
                        number: customer.contact.address.number || '',
                        postalCode: Number(
                            customer.contact.address.postalCode,
                        ).toString(),
                        street: customer.contact.address.street,
                    },
                    company: {
                        companyName: customer.contact.companyName,
                        id: '',
                        identificationNumber: '',
                        legalForme: '',
                        naceCode: '',
                        vatable: '',
                        vatNumber: '',
                    },
                    contact: {
                        email: customer.contact.email,
                        fax: '',
                        id: customer.customerNumber,
                        phone1: customer.contact.phone,
                        phone2: '',
                        phone3: '',
                    },
                    customerCategory: '',
                    customerType: customer.type,
                    id: customer.customerNumber,
                    individual: {
                        civility: customer.contact.civility,
                        dateOfBirth: customer.contact.birthdate,
                        firstName: customer.contact.firstName,
                        id: customer.customerNumber,
                        lastName: customer.contact.lastName,
                        placeOfBirth: customer.contact.birthTown
                            ? {
                                  box: '',
                                  city: customer.contact.birthTown.townName,
                                  countryIso: '',
                                  number: customer.contact.birthTown.number,
                                  postalCode:
                                      customer.contact.birthTown.postalCode,
                                  street: customer.contact.birthTown.street,
                              }
                            : undefined,
                    },
                    language: '',
                    perimeters: [],
                    status: ECustomerStatus.CUSTOMER, // TODO not fetched data by order.
                    contracts: it.order.contracts.map(itr => ({
                        ...itr,
                        status: it.order.orderStatus,
                    })),
                } as TCustomer;
            }),
        ];

        return (
            <div className="customer-contract-container">
                {this.renderResetPasswordModal()}

                <ErrorElement error={orders.message} />

                {this.renderReinitSubscriptionEmailModal()}
                {this.enableOrDessableUserResponseModal(code, message)}

                <AdministrationClientSpace
                    groupList={this.props.groupList}
                    schemaProps={schemaProps}
                    createUser={this.props.createUser}
                    createUserResponse={this.props.createUserResponse}
                    resetCreateUserResponse={this.props.resetCreateUserResponse}
                    resetGroupListResponse={this.props.resetGroupListResponse}
                    manageUser={this.props.manageUser}
                    administrationList={this.props.administrationList}
                    resetAdminClientSpaceResponse={
                        this.props.resetAdminClientSpaceResponse
                    }
                    error={this.props.administrationError}
                    currentUser={this.state.currentUser}
                    manageUserResponse={this.props.manageUserState}
                    resetManageUserResponse={this.props.resetManageUserResponse}
                />

                {isEmpty(customers) && (
                    <BackgroundElement>
                        <CustomTable
                            columns={this.prepareColumns()}
                            data={customers}
                            className="-striped -highlight"
                            showPagination={true}
                            filterable={false}
                            SubComponent={({ original, index }: any) => {
                                return (
                                    <SubComponent
                                        customer={original as TCustomer}
                                        index={index}
                                        transferError={
                                            this.props.transferState.error
                                        }
                                        schemaProps={schemaProps}
                                        transferCustomerContract={
                                            this.props
                                                .transferGetContractDispatch
                                        }
                                        transferContract={transferContract}
                                        error={this.props.transferState.error!!}
                                        resetResponse={this.props.resetResponse}
                                        transferContractDispatch={
                                            transferContractDispatch
                                        }
                                        userId={userId || ''}
                                        transferContractResponse={
                                            transferContractResponse
                                        }
                                        resetResponseTransfer={
                                            resetResponseTransfer
                                        }
                                    />
                                );
                            }}
                            {...this.downloadDataTable(customers)}
                        />
                    </BackgroundElement>
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    dispatchProps,
)(CustomerContractContainer);
