import React from 'react';
import { TContract, TContact } from 'Models';
import { ECustomerType } from '../utils/enums';
import { Banner, Modal } from '../components';

import WordingConstant from '../utils/wording.json';

const CommonWording = WordingConstant.Common;

export type ExternalProps = {
    title?: string;
    bannerError?: string;
    contracts: TContract[];
    contact: TContact;
    customerType: ECustomerType;
    selectedContractNumber: string;
    handleChangeBanner: (contractNumber: string) => void;
    handleCloseModalBanner?: () => void;
};

export type InjectedProps = {
    clickCount?: number;
};

type Options = {
    debug?: boolean;
};

const withBanner = ({ debug = false }: Options = {}) => <OwnProps extends {}>(
    WrappedComponent: React.ComponentType<OwnProps & InjectedProps>,
) => {
    type HocProps = OwnProps & ExternalProps;

    return class extends React.Component<HocProps, {}> {
        handleChange = (contractNumber: string) => {
            const { handleChangeBanner } = this.props;
            handleChangeBanner(contractNumber);
        };

        render() {
            const {
                contracts,
                contact,
                customerType,
                title,
                selectedContractNumber,
                handleCloseModalBanner,
                bannerError,
            } = this.props;
            const contract =
                contracts.find(
                    (c: TContract) =>
                        c.contractNumber === selectedContractNumber,
                ) || contracts[0];
            return (
                <div className="banner-container">
                    {title && (
                        <h1 className="banner-container__title">{title}</h1>
                    )}

                    <Modal
                        show={bannerError !== undefined}
                        title={CommonWording.contractError.title}
                        description={CommonWording.contractError.description}
                        state={1}
                        handleCancel={handleCloseModalBanner}
                    />

                    <Banner
                        currentContract={contract}
                        contracts={contracts}
                        contact={contact}
                        customerType={customerType}
                        handleChange={this.handleChange}
                    />
                    <WrappedComponent {...this.props} />
                </div>
            );
        }
    };
};

export default withBanner;
