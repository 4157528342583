import { Epic } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, isActionOf, ActionType } from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';
import { TFile, TResponse } from 'Models';

import {
    FETCH_LIST_FILES_REQUEST,
    FETCH_LIST_FILES_SUCCESS,
    FETCH_LIST_FILES_FAILURE,
} from './actionTypes';
import isArray from 'lodash/isArray';

interface IFetchFileListRequest {
    customerNbr: string;
    contractNbr: string;
    mode: string;
}

const fetchFilesListAsync = createAsyncAction(
    FETCH_LIST_FILES_REQUEST,
    FETCH_LIST_FILES_SUCCESS,
    FETCH_LIST_FILES_FAILURE,
)<IFetchFileListRequest, TFile, TResponse>();

export type FileActions = ActionType<typeof fetchFilesListAsync>;

const preparePayloadListFile = ({
    contractNbr,
    customerNbr,
    mode = 'DOCUMENT',
}: IFetchFileListRequest) => {
    return {
        contractNbr,
        customerNbr,
        mode,
    };
};

const mapGetFiles = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadListFile(action.payload);
    return apiRequest<TFile>({
        path: '/getDocumentsList',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: TFile) => {
            if (response) {
                return of(fetchFilesListAsync.success(response));
            }
            return throwError({ code: '200', message: response });
        }),
        catchError(error => of(fetchFilesListAsync.failure(error))),
    );
};

const fetchFileEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchFilesListAsync.request)),
        mergeMap(action => mapGetFiles(action, dependency)),
    );

export { fetchFilesListAsync, mapGetFiles, fetchFileEpic };
