import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { TContact } from 'Models';
import { ECivility, ECustomerType } from '../utils/enums';

import * as actions from '../_actions/profile.actions';
import { initialCustomerFullName } from '../utils/initialState';

export type ProfileState = Readonly<{
    error?: string;
    customerType: ECustomerType;
    customerNumber: string;
    customerCategoryCode: string;
    contact: TContact;
    updateResponse: {
        code: string;
        message: string;
    };
    checkUpdateProfile: {
        checkUpdateContact: boolean;
        checkUpdateBilling: boolean;
        checkUpdateIban: boolean;
    };
}>;

export const initialState = {
    error: undefined,
    customerType: ECustomerType.INDIVIDUAL,
    customerNumber: '',
    customerCategoryCode: '',
    contact: {
        marketing: false,
        newsletter: false,
        customerFullName: {
            civility: ECivility.MR,
            firstName: '',
            lastName: '',
        },
        birthdate: '',
        address: {
            number: '',
            street: '',
            netArea: '',
            postalCode: '',
            townName: '',
            country: 'FR',
        },
        email: '',
        phone: '',
        companyName: '',
        companyType: '',
        identificationNumber: '',
        activityCode: '',
    },
    updateResponse: {
        code: '',
        message: '',
    },
    checkUpdateProfile: {
        checkUpdateContact: false,
        checkUpdateBilling: false,
        checkUpdateIban: false,
    },
};

type Actions = actions.ProfileAction;

const profileReducer: Reducer<ProfileState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchContextAsync.request): {
            return {
                ...state,
                updateResponse: {
                    code: '',
                    message: '',
                },
            };
        }

        case getType(actions.fetchContextAsync.failure): {
            return {
                ...state,
                updateResponse: {
                    code: action.payload.code,
                    message:
                        action.payload.message ||
                        action.payload.description ||
                        '',
                },
            };
        }

        case getType(actions.fetchContextAsync.success): {
            const {
                customerNumber,
                customerCategoryCode,
                type,
                contact: {
                    customerFullName,
                    address,
                    email,
                    phone,
                    newsletter,
                    marketing,
                    activityCode,
                    birthdate,
                    companyName,
                    companyType,
                    identificationNumber,
                },
            } = action.payload.customer;

            return {
                ...state,
                customerNumber,
                customerCategoryCode,
                customerType: type,
                contact: {
                    ...state.contact,
                    email,
                    phone,
                    newsletter: newsletter || false,
                    marketing: marketing || false,
                    activityCode,
                    birthdate,
                    companyName,
                    companyType,
                    identificationNumber,
                    customerFullName: (customerFullName && {
                        civility: customerFullName.civility,
                        firstName: customerFullName.firstName,
                        lastName: customerFullName.lastName,
                    }) || { ...initialCustomerFullName },
                    address: {
                        number:
                            address.number ||
                            state.contact.address.number ||
                            '',
                        street: address.street || '',
                        netArea:
                            address.netArea ||
                            state.contact.address.netArea ||
                            '',
                        postalCode: address.postalCode || '',
                        townName: address.townName || '',
                        country:
                            address.country || state.contact.address.country,
                    },
                },
            };
        }

        case getType(actions.updateCustomerAsync.request):
            return {
                ...state,
            };

        case getType(actions.updateCustomerAsync.success):
            return {
                ...state,
                updateResponse: {
                    code: '200',
                    message: 'success',
                },
                contact: {
                    ...state.contact,
                    email: action.payload.email || state.contact.email,
                    phone: action.payload.phone || state.contact.phone,
                    newsletter:
                        action.payload.newsletter || state.contact.newsletter,
                    marketing:
                        action.payload.marketing || state.contact.marketing,
                },
            };

        case getType(actions.resetUpdate):
            return {
                ...state,
                updateResponse: {
                    code: '',
                    message: '',
                },
            };

        case getType(actions.updateBillingAsync.request):
            return {
                ...state,
            };

        case getType(actions.updateCustomerAsync.failure):
        case getType(actions.updateBillingAsync.failure):
        case getType(actions.updatePasswordAsync.failure):
        case getType(actions.createSponsorAsync.failure): {
            console.log('failure detected in profile reducer');
            return {
                ...state,
                updateResponse: {
                    code: '500',
                    message: 'failure',
                },
            };
        }

        case getType(actions.updateBillingAsync.success):
        case getType(actions.updatePasswordAsync.success):
        case getType(actions.createSponsorAsync.success): {
            return {
                ...state,
                updateResponse: {
                    code: '200',
                    message: 'success',
                },
            };
        }

        default:
            return state;
    }
};

export default profileReducer;
