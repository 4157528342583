import React from 'react';

import { View, StyleSheet, Text } from '@react-pdf/renderer';
import Wording from '../../wording.json';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    primary: {
        fontWeight: 800,
        fontSize: 30,
        color: '#000000',
        fontFamily: 'Montserrat',
    },
    secondary: {
        fontWeight: 800,
        fontSize: 10,
        color: '#5e5e5e',
        fontFamily: 'Montserrat',
    },
});

export default () => {
    const {
        titles: { main, sub_title },
    } = Wording;

    return (
        <View style={styles.container}>
            <Text style={styles.primary}>{main}</Text>
            <Text style={styles.secondary}>{sub_title}</Text>
        </View>
    );
};
