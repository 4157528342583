import React from 'react';
import DateContainer from './DateContainer';
import { Modifier } from 'react-day-picker';
import { EEffectiveStartRanges, ETimeslots } from '../../utils/network/types';
import { SelectOption } from 'Types';

type DateContractEnergy = {
    date: number;
    month: number;
    year: number;
    timeslot: ETimeslots[] | null;
};

type DateContract = {
    EL?: DateContractEnergy | Date;
    NG?: DateContractEnergy | Date;
};

type Energy = 'EL' | 'NG';

type Status = 'disabled' | 'selected';

/* OVERVIEW
    dateContract:
        Selected dates + timeslots
    energyTypes:
        ['EL' and /or 'NG']
        Selected energy types
    handleChangeTimeslotSelect:
        Timeslot selection handler
    handleDateCalendarOption: (energy: Energy, status: Status) => Modifier;
        Callback used to determine which days should be enabled/disabled in the calendar
    handleDatePicker:
        Date selection callback for selected energy types
    handleDropdownContract: any;
        Selection handler (in case situtation === 'MOVE_IN')
    situation:
        'MOVE_IN' or 'SWITCH'
        Used to switch between layout (select / calendar)
*/

interface IProps {
    channel: string;
    dateContract: DateContract;
    dropdownContractOptions: Array<SelectOption<EEffectiveStartRanges>>;
    dropdownContractOptionSelection: SelectOption<EEffectiveStartRanges> | null;
    energyTypes: Energy[];
    handleChangeTimeslotSelect: (
        opt: SelectOption<any>,
        energy: Energy,
    ) => void;
    handleDateCalendarOption: (energy: Energy, status: Status) => Modifier[];
    handleDatePicker: (d: Date, energy: Energy) => void;
    handleDropdownContract: (
        startTime: SelectOption<EEffectiveStartRanges>,
    ) => void;
    situation: string;
    isCyclicalProductElec: boolean;
    isCyclicalProductGas: boolean;
}

const CustomDatePicker: React.FC<IProps> = (props: IProps) => {
    const isSwitch = props.situation === 'SWITCH';
    const switchType = props.dropdownContractOptionSelection
        ? props.dropdownContractOptionSelection.value
        : undefined;

    const nextProps = { ...props, isSwitch, switchType };

    return <DateContainer {...nextProps} />;
};

export default CustomDatePicker;
