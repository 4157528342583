import { of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import { createAsyncAction, ActionType, isActionOf } from 'typesafe-actions';
import { ReducerError, RootAction, RootState, Services, TError } from 'Types';
import {
    CREATE_PASSWORD_FAILURE,
    CREATE_PASSWORD_SUCCESS,
    CREATE_PASSWORD_REQUEST,
} from './actionTypes';
import { TResponse } from 'Models';
import { Epic } from 'redux-observable';
import { CreatePasswordMode } from '../utils/enums';

export type TCreatePasswordPayload = Partial<{
    customerNbr: string;
    temporaryPassword: string;
    newPassword: string;
    mode: CreatePasswordMode;
}>;

const createPasswordAsync = createAsyncAction(
    CREATE_PASSWORD_FAILURE,
    CREATE_PASSWORD_SUCCESS,
    CREATE_PASSWORD_REQUEST,
)<TCreatePasswordPayload, TResponse, ReducerError>();

export type CreatePasswordAction = ActionType<typeof createPasswordAsync>;

const preparePayloadCreatePssword = (
    payload: TCreatePasswordPayload,
): TCreatePasswordPayload => {
    return { ...payload };
};

const mapCreatePassword = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadCreatePssword(action.payload);
    return apiRequest<TResponse>({
        path: '/resetPassword',
        method: 'post',
        body: payload,
        isPay: false,
        isSubscription: false,
    }).pipe(
        mergeMap((response: TResponse | TError) => {
            if (
                response.code === 'MISSING_PARAMETER' ||
                response.code === 'UNEXPECTED_ERROR' ||
                response.code === 'NO_RESPONSE_FROM_PARTNER'
            ) {
                return of(
                    createPasswordAsync.failure({
                        code: response.code,
                        message: response.message,
                    }),
                );
            }
            return of(createPasswordAsync.success(response as TResponse));
        }),
        catchError(error => {
            return of(
                createPasswordAsync.failure({
                    code: error.code,
                    message: error.description,
                }),
            );
        }),
    );
};

const createPasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(createPasswordAsync.request)),
        mergeMap(action => mapCreatePassword(action, dependency)),
    );

export { createPasswordAsync, createPasswordEpic, mapCreatePassword };
