import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { RootAction, RootState, Services } from 'Types';
import rootEpic from './root-epic';
import rootReducer from './root-reducer';
import services from '../services';

import { initialState } from '../_reducers/paymentCB.reducer';

const getUserId = () => {
    try {
        return sessionStorage.getItem('currentClient');
    } catch (err) {
        return null;
    }
};

const prepareState = (state: RootState): RootState => ({
    ...state,
    paymentCBReducer: { ...initialState },
});

const loadState = () => {
    try {
        const userId = getUserId();
        let serializedState;
        if (userId) {
            serializedState = sessionStorage.getItem(userId);
        } else {
            serializedState = localStorage.getItem('reduxState');
        }
        if (!serializedState) {
            return {};
        }

        return JSON.parse(serializedState);
    } catch (err) {
        console.log('Unable to find state');
        return {};
    }
};

const saveState = (state: any) => {
    try {
        const userId = getUserId();
        const serializedState = JSON.stringify(state);
        if (userId) {
            sessionStorage.setItem(userId, serializedState);
        } else {
            localStorage.setItem('reduxState', serializedState);
        }
    } catch (err) {
        console.log('Unable to set state');
    }
};

const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    Services
>({
    dependencies: services,
});

const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const persistedState = prepareState(loadState());

const middlewares = [epicMiddleware];

const reducer = (state: any, action: RootAction) => {
    if (action.type === 'REDUX_RESET_STATE') {
        delete state.authReducer;
        delete state.profileReducer;
        delete state.contractReducer;
        delete state.packageReducer;
        delete state.fileReducer;
        delete state.orderReducer;
        delete state.eventReducer;
        delete state.consumptionReducer;
        delete state.userGroupReducer;
        delete state.customersContractReducer;
        delete state.paymentReducer;
        delete state.transferContractReducer;
    }

    return rootReducer(state, action);
};

const store = createStore(
    reducer,
    persistedState,
    // initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
);

store.subscribe(() => {
    saveState(store.getState());
});

epicMiddleware.run(rootEpic);

export default store;
