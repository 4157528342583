import React from 'react';

import { View, StyleSheet } from '@react-pdf/renderer';

import Wording from '../../wording.json';

import TextValue from './TextValue';
import { useProof } from '../../lib';
import { getFormattedDate } from '../../../../../utils/helpers';

const styles = StyleSheet.create({
    container: {
        padding: '10px 0px',
    },
});

const {
    sections: {
        summary: {
            transactionDate,
            paymentMode,
            paymentRef,
            paymentModeMapping,
        },
    },
} = Wording;

const Summary = () => {
    const {
        piece: { payment_method_name: piecePaymentMode, date: paymentDate, id },
    } = useProof();

    // const transactionDueDate =
    //    processusList && processusList[0] && processusList[0].transactions
    //       ? processusList[0].transactions[0].dueDate
    //        : undefined;

    const findPaymentMode = paymentModeMapping.find(
        pm => pm.value === piecePaymentMode,
    ) || { label: '' };
    return (
        <View style={styles.container}>
            <TextValue title={transactionDate} value={paymentDate || ''} />
            <TextValue title={paymentMode} value={piecePaymentMode} />
            <TextValue title={paymentRef} value={id} />
        </View>
    );
};

export default Summary;
