import React, { FC } from 'react';
import { buildClassName as bcn } from '../../utils/helpers';

type Props = {
    text?: string;
    textAlignment?: 'left' | 'right';
    toggled: boolean;
};

const baseClassName: string = 'toggle-indicator';
const leftClassName: string = bcn([baseClassName, 'left'], '--');
const rightClassName: string = bcn([baseClassName, 'right'], '--');
const centeredClassName: string = bcn([baseClassName, 'centered', '--']);

const ToggleIndicator: FC<Props> = (props: Props) => {
    const { text, textAlignment, toggled } = props;
    const classNames: string[] = [baseClassName];
    const isLeft: boolean = !!textAlignment && textAlignment === 'left';
    if (!!text && !isLeft) classNames.push(rightClassName);
    else if (!!text && isLeft) classNames.push(leftClassName);
    else classNames.push(centeredClassName);
    return (
        <div className={classNames.join(' ')}>
            {!!text && isLeft && <span>{text}</span>}
            <div className={!!toggled ? 'on' : 'off'} />
            {!!text && !isLeft && <span>{text}</span>}
        </div>
    );
};

export default ToggleIndicator;
