import React from 'react';

import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';

import Logo from '../../../../assets/icons/PDFs/ProofOfPayment/ic-logo-sidebar.png';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '35px',
        backgroundColor: '#ffb40a',
        padding: 2,
    },
    img: {
        width: '110px',
        height: '55px',
        padding: '10px 0px',
    },
    text: {
        fontSize: 15,
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
    },
    smallText: {
        fontSize: 9,
    },
});

export default () => (
    <View fixed style={styles.container}>
        <Text style={styles.text}>www.espace-clients.wekiwi.fr</Text>
        <Text style={styles.smallText}>
            L'énergie est notre avenir, économisons la.
        </Text>
    </View>
);
