import React, { useState } from 'react';
import { InputEvent } from 'Types';
import { TContract } from 'Models';
import {
    IFields,
    ISetting,
    IContact,
    IBilling,
    TPaymentMethodSection,
} from './types';
import { ECustomerType } from '../../utils/enums';

import CustomerContact from './CustomerContact';
import Payment from './Payment';
import ModificationCoholder from './ModificationCoholder';
import ModificationPayment from './ModificationPayment';
import ModificationContact from './ModificationContact';
import ModificationBilling from './ModificationBilling';
import ModificationButtons from './ModificationButtons';

import {
    isValidEmail,
    isValidPhone,
    isValidIban,
    isValidString,
    isValidName,
    displayPhoneWithoutPrefix,
} from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.ProfilePage;

export type Props = {
    selectedContractNumber: string;
    contracts: TContract[];
    customerType: string;
    setting: ISetting;
    fields: IFields;
    currentContact: IContact;
    billing: IBilling;
    handleChangeDropdown?: any;
    handleAddressChange?: any;
    onFocusDropdown?: any;
    checkUpdateProfile: any;
    handleResetCoholder(civility?: string, firstName?: string): void;
    handleInputChange(e: InputEvent, section: string, type: string): void;
    handleChangeCivility(section: string, value: string): void;
    handleShowErrorMessage(section: string): void;
    handleShowModal(section: string): void;
    handleDebitDay(value: string): void;
    handlePaymentSection(section: TPaymentMethodSection): void;
    handleCheckUpdate(section: string, value: boolean): void;
};

/**
 * Display the only container for profile
 * @param currentContact is fixed data containing contact info
 * @param billing is fixed data containing billing info
 * @param fields is variable data containing contact info and billing info
 * @param setting is variable data containing error and address info
 */
const ProfileContainer: React.FC<Props> = ({
    selectedContractNumber,
    contracts,
    customerType,
    currentContact,
    billing,
    fields,
    setting,
    handleChangeDropdown,
    onFocusDropdown,
    handleAddressChange,
    handleResetCoholder,
    handleShowModal,
    handleShowErrorMessage,
    handleInputChange,
    handleChangeCivility,
    handleDebitDay,
    handlePaymentSection,
    handleCheckUpdate,
    checkUpdateProfile,
}) => {
    const handleValidateContact = (): boolean => {
        const {
            contact: { email, phone, coholderCivility, coholderFirstName },
        } = fields;
        const coholderCondition =
            coholderCivility && coholderFirstName
                ? isValidName(coholderFirstName)
                : !setting.checkCoholder;
        return (
            isValidEmail(email) &&
            isValidPhone(displayPhoneWithoutPrefix(phone)) &&
            coholderCondition
        );
    };

    const handleValidateBilling = (): boolean => {
        const {
            email,
            firstName,
            lastName,
            address: { postalCode, street, townName },
        } = fields.billing;
        return (
            isValidString(firstName) &&
            isValidString(lastName) &&
            isValidEmail(email) &&
            postalCode !== '' &&
            street !== '' &&
            townName !== ''
        );
    };

    const handleValidateIban = (): boolean => {
        const {
            billing: { iban },
        } = fields;
        return isValidIban(iban);
    };

    const handleValidateDebitDay = () => {
        const { debitDay } = fields;

        return debitDay !== contract.finance.debitDay;
    };

    const contract =
        contracts.find(
            (c: TContract) => c.contractNumber === selectedContractNumber,
        ) || contracts[0];

    const isValidContact = handleValidateContact();
    const isValidBilling = handleValidateBilling();
    const isValidIbanCode = handleValidateIban();
    const isValidDebitDay = handleValidateDebitDay();

    return (
        <div className="profile-container">
            <h1 className="profile-container__subtitle">{Wording.subtitle}</h1>
            {!checkUpdateProfile.checkUpdateContact ? (
                <CustomerContact
                    type="contact"
                    contractNumber={contract.contractNumber}
                    contact={currentContact}
                    billing={billing}
                    customerType={customerType}
                />
            ) : (
                <>
                    <ModificationContact
                        fields={fields}
                        handleInputChange={handleInputChange}
                        showError={setting.showErrorCustomer}
                    />
                    {customerType === ECustomerType.INDIVIDUAL && (
                        <ModificationCoholder
                            fields={fields}
                            setting={setting}
                            showError={setting.showErrorCustomer}
                            handleChangeCivility={handleChangeCivility}
                            handleInputChange={handleInputChange}
                            handleResetCoholder={handleResetCoholder}
                        />
                    )}
                </>
            )}
            <ModificationButtons
                showButton={!checkUpdateProfile.checkUpdateContact}
                condition={isValidContact}
                handleButtonClick={() => handleCheckUpdate('contact', true)}
                handleButtonCancel={() => handleCheckUpdate('contact', false)}
                handleButtonValidate={
                    isValidContact
                        ? () => handleShowModal('contact')
                        : () => handleShowErrorMessage('contact')
                }
            />
            <span className="profile-container__line" />
            {!checkUpdateProfile.checkUpdateBilling ? (
                <React.Fragment>
                    <CustomerContact
                        type="billing"
                        contact={currentContact}
                        billing={billing}
                        contractNumber={contract.contractNumber}
                    />
                </React.Fragment>
            ) : (
                <ModificationBilling
                    fields={fields}
                    setting={setting}
                    handleChangeCivility={handleChangeCivility}
                    handleChangeDropdown={handleChangeDropdown}
                    handleAddressChange={handleAddressChange}
                    handleInputChange={handleInputChange}
                    onFocusDropdown={onFocusDropdown}
                />
            )}
            <ModificationButtons
                showButton={!checkUpdateProfile.checkUpdateBilling}
                condition={isValidBilling}
                handleButtonClick={() => handleCheckUpdate('billing', true)}
                handleButtonCancel={() => handleCheckUpdate('billing', false)}
                handleButtonValidate={
                    isValidBilling
                        ? () => handleShowModal('billing')
                        : () => handleShowErrorMessage('billing')
                }
            />
            <span className="profile-container__line" />
            {!checkUpdateProfile.checkUpdateIban ? (
                <Payment finance={contract.finance} />
            ) : (
                <ModificationPayment
                    fields={fields}
                    handleCheckUpdate={handleCheckUpdate}
                    showError={setting.showErrorIban}
                    handleInputChange={handleInputChange}
                    handleDebitDay={handleDebitDay}
                    paymentMethodSection={setting.paymentMethodSection}
                    handlePaymentSection={handlePaymentSection}
                    validateItems={{
                        isValidIbanCode,
                        isValidDebitDay,
                    }}
                    handleShowModal={handleShowModal}
                    handleShowErrorMessage={handleShowErrorMessage}
                />
            )}
            <ModificationButtons
                showButton={!checkUpdateProfile.checkUpdateIban}
                condition={isValidIbanCode}
                disabledValidate={true}
                handleButtonClick={() => handleCheckUpdate('iban', true)}
                handleButtonCancel={() => {
                    handleCheckUpdate('iban', false);
                    handlePaymentSection('IBAN');
                }}
                handleButtonValidate={() => {}}
            />
        </div>
    );
};

export default ProfileContainer;
