import React from 'react';
import { ReducerError } from 'Types';

export type Prop = {
    error?: string;
    reducerError?: ReducerError;
};

const ErrorElement: React.FC<Prop> = ({ error }) => {
    const renderError = ({  }: string) => (
        <div className="error-element">
            <p>{error}</p>
        </div>
    );
    if (error) return renderError(error as string);
    return null;
};

export default ErrorElement;
