import React from 'react';

import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';

import Logo from '../../../../assets/icons/PDFs/ProofOfPayment/ic-logo-sidebar.png';

import Wording from '../wording.json';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#ffb40a',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        flex: 0,
    },
    img: {
        width: '110px',
        height: '55px',
        padding: '10px 0px',
    },
    text: {
        textTransform: 'uppercase',
        fontWeight: 'ultrabold',
        fontFamily: 'Montserrat',
    },
});

const headerWording = Wording.header;

export default () => (
    <View style={styles.container}>
        <Text style={styles.text}>{headerWording.title}</Text>
        <Image style={styles.img} src={Logo} />
    </View>
);
