import React, { Fragment } from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { useTimeline } from '../lib';

import WordingConstant from '../wording.json';
import { TPiece, TTransaction, TEPInvoice } from 'Models';
import {
    findLastValidPiece,
    findLastValidTransaction,
} from '../../../../utils/helpers';

const W = WordingConstant.contractContainer;

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 11,
        marginTop: 15,
    },
    containerDate: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    containerDateMarginTop: {
        marginTop: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    date: {
        fontFamily: 'Montserrat',
        fontWeight: 'thin',
        fontSize: 11,
        marginLeft: 50,
    },
    dateBold: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: 11,
    },
});

const ContractContainer = () => {
    const { payments, contract } = useTimeline();
    const data = findLastValidPiece(payments);
    const subscriptionDate = new Date(contract.subscriptionDate);
    const effectiveStartDate = new Date(contract.effectiveStartDate);
    // const dataTransaction = findLastValidTransaction(
    //    (data.lastValid as TEPInvoice).processusList[0].transactions,
    // ).lastValidTransaction;
    const dueDate = data.lastValid
        ? new Date((data.lastValid as TEPInvoice).invoicing_period_end_date)
        : effectiveStartDate;
    const nextYearDate = new Date(dueDate);
    nextYearDate.setFullYear(dueDate.getFullYear() + 1);
    const sentence = `${W.timelineFrom} ${dueDate.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
    })} ${W.to} ${nextYearDate.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
    })} / ${W.kiwi}`;

    return (
        <View style={styles.container}>
            <Text style={styles.text}>{sentence}</Text>
            <View style={styles.subContainer}>
                <View>
                    <View style={styles.containerDateMarginTop}>
                        <Text style={styles.dateBold}>
                            {W.subscriptionDate} :
                        </Text>
                        <Text style={styles.date}>
                            {subscriptionDate.toLocaleDateString()}
                        </Text>
                    </View>
                    <View style={styles.containerDate}>
                        <Text style={styles.dateBold}>
                            {W.contractStartDate} :
                        </Text>
                        <Text style={styles.date}>
                            {effectiveStartDate.toLocaleDateString()}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default ContractContainer;
