import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import shortid from 'shortid';
import {
    EUnitAmounts,
    ECategories,
    TAmountWithLabel,
    TUnitPriceWithLabel,
    TOffer,
    TSeasonalPackage,
    TPackage,
    EEnergyTypes,
    EInstallmentFrequencies,
} from '../../../../../utils/network/types';
import RecapField from './RecapField';
import { toIsc } from '../../../../../utils/text';
import WordingConstant from '../../../../../utils/wording.json';
import { EnergyGauge } from '../../../../../components';
import {
    additionalRatesDuplicateValue,
    array_move,
} from '../../../../../utils/helpers';

const baseClassName = 'recap-group';
const fieldsClassName = 'fields';
// const rebateLabelRegex = new RegExp(/^[rR]emise */);
const Wording = WordingConstant.AdminSubscriptionContainer.Recap;

type GroupProps = {
    offer: TOffer;
    title: string;
    installmentFrequency?: EInstallmentFrequencies;
    chosenPackages: Array<TPackage | TSeasonalPackage>;
    gaugeProps: {
        electricityGaugeProps: GaugeProps | null;
        gasGaugeProps: GaugeProps | null;
    };
};

type Line = [string, string];

function isRawPriceDetail(detail: TAmountWithLabel): boolean {
    let is = false;
    switch (detail.category) {
        case ECategories.TOTAL_AMOUNT:
            is = true;
            break;
        case ECategories.ADDITIONAL_RATE:
            // if (!rebateLabelRegex.test(detail.label || '')) is = true;
            is = true;
            break;
    }
    return is;
}

function isRebateDetail(dt: TAmountWithLabel): boolean {
    const { category } = dt;
    return (
        category === ECategories.ADDITIONAL_RATE ||
        category === ECategories.TOTAL_AMOUNT ||
        category === ECategories.TOTAL_BUDGET
    );
}

function isPriceAfterRebateDetail(detail: TAmountWithLabel): boolean {
    let is = false;
    switch (detail.category) {
        case ECategories.DISCOUNTED_UNIT_PRICE:
        case ECategories.DISCOUNTED_UNIT_PRICE_TTC:
            is = true;
            break;
    }
    return is;
}

function formatUnit(unit: EUnitAmounts): string {
    let formattedUnit: string = unit;
    switch (unit) {
        case EUnitAmounts.PERCENT:
            formattedUnit = '%';
            break;
    }
    return formattedUnit;
}

function formatUnitLabel(price: TUnitPriceWithLabel): string {
    let formattedLabel: string = price.label || '';
    if (!!price.unit && price.unit === EUnitAmounts.EURO_KWH) {
        formattedLabel = [formattedLabel, `(${Wording.unitPrice})`].join(' ');
    }
    return formattedLabel;
}

function formatLine(detail: TAmountWithLabel): Line[] | null {
    const lines: Line[] = [];

    if (!!detail.label) {
        const name: string = detail.label;
        let value: string = '';
        if (!!detail.amount && !!detail.unit) {
            value = toIsc(`${detail.amount} ${formatUnit(detail.unit)}`);
        }
        lines.push([name, value]);
    }
    // if (!!detail.unitPrices && detail.unitPrices.length > 0) {
    //     _.forEach(detail.unitPrices, (unitPrice: TUnitPriceWithLabel) => {
    //         if (!!unitPrice.label && !!unitPrice.price && !!unitPrice.unit) {
    //             const value = toIsc(
    //                 `${unitPrice.price} ${formatUnit(unitPrice.unit)}`,
    //             );
    //             lines.push([formatUnitLabel(unitPrice), value]);
    //         }
    //     });
    // }
    return lines;
}

function formatFields(title: string, lines: Line[]): JSX.Element | null {
    return (
        <div key={shortid.generate()} className={fieldsClassName}>
            <h4>{title}</h4>
            {lines &&
                lines
                    .filter(ln => ln !== undefined && ln.length > 0)
                    .map((line: Line) => (
                        <RecapField
                            key={line[0]}
                            name={line[0]}
                            value={line[1]}
                        />
                    ))
            }
        </div>
    );
}

const unitPricesArr = (unitPrices: TUnitPriceWithLabel[]) =>
    unitPrices.map(({ unit, price, label }) => [
        label,
        `${price}${unit}`,
    ]) as Line[];

type GaugeProps = {
    initial: number;
    ranges: number[][];
    maxValue: number;
};

const RecapGroup: FC<GroupProps> = (props: GroupProps) => {
    const { offer, title, chosenPackages, installmentFrequency } = props;

    const rawDetailsPriceLines: Line[] = [];
    const priceAfterPresentation: TAmountWithLabel[] = [];

    const rawPriceLine: Line[] = [];

    _.forEach(offer.details, (detail: TAmountWithLabel) => {

        const line: Line[] | null = formatLine(detail);

        if (isRawPriceDetail(detail)) {
            if (detail.unitPrices) {
                unitPricesArr(detail.unitPrices).forEach(l =>
                    rawDetailsPriceLines.push(l),
                );

            }
        }
        if (isRebateDetail(detail)) {
            rawPriceLine.push(...line);

            if (
                detail.category &&
                detail.category === ECategories.ECONOMY &&
                !!detail.unitPrices &&
                detail.unitPrices.length > 0
            ) {
                _.forEach(
                    detail.unitPrices,
                    (unitPrice: TUnitPriceWithLabel) => {
                        if (
                            !!unitPrice.label &&
                            !!unitPrice.price &&
                            !!unitPrice.unit
                        ) {
                            const value = toIsc(
                                `${unitPrice.price} ${formatUnit(
                                    unitPrice.unit,
                                )}`,
                            );
                            rawPriceLine.push([
                                formatUnitLabel(unitPrice),
                                value,
                            ]);
                        }
                    },
                );
            }
        }
        if (isPriceAfterRebateDetail(detail)) {
            priceAfterPresentation.push(detail);
        }
    });

    const getGaugePropsByEnergy = (): GaugeProps | null => {
        if (offer.energy === EEnergyTypes.ELECTRICTY) {
            return props.gaugeProps.electricityGaugeProps;
        }

        if (offer.energy === EEnergyTypes.GAS) {
            return props.gaugeProps.gasGaugeProps;
        }
        return null;
    };

    const renderChosenPackage = () => {
        const title = (
            <div className={fieldsClassName}>
                <h4 className="without-margin">{Wording.fields.forfais}</h4>
            </div>
        );
        if (chosenPackages.length === 1) {
            const current = chosenPackages[0];
            return (
                <>
                    {title}
                    <div className="chosen-package-mono">
                        <p>
                            {current.amount} {current.currency}
                        </p>
                    </div>
                </>
            );
        }

        const gaugeProps = getGaugePropsByEnergy();

        if (gaugeProps !== null) {
            return (
                <>
                    {title}

                    <div className="chosen-package-bi">
                        <EnergyGauge {...gaugeProps} onChange={() => { }} />
                    </div>
                </>
            );
        }

        return (
            chosenPackages.length !== 0 &&
            chosenPackages[0] && (
                <>
                    {title}
                    <div className="chosen-package-mono chosen-package-mono-bi">
                        <p>
                            {chosenPackages[0].amount}{' '}
                            {chosenPackages[0].currency} |{' '}
                            {chosenPackages[1].amount}{' '}
                            {chosenPackages[1].currency}
                        </p>
                    </div>
                </>
            )
        );
    };

    const sortRawDetailsPriceLines = rawDetailsPriceLines.sort((a, b) => {
        if (a[1] > b[1]) return -1;
        if (a[1] < b[1]) return 1;
        return 0;
    });

    if (
        installmentFrequency &&
        installmentFrequency === 'MONTHLY' &&
        sortRawDetailsPriceLines.length === 4
    ) {
        array_move(sortRawDetailsPriceLines, 2, 3);

        if (sortRawDetailsPriceLines[1][0] === 'Abonnement') {
            array_move(sortRawDetailsPriceLines, 0, 1);
        }
    }

    return (
        <div className={baseClassName}>
            <h3>{title}</h3>
            {renderChosenPackage()}

            {!!rawPriceLine.length &&
                formatFields(
                    Wording.fields.rawPrice,
                    additionalRatesDuplicateValue(rawPriceLine),
                )}

            {!!rawDetailsPriceLines.length &&
                formatFields(
                    Wording.fields.detailsPrice_HT,
                    sortRawDetailsPriceLines,
                )}

            {priceAfterPresentation.map(({ category, unitPrices }) => {
                if (category) {
                    const nup = unitPrices && unitPricesArr(unitPrices);

                    if (category === ECategories.DISCOUNTED_UNIT_PRICE) {
                        return formatFields(
                            Wording.fields.discountPrice_HT,
                            nup as Line[],
                        );
                    }

                    if (category === ECategories.DISCOUNTED_UNIT_PRICE_TTC) {
                        return formatFields(
                            Wording.fields.discountPrice_TTC,
                            nup as Line[],
                        );
                    }
                }
            })}
        </div>
    );
};

export default RecapGroup;
