import React from 'react';
import truncate from 'lodash/truncate';

import CustomCollapse from '../CustomCollapse';

const MessageCollapse: React.FC<{
    message: string;
    initialLength?: number;
}> = ({ message, initialLength }) => {
    return (
        <CustomCollapse
            customClassName="message-collapse"
            hideFirst
            firstElement={
                <p className="message-collapse-first-elm">
                    {truncate(message, {
                        length: initialLength ? initialLength : 30,
                    })}
                </p>
            }
        >
            <p className="message-collapse-message">{message}</p>
        </CustomCollapse>
    );
};

export default MessageCollapse;
