import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Types';

import FileContainer from '../../containers/FileContainer';
import withBanner from '../../libraries/withBanner';

import {
    fetchContractAsync,
    resetContractState,
    setContract,
} from '../../_actions/contract.actions';
import { fetchFilesListAsync } from '../../_actions/file.actions';

import { initialCustomerFullName } from '../../utils/initialState';

import apiRequest, { fetchApiRequestBlob } from '../../services/api-service';
import { findContractByNumber } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';
import { TFile, TFiles } from 'Models';
import { EEnergy, EFileName } from '../../utils/enums';
import { includes } from 'lodash';

import { GetSupportingDocumentsRequest } from '../../_actions/payment.actions';
import { showDocument } from '../../utils/helpers/pdf';

const Wording = WordingConstant.FilePage;

const mapStateToProps = (state: RootState) => ({
    profileState: state.profileReducer,
    contractState: state.contractReducer,
    fileState: state.fileReducer,
});

const dispatchProps = {
    contractDispatch: fetchContractAsync.request,
    filesListDispatch: fetchFilesListAsync.request,
    resetContractDispatch: resetContractState,
    setContractDispatch: setContract,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const WrappedFile = withBanner()(FileContainer);

type State = {
    isLoading: boolean;
};

export class FilePage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {
        const {
            contractState: { selectedContractNumber },
            filesListDispatch,
            profileState: { customerNumber },
        }: Props = this.props;

        filesListDispatch({
            contractNbr: selectedContractNumber,
            customerNbr: customerNumber,
            mode: 'DOCUMENT',
        });
    }

    handleChangeBanner = (contractNumber: string) => {
        const {
            contractState: { contracts },
            contractDispatch,
            profileState: { customerNumber },
            setContractDispatch,
            filesListDispatch,
        } = this.props;
        const payload = {
            customerNbr: customerNumber,
            contractNbr: contractNumber,
            mode: 'DOCUMENT',
        };
        const contract = findContractByNumber(contractNumber, contracts);
        if (contract.isFetched) {
            setContractDispatch({ contract });
        } else {
            contractDispatch({
                customerNbr: customerNumber,
                contractNbr: contractNumber,
            });
        }
        filesListDispatch(payload);
    };

    handleDownloadFile = (key: string, name: string) => {
        this.responseFetchPDF(
            {
                documentKey: key,
            },
            '/getDocument',
            name,
        );
    };

    handleDownloadProofOfResidence = (): void => {
        const {
            contractState: { selectedContractNumber, contracts },
            profileState: {
                customerNumber,
                customerType,
                contact: { customerFullName, address },
            },
        } = this.props;

        const contract = findContractByNumber(
            selectedContractNumber,
            contracts,
        );
        const thirdParties = contract.thirdParties;
        const coholderName = {
            civility: 'MR',
            firstName: '',
            lastName: '',
        };
        if (thirdParties) {
            const coholder = thirdParties.find(p => p.role === 'COHOLDER');
            if (coholder && coholder.civility && coholder.firstName) {
                coholderName.civility = coholder.civility;
                coholderName.firstName = coholder.firstName;
                coholderName.lastName = coholder.lastName || '';
            }
        }

        const payload: GetSupportingDocumentsRequest = {
            mode: 'proofOfResidence',
            contractNbr: selectedContractNumber,
            customerNbr: customerNumber,
            customerType,
            cunstomerName: customerFullName || { ...initialCustomerFullName },
            coholderName,
            energyType: contract.energy,
            subscriptionDate: contract.subscriptionDate,
            residenceAdress: {
                number: address.number,
                street: address.street,
                netArea: address.netArea,
                postalCode: address.postalCode,
                townName: address.townName,
                country: address.country,
            },
            pointOfDelivery: contract.deliveryPoint.pointOfDelivery,
        };

        this.handleSupportingDocuments(payload);

        this.setState({ isLoading: true });
    };

    handleSupportingDocuments = (payload: any) =>
        apiRequest({
            path: '/getSupportingDocuments',
            method: 'POST',
            body: payload,
        })
            .toPromise()
            .then((res: any) => {
                this.setState({ isLoading: false });
                showDocument(res, 'application/pdf');
            });

    responseFetchPDF = async (payload: any, path: string, pdfName: string) => {
        if (pdfName === EFileName.EXPLANATIONS_OFFER_CAPACITY) {
            await this.handleOpenLocalFile(payload.documentKey);
            return this.setState({ isLoading: false });
        }

        const blob = await fetchApiRequestBlob(payload, path);

        if (blob) {
            const fileURL = URL.createObjectURL(blob);

            window.open(fileURL);
        }
        this.setState({ isLoading: false });
    };

    handleOpenLocalFile = async (key: string) =>
        import(`../../assets/documents/${key}`).then(file => {
            window.open(file.default);
        });

    checkIsCAPA = () => {
        const {
            contractState: { contracts, selectedContractNumber },
        }: Props = this.props;

        const currentContract = contracts.find(
            ct => ct.contractNumber === selectedContractNumber,
        );

        if (currentContract && currentContract.energy === EEnergy.EL) {
            return includes(currentContract.chosenProduct.productCode, 'CAPA')
                ? currentContract
                : undefined;
        }

        return undefined;
    };

    injectExplanationsOfferCapacityFile = (files?: TFiles[]): TFiles[] => {
        const capaContract = this.checkIsCAPA();

        if (capaContract !== undefined) {
            const prepare: any = {
                documentName: EFileName.EXPLANATIONS_OFFER_CAPACITY,
                documentKey: Wording.EXPLANATIONS_OFFER_CAPACITY_key,
                contractNbr: capaContract.contractNumber,
                energyType: capaContract.energy,
            };
            if (!files) return [prepare];
            return [prepare, ...files];
        }
        if (!files) return [];
        return files;
    };

    sortFiles = (file: TFiles[]) => {
        if (file) {
            return file.sort((a: TFiles, b: TFiles) => {
                if (a.documentDate > b.documentDate) {
                    return -1;
                }
                if (a.documentDate < b.documentDate) {
                    return 1;
                }
                return 0;
            });
        }
        return [];
    };

    filesToContainer = (docs: TFiles[]) => {
        return this.sortFiles(this.injectExplanationsOfferCapacityFile(docs));
    };

    render() {
        const {
            profileState: { contact, customerType },
            contractState: { contracts, selectedContractNumber, error },
            fileState: { files },
            resetContractDispatch,
        }: Props = this.props;
        const tab = files.documentsList;
        const { isLoading } = this.state;
        return (
            <WrappedFile
                data={this.filesToContainer(files.documentsList)}
                isLoading={isLoading}
                handleDownloadProofOfResidence={
                    this.handleDownloadProofOfResidence
                }
                handleDownloadFile={this.handleDownloadFile}
                // Banner
                title={Wording.title}
                bannerError={error}
                handleCloseModalBanner={() => resetContractDispatch()}
                contracts={contracts}
                contact={contact}
                customerType={customerType}
                handleChangeBanner={this.handleChangeBanner}
                selectedContractNumber={selectedContractNumber}
            />
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(FilePage);
