import { Epic } from 'redux-observable';
import { of, forkJoin, Observable, throwError } from 'rxjs';
import { mergeMap, switchMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    createStandardAction,
    isActionOf,
    ActionType,
    getType,
} from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';
import {
    TPackage,
    TPrepaymentFrequency,
    TContract,
    TProduct,
    TOffer,
    TResponse,
    TEstimate,
} from 'Models';
import {
    EEnergy,
    ECustomerType,
    EModeManageOffer,
    EFrequency,
    ETimeframe,
    ERateOption,
} from '../utils/enums';
import {
    FETCH_PACKAGE_REQUEST,
    FETCH_PACKAGE_SUCCESS,
    FETCH_PACKAGE_FAILURE,
    FETCH_PRODUCT_REQUEST,
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_FAILURE,
    FETCH_OFFER_REQUEST,
    FETCH_OFFER_SUCCESS,
    FETCH_OFFER_FAILURE,
    UPDATE_PACKAGE_REQUEST,
    UPDATE_PACKAGE_SUCCESS,
    UPDATE_PACKAGE_FAILURE,
    UPDATE_PACKAGE_RESET,
} from './actionTypes';
import { getTodayDate } from '../utils/helpers';
import {
    mapCreateEvent,
    ICreateEventPayload,
    createEventAsync,
} from './event.actions';
import { getNewAdditionalRates } from '../utils/network/request';

interface IPayloadPackage {
    contractNbr: string;
    estimates?: TEstimate[];
    customerType?: ECustomerType;
    energy?: EEnergy;
    reference?: string;
    packagesList?: TPackage[];
    prepaymentFrequencies?: TPrepaymentFrequency[];
    error?: string;
}

interface IPayloadProduct {
    customerType?: ECustomerType;
    contracts?: TContract[];
    productsList?: TProduct[];
    error?: string;
}

interface IPayloadOffer {
    customerType?: ECustomerType;
    contracts?: TContract[];
    offers?: TOffer[];
    products?: TProduct[];
    contractNbr?: string;
    error?: string;
    code?: string;
    message?: string;
}

export interface IPayloadUpdatePackage {
    customerType?: string;
    customerNbr?: string;
    contract?: TContract;
    mode?: EModeManageOffer;
    chosenPackages?: TPackage[];
    frequency?: EFrequency;
    product?: TProduct;
    option?: {
        label: string;
        active: boolean;
    };
    offer?: TOffer;
    contractNbr?: string;
    code?: string;
    message?: string;
    description?: string;
    event?: ICreateEventPayload;
}

const resetUpdate = createStandardAction(UPDATE_PACKAGE_RESET)();

const fetchPackageAsync = createAsyncAction(
    FETCH_PACKAGE_REQUEST,
    FETCH_PACKAGE_SUCCESS,
    FETCH_PACKAGE_FAILURE,
)<IPayloadPackage, IPayloadPackage, TResponse>();

const fetchProductAsync = createAsyncAction(
    FETCH_PRODUCT_REQUEST,
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_FAILURE,
)<IPayloadProduct, IPayloadProduct, TResponse>();

const fetchOfferAsync = createAsyncAction(
    FETCH_OFFER_REQUEST,
    FETCH_OFFER_SUCCESS,
    FETCH_OFFER_FAILURE,
)<IPayloadOffer, IPayloadOffer, TResponse>();

const updatePackageAsync = createAsyncAction(
    UPDATE_PACKAGE_REQUEST,
    UPDATE_PACKAGE_SUCCESS,
    UPDATE_PACKAGE_FAILURE,
)<IPayloadUpdatePackage, IPayloadUpdatePackage, TResponse>();

export type PackageAction =
    | ActionType<typeof fetchPackageAsync>
    | ActionType<typeof fetchProductAsync>
    | ActionType<typeof fetchOfferAsync>
    | ActionType<typeof updatePackageAsync>
    | ActionType<typeof resetUpdate>;

const preparePayloadFetchPackage = ({
    energy,
    reference,
    customerType,
    estimates,
}: IPayloadPackage) => {
    return {
        customerType,
        mode: 'POINT_OF_DELIVERY',
        estimates,
        pointOfDeliveryList: [
            {
                energy,
                reference,
            },
        ],
    };
};

const mapFetchPackage = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadFetchPackage(action.payload);
    return apiRequest<IPayloadPackage>({
        path: '/getPackages',
        method: 'post',
        body: payload,
        isSubscription: true,
    }).pipe(
        mergeMap((response: IPayloadPackage) => {
            if (!response) {
                return of(
                    fetchPackageAsync.failure({
                        code: '500',
                        message: 'Erreur serveur',
                    }),
                );
            }
            return of(
                fetchPackageAsync.success({
                    packagesList: response.packagesList,
                    prepaymentFrequencies: response.prepaymentFrequencies,
                    contractNbr: action.payload.contractNbr,
                }),
            );
        }),
        catchError(error => of(fetchPackageAsync.failure(error))),
    );
};

const fetchPackageEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchPackageAsync.request)),
        mergeMap(action => mapFetchPackage(action, dependency)),
    );

const getEstimates = (contract?: TContract): TEstimate[] => {
    if (contract === undefined) {
        return [];
    }
    const estimates = contract.estimates;
    if (estimates && estimates[0].quantity > 0) {
        return estimates;
    }
    if (contract.rateOption === ERateOption.TOTAL_HOUR) {
        return [
            {
                energy: contract.energy,
                timeframe: ETimeframe.TOTAL_HOUR,
                quantity: 1,
            },
        ];
    }
    if (contract.rateOption === ERateOption.HIGH_LOW) {
        return [
            {
                energy: contract.energy,
                timeframe: ETimeframe.HIGH,
                quantity: 1,
            },
            {
                energy: contract.energy,
                timeframe: ETimeframe.LOW,
                quantity: 1,
            },
        ];
    }
    return [];
};

const preparePayloadFetchOffer = ({
    contracts,
    customerType,
}: IPayloadOffer) => {
    const modifiedContracts =
        contracts &&
        contracts.map(contract => {
            const estimates = getEstimates(contract);
            return {
                ...contract,
                estimates,
            };
        });
    return {
        customerType,
        contracts: modifiedContracts,

        contractChannel: Boolean(
            modifiedContracts &&
                modifiedContracts[0] &&
                modifiedContracts[0].channel,
        )
            ? (modifiedContracts as any)[0].channel
            : null,
    };
};

const mapFetchOffer = (
    action: RootAction,
    { apiRequest }: Services,
): Observable<IPayloadOffer> => {
    const payload = preparePayloadFetchOffer(action.payload);
    return apiRequest<IPayloadOffer>({
        path: '/getOffers',
        method: 'post',
        body: payload,
        isSubscription: true,
    }).pipe(
        mergeMap((response: IPayloadOffer) => {
            if (response && response.code !== 'UNEXPECTED_ERROR') {
                const contractNbr = action.payload.contracts[0].contractNumber;
                return of({
                    contractNbr,
                    offers: response.offers,
                });
            }
            return of({
                code: '500',
                message: 'failure',
                error: 'failure',
            });
        }),
        catchError(error =>
            of({
                code: '500',
                message: 'failure',
                error: 'failure',
            }),
        ),
    );
};

const fetchOfferEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchOfferAsync.request)),
        mergeMap(action => mapFetchOffer(action, dependency)),
    );

const preparePayloadFetchProduct = ({
    customerType,
    contracts,
}: IPayloadProduct) => {
    return {
        customerType,
        contracts,
        contextOfUse: 'MARKET',
        contractChannel: Boolean(
            contracts && contracts[0] && contracts[0].channel,
        )
            ? (contracts as any)[0].channel
            : null,
    };
};

const mapFetchProduct = (
    action: RootAction,
    { apiRequest }: Services,
): Observable<IPayloadProduct> => {
    const payload = preparePayloadFetchProduct(action.payload);
    return apiRequest<IPayloadProduct>({
        path: '/getProducts',
        method: 'post',
        body: payload,
        isSubscription: true,
    }).pipe(
        mergeMap((response: IPayloadProduct) => {
            if (response.productsList) {
                return of({ productsList: response.productsList });
            }
            return of({
                error: 'failure',
                code: '200',
                message: 'Erreur serveur',
            });
        }),
        catchError(error =>
            of({
                error: error.message,
                code: error.code,
                message: error.message,
            }),
        ),
    );
};

const fetchProductEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchProductAsync.request)),
        switchMap(
            (action: RootAction) => mapFetchProduct(action, dependency),
            (action: RootAction, r: IPayloadProduct) => [action, r],
        ),
        switchMap(([action, productResponse]) => {
            if (productResponse.code === '401') {
                return forkJoin(
                    of(productResponse),
                    mapFetchOffer(action, dependency),
                );
                // return throwError(productResponse);
            }
            const { contracts }: IPayloadProduct = action.payload;
            if (contracts && contracts[0].offer) {
                return forkJoin(of(productResponse));
            }
            return forkJoin(
                of(productResponse),
                mapFetchOffer(action, dependency),
            );
        }),
        switchMap(([productResponse, offerResponse]) => {
            const promiseProduct = productResponse.error
                ? fetchProductAsync.failure({
                      code: '500',
                      message: 'Erreur serveur',
                  })
                : fetchProductAsync.success({
                      productsList: productResponse.productsList,
                  });
            if (!offerResponse) {
                return of(promiseProduct);
            }
            const promiseOffer = offerResponse.error
                ? fetchOfferAsync.failure({
                      code: '500',
                      message: 'Erreur serveur',
                  })
                : fetchOfferAsync.success({
                      products: productResponse.productsList,
                      offers: offerResponse.offers,
                      contractNbr: offerResponse.contractNbr,
                  });

            return of(promiseProduct, promiseOffer);
        }),
        catchError(error => {
            return of(fetchProductAsync.failure(error));
        }),
    );

const createProduct = ({
    payloadProduct,
    contract,
}: {
    payloadProduct: any;
    contract: any;
}) => ({
    ...payloadProduct,
    additionalRates:
        contract.chosenProduct.additionalRates ||
        payloadProduct.additionalRates,
});

const preparePayloadUpdatePackage = ({
    customerNbr,
    contract,
    mode,
    product,
    chosenPackages,
    frequency,
    option,
}: IPayloadUpdatePackage) => {
    if (contract === undefined) {
        return {
            customerNbr,
            mode,
            contract,
        };
    }
    const modifiedContract: TContract = { ...contract };
    switch (mode) {
        case EModeManageOffer.INSTALLMENT_FREQUENCY: {
            if (frequency) {
                modifiedContract.installmentFrequency = frequency;
            }
            break;
        }

        case EModeManageOffer.PACKAGE: {
            if (chosenPackages) {
                modifiedContract.chosenPackages = chosenPackages;
            }
            break;
        }

        case EModeManageOffer.PRODUCT: {
            if (product) {
                const today = getTodayDate();
                modifiedContract.chosenProduct = {
                    ...createProduct({ contract, payloadProduct: product }),
                    effectiveDate: today,
                };
                // modifiedContract.effectiveStartDate = today;
            }
            break;
        }

        case EModeManageOffer.OPTIONS: {
            if (option) {
                let additionalRates =
                    modifiedContract.chosenProduct.additionalRates;
                if (additionalRates) {
                    additionalRates = additionalRates.map(r => {
                        if (r.label !== option.label) {
                            if (r.updated) delete r.updated;
                            return r;
                        }
                        return {
                            ...r,
                            active: option.active,
                            updated: true,
                        };
                    });
                }
                modifiedContract.chosenProduct = {
                    ...modifiedContract.chosenProduct,
                    additionalRates,
                };
            }
            break;
        }

        default:
            break;
    }
    return {
        customerNbr,
        mode,
        contract: modifiedContract,
    };
};

const mapUpdatePackage = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadUpdatePackage(action.payload);
    return apiRequest<IPayloadUpdatePackage>({
        path: '/manageOffer',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IPayloadUpdatePackage) => {
            // Server might respond with code and description
            // It should always return code and message

            if (response) {
                if (response.code === 'OK') {
                    return of({
                        code: response.code,
                        contract: payload.contract,
                    });
                }
                if (response.code === 'KO') {
                    return of({
                        code: response.code,
                        description: response.description,
                    });
                }
            }

            return of({
                code: '500',
                message: 'Erreur serveur',
            });
        }),
        catchError(error => of(error)),
    );
};

const updatePackageEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(updatePackageAsync.request)),
        switchMap(
            (action: RootAction) => {
                if (action.payload.mode === EModeManageOffer.PRODUCT) {
                    const modifiedAction = {
                        ...action,
                        payload: {
                            ...action.payload,
                            contracts: [
                                {
                                    ...action.payload.contract,
                                    chosenProduct: createProduct({
                                        payloadProduct: action.payload.product,
                                        contract: action.payload.contract,
                                    }),
                                },
                            ],
                        },
                    };
                    return mapFetchOffer(modifiedAction, dependency);
                }
                return mapUpdatePackage(action, dependency);
            },
            (action: RootAction, r: IPayloadUpdatePackage) => [action, r],
        ),
        switchMap(([action, packageResponse]) => {
            if (packageResponse.offers) {
                const modifiedAction = {
                    ...action,
                    payload: {
                        ...action.payload,
                        contract: {
                            ...action.payload.contract,

                            chosenProduct: createProduct({
                                payloadProduct: action.payload.product,
                                contract: action.payload.contract,
                            }),
                            offer: packageResponse.offers[0],
                        },
                    },
                };
                return forkJoin(
                    of(action),
                    mapUpdatePackage(modifiedAction, dependency),
                    of(packageResponse),
                );
            }
            return forkJoin(of(action), of(packageResponse));
        }),
        switchMap(([action, firstResponse, secondResponse]) => {
            const newActionEvent = {
                type: getType(createEventAsync.request),
                payload: { ...action.payload.event },
            };
            if (
                firstResponse.code !== 'KO' &&
                secondResponse &&
                secondResponse.offers
            ) {
                return forkJoin(
                    of(firstResponse),
                    of({
                        offers: secondResponse.offers,
                        contractNbr: secondResponse.contractNbr,
                    }),
                    mapCreateEvent(newActionEvent, dependency),
                );
            }

            if (firstResponse.code === 'OK') {
                return forkJoin(
                    of(firstResponse),
                    of(undefined),
                    mapCreateEvent(newActionEvent, dependency),
                );
            }
            return forkJoin(of(firstResponse));
        }),
        switchMap(([firstResponse, secondResponse, eventResponse]) => {
            if (
                firstResponse.code === '401' ||
                firstResponse.code === '500' ||
                firstResponse.code === '0'
            ) {
                return of(updatePackageAsync.failure(firstResponse));
            }

            if (secondResponse) {
                return of(
                    updatePackageAsync.success(firstResponse),
                    fetchOfferAsync.success(secondResponse),
                );
            }

            return of(updatePackageAsync.success(firstResponse));
        }),
        catchError(error => {
            return of(updatePackageAsync.failure(error));
        }),
    );

export {
    fetchPackageAsync,
    fetchPackageEpic,
    fetchProductAsync,
    fetchProductEpic,
    fetchOfferAsync,
    fetchOfferEpic,
    updatePackageAsync,
    updatePackageEpic,
    resetUpdate,
};
