import React, { FC, ChangeEvent } from 'react';
import _ from 'lodash';
import { CustomCheckbox, Tile } from '..';
import { buildClassName as bcn } from '../../utils/helpers';

interface IProps {
    checked: boolean;
    distinctCheckbox?: boolean;
    onToggle: (checked: boolean) => void;
    text?: string;
    title?: string;
    titleHint?: string;
}

const baseClassName = 'checkbox-tile';

const CheckboxTile: FC<IProps> = (props: IProps) => {
    const {
        checked,
        distinctCheckbox,
        onToggle,
        text,
        title,
        titleHint,
    } = props;
    const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
        const toggled = !!_.get(e, 'target.value');
        onToggle(toggled);
    };
    return (
        <div className={baseClassName}>
            {!!distinctCheckbox && (
                <CustomCheckbox checked={checked} handleCheck={onCheck} />
            )}
            <Tile
                active={checked}
                onToggle={onToggle}
                text={text}
                title={title}
                titleHint={titleHint}
            />
        </div>
    );
};

export default CheckboxTile;
