import React, { useState, ChangeEvent } from 'react';
import { CustomModal, CustomButton, CustomInput } from '../../../components';
import { isValidEmail, isValidString } from '../../../utils/helpers';
import WordingConstant from '../../../utils/wording.json';

const Wording = WordingConstant.CustomerContractContainer;

interface IPayloadReinitSubscription {
    id: string;
    email: string;
    message?: string;
    code?: string;
}

type Props = {
    reinitSubscriptionEmailDispatch: (
        props: IPayloadReinitSubscription,
    ) => void;
    customerNbr: string;
    handleSubscriptionSendEmail: (e: string) => void;
    defaultEmail?: string;
};

const ReinitSubscriptionEmailButton: React.FC<Props> = ({
    reinitSubscriptionEmailDispatch,
    customerNbr,
    handleSubscriptionSendEmail,
    defaultEmail = '',
}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [currentEmail, setCurrentEmail] = useState<string>(defaultEmail);

    const getMailAddressError = (value: string): string => {
        let error = '';
        if (!isValidString(value)) error = Wording.emptyField;
        else if (!isValidEmail(value)) {
            error = Wording.addressValidity;
        }
        return error;
    };

    const sendCondition =
        currentEmail !== '' || getMailAddressError(currentEmail);

    const elementMap = {
        currentEmail: {
            value: currentEmail,
            onInputChange: (e: ChangeEvent<HTMLInputElement>) =>
                setCurrentEmail(e.currentTarget.value),
            error: getMailAddressError(currentEmail),
        },

        btn_validate: {
            title: 'Continuer',
            color: 'gray',
            small: true,
            onClick: () => {
                handleSubscriptionSendEmail(currentEmail);
                reinitSubscriptionEmailDispatch({
                    id: customerNbr,
                    email: currentEmail,
                });
            },
        },

        btn_cancel: {
            title: 'Retour',
            color: 'orange',
            small: true,
            onClick: () => {
                setOpen(false);
            },
        },
    };

    const renderModal = () => {
        return (
            <CustomModal show={isOpen}>
                <span
                    className="btn-close"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    {'X'}
                </span>
                <div className="reinit-subscription-modal">
                    <p className="warning-title">
                        {Wording.warningMessageTitle}
                    </p>
                    <p>{Wording.reinit_message}</p>
                    <p className="reponse">{Wording.enter_email}</p>

                    <CustomInput {...elementMap.currentEmail} />

                    <p>{Wording.advice_message}</p>

                    <div className="reinit-subscription-modal__buttons">
                        <CustomButton {...elementMap.btn_cancel} />
                        {sendCondition && (
                            <CustomButton {...elementMap.btn_validate} />
                        )}
                    </div>
                </div>
            </CustomModal>
        );
    };

    return (
        <div className="reinit-subscription">
            <p
                onClick={() => {
                    setOpen(true);
                }}
            >
                {Wording.sendEmail}
            </p>
            {renderModal()}
        </div>
    );
};

export default ReinitSubscriptionEmailButton;
