import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import {
    TResponse,
    TPiece,
    TTransaction,
    TProcessus,
    TEPInvoice,
} from 'Models';

import * as actions from '../_actions/payment.actions';
import { EPieceType, EPaymentModeKey } from '../utils/enums';

export type PaymentSupportingDocuments = {
    loading: boolean;
    errorMessage: undefined | string;
};

export type PaymentState = Readonly<{
    pieces: TPiece[];
    epInvoices: TEPInvoice[];
    epPayments: TEPInvoice[];
    updateResponse: TResponse;
    balance?: {
        customerBalance: string;
        contractBalance: string;
        invoiceBalance: string;
    };

    supportingDocument: PaymentSupportingDocuments;
}>;
export const initialState = {
    pieces: [],
    epInvoices: [],
    epPayments: [],
    updateResponse: {
        code: '',
        message: '',
    },
    balance: undefined,

    supportingDocument: {
        loading: false,
        errorMessage: undefined,
    },
};

type Actions = actions.PiecesAction;

const paymentReducer: Reducer<PaymentState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchPiecesAsync.success): {
            const { invoices, prepayments, adhocPieces } = action.payload;
            const ps: TPiece[] = [];
            if (invoices) {
                invoices.forEach(invoice => {
                    ps.push({
                        ...invoice,
                        type: EPieceType.INVOICE,
                    });
                });
            }
            if (prepayments) {
                prepayments.forEach(pp => {
                    ps.push({
                        ...pp,
                        type: EPieceType.PREPAYMENT,
                    });
                });
            }

            if (adhocPieces) {
                adhocPieces.forEach(adh => {
                    // TODO
                    // if (
                    //     !(
                    //         adh.initialTransactionStatus !== 'success' &&
                    //         adh.paymentMode === EPaymentModeKey.BANK_CARD &&
                    //         adh.paymentNature === 'contractActivation'
                    //     )
                    // ) {
                    //     ps.push({
                    //         ...adh,
                    //         type: EPieceType.ADHOC,
                    //     });
                    // }

                    ps.push({
                        ...adh,
                        type: EPieceType.ADHOC,
                    });
                });
            }
            return {
                ...state,
                pieces: ps,
            };
        }
        case getType(actions.fetchBalanceAsync.success): {
            return {
                ...state,
                balance: action.payload,
            };
        }
        case getType(actions.createPaymentAsync.success):
            // Update the piece from where transaction locates
            return {
                ...state,
                updateResponse: {
                    code: '200',
                    message: 'success',
                },
            };
        case getType(actions.createPaymentAsync.failure):
            return {
                ...state,
                updateResponse: {
                    code: '500',
                    message: 'failure',
                },
            };
        case getType(actions.resetUpdate):
            return {
                ...state,
                updateResponse: {
                    code: '',
                    message: '',
                },
            };

        case getType(actions.getSupportingDocumentsAsync.request): {
            return {
                ...state,
                supportingDocument: {
                    loading: true,
                    errorMessage: undefined,
                },
            };
        }

        case getType(actions.getSupportingDocumentsAsync.success): {
            return {
                ...state,
                supportingDocument: {
                    loading: false,
                    errorMessage: undefined,
                },
            };
        }

        case getType(actions.getSupportingDocumentsAsync.failure): {
            return {
                ...state,
                supportingDocument: {
                    loading: false,
                    errorMessage: action.payload,
                },
            };
        }

        case getType(actions.resetSupportingDocumentError): {
            return {
                ...state,
                supportingDocument: {
                    loading: false,
                    errorMessage: undefined,
                },
            };
        }

        default:
            return { ...state };
    }
};

export default paymentReducer;
