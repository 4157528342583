export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';
export const REDUX_RESET_STATE = 'REDUX_RESET_STATE';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const REMOVE_REINIT_SUBSCRIPTION_MESSAGE =
    'REMOVE_REINIT_SUBSCRIPTION_MESSAGE';

export const REINIT_SUBSCRIPTION_EMAIL_REQUEST =
    'REINIT_SUBSCRIPTION_EMAIL_REQUEST';
export const REINIT_SUBSCRIPTION_EMAIL_SUCCESS =
    'REINIT_SUBSCRIPTION_EMAIL_SUCCESS';
export const REINIT_SUBSCRIPTION_EMAIL_FAILURE =
    'REINIT_SUBSCRIPTION_EMAIL_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const FETCH_CONTEXT_REQUEST = 'FETCH_CONTEXT_REQUEST';
export const FETCH_CONTEXT_SUCCESS = 'FETCH_CONTEXT_SUCCESS';
export const FETCH_CONTEXT_FAILURE = 'FETCH_CONTEXT_FAILURE';

export const FETCH_CONTRACT_REQUEST = 'FETCH_CONTRACT_REQUEST';
export const FETCH_CONTRACT_SUCCESS = 'FETCH_CONTRACT_SUCCESS';
export const FETCH_CONTRACT_FAILURE = 'FETCH_CONTRACT_FAILURE';
export const RESET_CONTRACT_STATE = 'RESET_CONTRACT_STATE';
export const SET_CONTRACT = 'SET_CONTRACT';

export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';
export const CREATE_EVENT_RESET = 'CREATE_EVENT_RESET';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER_RESET = 'UPDATE_CUSTOMER_RESET';

export const UPDATE_BILLING_REQUEST = 'UPDATE_BILLING_REQUEST';
export const UPDATE_BILLING_SUCCESS = 'UPDATE_BILLING_SUCCESS';
export const UPDATE_BILLING_FAILURE = 'UPDATE_BILLING_FAILURE';

export const FETCH_METER_CONFIG_REQUEST = 'FETCH_METER_CONFIG_REQUEST';
export const FETCH_METER_CONFIG_SUCCESS = 'FETCH_METER_CONFIG_SUCCESS';
export const FETCH_METER_CONFIG_FAILURE = 'FETCH_METER_CONFIG_FAILURE';
export const FETCH_METER_READING_REQUEST = 'FETCH_METER_READING_REQUEST';
export const FETCH_METER_READING_SUCCESS = 'FETCH_METER_READING_SUCCESS';
export const FETCH_METER_READING_FAILURE = 'FETCH_METER_READING_FAILURE';

export const CREATE_INDEX_REQUEST = 'CREATE_INDEX_REQUEST';
export const CREATE_INDEX_SUCCESS = 'CREATE_INDEX_SUCCESS';
export const CREATE_INDEX_FAILURE = 'CREATE_INDEX_FAILURE';
export const CREATE_INDEX_RESET = 'CREATE_INDEX_RESET';

export const CREATE_SPONSOR_REQUEST = 'CREATE_SPONSOR_REQUEST';
export const CREATE_SPONSOR_SUCCESS = 'CREATE_SPONSOR_SUCCESS';
export const CREATE_SPONSOR_FAILURE = 'CREATE_SPONSOR_FAILURE';

export const FETCH_LIST_FILES_REQUEST = 'FETCH_LIST_FILES_REQUEST';
export const FETCH_LIST_FILES_SUCCESS = 'FETCH_LIST_FILES_SUCCESS';
export const FETCH_LIST_FILES_FAILURE = 'FETCH_LIST_FILES_FAILURE';

export const FETCH_PIECES_REQUEST = 'FETCH_PIECES_REQUEST';
export const FETCH_PIECES_SUCCESS = 'FETCH_PIECES_SUCCESS';
export const FETCH_PIECES_FAILURE = 'FETCH_PIECES_FAILURE';
export const FETCH_EPINVOICES_REQUEST = 'FETCH_EPINVOICES_REQUEST';
export const FETCH_EPINVOICES_SUCCESS = 'FETCH_EPINVOICES_SUCCESS';

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';
export const CREATE_PAYMENT_RESET = 'CREATE_PAYMENT_RESET';

export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILURE = 'FETCH_BALANCE_FAILURE';
export const FETCH_PACKAGE_REQUEST = 'FETCH_PACKAGE_REQUEST';
export const FETCH_PACKAGE_SUCCESS = 'FETCH_PACKAGE_SUCCESS';
export const FETCH_PACKAGE_FAILURE = 'FETCH_PACKAGE_FAILURE';
export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';
export const FETCH_OFFER_REQUEST = 'FETCH_OFFER_REQUEST';
export const FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS';
export const FETCH_OFFER_FAILURE = 'FETCH_OFFER_FAILURE';
export const UPDATE_PACKAGE_REQUEST = 'UPDATE_PACKAGE_REQUEST';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAILURE = 'UPDATE_PACKAGE_FAILURE';
export const UPDATE_PACKAGE_RESET = 'UPDATE_PACKAGE_RESET';

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

export const SEARCH_USER_GROUP_REQUEST = 'SEARCH_USER_GROUP_REQUEST';
export const SEARCH_USER_GROUP_SUCCESS = 'SEARCH_USER_GROUP_SUCCESS';
export const SEARCH_USER_GROUP_FAILURE = 'SEARCH_USER_GROUP_FAILURE';
export const RESET_USER_GROUP_RESPONSE = 'RESET_USER_GROUP_RESPONSE';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const RESET_RESPONSE = 'RESET_RESPONSE';
export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';
export const RESET_GROUP_RESPONSE = 'RESET_GROUP_RESPONSE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const RESET_CREATE_USER_RESPONSE = 'RESET_CREATE_USER_RESPONSE';

export const MANAGE_USER_REQUEST = 'MANAGE_USER_REQUEST';
export const MANAGE_USER_SUCCESS = 'MANAGE_USER_SUCCESS';
export const MANAGE_USER_FAILURE = 'MANAGE_USER_FAILURE';

export const FETCH_CUSTOMER_CONTRACT_REQUEST =
    'FETCH_CUSTOMER_CONTRACT_REQUEST';
export const FETCH_CUSTOMER_CONTRACT_SUCCESS =
    'FETCH_CUSTOMER_CONTRACT_SUCCESS';
export const FETCH_CUSTOMER_CONTRACT_FAILURE =
    'FETCH_CUSTOMER_CONTRACT_FAILURE';

export const TRANSFER_GET_CONTRACT_REQUEST = 'TRANSFER_GET_CONTRACT_REQUEST';
export const TRANSFER_GET_CONTRACT_SUCCESS = 'TRANSFER_GET_CONTRACT_SUCCESS';
export const TRANSFER_GET_CONTRACT_FAILURE = 'TRANSFER_GET_CONTRACT_FAILURE';
export const REMOVE_GET_TRANSFER_CONTRACT_MESSAGE =
    'REMOVE_GET_TRANSFER_CONTRACT_MESSAGE';

export const TRANSFER_CONTRACT_REQUEST = 'TRANSFER_CONTRACT_REQUEST';
export const TRANSFER_CONTRACT_SUCCESS = 'TRANSFER_CONTRACT_SUCCESS';
export const TRANSFER_CONTRACT_FAILURE = 'TRANSFER_CONTRACT_FAILURE';
export const REMOVE_TRANSFER_CONTRACT_MESSAGE =
    'REMOVE_TRANSFER_CONTRACT_MESSAGE';

export const REFRESH_SESSION_EXPIRATION_STATUS = 'CHECK_SESSION_EXPIRATION';

export const RESET_AUTH_ERRORS = 'RESET_AUTH_ERRORS';

export const ADMIN_CLIENT_SPACE_REQUEST = 'ADMIN_CLIENT_SPACE_REQUEST';
export const ADMIN_CLIENT_SPACE_SUCCESS = 'ADMIN_CLIENT_SPACE_SUCCESS';
export const ADMIN_CLIENT_SPACE_FAILURE = 'ADMIN_CLIENT_SPACE_FAILURE';
export const ADMIN_CLIENT_SPACE_RESET_RESPONSE =
    'ADMIN_CLIENT_SPACE_RESET_RESPONSE';

export const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';

export const CASES_EXTRACT_REQUEST = 'ADMIN_CLIENT_SPACE_REQUEST';
export const CASES_EXTRACT_SUCCESS = 'ADMIN_CLIENT_SPACE_SUCCESS';
export const CASES_EXTRACT_FAILURE = 'ADMIN_CLIENT_SPACE_FAILURE';

export const PAYMENT_CB_REQUEST = 'PAYMENT_CB_REQUEST';
export const PAYMENT_CB_FAILURE = 'PAYMENT_CB_FAILURE';
export const PAYMENT_CB_SUCCESS = 'PAYMENT_CB_SUCCESS';
export const RESET_PAYMENT_CB_MODAL = 'RESET_PAYMENT_CB_MODAL';

export const GET_SUPPORTING_DOCUMENTS_REQUEST =
    'GET_SUPPORTING_DOCUMENTS_REQUEST';
export const GET_SUPPORTING_DOCUMENTS_FAILURE =
    'GET_SUPPORTING_DOCUMENTS_FAILURE';
export const GET_SUPPORTING_DOCUMENTS_SUCCESS =
    'GET_SUPPORTING_DOCUMENTS_SUCCESS';

export const RESET_SUPPORTING_DOCUMENT = 'RESET_SUPPORTING_DOCUMENT';

export const UPLOAD_AMENDMENTS_REQUEST = 'UPLOAD_AMENDMENTS_REQUEST';
export const UPLOAD_AMENDMENTS_SUCCESS = 'UPLOAD_AMENDMENTS_SUCCESS';
export const UPLOAD_AMENDMENTS_FAILURE = 'UPLOAD_AMENDMENTS_FAILURE';
export const RESET_UPLOAD_AMENDMENTS = 'RESET_UPLOAD_AMENDMENTS';

export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE';

export const  RETRY_FIRST_PAYMENT_REQUEST = 'RETRY_FIRST_PAYMENT_REQUEST';
export const  RETRY_FIRST_PAYMENT_SUCCESS = 'RETRY_FIRST_PAYMENT_SUCCESS';
export const  RETRY_FIRST_PAYMENT_FAILURE = 'RETRY_FIRST_PAYMENT_FAILURE';
export const  RETRY_FIRST_PAYMENT_RESET_RESPONSE = 'RETRY_FIRST_PAYMENT_RESET_RESPONSE';


