import { SelectOption } from 'Types';
import _ from 'lodash';
import React, { useRef } from 'react';
import shortid from 'shortid';
import { ISectionData } from '.';
import ICLogo from '../../../assets/icons/ic-logo-wekiwi.svg';
import {
    CustomButton,
    CustomError,
    CustomOverlay,
    Separator,
} from '../../../components';
import { LoadingSpinner } from '../../../libraries/withLoader';
import { ConnectedProps } from '../../../pages/admin/AdminSubscriptionPage/AdminSubscriptionPage';
import {
    buildClassName as bcn,
    compareAddress,
    forEach,
    getInitialGroup,
    parseQueryParams,
} from '../../../utils/helpers';
import {
    Energy,
    buildFinalContracts,
    buildPendingOrder,
} from '../../../utils/network/builders';
import {
    NoMatchingPackagesError,
    NoPackageListError,
    RequestError,
} from '../../../utils/network/errors';
import {
    isCyclicalProduct,
    isEqualAuthorizedPackages,
    isEqualProduct,
    parseOrderRebateCode,
    requiresPayment,
    toEnergies,
} from '../../../utils/network/helpers';
import {
    getDeliveryPointPackages,
    getRawDeliveryPointPackages,
} from '../../../utils/network/request';
import {
    ECivilities,
    ECustomerTypes,
    EEffectiveStartRanges,
    EEnergyTypes,
    EFirstPaymentCBStatuses,
    EHeatingTypes,
    EHousingTypes,
    EInstallmentFrequencies,
    EOrderStatuses,
    EPaymentModes,
    EProcessTypes,
    ERateOptions,
    ERebateCodeTypes,
    EResidenceTypes,
    ERoles,
    ESubscriptionTypes,
    ETimeslots,
    TAdditionalRate,
    TAddress,
    TAppointmentDay,
    TAuthorizedPackage,
    TContract,
    TCustomerContact,
    TEstimate,
    TGetOrderStatusResponse,
    TGetPackageResponse,
    TGetProductResponse,
    TOffer,
    TOrder,
    TOrderResponse,
    TPackage,
    TPaymentCBResponse,
    TPrepaymentFrequencies,
    TProduct,
    TSaveOrderResponse,
    TSeasonalPackage,
    TSignOrderResponse,
    TSurvey,
    TThirdParty,
} from '../../../utils/network/types';
import { setState } from '../../../utils/reactHelpers';
import {
    removeSpaces as rs,
    toColonHeading as tch,
    toIsc,
} from '../../../utils/text';
import WordingConstant from '../../../utils/wording.json';
import CustomerFormModal from './modals/CustomerSurveyModal';
import PaymentIframeModal from './modals/PaymentIframeModal';
import ResetModal from './modals/ResetModal';
import ClientDataSection from './sections/ClientDataSection';
import ClientSectionData from './sections/ClientDataSection/Data';
import MissingInformationSection from './sections/MissingInformationSection';
import OfferSection from './sections/OfferSection';
import OfferSectionData from './sections/OfferSection/Data';
import RecapSection from './sections/RecapSection';
import RecapSectionData from './sections/RecapSection/Data';
import SiteSection from './sections/SiteSection';
import SiteSectionData from './sections/SiteSection/Data';
import SituationSection from './sections/SituationSection';
import SituationSectionData, {
    SwitchData,
} from './sections/SituationSection/Data';
import SubscribeSection from './sections/SubscribeSection';
import TopInformationSection from './sections/TopInformationSection';
import { EtypePayment } from '../../../utils/enums';

type GaugeProps = {
    initial: number;
    ranges: number[][];
    maxValue: number;
};

enum Sections {
    ClientData = 0,
    Site = 1,
    Offer = 2,
    Situation = 3,
    Recap = 4,
    MissingInformations = 5,
    Subscribe = 6,
}

type PendingOrder = {
    data: TOrder;
    number: string;
    validation: TSignOrderResponse;
};

type Situation = {
    coords?: {
        civility: ECivilities | null;
        firstName: string;
        lastName: string;
    };
    effectiveStartRange?: EEffectiveStartRanges;
    electricityEffectiveStartDate?: Date;
    electricityDueDate?: Date;
    gasDueDate?: Date;
    electricityExpress?: boolean;
    gasEffectiveStartDate?: Date;
    gasExpress?: boolean;
    electricityTimeslot?: ETimeslots;
    gasTimeslot?: ETimeslots;
    electricityFirstPaymentCB?: boolean;
    gasFirstPaymentCB?: boolean;
    billingAddress?: TAddress;
    iban?: string;
    processType?: EProcessTypes;
    thirdParty?: {
        civility: ECivilities;
        firstName: string;
        lastName: string;
    };
};

type Section = {
    id: Sections;
    initialData: ISectionData | null;
    key: string;
    toggled: boolean;
};

type Coholder = Pick<TThirdParty, 'civility' | 'firstName' | 'lastName'>;

type IProps = ConnectedProps;

interface IState {
    contracts: TContract[] | null;
    prepaymentFrequencies: TPrepaymentFrequencies[] | null;
    currentKey: string;
    customerContact: TCustomerContact | null;
    didSaveOrder: boolean;
    electricityDeliveryAddress: TAddress | null;
    error: { text: string; title: string } | null;
    finalContracts: TContract[];
    firstPaymentCBStatus: EFirstPaymentCBStatuses | null;
    gasDeliveryAddress: TAddress | null;
    isIndividual: boolean;
    isSurvey: boolean;
    loading: boolean;
    offers: TOffer[] | null;
    orderReference: string | null;
    orderStatus: EOrderStatuses | null;
    packages: TPackage[] | null;
    pendingOrder: PendingOrder | null;
    previousOrder: TOrder | null;
    products: TProduct[] | null;
    rebateCode: string | null;
    rebateCodeType: ERebateCodeTypes | null;
    sections: Map<Sections, Section>;
    selectedElectricityProduct: TProduct | null;
    selectedGasProduct: TProduct | null;
    shouldDisplayPaymentIframe: boolean;
    shouldDisplayResetModal: boolean;
    shouldDisplaySurveyModal: boolean;
    siteOptionSelection: ESubscriptionTypes | null;
    situation: Situation | null;
    channel: string;
    paramsOrderNumber: boolean;
    gaugeProps: {
        electricityGaugeProps: GaugeProps | null;
        gasGaugeProps: GaugeProps | null;
    };

    isNewSiteSectionData?: boolean;
    isNewSiteSectionDataCheckRender?: boolean;

    pointsOfDeliveries: {
        EL?: string;
        NG?: string;
    };
    isPackageValid: boolean;
    deliveryPointPackages: TPackage[];
}

const Wording = WordingConstant.AdminSubscriptionContainer;
const title = `${toIsc(Wording.title)} ${toIsc('- ')}${toIsc(
    Wording.titleMode,
)}`;
const baseClassName = 'admin-subscription-container';
const maskClassName = bcn([baseClassName, 'mask']);
const customerFormId = 'customer-form';
const paymentIframeId = 'payment-modal';
const resetModalId = 'reset-modal';
const buildSection = (
    id: Sections,
    toggled: boolean = false,
    data?: ISectionData,
): Section => ({
    id,
    initialData: data || null,
    key: shortid.generate(),
    toggled,
});
const buildInitialSections = () =>
    new Map<Sections, Section>([
        [Sections.ClientData, buildSection(Sections.ClientData, true)],
        [Sections.Site, buildSection(Sections.Site)],
        [Sections.Offer, buildSection(Sections.Offer)],
        [Sections.Situation, buildSection(Sections.Situation)],
        [Sections.Recap, buildSection(Sections.Recap)],
        [
            Sections.MissingInformations,
            buildSection(Sections.MissingInformations),
        ],
        [Sections.Subscribe, buildSection(Sections.Subscribe)],
    ]);
const initialState = {
    contracts: null,
    prepaymentFrequencies: null,
    currentKey: shortid.generate(),
    customerContact: null,
    didSaveOrder: false,
    electricityDeliveryAddress: null,
    electricityOptions: [],
    electricityOptionSelection: [],
    error: null,
    finalContracts: [],
    firstPaymentCBStatus: null,
    gasDeliveryAddress: null,
    gasOptions: [],
    gasOptionSelection: [],
    initialParams: null,
    isIndividual: true,
    isSurvey: false,
    loading: false,
    masked: false,
    offers: null,
    orderReference: null,
    orderStatus: null,
    packages: null,
    pendingOrder: null,
    previousOrder: null,
    products: null,
    rebateCode: null,
    rebateCodeType: null,
    sections: buildInitialSections(),
    selectedElectricityProduct: null,
    selectedGasProduct: null,
    shouldDisplayPaymentIframe: false,
    shouldDisplayResetModal: false,
    shouldDisplaySurveyModal: false,
    siteOptionSelection: null,
    situation: null,
    channel: '',
    gaugeProps: {
        electricityGaugeProps: null,
        gasGaugeProps: null,
    },
    orderNbr: null,
    paramsOrderNumber: false,
    isNewSiteSectionData: false,

    pointsOfDeliveries: {},
    isPackageValid: true,
    deliveryPointPackages: [],
};

class AdminSubscriptionContainer extends React.Component<IProps, IState> {
    currOrderNbr: string | null;
    PDLRef: React.RefObject<string | null>;
    PCERef: React.RefObject<string | null>;

    constructor(props: IProps) {
        super(props);
        this.currOrderNbr = null;
        this.PDLRef = React.createRef();
        this.PCERef = React.createRef();

        this.state = _.cloneDeep(initialState);
        this.checkOrderStatus = this.checkOrderStatus.bind(this);
        this.checkSponsor = this.checkSponsor.bind(this);
        this.fetchAppointmentTimeslot = this.fetchAppointmentTimeslot.bind(
            this,
        );
        this.fetchCalendarAppointment = this.fetchCalendarAppointment.bind(
            this,
        );
        this.getNextDisplayedSections = this.getNextDisplayedSections.bind(
            this,
        );
        this.handleProductSelection = this.handleProductSelection.bind(this);
        this.handleIsIndividualToggle = this.handleIsIndividualToggle.bind(
            this,
        );
        this.onSelectedGroup = this.onSelectedGroup.bind(this);
        this.handleMissingSiteID = this.handleMissingSiteID.bind(this);
        this.handleMissingInformations = this.handleMissingInformations.bind(
            this,
        );
        this.handleSiteModalValidation = this.handleSiteModalValidation.bind(
            this,
        );
        this.handleSiteOptionSelection = this.handleSiteOptionSelection.bind(
            this,
        );
        this.hideModal = this.hideModal.bind(this);
        this.onReceivePackages = this.onReceivePackages.bind(this);
        this.searchSIRET = this.searchSIRET.bind(this);
        this.submitSiteData = this.submitSiteData.bind(this);
        this.handleClientDataValidation = this.handleClientDataValidation.bind(
            this,
        );
        this.handleCodeValidation = this.handleCodeValidation.bind(this);
        this.handleOfferValidation = this.handleOfferValidation.bind(this);
        this.handlePaymentModalDismiss = this.handlePaymentModalDismiss.bind(
            this,
        );
        this.handleReset = this.handleReset.bind(this);
        this.handleResetCancel = this.handleResetCancel.bind(this);
        this.handleSituationValidation = this.handleSituationValidation.bind(
            this,
        );
        this.pay = this.pay.bind(this);
        this.reset = this.reset.bind(this);
        this.saveOrder = this.saveOrder.bind(this);
        this.signOrder = this.signOrder.bind(this);
        this.triggerSignaturePopin = this.triggerSignaturePopin.bind(this);
        this.handleSetGaugeProps = this.handleSetGaugeProps.bind(this);

        this.handleEstimatesPackagesByClient = this.handleEstimatesPackagesByClient.bind(
            this,
        );
    }
    componentDidMount() {
        const params = parseQueryParams(
            document.location,
            window.location.host,
            window.location.protocol,
        );
        const orderNumber: string | number | undefined = params.get(
            'orderNumber',
        );

        const customerType = params.get('customerType');

        if (!!orderNumber) {
            this.fetchOrder(String(orderNumber));
            this.currOrderNbr = orderNumber as string;
            this.setState({
                paramsOrderNumber: true,
            });
        } else if (!!customerType) {
            this.setState({
                isIndividual: customerType === ECustomerTypes.INDIVIDUAL,
            });
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { didSaveOrder } = this.state;
        const bannedProperties = [
            'didSaveOrder',
            'error',
            'loading',
            'orderReference',
            'previousOrder',
            'shouldDisplayPaymentIframe',
            'shouldDisplayResetModal',
            'shouldDisplaySurveyModal',
        ];
        const partialPrevState = _.omit(prevState, ...bannedProperties);
        const partialState = _.omit(this.state, ...bannedProperties);
        if (!_.isEqual(partialPrevState, partialState) && didSaveOrder) {
            this.setState({ didSaveOrder: false });
        }
    }

    buildOrder(isSign: boolean) {
        const {
            customerContact,
            electricityDeliveryAddress,
            finalContracts,
            gasDeliveryAddress,
            isIndividual,
            isSurvey,
            previousOrder,
            rebateCode,
            rebateCodeType,
            situation,
            channel,
        } = this.state;

        if (
            !situation ||
            finalContracts.length <= 0 ||
            customerContact === null
        ) {
            return null;
        }
        let customerAddress: TAddress | null = _.get(
            customerContact,
            'address',
            null,
        );
        if (!customerAddress) {
            customerAddress = _.get(
                finalContracts,
                '0.deliveryPoint.address',
                null,
            );
        }
        const order: TOrder | null = buildPendingOrder(
            {
                customerContact: {
                    ..._.omit(
                        customerContact,
                        isIndividual
                            ? [
                                  'companyName',
                                  'companyType',
                                  'identificationNumber',
                                  'activityCode',
                              ]
                            : [],
                    ),
                    address: customerAddress as TAddress,
                },
                typePayment:
                    situation.effectiveStartRange ===
                        EEffectiveStartRanges.LATER &&
                    situation.processType === EProcessTypes.SWITCH
                        ? EtypePayment.SDD
                        : EtypePayment.OTHER,
                customerType: isIndividual
                    ? ECustomerTypes.INDIVIDUAL
                    : ECustomerTypes.PROFESSIONAL,
            },
            {
                contracts: finalContracts,
            },
            {
                billingAddress: situation.billingAddress,
                coords: situation.coords,
                coholder: { ...situation.thirdParty },
                effectiveStartRange: situation.effectiveStartRange,
                energies: {
                    EL: !!situation.electricityEffectiveStartDate
                        ? {
                              effectiveStartDate:
                                  situation.electricityEffectiveStartDate,
                              express: !!situation.electricityEffectiveStartDate
                                  ? !!situation.electricityExpress
                                  : undefined,
                              firstPaymentCB: !!situation.electricityFirstPaymentCB,
                              timeslot: situation.electricityTimeslot,
                              dueDate: situation.electricityDueDate,
                          }
                        : undefined,
                    NG: !!situation.gasEffectiveStartDate
                        ? {
                              effectiveStartDate:
                                  situation.gasEffectiveStartDate,
                              express: situation.gasEffectiveStartDate
                                  ? !!situation.gasExpress
                                  : undefined,
                              firstPaymentCB: !!situation.gasFirstPaymentCB,
                              timeslot: situation.gasTimeslot,
                              dueDate: situation.gasDueDate,
                          }
                        : undefined,
                },
                payment: {
                    iban: situation.iban,
                },
                processType: situation.processType,
            },
            {
                rebateCode:
                    rebateCode !== null
                        ? {
                              code: rebateCode,
                              type: rebateCodeType,
                          }
                        : undefined,
                channel,
                isSign,
            },
        );
        if (!!order) {
            _.set(order, 'orderStatus', EOrderStatuses.IN_PROGRESS);
            const requiresFirstPaymentCB: boolean = _.reduce<
                TContract,
                boolean
            >(
                order.contracts,
                (result: boolean, value: TContract) => {
                    return result && requiresPayment(value);
                },
                true,
            );
            if (requiresFirstPaymentCB) {
                _.set(
                    order,
                    'firstPaymentCBStatus',
                    EFirstPaymentCBStatuses.PENDING,
                );
            }
        }
        if (!!order && !!previousOrder) {
            const previousOrderReference: string = _.get(
                previousOrder,
                'orderReference',
                '',
            );
            const previousOrderStatus: string = _.get(
                previousOrder,
                'orderStatus',
                '',
            );
            const previousElectricityContractNumber: string | undefined = _.get(
                _.find(previousOrder.contracts, (contract: TContract) => {
                    return contract.energy === EEnergyTypes.ELECTRICTY;
                }),
                'contractNumber',
            );
            const previousGasContractNumber: string | undefined = _.get(
                _.find(previousOrder.contracts, (contract: TContract) => {
                    return contract.energy === EEnergyTypes.GAS;
                }),
                'contractNumber',
            );
            if (
                previousOrderStatus === EOrderStatuses.IN_PROGRESS ||
                previousOrderStatus === EOrderStatuses.IN_FAILURE
            ) {
                _.set(order, 'orderReference', previousOrderReference);
                _.set(order, 'orderStatus', previousOrderStatus);
                _.forEach(order.contracts, (contract: TContract, i: number) => {
                    if (contract.energy === EEnergyTypes.ELECTRICTY) {
                        _.set(
                            order,
                            `contracts.${i}.contractNumber`,
                            previousElectricityContractNumber,
                        );
                    } else if (contract.energy === EEnergyTypes.GAS) {
                        _.set(
                            order,
                            `contracts.${i}.contractNumber`,
                            previousGasContractNumber,
                        );
                    }
                });
            }
        }

        return order;
    }
    async checkOrderStatus(
        orderNumber: string,
    ): Promise<TGetOrderStatusResponse | RequestError> {
        const { getOrderStatus } = this.props;
        const { pendingOrder, channel } = this.state;
        const res:
            | TGetOrderStatusResponse
            | RequestError = await getOrderStatus(
            getInitialGroup(channel),
            orderNumber,
        );
        if (!(res instanceof RequestError)) {
            if (!!pendingOrder) {
                _.set(pendingOrder, 'data.orderStatus', res.orderStatus);
            }
            this.setState({
                orderStatus: res.orderStatus,
                firstPaymentCBStatus: res.firstPaymentCBStatus,
                pendingOrder,
            });
        }
        return res;
    }
    async checkSponsor(code: string): Promise<boolean | RequestError> {
        const { checkSponsor } = this.props;
        return this.load(checkSponsor, [code]);
    }
    async fetchAppointmentTimeslot(
        date: Date,
        energy: EEnergyTypes,
    ): Promise<ETimeslots[] | RequestError> {
        const { searchAppointmentTimeslot } = this.props;
        const res:
            | ETimeslots[]
            | RequestError = await this.load(searchAppointmentTimeslot, [
            date,
            energy,
        ]);
        return res;
    }
    async fetchCalendarAppointment(
        energyType: EEnergyTypes,
        isIndividual: boolean,
        onInit?: boolean,
    ): Promise<TAppointmentDay[] | RequestError> {
        const { getCalendarAppointment } = this.props;
        const { previousOrder, channel } = this.state;
        const order =
            !!onInit && !!previousOrder
                ? previousOrder || undefined
                : undefined;
        const res:
            | TAppointmentDay[]
            | RequestError = await this.load(getCalendarAppointment, [
            getInitialGroup(channel),
            energyType,
            isIndividual,
            order,
        ]);
        return res;
    }
    didCompleteSubcription(): boolean {
        const { firstPaymentCBStatus } = this.state;
        return (
            this.didSignOrder() &&
            (firstPaymentCBStatus === null ||
                firstPaymentCBStatus === EFirstPaymentCBStatuses.FINALIZED)
        );
    }
    didSignOrder(): boolean {
        const { orderStatus } = this.state;
        return (
            orderStatus === EOrderStatuses.FINALIZED ||
            orderStatus === EOrderStatuses.FINALIZED_WITHOUT_DOC
        );
    }
    async fetchOrder(orderNumber: string) {
        const { getOrder } = this.props;
        const { channel } = this.state;
        const res: TOrderResponse | RequestError = await this.load(
            getOrder,
            [getInitialGroup(channel), orderNumber],
            (err: RequestError) => {
                this.reset({
                    error: {
                        title: Wording.errors.fetchOrder,
                        text: err.message,
                    },
                });
            },
        );
        if (res instanceof RequestError) return;
        const isIndividual: boolean = !(
            _.get(res, 'order.customer.type') === ECustomerTypes.PROFESSIONAL
        );

        const sections: Map<Sections, Section> | null = await this.load(
            this.getInitialSections,
            [res],
            () => {
                this.setState({
                    error: {
                        title: Wording.errors.defaultTitle,
                        text: Wording.errors.getInitialSections,
                    },
                });
            },
        );
        let siteOptionSelection: ESubscriptionTypes | null = null;
        const contracts: TContract[] | null = _.get(
            res,
            'order.contracts',
            null,
        );
        const electricityContract: TContract | undefined = _.find(
            contracts,
            (contract: TContract) => {
                return contract.energy === EEnergyTypes.ELECTRICTY;
            },
        );
        const gasContract: TContract | undefined = _.find(
            contracts,
            (contract: TContract) => {
                return contract.energy === EEnergyTypes.GAS;
            },
        );
        const hasElectricity: boolean = !!electricityContract;
        const hasGas: boolean = !!gasContract;
        if (hasElectricity && hasGas) {
            siteOptionSelection = ESubscriptionTypes.ELECTRICITY_AND_GAS;
        } else if (hasElectricity) {
            siteOptionSelection = ESubscriptionTypes.ELECTRICITY;
        } else if (hasGas) {
            siteOptionSelection = ESubscriptionTypes.GAS;
        }
        const orderCode = parseOrderRebateCode(contracts || []);
        const electricityEffectiveStartDate =
            new Date(_.get(electricityContract, 'effectiveStartDate', '')) ||
            undefined;
        const gasEffectiveStartDate =
            new Date(_.get(gasContract, 'effectiveStartDate', '')) || undefined;
        const thirdParty = _.find(
            _.get(res, 'contracts.0.thirdParties'),
            (tp: TThirdParty) => {
                return tp.role === ERoles.COHOLDER;
            },
        );
        const situation: Situation = {
            effectiveStartRange: _.get(
                res,
                'order.contracts.0.effectiveStartRange',
            ),
            electricityEffectiveStartDate,
            electricityExpress: !!_.get(
                electricityContract,
                'deliveryPoint.process.express',
            ),
            gasEffectiveStartDate,
            gasExpress: !!_.get(gasContract, 'deliveryPoint.process.express'),
            electricityTimeslot: _.get(
                electricityContract,
                'deliveryPoint.process.appointmentTimeslot',
            ),
            gasTimeslot: _.get(
                gasContract,
                'deliveryPoint.process.appointmentTimeslot',
            ),
            electricityFirstPaymentCB: _.get(
                electricityContract,
                'firstPaymentCB',
            ),
            gasFirstPaymentCB: _.get(gasContract, 'firstPaymentCB'),
            billingAddress: _.get(
                res,
                'customer.finance.billingContact.address',
            ),
            iban: _.get(res, 'customer.finance.ibanCode'),
            processType: _.get(
                electricityContract,
                'deliveryPoint.process.type',
            ),
            thirdParty,
        };
        /* The following state attributes are missing from the reset pendingState
        packages: TPackage[] | null;
        products: TProduct[] | null;
        selectedElectricityProduct: TProduct | null;
        selectedGasProduct: TProduct | null;
        */
        if (!!sections) {
            this.reset({
                contracts,
                customerContact: _.get(res, 'order.customer.contact') || null,
                electricityDeliveryAddress:
                    _.get(electricityContract, 'deliveryPoint.address') || null,
                finalContracts: contracts || undefined,
                firstPaymentCBStatus: _.get(
                    res,
                    'order.firstPaymentCBStatus',
                    null,
                ),
                gasDeliveryAddress:
                    _.get(gasContract, 'deliveryPoint.address') || null,
                isIndividual,
                isSurvey: !_.get(contracts, '0.deliveryPoint.pointOfDelivery'),
                orderStatus: _.get(res, 'order.orderStatus', null),
                previousOrder: res.order,
                rebateCode: !!orderCode ? orderCode.code : null,
                rebateCodeType: !!orderCode ? orderCode.type : null,
                sections,
                siteOptionSelection,
                situation,
                channel: res.channel,
            });
        }
    }
    getStatusWording(
        orderStatus: EOrderStatuses | null,
        firstPaymentCBStatus: EFirstPaymentCBStatuses | null,
    ): [[string, string], [string, string]] {
        let orderStr: string = Wording.orderStatuses.inProgress;
        let paymentStr: string = '-';
        switch (orderStatus) {
            case EOrderStatuses.FINALIZED:
            case EOrderStatuses.FINALIZED_WITHOUT_DOC:
                orderStr = Wording.orderStatuses.finalized;
                break;
        }
        switch (firstPaymentCBStatus) {
            case EFirstPaymentCBStatuses.FINALIZED:
                paymentStr = Wording.paymentStatuses.finalized;
                break;
            case EFirstPaymentCBStatuses.PENDING:
                paymentStr = Wording.paymentStatuses.pending;
                break;
            case EFirstPaymentCBStatuses.CANCELED:
                paymentStr = Wording.paymentStatuses.canceled;
                break;
            case EFirstPaymentCBStatuses.IN_FAILURE:
                paymentStr = Wording.paymentStatuses.inFailure;
                break;
        }
        return [
            [tch(Wording.orderStatus), orderStr],
            [tch(Wording.paymentStatus), paymentStr],
        ];
    }

    handleSetGaugeProps(gaugeProps: {
        electricityGaugeProps: GaugeProps | null;
        gasGaugeProps: GaugeProps | null;
    }) {
        this.setState({ gaugeProps });
    }

    handleProductSelection(energy: EEnergyTypes, product: TProduct | null) {
        const nextState: any = {};
        if (energy === EEnergyTypes.ELECTRICTY) {
            nextState.selectedElectricityProduct = product;
            if (!!product && !!product.additionalRates) {
                nextState.electricityOptions = product.additionalRates;
            }
        } else if (energy === EEnergyTypes.GAS) {
            nextState.selectedGasProduct = product;
            if (!!product && !!product.additionalRates) {
                nextState.gasOptions = product.additionalRates;
            }
        }
        this.setState({ ...nextState });
    }
    handleIsIndividualToggle(isIndividual: boolean) {
        this.reset({ isIndividual });
    }
    onSelectedGroup(group: SelectOption<string>) {
        this.setState({ channel: group.value });
    }
    handleMissingSiteID() {
        this.setState({ shouldDisplaySurveyModal: true });
    }
    async load<R, F extends (...args: any[]) => Promise<R>>(
        fn: F,
        args: Parameters<F>,
        errorHandler?: (err: RequestError) => void | Promise<void>,
    ): Promise<R> {
        this.setState({ error: null, loading: true });
        const res = await fn.call(this, ...args);
        if (res instanceof RequestError && !!errorHandler) {
            console.error(res);
            if (!!errorHandler) {
                await errorHandler(res);
            } else {
                this.setState({
                    error: {
                        title: Wording.errors.defaultTitle,
                        text: res.message,
                    },
                });
            }
        }
        this.setState({ loading: false });
        return res;
    }
    handleClientDataValidation(
        validated: boolean,
        customerContact: TCustomerContact,
    ) {
        this.setState({
            customerContact,
            sections: this.getNextDisplayedSections(
                Sections.Site,
                validated,
                true,
            ),
        });
    }
    handleCodeValidation(
        validated: boolean,
        code: string,
        type?: ERebateCodeTypes,
    ) {
        this.setState({
            rebateCode: validated ? code : null,
            rebateCodeType: type || null,
        });
    }
    async handleMissingInformations(PCE?: string, PDL?: string) {
        const { getDeliveryPointPackages } = this.props;

        const {
            finalContracts,
            isIndividual,
            siteOptionSelection,
            channel,
            customerContact,
        } = this.state;

        const electricityEnabled =
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY ||
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS;
        const gasEnabled =
            siteOptionSelection === ESubscriptionTypes.GAS ||
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS;
        this.setState({ error: null });
        const res: TGetPackageResponse | RequestError = await this.load(
            getDeliveryPointPackages,
            [getInitialGroup(channel), PDL || null, PCE || null, isIndividual],
            (err: RequestError) => {
                this.setState({
                    error: {
                        title: Wording.errors.getPackages,
                        text: err.message,
                    },
                });
            },
        );
        if (res instanceof RequestError) return;

        const newContracts: TContract[] = res.contracts;
        const newPackages: TPackage[] = res.packagesList;

        let electricityDeliveryAddress: TContract | null = null;
        let gasDeliveryAddress: TContract | null = null;
        const electricityIndex: number = _.findIndex(
            finalContracts,
            (contract: TContract) => {
                const energy: EEnergyTypes = _.get(contract, 'energy');
                return energy === EEnergyTypes.ELECTRICTY;
            },
        );
        const gasIndex: number = _.findIndex(
            finalContracts,
            (contract: TContract) => {
                const energy: EEnergyTypes = _.get(contract, 'energy');
                return energy === EEnergyTypes.GAS;
            },
        );

        let isPackageValid = true;
        for (let i = 0; i < finalContracts.length; i += 1) {
            const chosenPackages = finalContracts[i].chosenPackages || [];
            if (finalContracts[i].chosenPackages !== undefined) {
                for (let p = 0; p < chosenPackages.length; p += 1) {
                    if (
                        newPackages.findIndex(
                            newPackage =>
                                newPackage.energy ===
                                    chosenPackages[p].energy &&
                                newPackage.amount === chosenPackages[p].amount,
                        ) === -1
                    ) {
                        isPackageValid = false;
                        break;
                    }
                }
            } else {
                isPackageValid = false;
                break;
            }
        }

        this.setState({
            isPackageValid,
            deliveryPointPackages: newPackages,
        });

        if (!isPackageValid) {
            return;
        }

        _.forEach(newContracts, (contract: TContract) => {
            if (
                electricityEnabled &&
                contract.energy === EEnergyTypes.ELECTRICTY
            ) {
                electricityDeliveryAddress = _.get(
                    contract,
                    'deliveryPoint.address',
                    null,
                );
                // if (
                //     electricityIndex !== -1 &&
                //     !_.get(
                //         finalContracts[electricityIndex],
                //         'deliveryPoint.pointOfDelivery',
                //     )
                // ) {
                _.set(
                    finalContracts[electricityIndex],
                    'deliveryPoint.pointOfDelivery',
                    _.get(contract, 'deliveryPoint.pointOfDelivery'),
                );
                _.set(
                    finalContracts[electricityIndex],
                    'deliveryPoint.address',
                    _.get(contract, 'deliveryPoint.address'),
                );
                _.set(
                    finalContracts[electricityIndex],
                    'deliveryPoint.contractualCapacity',
                    _.get(contract, 'deliveryPoint.contractualCapacity'),
                );
                _.set(
                    finalContracts[electricityIndex],
                    'deliveryPoint.effectiveStartDate',
                    _.get(contract, 'deliveryPoint.effectiveStartDate'),
                );
                _.set(
                    finalContracts[electricityIndex],
                    'deliveryPoint.greedRate',
                    _.get(contract, 'deliveryPoint.greedRate'),
                );
                if (
                    electricityIndex !== -1 &&
                    !_.get(
                        finalContracts[electricityIndex],
                        'deliveryPoint.profile',
                    )
                ) {
                    _.set(
                        finalContracts[electricityIndex],
                        'deliveryPoint.profile',
                        _.get(contract, 'deliveryPoint.profile'),
                    );
                }
            } else if (gasEnabled && contract.energy === EEnergyTypes.GAS) {
                gasDeliveryAddress = _.get(
                    contract,
                    'deliveryPoint.address',
                    null,
                );
                // if (
                //     gasIndex !== -1 &&
                //     !_.get(
                //         finalContracts[gasIndex],
                //         'deliveryPoint.pointOfDelivery',
                //     )
                // ) {
                _.set(
                    finalContracts[gasIndex],
                    'deliveryPoint.pointOfDelivery',
                    _.get(contract, 'deliveryPoint.pointOfDelivery'),
                );
                _.set(
                    finalContracts[gasIndex],
                    'deliveryPoint.address',
                    _.get(contract, 'deliveryPoint.address'),
                );
                _.set(
                    finalContracts[gasIndex],
                    'deliveryPoint.greedRate',
                    _.get(contract, 'deliveryPoint.greedRate'),
                );
                if (
                    gasIndex !== -1 &&
                    !_.get(finalContracts[gasIndex], 'deliveryPoint.profile')
                ) {
                    _.set(
                        finalContracts[gasIndex],
                        'deliveryPoint.profile',
                        _.get(contract, 'deliveryPoint.profile'),
                    );
                }
            }
            // }
        });

        const newContact = { ...customerContact };

        if (customerContact) {
            (newContact as any).address = _.get(
                finalContracts[0],
                'deliveryPoint.address',
                customerContact !== null
                    ? (customerContact as TCustomerContact).address
                    : undefined,
            );
        }

        this.setState({
            electricityDeliveryAddress,
            gasDeliveryAddress,
            finalContracts,
            customerContact: newContact as TCustomerContact,
        });
    }
    async handleOfferValidation(selection: {
        electricityFrequency?: EInstallmentFrequencies;
        electricityPackage?: TPackage;
        electricityProduct?: TProduct;
        electricitySeasonalPackages?: TAuthorizedPackage | null;
        gasFrequency?: EInstallmentFrequencies;
        gasPackage?: TPackage;
        gasProduct?: TProduct;
        gasSeasonalPackages?: TAuthorizedPackage | null;
    }) {
        const { getOffers } = this.props;
        const {
            contracts,
            isIndividual,
            siteOptionSelection,
            channel,
            pointsOfDeliveries,
            isSurvey,
        } = this.state;
        if (!contracts || contracts.length === 0) return;

        const energies: EEnergyTypes[] = toEnergies(siteOptionSelection);
        let error: { text: string; title: string } | null = null;
        if (
            (_.includes(energies, EEnergyTypes.ELECTRICTY) &&
                !selection.electricityProduct) ||
            (_.includes(energies, EEnergyTypes.GAS) && !selection.gasProduct)
        ) {
            error = {
                text: Wording.errors.missingEnergies,
                title: Wording.errors.defaultTitle,
            };
            await setState(this, { error });
            return;
        }
        let electricityEnergy: Partial<Energy> | null = null;
        let gasEnergy: Partial<Energy> | null = null;
        _.forEach(contracts, (contract: TContract) => {
            if (contract.energy === EEnergyTypes.ELECTRICTY) {
                if (isCyclicalProduct(selection.electricityProduct)) {
                    electricityEnergy = {
                        contract,
                        chosenProduct: selection.electricityProduct,
                        pointOfDelivery: pointsOfDeliveries.EL,
                    };
                } else if (!!selection.electricityPackage) {
                    electricityEnergy = {
                        contract,
                        chosenPackages:
                            !!selection.electricitySeasonalPackages &&
                            selection.electricitySeasonalPackages.length > 0
                                ? [...selection.electricitySeasonalPackages]
                                : [selection.electricityPackage],
                        chosenProduct: selection.electricityProduct,
                        selectedFrequency: selection.electricityFrequency,
                        pointOfDelivery: pointsOfDeliveries.EL,
                    };
                }
            } else if (contract.energy === EEnergyTypes.GAS) {
                if (isCyclicalProduct(selection.gasProduct)) {
                    gasEnergy = {
                        contract,
                        chosenProduct: selection.gasProduct,
                        pointOfDelivery: pointsOfDeliveries.NG,
                    };
                } else if (!!selection.gasPackage) {
                    gasEnergy = {
                        contract,
                        chosenPackages:
                            !!selection.gasSeasonalPackages &&
                            selection.gasSeasonalPackages.length > 0
                                ? [...selection.gasSeasonalPackages]
                                : [selection.gasPackage],
                        chosenProduct: selection.gasProduct,
                        selectedFrequency: selection.gasFrequency,
                        pointOfDelivery: pointsOfDeliveries.NG,
                    };
                }
            }
        });

        const finalContracts: TContract[] = buildFinalContracts(
            electricityEnergy,
            gasEnergy,
        );
        await setState(this, {
            finalContracts,
            isNewSiteSectionDataCheckRender: false,
        });
        const res: TOffer[] | RequestError = await this.load(
            getOffers,
            [getInitialGroup(channel), finalContracts, isIndividual],
            () => {},
        );
        const validated = !(res instanceof RequestError);

        if (!validated) {
            return;
        }
        await setState(this, {
            isPackageValid: true,
            offers: validated ? (res as TOffer[]) : [],
            sections: this.getNextDisplayedSections(
                Sections.Situation,
                validated,
            ),
        });
    }
    handlePaymentModalDismiss(
        paymentCBStatus: EFirstPaymentCBStatuses,
        error?: { title: string; text: string },
    ) {
        this.setState({
            shouldDisplayPaymentIframe: false,
            error: error || null,
            firstPaymentCBStatus: paymentCBStatus,
        });
    }
    handleReset() {
        this.setState({ shouldDisplayResetModal: true });
    }
    handleResetCancel() {
        this.setState({ shouldDisplayResetModal: false });
    }
    async handleSiteModalValidation(
        accomodationType: EResidenceTypes,
        area: number,
        address: TAddress,
        consumption: number,
        heatingType: EHeatingTypes,
        inhabitants: number,
        livingIn: EHousingTypes,
        rateOption: ERateOptions,
    ) {
        this.hideModal();
        const { getSurveyPackages } = this.props;
        const { isIndividual, siteOptionSelection, channel } = this.state;
        const customerType: ECustomerTypes = isIndividual
            ? ECustomerTypes.INDIVIDUAL
            : ECustomerTypes.PROFESSIONAL;
        const energyList: EEnergyTypes[] = [];
        if (siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS) {
            energyList.push(EEnergyTypes.ELECTRICTY);
            energyList.push(EEnergyTypes.GAS);
        } else if (siteOptionSelection === ESubscriptionTypes.ELECTRICITY) {
            energyList.push(EEnergyTypes.ELECTRICTY);
        } else if (siteOptionSelection === ESubscriptionTypes.GAS) {
            energyList.push(EEnergyTypes.GAS);
        }
        const survey: TSurvey = {
            energyList,
            housingType: livingIn,
            residenceType: accomodationType,
            area,
            inhabitantsNumber: inhabitants,
            heatingType,
            rateOption,
            address,
        };
        const prevContracts = this.state.contracts
            ? this.state.contracts.slice()
            : [];
        await setState(this, {
            contracts: null,
            packages: null,
            products: null,
            selectedElectricityProduct: null,
            selectedGasProduct: null,
            // pointsOfDeliveries: { NG: undefined, EL: undefined }
        });
        const res = await this.load(getSurveyPackages, [
            getInitialGroup(channel),
            survey,
            isIndividual,
        ]);
        if (res instanceof RequestError) {
            return;
        }
        this.setState({
            sections: this.getNextDisplayedSections(Sections.Offer, true),
        });
        const [contracts, packages, frequencies]: [
            TContract[] | null,
            TPackage[] | null,
            TPrepaymentFrequencies[] | null,
        ] = this.parseGetPackageResponse(res);
        if (prevContracts && contracts) {
            contracts.map((c, index) => Object.assign(c, prevContracts[index]));
        }
        this.onReceivePackages(
            contracts,
            packages,
            frequencies,
            customerType,
            true,
        );
    }
    handleSiteOptionSelection(siteOptionSelection: ESubscriptionTypes) {
        const nextState: any = {
            contracts: null,
            electricityOptions: [],
            gasOptions: [],
            packages: null,
            products: null,
            selectedElectricityProduct: null,
            selectedGasProduct: null,
            siteOptionSelection,
            orderStatus: null,
        };
        this.setState({ ...nextState });
    }
    handleSituationValidation(validated: boolean, situation: Situation) {
        this.setState({
            situation: validated ? situation : null,
            sections: this.getNextDisplayedSections(
                Sections.Subscribe,
                validated,
            ),
        });
    }
    hideModal() {
        this.setState({ shouldDisplaySurveyModal: false });
    }
    async onReceivePackages(
        contracts: TContract[] | null,
        packages: TPackage[] | null,
        frequencies: TPrepaymentFrequencies[] | null,
        customerType: ECustomerTypes,
        survey: boolean,
    ) {
        const { getProducts } = this.props;
        const { channel } = this.state;
        if (!contracts || !packages) {
            console.error(
                `Could not fetch products: args: contracts: ${contracts} packages: ${packages}`,
            );
            return;
        }
        let products = null;
        const res: TGetProductResponse | RequestError = await this.load(
            getProducts,
            [getInitialGroup(channel), contracts, customerType],
            () => {
                this.setState({
                    error: {
                        title: Wording.errors.defaultTitle,
                        text: Wording.errors.offer,
                    },
                });
            },
        );
        if (res instanceof RequestError) return;
        products = this.parseGetProductsResponse(res);
        this.setState({
            contracts,
            isSurvey: survey,
            packages,
            products,
            prepaymentFrequencies: frequencies,
        });
    }
    parseGetPackageResponse(
        res: any,
    ): [
        TContract[] | null,
        TPackage[] | null,
        TPrepaymentFrequencies[] | null,
    ] {
        const contracts = _.get(res, 'contracts', null);
        const packages = _.get(res, 'packagesList', null);
        const frequencies = _.get(res, 'prepaymentFrequencies', null);
        return [contracts, packages, frequencies];
    }
    parseGetProductsResponse(res: TGetProductResponse): TProduct[] | null {
        const products: TProduct[] | null = _.get(res, 'productsList', null);
        return products;
    }
    reset(pendingState: Partial<IState> = { isIndividual: true }) {
        const nextKey: string = shortid.generate();
        this.setState(
            {
                ..._.cloneDeep(initialState),
                sections: buildInitialSections(),
                ...pendingState,
                currentKey: nextKey,
            },
            () => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            },
        );
    }
    async searchSIRET(value: string) {
        const { searchSiret } = this.props;
        this.setState({ loading: true });
        const res = await searchSiret(value);
        this.setState({ loading: false });
        return res;
    }
    setLoading(loading: boolean) {
        this.setState({ loading });
    }
    shouldAllowSignOrder() {
        const {
            electricityDeliveryAddress,
            gasDeliveryAddress,
            isSurvey,
            siteOptionSelection,
            finalContracts,
            isNewSiteSectionDataCheckRender,
            contracts,
        } = this.state;

        const checkPointOfDelivery = finalContracts.reduce(
            (acc, curr) =>
                Boolean(
                    acc &&
                        curr.deliveryPoint &&
                        curr.deliveryPoint.pointOfDelivery !== undefined,
                ),
            true,
        );

        let hasAddress = true;
        const electricityEnabled =
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY ||
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS;
        const gasEnabled =
            siteOptionSelection === ESubscriptionTypes.GAS ||
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS;

        if (isSurvey) {
            if (electricityEnabled && !checkPointOfDelivery) {
                hasAddress = hasAddress && !!electricityDeliveryAddress;
            }
            if (gasEnabled && !checkPointOfDelivery) {
                hasAddress = hasAddress && !!gasDeliveryAddress;
            }
        }
        let offerCheck = true;

        if (isNewSiteSectionDataCheckRender && contracts) {
            // @ts-ignore
            offerCheck = contracts.reduce(
                (acc, curr) =>
                    // @ts-ignore
                    Boolean(
                        acc &&
                            curr.chosenPackages !== undefined &&
                            curr.chosenPackages.length !== 0,
                    ),
                true,
            );
        }

        return hasAddress && checkPointOfDelivery && offerCheck;
    }
    shouldShow(target: Sections) {
        const { sections } = this.state;
        const section: Section | undefined = sections.get(target);
        if (section) {
            if (
                this.state.previousOrder &&
                this.state.previousOrder.orderReference &&
                section.id !== 5
            ) {
                return true;
            }
        }
        return !!section && section.toggled;
    }
    getNextDisplayedSections(
        targetSection: Sections,
        toggled: boolean,
        resetInitialData: boolean = false,
    ) {
        const { isSurvey, sections, finalContracts } = this.state;
        const nextSections: Map<Sections, Section> = new Map(sections);
        const nextSectionsArr: Section[] = [
            ...nextSections.values(),
        ].sort((a: Section, b: Section) => (a.id < b.id ? -1 : 1));
        for (let i = 0; i < nextSectionsArr.length; i = i + 1) {
            const section: Section | undefined = nextSectionsArr[i];
            if (!section) {
                console.error('Section validation error');
                return sections;
            }
            if (i === targetSection) {
                section.toggled = toggled;
                if (resetInitialData) section.initialData = null;
            } else {
                const hidden: boolean = i >= targetSection;
                section.toggled = !hidden;
                if (hidden) section.key = shortid.generate();
            }
            if (i === Sections.Recap) {
                section.toggled =
                    section.toggled &&
                    targetSection >= Sections.Subscribe &&
                    toggled;
            } else if (i === Sections.MissingInformations) {
                const checkDeliveryPoints = finalContracts.reduce(
                    (acc, curr) =>
                        acc &&
                        curr.deliveryPoint !== undefined &&
                        curr.deliveryPoint.pointOfDelivery === undefined,
                    true,
                );

                section.toggled =
                    section.toggled && isSurvey && checkDeliveryPoints;
            } else if (i === Sections.Subscribe) {
                section.toggled =
                    section.toggled && !this.didCompleteSubcription();
            }
            nextSections.set(section.id, section);
        }
        return nextSections;
    }
    async getInitialSections(
        order: TOrderResponse,
    ): Promise<Map<Sections, Section>> {
        const sections = new Map<Sections, Section>(buildInitialSections());
        let clientData: ClientSectionData | null = null;
        let siteData: SiteSectionData | null = null;
        let offerData: OfferSectionData | null = null;
        let situationData: SituationSectionData | null = null;
        let recapData: RecapSectionData | null = null;
        await forEach(sections, async (value: Section, key: Sections) => {
            switch (key) {
                case Sections.ClientData:
                    clientData = this.parsePreviousClientData(order);
                    value.initialData = clientData;
                    value.toggled = !!clientData;
                    break;
                case Sections.Site:
                    siteData = await this.parsePreviousSiteData(order.order);
                    value.initialData = siteData;
                    value.toggled = !!siteData;
                    break;
                case Sections.Offer:
                    offerData = await this.parsePreviousOfferData(order);
                    value.initialData = offerData;
                    value.toggled = !!offerData;
                    break;
                case Sections.Situation:
                    situationData = this.parsePreviousSituationData(
                        order.order,
                    );

                    value.initialData = situationData;
                    value.toggled = !!situationData;
                    break;
                case Sections.Recap:
                    recapData = await this.parsePreviousRecapData(order);
                    value.initialData = recapData;
                    value.toggled = !!recapData;
                    break;
            }
        });
        await setState(this, {
            orderReference: order.order.orderReference || null,
            orderStatus: order.order.orderStatus || null,
            firstPaymentCBStatus: order.order.firstPaymentCBStatus || null,
        });
        return sections;
    }

    async handleEstimatesPackagesByClient(
        estimates: TEstimate[],
        energies: EEnergyTypes[],
    ) {
        const { getEstimatesPackages } = this.props;
        const { channel, isIndividual } = this.state;

        try {
            const pkgRes: TGetPackageResponse | RequestError = await this.load(
                getEstimatesPackages,
                [getInitialGroup(channel), estimates, isIndividual, energies],
                (err: RequestError) => {
                    this.setState({
                        error: {
                            title: Wording.errors.getPackages,
                            text: err.message,
                        },
                    });
                },
            );
            if (pkgRes instanceof RequestError) return;

            const nextState: any = {};
            if (pkgRes.prepaymentFrequencies) {
                nextState.prepaymentFrequencies = pkgRes.prepaymentFrequencies;
            }

            if (pkgRes.packagesList) nextState.packages = pkgRes.packagesList;
            if (pkgRes.contracts) nextState.contracts = pkgRes.contracts;

            await setState(this, {
                ...nextState,
                selectedGasProduct: null,
                selectedElectricityProduct: null,
            });
        } catch (err) {
            if (err instanceof RequestError) {
                console.error('Could not restore previous contracts');
                console.error(err);
            } else console.log('No prior offer data');
        }
    }

    getSectionCurrentKey(index: Sections): string {
        const { currentKey, sections } = this.state;
        const key: string =
            currentKey + _.get(sections.get(index), 'key', shortid.generate());
        return key;
    }
    parsePreviousClientData(order: TOrderResponse): ClientSectionData | null {
        let data: ClientSectionData | null = null;

        try {
            const { contracts } = order.order;
            const contact = _.get(order, 'order.customer.contact', {});
            const mainContract =
                contracts.length === 2
                    ? contracts.find(
                          ctr => ctr.energy === EEnergyTypes.ELECTRICTY,
                      )
                    : contracts[0];

            let addressDeliverypoint = {};

            if (
                mainContract &&
                mainContract.deliveryPoint &&
                mainContract.deliveryPoint.address
            ) {
                addressDeliverypoint = mainContract.deliveryPoint.address;
            } else {
                addressDeliverypoint = contact.address;
            }

            const isSame = compareAddress(
                addressDeliverypoint as any,
                contact.address,
            );

            data = {
                section: Sections.ClientData,
                firstNameValue: contact.firstName,
                lastNameValue: contact.lastName,
                birthDatePick: new Date(contact.birthdate),
                businessNameValue: contact.companyName || null,
                SIRETValue: contact.identificationNumber || null,
                mailAddressValue: contact.email,
                phoneNumberValue: contact.phone,
                identicalAddressToggleState: !order.order.customer.contact
                    .address,
                addressAddressValue: `${_.get(
                    contact,
                    'address.number',
                    '',
                )} ${_.get(contact, 'address.street', '')}`,
                address: isSame ? undefined : contact.address,
                civilStatus: contact.civility,
                legalStatus: contact.companyType || null,
                APE: contact.activityCode || '',
                defaultGroup: order.channel,
                birthTown: contact.birthTown,
            };
        } catch (err) {
            console.log('No prior client data');
        }
        return data;
    }
    async parsePreviousSiteData(
        order: TOrder,
    ): Promise<SiteSectionData | null> {
        let data: SiteSectionData | null = null;
        try {
            const electricityContract: TContract | undefined = _.find(
                order.contracts,
                (contract: TContract) => {
                    return contract.energy === EEnergyTypes.ELECTRICTY;
                },
            );
            const gasContract: TContract | undefined = _.find(
                order.contracts,
                (contract: TContract) => {
                    return contract.energy === EEnergyTypes.GAS;
                },
            );
            let subscriptionType: ESubscriptionTypes | null = null;
            if (!!electricityContract && !!gasContract) {
                subscriptionType = ESubscriptionTypes.ELECTRICITY_AND_GAS;
            } else if (!!electricityContract) {
                subscriptionType = ESubscriptionTypes.ELECTRICITY;
            } else if (!!gasContract) {
                subscriptionType = ESubscriptionTypes.GAS;
            }
            const contracts: TContract[] = [];
            if (!!electricityContract) contracts.push(electricityContract);
            if (!!gasContract) contracts.push(gasContract);
            await setState(this, { contracts });
            data = {
                PCEValue: _.get(
                    gasContract,
                    'deliveryPoint.pointOfDelivery',
                    '',
                ),
                PDLValue: _.get(
                    electricityContract,
                    'deliveryPoint.pointOfDelivery',
                    '',
                ),
                section: Sections.Site,
                siteOptionSelection: subscriptionType,
            };
        } catch (err) {
            console.log('No prior site data');
        }
        return data;
    }
    async parsePreviousOfferData(
        orderResponse: TOrderResponse,
    ): Promise<OfferSectionData | null> {
        const { getEstimatesPackages, getProducts } = this.props;
        const { prepaymentFrequencies } = this.state;
        const { channel, order } = orderResponse;

        let data: OfferSectionData | null = null;

        try {
            const previousElectricityContract: TContract | undefined = _.find(
                order.contracts,
                (contract: TContract) => {
                    return contract.energy === EEnergyTypes.ELECTRICTY;
                },
            );
            const previousGasContract: TContract | undefined = _.find(
                order.contracts,
                (contract: TContract) => {
                    return contract.energy === EEnergyTypes.GAS;
                },
            );
            const isIndividual: boolean =
                order.customer.type !== ECustomerTypes.PROFESSIONAL;
            const energies: EEnergyTypes[] = [];
            if (!!previousElectricityContract) {
                energies.push(EEnergyTypes.ELECTRICTY);
            }
            if (!!previousGasContract) {
                energies.push(EEnergyTypes.GAS);
            }
            const estimates: TEstimate[] = [];
            _.forEach(order.contracts, (contract: TContract) => {
                if (!!contract.estimates && !!contract.estimates[0]) {
                    estimates.push({
                        ...contract.estimates[0],
                        energy: contract.energy,
                    });
                }
            });

            const handleGetPackages = async () => {
                const [elecPoints, gasPoints] = [
                    previousElectricityContract,
                    previousGasContract,
                ].map(
                    ctr =>
                        ctr &&
                        ctr.deliveryPoint &&
                        ctr.deliveryPoint.pointOfDelivery,
                );

                if (elecPoints || gasPoints) {
                    return await getDeliveryPointPackages(
                        channel,
                        elecPoints,
                        gasPoints,
                        isIndividual,
                    );
                }

                return await getEstimatesPackages(
                    channel,
                    estimates,
                    isIndividual,
                    energies,
                );
            };

            const pkgRes:
                | TGetPackageResponse
                | RequestError = await handleGetPackages();

            if (pkgRes instanceof RequestError) throw pkgRes;
            const prodRes:
                | TGetProductResponse
                | RequestError = await getProducts(
                getInitialGroup(channel),
                order.contracts, // or pkgRes.contracts ?
                isIndividual
                    ? ECustomerTypes.INDIVIDUAL
                    : ECustomerTypes.PROFESSIONAL,
            );
            if (prodRes instanceof RequestError) throw prodRes;
            const previousElectricityPackages: Array<
                TPackage | TSeasonalPackage
            > = _.get(previousElectricityContract, 'chosenPackages', []);
            const previousGasPackages: Array<
                TPackage | TSeasonalPackage
            > = _.get(previousGasContract, 'chosenPackages', []);
            let authorizedElectricityPackages: TAuthorizedPackage[] = [];
            let authorizedElectricityPackageSelection: TAuthorizedPackage | null = null;
            let authorizedGasPackages: TAuthorizedPackage[] = [];
            let authorizedGasPackageSelection: TAuthorizedPackage | null = null;
            const electricityFrequencies: EInstallmentFrequencies[] = [];
            let electricityFrequencySelection: EInstallmentFrequencies | null = null;
            let electricityPlans: TPackage[] = [];
            let electricityPlanSelection: TPackage | null = null;
            const electricityProducts: TProduct[] = [];
            let electricityProductSelection: TProduct | null = null;
            const gasFrequencies: EInstallmentFrequencies[] = [];
            let gasFrequencySelection: EInstallmentFrequencies | null = null;
            let gasPlans: TPackage[] = [];
            let gasPlanSelection: TPackage | null = null;
            const gasProducts: TProduct[] = [];
            let gasProductSelection: TProduct | null = null;
            let previousElectricityPlanSelection: TPackage | null = null;
            let previousGasPlanSelection: TPackage | null = null;
            _.forEach(prodRes.productsList, (prod: TProduct) => {
                if (prod.energy === EEnergyTypes.ELECTRICTY) {
                    electricityProducts.push(prod);
                    if (
                        !!previousElectricityContract &&
                        isEqualProduct(
                            prod,
                            previousElectricityContract.chosenProduct,
                        )
                    ) {
                        electricityProductSelection = prod;
                    }
                } else if (prod.energy === EEnergyTypes.GAS) {
                    gasProducts.push(prod);
                    if (
                        !!previousGasContract &&
                        isEqualProduct(prod, previousGasContract.chosenProduct)
                    ) {
                        gasProductSelection = prod;
                    }
                }
            });
            electricityPlans = _.filter(
                pkgRes.packagesList,
                (pkg: TPackage) => {
                    return pkg.energy === EEnergyTypes.ELECTRICTY;
                },
            );
            if (
                previousElectricityPackages.length === 2 &&
                !!electricityProductSelection
            ) {
                _.forEach(electricityPlans, (pkg: TPackage) => {
                    const shouldBreak: boolean = false;
                    if (!!pkg.authorizedPackages) {
                        _.forEach(
                            pkg.authorizedPackages,
                            (authPkg: TAuthorizedPackage) => {
                                const authorizedPackagesMatched: boolean = isEqualAuthorizedPackages(
                                    authPkg,
                                    previousElectricityPackages as TSeasonalPackage[],
                                );
                                if (authorizedPackagesMatched) {
                                    electricityPlanSelection = pkg;
                                    authorizedElectricityPackages =
                                        pkg.authorizedPackages || [];
                                    authorizedElectricityPackageSelection = authPkg;
                                }
                            },
                        );
                    }
                    if (shouldBreak) return false;
                });
            } else if (previousElectricityPackages.length === 1) {
                previousElectricityPlanSelection = previousElectricityPackages[0] as TPackage;
                electricityPlanSelection =
                    _.find(electricityPlans, (pkg: TPackage) => {
                        return (
                            pkg.energy === EEnergyTypes.ELECTRICTY &&
                            pkg.id === previousElectricityPackages[0].id
                        );
                    }) || null;
            }
            gasPlans = _.filter(pkgRes.packagesList, (pkg: TPackage) => {
                return pkg.energy === EEnergyTypes.GAS;
            });
            if (previousGasPackages.length === 2 && !!gasProductSelection) {
                _.forEach(gasPlans, (pkg: TPackage) => {
                    const shouldBreak: boolean = false;
                    if (!!pkg.authorizedPackages) {
                        _.forEach(
                            pkg.authorizedPackages,
                            (authPkg: TAuthorizedPackage) => {
                                const authorizedPackagesMatched: boolean = isEqualAuthorizedPackages(
                                    authPkg,
                                    previousGasPackages as TSeasonalPackage[],
                                );
                                if (authorizedPackagesMatched) {
                                    gasPlanSelection = pkg;
                                    authorizedGasPackages =
                                        pkg.authorizedPackages || [];
                                    authorizedGasPackageSelection = authPkg;
                                }
                            },
                        );
                    }
                    if (shouldBreak) return false;
                });
            } else if (previousGasPackages.length === 1) {
                previousGasPlanSelection = previousGasPackages[0] as TPackage;
                gasPlanSelection =
                    _.find(gasPlans, (pkg: TPackage) => {
                        return (
                            pkg.energy === EEnergyTypes.GAS &&
                            pkg.id === previousGasPackages[0].id
                        );
                    }) || null;
            }
            const previousElectricityFrequency: EInstallmentFrequencies | null = _.get(
                previousElectricityContract,
                'installmentFrequency',
                null,
            );
            const previousGasFrequency: EInstallmentFrequencies | null = _.get(
                previousGasContract,
                'installmentFrequency',
                null,
            );

            // TODO
            // electricityFrequencySelection =
            //     _.find(
            //         electricityFrequencies,
            //         (freq: EInstallmentFrequencies) => {
            //             return previousElectricityFrequency === freq;
            //         },
            //     ) || null;

            electricityFrequencySelection = previousElectricityFrequency;

            // TODO
            // gasFrequencySelection =
            //     _.find(gasFrequencies, (freq: EInstallmentFrequencies) => {
            //         return previousGasFrequency === freq;
            //     }) || null;

            gasFrequencySelection = previousGasFrequency;

            const electricityOptions: TAdditionalRate[] = _.get(
                electricityProductSelection,
                'additionalRates',
                [],
            );
            const previousElectricityOptions: TAdditionalRate[] = _.get(
                previousElectricityContract,
                'chosenProduct.additionalRates',
                [],
            );
            const electricitySelectedOptions: TAdditionalRate[] = _.filter(
                previousElectricityOptions,
                (rate: TAdditionalRate) => {
                    return (
                        !!rate.active &&
                        !!_.find(electricityOptions, _.omit(rate, 'active'))
                    );
                },
            );
            const gasOptions: TAdditionalRate[] = _.get(
                gasProductSelection,
                'additionalRates',
                [],
            );
            const previousGasOptions: TAdditionalRate[] = _.get(
                previousGasContract,
                'chosenProduct.additionalRates',
                [],
            );
            const gasSelectedOptions: TAdditionalRate[] = _.filter(
                previousGasOptions,
                (rate: TAdditionalRate) => {
                    return (
                        !!rate.active &&
                        !!_.find(gasOptions, _.omit(rate, 'active'))
                    );
                },
            );

            data = {
                authorizedElectricityPackages,
                authorizedElectricityPackageSelection,
                authorizedGasPackages,
                authorizedGasPackageSelection,
                electricityFrequencySelection,
                electricityPlans,
                electricityPlanSelection,
                electricityProducts,
                electricityProductSelection,
                electricityOptions,
                electricitySelectedOptions,
                gasFrequencySelection,
                gasPlans,
                gasPlanSelection,
                gasProducts,
                gasProductSelection,
                gasOptions,
                gasSelectedOptions,
                section: Sections.Offer,
                previousElectricityPlanSelection,
                previousGasPlanSelection,
                frequencies:
                    pkgRes.prepaymentFrequencies || prepaymentFrequencies,
            };
        } catch (err) {
            if (err instanceof RequestError) {
                console.error('Could not restore previous contracts');
                console.error(err);
            } else console.log('No prior offer data');
        }

        return data;
    }
    parsePreviousSituationData(order: TOrder): SituationSectionData | null {
        let data: SituationSectionData | null = null;
        try {
            const energies: EEnergyTypes[] = [];
            const iban: string | undefined = order.customer.finance.ibanCode;
            let switchingSupplierStartRange: EEffectiveStartRanges | undefined;
            const movingInData: {
                EL?: {
                    date: Date;
                    timeslot: ETimeslots;
                };
                NG?: {
                    date: Date;
                    timeslot: ETimeslots;
                };
            } = {};

            const switchData: SwitchData = {};

            const billingAddress: TAddress | undefined = _.get(
                order,
                'customer.finance.billingContact.address',
            );
            const coords = !!billingAddress
                ? {
                      civility: _.get(
                          order,
                          'customer.finance.billingContact.civility',
                          ECivilities.MR,
                      ),
                      firstName: _.get(
                          order,
                          'customer.finance.billingContact.firstName',
                          '',
                      ),
                      lastName: _.get(
                          order,
                          'customer.finance.billingContact.lastName',
                          '',
                      ),
                  }
                : undefined;

            const thirdParty: Coholder | undefined =
                _.filter(
                    _.get(order, 'contracts.0.thirdParties', []),
                    (tp: TThirdParty) => {
                        return tp.role === ERoles.COHOLDER;
                    },
                ).map((tp: TThirdParty) => {
                    return _.pick(tp, ['civility', 'firstName', 'lastName']);
                })[0] || undefined;
            const paymentMode: EPaymentModes = _.get(
                order,
                'customer.finance.paymentMode',
            );
            // if (paymentMode === EPaymentModes.DIRECT_DEBIT) {
            //     // TODO Currently removed from saveOrder request
            //     iban = '';
            // }

            const processType: EProcessTypes | undefined = _.get(
                order,
                'contracts.0.deliveryPoint.process.type',
            );

            const previousElectricityContract: TContract | undefined = _.find(
                _.get(order, 'contracts', []),
                (contract: TContract) => {
                    return contract.energy === EEnergyTypes.ELECTRICTY;
                },
            );
            const previousGasContract: TContract | undefined = _.find(
                _.get(order, 'contracts', []),
                (contract: TContract) => {
                    return contract.energy === EEnergyTypes.GAS;
                },
            );

            if (processType === EProcessTypes.MOVE_IN) {
                if (!!previousElectricityContract) {
                    const date: Date | undefined = new Date(
                        _.get(
                            previousElectricityContract,
                            'effectiveStartDate',
                            '',
                        ),
                    );
                    const timeslot: ETimeslots | undefined = _.get(
                        previousElectricityContract,
                        'deliveryPoint.process.appointmentTimeslot',
                    );
                    if (!!date && !!timeslot && !isNaN(date.getTime())) {
                        movingInData.EL = {
                            date,
                            timeslot,
                        };
                    }
                    energies.push(EEnergyTypes.ELECTRICTY);
                }
                if (!!previousGasContract) {
                    const date: Date | undefined = new Date(
                        _.get(previousGasContract, 'effectiveStartDate', ''),
                    );
                    const timeslot: ETimeslots | undefined = _.get(
                        previousGasContract,
                        'deliveryPoint.process.appointmentTimeslot',
                    );
                    if (!!date && !!timeslot && !isNaN(date.getTime())) {
                        movingInData.NG = {
                            date,
                            timeslot,
                        };
                    }
                    energies.push(EEnergyTypes.GAS);
                }
            }

            order.contracts.forEach(ctr =>
                energies.push(ctr.energy as EEnergyTypes),
            );

            data = {
                coords,
                billingAddress,
                energies,
                payment: {
                    mode: paymentMode,
                    data: { iban },
                },
                section: Sections.Situation,
                startDate: {
                    processType: processType || EProcessTypes.MOVE_IN,
                    movingInData: { ...movingInData },
                    switchData: (() => {
                        if (processType === EProcessTypes.SWITCH) {
                            switchingSupplierStartRange = _.get(
                                order,
                                'contracts.0.effectiveStartRange',
                            );

                            const nextStartRange = switchingSupplierStartRange as EEffectiveStartRanges;

                            const nextSwitchData = order.contracts.reduce(
                                (acc, curr) => ({
                                    ...acc,
                                    [curr.energy]: {
                                        dueDate: new Date(
                                            curr.dueDate as string,
                                        ),
                                        effectiveStartDate: new Date(
                                            curr.effectiveStartDate as string,
                                        ),
                                    },
                                }),
                                {} as any,
                            );

                            return {
                                ...nextSwitchData,
                                startRange: nextStartRange,
                            };
                        }
                        return {};
                    })(),
                },
                thirdParty,
            };
        } catch (err) {
            console.log('No prior situation data');
        }

        return data;
    }
    async parsePreviousRecapData(
        orderResponse: TOrderResponse,
    ): Promise<RecapSectionData | null> {
        const { getOffers } = this.props;
        const { order, channel } = orderResponse;
        let data: RecapSectionData | null = null;
        let offers: TOffer[] = [];
        try {
            const contracts: TContract[] = _.get(order, 'contracts', []);
            const isIndividual: boolean =
                order.customer.type === ECustomerTypes.INDIVIDUAL;
            const offerRes: TOffer[] | RequestError = await getOffers(
                getInitialGroup(channel),
                contracts,
                isIndividual,
            );
            if (!(offerRes instanceof RequestError)) offers = offerRes;
            const orderCode = parseOrderRebateCode(contracts);

            data = {
                codeType: _.get(orderCode, 'type'),
                codeInputToggled: !!orderCode,
                code: _.get(orderCode, 'code', ''),
                offers,
                section: Sections.Recap,
            };
        } catch (err) {}
        return data;
    }
    async pay(order: TOrder): Promise<TPaymentCBResponse | RequestError> {
        const { paymentCB } = this.props;
        const { channel } = this.state;
        return await paymentCB(getInitialGroup(channel), order);
    }
    async submitSiteData(
        electricity: string,
        gas: string,
    ): Promise<boolean | RequestError> {
        const { getDeliveryPointPackages } = this.props;

        const { isIndividual, channel, customerContact } = this.state;
        const customerType: ECustomerTypes = isIndividual
            ? ECustomerTypes.INDIVIDUAL
            : ECustomerTypes.PROFESSIONAL;
        const fmtElectricity = !!electricity ? rs(electricity) : null;
        const fmtGas = !!gas ? rs(gas) : null;
        const pointsOfDeliveries: any = {};
        const email = customerContact ? customerContact.email : '';
        const firstName = customerContact ? customerContact.firstName : '';
        const lastName = customerContact ? customerContact.lastName : '';
        if (electricity !== '') pointsOfDeliveries.EL = electricity;
        if (gas !== '') pointsOfDeliveries.NG = gas;

        await setState(this, {
            contracts: null,
            packages: null,
            products: null,
            selectedElectricityProduct: null,
            selectedGasProduct: null,
            pointsOfDeliveries,
        });
        const res:
            | TGetPackageResponse
            | RequestError = await this.load(getDeliveryPointPackages, [
            getInitialGroup(channel),
            fmtElectricity || null,
            fmtGas || null,
            isIndividual,
            email,
            firstName,
            lastName,
        ]);
        if (res instanceof RequestError) {
            if (
                res instanceof NoMatchingPackagesError ||
                res instanceof NoPackageListError
            ) {
                const resContracts:
                    | TGetPackageResponse
                    | RequestError = await this.load(
                    getRawDeliveryPointPackages,
                    [
                        getInitialGroup(channel),
                        fmtElectricity || null,
                        fmtGas || null,
                        isIndividual,
                        email,
                        firstName,
                        lastName,
                    ],
                );
                const [contracts]: [
                    TContract[] | null,
                    TPackage[] | null,
                    TPrepaymentFrequencies[] | null,
                ] = this.parseGetPackageResponse(resContracts);
                this.setState({ contracts });
            }
            return res;
        }
        const [contracts, packages, frequencies]: [
            TContract[] | null,
            TPackage[] | null,
            TPrepaymentFrequencies[] | null,
        ] = this.parseGetPackageResponse(res);

        this.onReceivePackages(
            !!this.currOrderNbr
                ? contracts &&
                      contracts.map(c => ({ ...c, chosenPackages: [] }))
                : contracts,
            packages,
            frequencies,
            customerType,
            false,
        );

        this.setState({
            loading: false,
            isNewSiteSectionData: !!this.currOrderNbr,
            isNewSiteSectionDataCheckRender: !!this.currOrderNbr,
        });
        return _.get(packages, 'length', 0) > 0;
    }

    async saveOrder() {
        const { group: channel, saveOrder, userId } = this.props;
        const { orderReference } = this.state;
        const pendingOrder = this.buildOrder(false);

        if (!!pendingOrder) {
            pendingOrder.createdBy = userId;
        }
        this.setState({ error: null });
        if (!pendingOrder) {
            console.error('Could not save order: missing parameter');
            return;
        } else if (!!orderReference) {
            _.set(pendingOrder, 'orderReference', orderReference);
        }
        const res: RequestError | TSaveOrderResponse = await this.load(
            saveOrder,
            [this.state.channel, pendingOrder],
            handlerProps => {
                this.setState({
                    error: {
                        title: Wording.saveOrder.title,
                        text:
                            handlerProps.message ||
                            Wording.saveOrder.defaultText,
                    },
                });
            },
        );
        if (!(res instanceof RequestError)) {
            this.setState({
                didSaveOrder: true,
                orderReference: _.get(res, 'orderNumber', null),
            });
        }
    }
    async signOrder() {
        const { group: channel, signOrder, userId } = this.props;
        const { orderReference, pendingOrder } = this.state;
        this.setState({ pendingOrder: null });
        const order: TOrder | null = this.buildOrder(true);

        if (!!order && !!orderReference) {
            _.set(order, 'orderReference', orderReference);
        }
        const previousExternalReference: string | undefined = _.get(
            pendingOrder,
            'validation.orderExternalReference',
        );
        if (
            !!order &&
            !!pendingOrder &&
            !!previousExternalReference &&
            _.isEqual(order, _.omit(pendingOrder.data, 'createdBy'))
        ) {
            order.externalReference = previousExternalReference;
        } else if (!order) {
            console.error('Cannot sign order: missing order');
            const text = Wording.signOrder.defaultText;
            const title = Wording.signOrder.title;
            this.setState({ error: { text, title } });
            return;
        }

        const res: TSignOrderResponse | RequestError = await this.load(
            signOrder,
            [this.state.channel, order, userId],
            () => {
                this.setState({
                    error: {
                        title: Wording.signOrder.title,
                        text: Wording.signOrder.defaultText,
                    },
                });
            },
        );
        if (res instanceof RequestError) return;
        this.setState({
            orderReference: res.orderNumber || null,
            pendingOrder: {
                data: order,
                number: res.orderNumber,
                validation: res,
            },
            shouldDisplayPaymentIframe: true,
        });
    }
    triggerSignaturePopin() {
        const didCompleteSubcription = this.didCompleteSubcription();

        const didSignOrder = this.didSignOrder();
        this.setState({ error: null });
        if (didCompleteSubcription) {
            return;
        }
        if (didSignOrder) {
            this.setState({ shouldDisplayPaymentIframe: true });
        } else {
            this.signOrder();
        }
    }
    render() {
        const {
            contracts,
            situation,
            currentKey,
            didSaveOrder,
            error,
            firstPaymentCBStatus,
            isIndividual,
            isSurvey,
            loading,
            offers,
            orderStatus,
            orderReference,
            packages,
            pendingOrder,
            finalContracts,
            previousOrder,
            products,
            sections,
            selectedElectricityProduct,
            selectedGasProduct,
            shouldDisplayPaymentIframe,
            shouldDisplayResetModal,
            shouldDisplaySurveyModal,
            siteOptionSelection,
            gaugeProps,
            prepaymentFrequencies,
            isNewSiteSectionData,
            isNewSiteSectionDataCheckRender,
            channel,
            isPackageValid,
            deliveryPointPackages,
        } = this.state;

        const electricityEnabled =
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS ||
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY;
        const gasEnabled =
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS ||
            siteOptionSelection === ESubscriptionTypes.GAS;
        const [
            orderStatusStrs,
            firstPaymentCBStatusStrs,
        ] = this.getStatusWording(orderStatus, firstPaymentCBStatus);
        const shouldShowSiteSection = this.shouldShow(Sections.Site);
        const shouldShowOfferSection = this.shouldShow(Sections.Offer);
        const shouldShowStatusSection = this.shouldShow(Sections.Situation);
        const shouldShowRecapSection =
            this.shouldShow(Sections.Recap) && !isNewSiteSectionDataCheckRender;
        const shouldShowMissingInformationSection = this.shouldShow(
            Sections.MissingInformations,
        );
        const shouldShowSubscribeSection = this.shouldShow(Sections.Subscribe);
        const cyclicalProducts: EEnergyTypes[] = [];
        if (isCyclicalProduct(selectedElectricityProduct)) {
            cyclicalProducts.push(EEnergyTypes.ELECTRICTY);
        }
        if (isCyclicalProduct(selectedGasProduct)) {
            cyclicalProducts.push(EEnergyTypes.GAS);
        }
        const signatureEnabled: boolean = this.shouldAllowSignOrder();
        const shouldApplyMask = this.didSignOrder();
        const shouldHighlightSignButton =
            shouldApplyMask && !loading && !this.didCompleteSubcription();
        const initialClientData: ClientSectionData | undefined = !!sections.get(
            Sections.ClientData,
        )
            ? ((sections.get(Sections.ClientData) as Section)
                  .initialData as ClientSectionData)
            : undefined;
        const initialSiteData: SiteSectionData | undefined = !!sections.get(
            Sections.Site,
        )
            ? ((sections.get(Sections.Site) as Section)
                  .initialData as SiteSectionData)
            : undefined;
        const initialOfferData: OfferSectionData | undefined =
            !!sections.get(Sections.Offer) && !isNewSiteSectionData
                ? ((sections.get(Sections.Offer) as Section)
                      .initialData as OfferSectionData)
                : undefined;
        const initialSituationData:
            | SituationSectionData
            | undefined = !!sections.get(Sections.Situation)
            ? ((sections.get(Sections.Situation) as Section)
                  .initialData as SituationSectionData)
            : undefined;
        const initialRecapData: RecapSectionData | undefined =
            !!sections.get(Sections.Recap) && !isNewSiteSectionDataCheckRender
                ? ((sections.get(Sections.Recap) as Section)
                      .initialData as RecapSectionData)
                : undefined;
        const clientDataSectionKey: string = this.getSectionCurrentKey(
            Sections.ClientData,
        );
        const siteSectionKey: string = this.getSectionCurrentKey(Sections.Site);
        const offerSectionKey: string = this.getSectionCurrentKey(
            Sections.Offer,
        );
        const situationSectionKey: string = this.getSectionCurrentKey(
            Sections.Situation,
        );
        const recapSectionKey: string = this.getSectionCurrentKey(
            Sections.Recap,
        );
        const missingInformationsSectionKey: string = this.getSectionCurrentKey(
            Sections.MissingInformations,
        );
        const subscribeSectionKey: string = this.getSectionCurrentKey(
            Sections.Subscribe,
        );

        return (
            <div className={baseClassName} key={currentKey}>
                {shouldApplyMask && <div className={maskClassName} />}
                {loading && (
                    <div className={bcn([baseClassName, 'loader-background'])}>
                        <LoadingSpinner />
                    </div>
                )}
                <CustomOverlay
                    centered={true}
                    id={paymentIframeId}
                    show={!!shouldDisplayPaymentIframe}
                    showCloseButton
                >
                    <PaymentIframeModal
                        orderReference={orderReference}
                        checkOrderStatus={this.checkOrderStatus}
                        onClose={this.handlePaymentModalDismiss}
                        order={pendingOrder}
                        previousOrder={previousOrder}
                        pay={this.pay}
                    />
                </CustomOverlay>
                <CustomOverlay
                    centered={true}
                    id={resetModalId}
                    show={shouldDisplayResetModal}
                >
                    <ResetModal
                        onBack={this.handleResetCancel}
                        onConfirm={this.reset}
                    />
                </CustomOverlay>
                <div className={bcn([baseClassName, 'header'])}>
                    <h1>{title}</h1>
                    <img src={ICLogo} />
                </div>
                <div className={bcn([baseClassName, 'body'])}>
                    <TopInformationSection
                        currOrderNbr={this.currOrderNbr}
                        orderStatusStrs={orderStatusStrs}
                        firstPaymentCBStatusStrs={firstPaymentCBStatusStrs}
                    />

                    <ClientDataSection
                        key={clientDataSectionKey}
                        initialParams={initialClientData}
                        isIndividual={isIndividual}
                        onIsIndividualToggle={this.handleIsIndividualToggle}
                        onValidationStatusChange={
                            this.handleClientDataValidation
                        }
                        searchSiret={this.searchSIRET}
                        groupList={this.props.groupList}
                        schemaProps={(this.props as any).schemaProps}
                        handleGroupSelection={this.onSelectedGroup}
                    />
                    <CustomOverlay
                        id={customerFormId}
                        show={shouldDisplaySurveyModal}
                    >
                        <CustomerFormModal
                            onCancel={this.hideModal}
                            onValidation={this.handleSiteModalValidation}
                        />
                    </CustomOverlay>
                    {shouldShowSiteSection && <Separator />}
                    {shouldShowSiteSection && (
                        <SiteSection
                            key={siteSectionKey}
                            didValidateSurvey={isSurvey}
                            initialParams={initialSiteData}
                            onMissingSiteId={this.handleMissingSiteID}
                            PDLRef={this.PDLRef}
                            PCERef={this.PCERef}
                            onSiteOptionSelection={
                                this.handleSiteOptionSelection
                            }
                            onValidationStatusChange={(validated: boolean) => {
                                this.setState({
                                    sections: this.getNextDisplayedSections(
                                        Sections.Offer,
                                        validated,
                                    ),
                                });
                            }}
                            submitSiteData={this.submitSiteData}
                        />
                    )}
                    {shouldShowOfferSection && <Separator />}
                    {shouldShowOfferSection && (
                        <OfferSection
                            key={offerSectionKey}
                            contracts={contracts}
                            electricityEnabled={electricityEnabled}
                            gasEnabled={gasEnabled}
                            initialParams={initialOfferData}
                            onProductSelection={this.handleProductSelection}
                            onValidate={this.handleOfferValidation}
                            packages={packages}
                            products={products}
                            isPackageValid={isPackageValid}
                            deliveryPointPackages={deliveryPointPackages}
                            handleSetGaugeProps={this.handleSetGaugeProps}
                            frequencies={prepaymentFrequencies}
                            handleEstimatesPackagesByClient={
                                this.handleEstimatesPackagesByClient
                            }
                        />
                    )}
                    {shouldShowStatusSection && <Separator />}
                    {shouldShowStatusSection && (
                        <SituationSection
                            key={situationSectionKey}
                            channel={channel}
                            cyclicalProducts={cyclicalProducts}
                            getCalendarAppointment={
                                this.fetchCalendarAppointment
                            }
                            initialParams={initialSituationData}
                            isIndividual={isIndividual}
                            onValidationStatusChange={
                                this.handleSituationValidation
                            }
                            searchAppointmentTimeslot={
                                this.fetchAppointmentTimeslot
                            }
                            siteOptionSelection={siteOptionSelection}
                        />
                    )}
                    {shouldShowRecapSection && <Separator />}
                    {shouldShowRecapSection && (
                        <RecapSection
                            key={recapSectionKey}
                            initialParams={initialRecapData}
                            offers={offers}
                            onCodeValidation={this.handleCodeValidation}
                            validateSponsorCode={this.checkSponsor}
                            finalContracts={finalContracts}
                            gaugeProps={gaugeProps}
                        />
                    )}
                    {shouldShowMissingInformationSection && <Separator />}
                    {shouldShowMissingInformationSection && (
                        <MissingInformationSection
                            key={missingInformationsSectionKey}
                            requiresPCE={gasEnabled}
                            requiresPDL={electricityEnabled}
                            isPackageValid={isPackageValid}
                            onValidate={this.handleMissingInformations}
                            PDLRef={this.PDLRef}
                            PCERef={this.PCERef}
                        />
                    )}
                    {shouldShowSubscribeSection && <Separator />}
                    {shouldShowSubscribeSection && (
                        <SubscribeSection
                            orderReference={orderReference}
                            key={subscribeSectionKey}
                            didSaveOrder={didSaveOrder}
                            firstPaymentCBStatus={firstPaymentCBStatus}
                            highlightSignature={shouldHighlightSignButton}
                            orderStatus={orderStatus}
                            saveOrder={this.saveOrder}
                            signatureEnabled={signatureEnabled}
                            triggerPopin={this.triggerSignaturePopin}
                            isPackageValid={isPackageValid}
                            finalContracts={finalContracts as TContract[]}
                            PDLRef={this.PDLRef}
                            PCERef={this.PCERef}
                            contracts={contracts as TContract[]}
                            situation={situation as Situation}
                        />
                    )}
                    {!!error && (
                        <CustomError text={error.text} title={error.title} />
                    )}
                </div>
                <div className={bcn([baseClassName, 'footer'])} />
                {!loading && (
                    <CustomButton
                        color={'gray'}
                        onClick={this.handleReset}
                        title={Wording.reset}
                    />
                )}
            </div>
        );
    }
}

export default AdminSubscriptionContainer;
