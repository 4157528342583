import React, { FC } from 'react';
import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';

import ArrowIcon from '../../../../../assets/icons/PDFs/ProofOfPayment/arrow.png';
import Wording from '../../wording.json';

const { beneficiaryInfo } = Wording;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 110,
        marginBottom: 10,
        marginTop: 10,
    },

    img: {
        objectFit: 'cover',
        width: 25,
        height: 30,
    },

    text: {
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 11,
    },
});

const BeneficiaryTitle: FC = () => (
    <View style={styles.container}>
        <Image src={ArrowIcon} style={styles.img} />

        <Text style={styles.text}>{beneficiaryInfo.title}</Text>
    </View>
);

export default BeneficiaryTitle;
