import React, { useRef, useState } from 'react';
import { InputEvent, SelectOption, TSchemaProps } from 'Types';
import WordingConstant from '../../../utils/wording.json';
import {
    CustomInput,
    CustomButton,
    CustomSelect,
    Group,
    CustomModal,
} from '../../../components';
import CustomDate from '../../../components/admin/CustomDate';
import {
    getDateFormatted,
    getSelectOptionValue,
    isLimitedAuthorization,
    isMixedAuthorization,
} from '../../../utils/helpers';
import { TOrderPayload } from '../../../_actions/searchOrder.actions';
import _ from 'lodash';
import { isObject } from 'util';
import { CSVLink } from 'react-csv';
import { getCasesHistory } from '../../../utils/network/request';
import * as RequestErrors from '../../../utils/network/errors';
import Modal from '../../../components/Modal';
import { casesExtractAsync } from '../../../_actions/auth.actions';
import { connect } from 'react-redux';

const Wording = WordingConstant.SearchCaseContainer;

const situationStatus: Array<SelectOption<any>> = [
    { id: '0', label: 'CHF', value: 'SWITCH' },
    { id: '1', label: 'MES', value: 'MOVE_IN' },
];

type Props = {
    fetchOrder: (props: TOrderPayload) => void;
    groupList: string[];
    schemaProps: TSchemaProps;
    userId: string | undefined;
    userLimited: string | undefined;
} & typeof dispatchProps;

const mapStateToProps = () => ({});
const dispatchProps = {
    casesExtractRequestDispatch: casesExtractAsync.request,
    casesExtractSuccessDispatch: casesExtractAsync.success,
    casesExtractFailureDispatch: casesExtractAsync.failure,
};

const SearchCaseContainer: React.FC<Props> = ({
    fetchOrder,
    userId,
    userLimited,
    groupList,
    schemaProps,
    casesExtractFailureDispatch,
    casesExtractRequestDispatch,
    casesExtractSuccessDispatch,
}) => {
    const isDisabledActionBtn = isLimitedAuthorization(schemaProps);
    const isMixed = isMixedAuthorization(schemaProps);
    const csvLinkRef = useRef<
        CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }
    >();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [CSVHeaders, setCSVHeaders] = useState<
        Array<{ label: string; key: string }>
    >([{ label: 'toto', key: 'key' }]);
    const [CSVData, setCSVData] = useState<any[]>([{ oui: 'oui', non: 'non' }]);

    const renderDefaultGroup = () => {
        const defaultVal = {
            id: 0,
            label: schemaProps.currentGroup,
            value: schemaProps.currentGroup,
        };

        if (isMixed) {
            return [defaultVal];
        }

        return isDisabledActionBtn ? defaultVal : '';
    };

    const isLimited = userLimited ? userLimited === 'LIMITED' : false;

    const [caseId, setCaseId] = useState<null | string>(null || '');
    const [clientLastName, setclientLastName] = useState<null | string>(
        null || '',
    );
    const [clientFirstName, setClientFirstName] = useState<null | string>(
        null || '',
    );
    const [refExt, setRefExt] = useState<null | string>(null || '');
    const [customerPhone, setCustomerPhone] = useState<null | string>(
        null || '',
    );
    const [customerEmail, setCustomerEmail] = useState<null | string>(
        null || '',
    );
    const [createdBy, setCreatedBy] = useState<null | string>(
        isLimited ? userId || '' : null || '',
    );

    const [customerGroup, setCustomerGroup] = useState<
        '' | SelectOption<string> | Array<SelectOption<string>>
    >(renderDefaultGroup());

    const [customerSituation, setCustomerSituation] = useState<
        '' | SelectOption<string>
    >('');

    const [fromDate, setFrom] = useState<undefined | Date>(undefined);
    const [toDate, setTo] = useState<undefined | Date>(undefined);

    const [showGroupErrorMesage, setShowErroGroup] = useState(false);

    const renderValueGroup = () => {
        if (isDisabledActionBtn) {
            return (customerGroup as SelectOption<string>).value;
        }

        if (customerGroup !== '' && isObject(customerGroup)) {
            return (customerGroup as Array<SelectOption<string>>).map(
                (obj: SelectOption<string>) => obj.value,
            );
        }

        return '';
    };

    const groupeValue = renderValueGroup();

    const groupeCondition = groupeValue !== '' && groupeValue.length !== 0;

    const searchCondition =
        caseId !== '' ||
        clientFirstName !== '' ||
        clientLastName !== '' ||
        refExt !== '' ||
        createdBy !== '' ||
        fromDate !== undefined ||
        toDate !== undefined ||
        customerEmail !== '' ||
        customerSituation !== '' ||
        customerPhone !== '';

    const mainCondition = isMixed
        ? groupeCondition
        : searchCondition || groupeCondition;

    const elementMap = {
        caseId: {
            text: Wording.num_case,
            value: caseId,
            onInputChange: (e: InputEvent) => setCaseId(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setCaseId(''),
        },

        to: {
            text: '',
            selectedDay: toDate,
            handleDayChange: (date: Date) => setTo(date),
            deleteButton: true,
            deleteButtonOnClick: () => setTo(undefined),
        },

        from: {
            text: '',
            selectedDay: fromDate,
            handleDayChange: (date: Date) => setFrom(date),
            deleteButton: true,
            deleteButtonOnClick: () => setFrom(undefined),
        },

        customerGroup: {
            id: 'group',
            options: _.map(groupList, (group: string, id: number) => ({
                id,
                label: group,
                value: group,
            })),

            withSelectAll: true,

            handleChangeSelect: (group: any) => {
                setCustomerGroup(group);
                if (isMixed) setShowErroGroup(false);
            },
            error:
                isMixed && !groupeCondition && showGroupErrorMesage
                    ? 'Veuillez sélectionner un Groupe'
                    : undefined,
            value: customerGroup,
            text: Wording.group,
            placeholder: ' ',
            isMulti: true,
            schemaProps,
        },

        clientFirstName: {
            text: Wording.clientFirstName,
            value: clientFirstName,
            onInputChange: (e: InputEvent) =>
                setClientFirstName(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setClientFirstName(''),
        },

        clientLastName: {
            text: Wording.clientLastName,
            value: clientLastName,
            onInputChange: (e: InputEvent) =>
                setclientLastName(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setclientLastName(''),
        },

        customerSituation: {
            id: 'situation',
            defaultValue: '',
            placeholder: 'Sélectionner',
            options: situationStatus,
            handleChangeSelect: (situation: SelectOption<string>) => {
                setCustomerSituation(situation);
            },
            value: customerSituation,
            text: Wording.situation,
        },

        refExt: {
            text: Wording.client_ref,
            value: refExt,
            onInputChange: (e: InputEvent) => setRefExt(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setRefExt(''),
        },

        customerPhone: {
            text: Wording.clientPhoneNbr,
            value: customerPhone,
            onInputChange: (e: InputEvent) =>
                setCustomerPhone(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setCustomerPhone(''),
        },

        customerEmail: {
            text: Wording.email,
            value: customerEmail,
            onInputChange: (e: InputEvent) =>
                setCustomerEmail(e.currentTarget.value),
            deleteButton: true,
            deleteButtonOnClick: () => setCustomerEmail(''),
        },
        createdBy: {
            text: Wording.createdBy,
            value: createdBy,
            onInputChange: (e: InputEvent) =>
                setCreatedBy(e.currentTarget.value),
            isLocked: isLimited,
            deleteButton: !isLimited,
            deleteButtonOnClick: () => setCreatedBy(''),
        },

        btn_validate: {
            title: Wording.btn_validate,
            color: mainCondition ? 'orange' : 'gray',
            small: true,
            onClick: () => {
                if (mainCondition) {
                    fetchOrder({
                        fromDate: getDateFormatted(fromDate),
                        toDate: getDateFormatted(toDate),
                        email: customerEmail,
                        orderNumber: caseId,
                        firstName: clientFirstName,
                        lastName: clientLastName,
                        externalReference: refExt,
                        processType: getSelectOptionValue(
                            customerSituation as SelectOption<string>,
                        ),

                        channel: groupeValue,
                        phone: customerPhone,
                        createdBy,
                    });

                    setShowErroGroup(false);
                    return;
                }

                if (isMixed && !groupeCondition) {
                    setShowErroGroup(true);
                }
            },
        },

        btn_reset: {
            title: Wording.button_reset,
            small: true,
            color: 'gray',
            onClick: () => {
                setCaseId('');
                setclientLastName('');
                setClientFirstName('');
                setRefExt('');
                setFrom(undefined);
                setTo(undefined);
                setCustomerPhone('');
                setCustomerEmail('');
                setCustomerSituation('');
                setCreatedBy('');
                setShowErroGroup(false);

                if (!isDisabledActionBtn) {
                    setCustomerGroup('');
                }
            },
        },
    };

    const getHistoryCSVHeaders = (headersArray: [string]) => {
        const headers: Array<{ label: string; key: string }> = [];
        headersArray.map(header => {
            const newHeader = { label: '', key: '' };
            newHeader.label = header;
            newHeader.key = header;
            headers.push(newHeader);
        });
        return headers;
    };

    const getHistoryCSVData = (splittedData: string[]) => {
        const headers = splittedData[0].split(',');
        splittedData.shift();
        const data = splittedData.map(row => {
            const splittedRow = row.split(',');
            const newObject: any = {};
            splittedRow.map((elem, index) => {
                return (newObject[`${headers[index]}`] = elem);
            });
            return newObject;
        });
        return data;
    };

    const prepareHistoryDownload = async () => {
        casesExtractRequestDispatch(null);
        const rawData: any = await getCasesHistory();
        if (!rawData) {
            casesExtractFailureDispatch(null);
            setShowErrorModal(true);
            return null;
        }
        if (rawData instanceof RequestErrors.RequestError) {
            dispatchProps.casesExtractFailureDispatch(null);
            setShowErrorModal(true);
            return null;
        }
        const parsedData = JSON.parse(JSON.stringify(rawData));
        const splittedData = parsedData.data.split('\n');

        const headers = getHistoryCSVHeaders(splittedData[0].split(','));
        const data = getHistoryCSVData(splittedData);

        setCSVData(data);
        setCSVHeaders(headers);
        if (csvLinkRef && csvLinkRef.current && csvLinkRef.current.link) {
            dispatchProps.casesExtractSuccessDispatch(null);
            csvLinkRef.current.link.click();
        }
        return { headers, data };
    };

    const className = 'custom-table__downloadLink';
    const filename = 'cases_history.csv';
    return (
        <div className="search-case-container">
            <div className="search-case-container__container">
                <div className="search-case-container__block">
                    <CustomInput {...elementMap.caseId} />
                    <label>{Wording.from}</label>
                    <CustomDate {...elementMap.from} />
                    <Group {...elementMap.customerGroup} />
                    {schemaProps.currentGroup === 'ADMIN' && (
                        <div className={'download-button'}>
                            <CustomButton
                                title={"Télécharger l'historique"}
                                color={'orange'}
                                onClick={prepareHistoryDownload}
                            />
                        </div>
                    )}
                </div>
                <div className="search-case-container__block">
                    <CustomInput {...elementMap.clientLastName} />
                    <label>{Wording.to}</label>
                    <CustomDate {...elementMap.to} />
                    <CustomSelect {...elementMap.customerSituation} />
                </div>

                <div className="search-case-container__block">
                    <CustomInput {...elementMap.clientFirstName} />
                    <CustomInput {...elementMap.refExt} />
                    <CustomInput {...elementMap.createdBy} />
                </div>

                <div className="search-case-container__block">
                    <CustomInput {...elementMap.customerPhone} />
                    <CustomInput {...elementMap.customerEmail} />
                    <div className="search-rs-vl-btn">
                        <CustomButton {...elementMap.btn_reset} />

                        <CustomButton {...elementMap.btn_validate} />
                    </div>
                    <CSVLink
                        ref={csvLinkRef as any}
                        className={className}
                        data={CSVData}
                        filename={filename}
                        separator={','}
                        headers={CSVHeaders}
                        asyncOnClick={true}
                    ></CSVLink>
                    <CustomModal show={showErrorModal}>
                        <span
                            className="btn-close"
                            onClick={() => {
                                setShowErrorModal(false);
                            }}
                        >
                            {'X'}
                        </span>
                        <div className="modal-response">
                            <p className="code">
                                {WordingConstant.Errors.default}
                            </p>

                            <p className="message">Veuillez reessayer</p>
                            <div className="search-rs-vl-btn">
                                <CustomButton
                                    title={'Fermer'}
                                    onClick={() => {
                                        setShowErrorModal(false);
                                    }}
                                    color={'orange'}
                                />
                            </div>
                        </div>
                    </CustomModal>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, dispatchProps)(SearchCaseContainer);
