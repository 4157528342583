import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';
import { RootState } from 'Types';
import { EEnergy, ECustomerType, EModeManageOffer } from '../../utils/enums';

import withBanner, {
    ExternalProps as ExternalPropsBanner,
} from '../../libraries/withBanner';
import withModal, {
    ExternalProps as ExternalPropsModal,
    InjectedProps as InjectedPropsModal,
} from '../../libraries/withModal';

import PackageContainer, {
    Props as ContainerProps,
} from '../../containers/PackageContainer/PackageContainer';
import {
    fetchContractAsync,
    resetContractState,
    setContract,
} from '../../_actions/contract.actions';
import {
    fetchPackageAsync,
    fetchProductAsync,
    updatePackageAsync,
    resetUpdate,
    IPayloadUpdatePackage as PackagePayload,
} from '../../_actions/package.actions';

import { findContractByNumber, getTodayDate } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
import { TEstimate } from 'Models';

const Wording = WordingConstant.PackagePage;

const mapStateToProps = (state: RootState) => ({
    authState: state.authReducer,
    profileState: state.profileReducer,
    contractState: state.contractReducer,
    packageState: state.packageReducer,
});

const dispatchProps = {
    contractDispatch: fetchContractAsync.request,
    fetchPackageDispatch: fetchPackageAsync.request,
    fetchProductDispatch: fetchProductAsync.request,
    updatePackageDispatch: updatePackageAsync.request,
    resetContractDispatch: resetContractState,
    resetUpdateDispatch: resetUpdate,
    setContractDispatch: setContract,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const WrappedPackage = compose<
    ContainerProps,
    Subtract<ContainerProps, InjectedPropsModal> &
        ExternalPropsBanner &
        ExternalPropsModal
>(
    withBanner(),
    withModal(),
)(PackageContainer);

export type TCurrentActionMode = 'packageID' | 'option' | 'frequency';
type State = {
    options: [];
    additionalOptions: [];
    payload?: PackagePayload;
    modalMode: TCurrentActionMode;
};

class PackagePage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            options: [],
            additionalOptions: [],
            payload: undefined,
            modalMode: 'packageID',
        };
    }

    componentDidMount() {
        const {
            fetchProductDispatch,
            profileState: { customerType },
            contractState: { contracts, selectedContractNumber },
        } = this.props;
        const contract = findContractByNumber(
            selectedContractNumber,
            contracts,
        );

        fetchProductDispatch({
            customerType: customerType as ECustomerType,
            contracts: [contract],
        });
    }

    componentDidUpdate(prevProps: Props) {
        const { contractState } = prevProps;
        const {
            fetchProductDispatch,
            profileState: { customerType },
            contractState: { contracts, selectedContractNumber },
        } = this.props;
        if (selectedContractNumber !== contractState.selectedContractNumber) {
            const contract = findContractByNumber(
                selectedContractNumber,
                contracts,
            );
            fetchProductDispatch({
                customerType,
                contracts: [contract],
            });
        }
    }

    handleFetchPackage = () => {
        const {
            fetchPackageDispatch,
            profileState: { customerType },
            contractState: { contracts, selectedContractNumber },
        } = this.props;
        const contract = findContractByNumber(
            selectedContractNumber,
            contracts,
        );

        const newEstimates =
            contract.estimates &&
            contract.estimates.map((e: TEstimate) => {
                return {
                    ...e,
                    energy: contract.energy,
                };
            });

        fetchPackageDispatch({
            customerType: customerType as ECustomerType,
            contractNbr: selectedContractNumber,
            estimates: newEstimates as TEstimate[],
            energy: contract.energy as EEnergy,
            reference: contract.deliveryPoint.pointOfDelivery,
        });
    };

    prepareEvent = () => {
        const { payload } = this.state;
        const {
            profileState: { customerNumber },
            contractState: { selectedContractNumber },
        } = this.props;

        let subCategory = '';
        let message = '';

        if (payload) {
            switch (payload.mode) {
                case EModeManageOffer.INSTALLMENT_FREQUENCY: {
                    subCategory =
                        Wording.subCategories.prepaymentFrequency.value;
                    message = Wording.subCategories.prepaymentFrequency.label;
                    break;
                }

                case EModeManageOffer.PACKAGE: {
                    if (
                        payload.chosenPackages &&
                        payload.chosenPackages.length === 2
                    ) {
                        subCategory =
                            Wording.subCategories.seasonalityThreshold.value;
                        message =
                            Wording.subCategories.seasonalityThreshold.label;
                    } else {
                        subCategory = Wording.subCategories.packageChange.value;
                        message = Wording.subCategories.packageChange.label;
                    }

                    break;
                }

                case EModeManageOffer.OPTIONS: {
                    if (payload.option) {
                        switch (payload.option.label) {
                            case Wording.eventCheckOptions.saveTree:
                                subCategory = payload.option.active
                                    ? Wording.subCategories.saveTreeAdd.value
                                    : Wording.subCategories.saveTreeSupp.value;

                                message = payload.option.active
                                    ? Wording.subCategories.saveTreeAdd.label
                                    : Wording.subCategories.saveTreeSupp.label;
                                break;

                            case Wording.eventCheckOptions.green:
                                subCategory = payload.option.active
                                    ? Wording.subCategories.greenAdd.value
                                    : Wording.subCategories.greenSupp.value;

                                message = payload.option.active
                                    ? Wording.subCategories.greenAdd.label
                                    : Wording.subCategories.greenSupp.label;
                                break;

                            case Wording.eventCheckOptions.plastic:
                                subCategory = payload.option.active
                                    ? Wording.subCategories.plasticAdd.value
                                    : Wording.subCategories.plasticSupp.value;

                                message = payload.option.active
                                    ? Wording.subCategories.plasticAdd.label
                                    : Wording.subCategories.plasticSupp.label;
                                break;

                            default:
                                message = Wording.subCategories.default.label;
                                subCategory = '';
                                break;
                        }
                    }
                    break;
                }
                case EModeManageOffer.PRODUCT: {
                    subCategory = Wording.subCategories.changeOffer.value;
                    message = Wording.subCategories.changeOffer.label;
                }

                default:
                    break;
            }
        }

        return {
            customerNbr: customerNumber,
            contractNbr: selectedContractNumber,
            category: Wording.categoryToSend,
            date: getTodayDate(),
            subCategory,
            message,
        };
    };

    handleUpdate = () => {
        const { payload } = this.state;
        if (payload) {
            const {
                profileState: { customerNumber },
                contractState: { contracts, selectedContractNumber },
                updatePackageDispatch,
            } = this.props;
            const contract = findContractByNumber(
                selectedContractNumber,
                contracts,
            );

            const event = this.prepareEvent();

            updatePackageDispatch({
                customerNbr: customerNumber,
                contract,
                event,
                ...payload,
            });
        }
    };

    handleChangeBanner = (contractNumber: string) => {
        const {
            contractState: { contracts },
            contractDispatch,
            profileState: { customerNumber },
            setContractDispatch,
        } = this.props;
        const contract = findContractByNumber(contractNumber, contracts);
        if (contract.isFetched) {
            setContractDispatch({ contract });
        } else {
            contractDispatch({
                customerNbr: customerNumber,
                contractNbr: contractNumber,
            });
        }
    };

    renderWordingModalByMode = () => {
        const { modalMode } = this.state;
        return modalMode === 'option' ? Wording.popup_option : Wording.popup;
    };

    handleWordingModalMyMode = (mode: TCurrentActionMode) => {
        this.setState({ modalMode: mode });
    };

    render() {
        const {
            profileState: { contact, customerType },
            contractState: { contracts, selectedContractNumber, error },
            authState: { groupe },
            packageState: {
                loadingPackage,
                loadingOffer,
                pkg,
                products,
                offer,
                prepaymentFrequencies,
                updateResponse,
                description,
            },
            resetUpdateDispatch,
            resetContractDispatch,
        }: Props = this.props;

        const contract = findContractByNumber(
            selectedContractNumber,
            contracts,
        );

        const packages = pkg[selectedContractNumber];
        const currentOffer = contract.offer || offer[selectedContractNumber];
        const pf = prepaymentFrequencies[selectedContractNumber];

        return (
            <WrappedPackage
                handleWordingModalMyMode={this.handleWordingModalMyMode}
                loadingPackage={loadingPackage}
                loadingOffer={loadingOffer}
                contract={contract}
                packages={packages}
                products={products}
                groupe={groupe}
                prepaymentFrequencies={pf}
                offer={currentOffer}
                handleFetchPackage={this.handleFetchPackage}
                handleSetPayload={(payload: PackagePayload) =>
                    this.setState({
                        payload: {
                            ...payload,
                            customerType,
                        },
                    })
                }
                // Banner
                title={Wording.title}
                bannerError={error}
                handleCloseModalBanner={() => resetContractDispatch()}
                selectedContractNumber={selectedContractNumber}
                contracts={contracts}
                contact={contact}
                customerType={customerType}
                handleChangeBanner={this.handleChangeBanner}
                // Modal
                modalMessage={this.renderWordingModalByMode()}
                customDescription={description}
                updateResponse={updateResponse}
                handleValidate={this.handleUpdate}
                handleReset={() => resetUpdateDispatch()}
            />
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(PackagePage);
