import React, { useState } from 'react';
import map from 'lodash/map';
import { InputEvent, TSchemaProps } from 'Types';
import { TResponse } from 'Models';
import WordingConstant from '../../../utils/wording.json';
import {
    CustomInput,
    CustomButton,
    CustomModal,
    CustomSelect,
    CustomRadio,
    Group,
    AuthorizationSchemaGroup,
} from '../../../components';
import {
    isValidString,
    formatGroupString,
    formatGroupNameString,
    isFullAuthorization,
    getInitialGroup,
    getAllInitialGroups,
} from '../../../utils/helpers';
import { EResponseCode, EGroups } from '../../../utils/enums';
import keys from 'lodash/keys';
import {
    TAuthorizationSchema,
    TGroupPayload,
} from '../../../_actions/createGroup.actions';

const Wording = WordingConstant.UserAdministrationResult;
type ErrorTypeState = 'GROUP' | 'SUB_GROUP' | 'MENU' | 'SUB_MENU';

type Props = {
    isOpen: boolean;
    schemaProps: TSchemaProps;
    setOpen: (value: boolean) => void;
    createGroup: (prop: TGroupPayload) => void;
    createGroupResponse: TResponse;
    groupList: string[];

    resetGroupResponse: () => void;
};

function getGroupNameError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateGroupModal.errors.emptyField;
    }
    return error;
}

const getSchemaSubMenuError = (schema?: any[]) => {
    let error = '';

    if (schema) {
        const validationError = schema.reduce(
            (acc, curr) => acc && curr.subMenu.length > 0,
            true,
        );

        if (!validationError) {
            error = Wording.CreateGroupModal.errors.subMenuError;
        }
    }

    return error;
};

const CreateGroupModal: React.FC<Props> = ({
    isOpen,
    setOpen,
    createGroup,
    createGroupResponse,
    groupList,
    schemaProps,
    resetGroupResponse,
}) => {
    const isLimitedGroup = schemaProps.groupMode === EGroups.LIMITED;

    const [groupName, setGroupName] = useState<string>(
        isLimitedGroup ? getInitialGroup(schemaProps.currentGroup) : '',
    );
    const [subGroup, setSubGroup] = useState<string>('');
    const [errorsState, setErrorState] = useState<any>({});
    const [authorisationType, setType] = useState<boolean>(false);
    const [authorizationSchema, setSchema] = useState<
        undefined | TAuthorizationSchema[]
    >(undefined);

    const { code, message } = createGroupResponse;

    const setError = (type: ErrorTypeState, errorMessage: string) =>
        setErrorState({ ...errorsState, [type]: errorMessage });

    const renderFinalGroup = () =>
        subGroup !== '' ? `${groupName}_${subGroup}` : groupName;

    const manageErrors = () => {
        const prepareObject: any = {};

        const groupNameErr = getGroupNameError(groupName);
        const subGroupErr =
            isLimitedGroup && subGroup === ''
                ? Wording.CreateGroupModal.errors.emptySubGroup
                : '';

        const menuSchemaError =
            authorisationType && authorizationSchema === undefined
                ? Wording.CreateGroupModal.errors.menuError
                : '';
        const subMenuSchemaError = getSchemaSubMenuError(authorizationSchema);

        let isValidMenu = true;

        if (groupNameErr !== '') {
            prepareObject.GROUP = groupNameErr;
        }

        if (subGroupErr !== '') {
            prepareObject.SUB_GROUP = subGroupErr;
        }

        if (menuSchemaError !== '') {
            prepareObject.MENU = menuSchemaError;
            isValidMenu = false;
        }

        if (subMenuSchemaError !== '' && isValidMenu) {
            prepareObject.SUB_MENU = subMenuSchemaError;
        }

        setErrorState(prepareObject);

        return keys(prepareObject).length === 0;
    };

    const handleCloseModal = () => {
        if (isLimitedGroup) {
            setSubGroup('');
        } else {
            setSubGroup('');
            setGroupName('');
        }
        setErrorState(undefined);
        setOpen(false);
        setSchema(undefined);
    };

    const elementMap = {
        subGroup: {
            text: '',
            value: subGroup,
            onInputChange: (e: InputEvent) => {
                setSubGroup(e.currentTarget.value.toUpperCase());
            },
            error: errorsState ? errorsState.SUB_GROUP : undefined,
        },
        btn_validate: {
            title: Wording.btnValidate,
            color: 'gray',
            small: true,
            onClick: () => {
                const gn = renderFinalGroup();

                const createGroupCondition = manageErrors();

                if (createGroupCondition) {
                    createGroup({
                        group: gn.trim(),
                        autorizationsScheme: authorisationType
                            ? (authorizationSchema as TAuthorizationSchema[])
                            : undefined,
                    });
                    handleCloseModal();
                }
            },
        },

        btn_reset: {
            title: Wording.buttonReset,
            color: 'orange',
            small: true,
            onClick: () => {
                handleCloseModal();
            },
        },

        btn_hide_modal: {
            title: Wording.hideModal,
            color: 'orange',
            small: true,
            onClick: () => {
                handleCloseModal();
                resetGroupResponse();
            },
        },
    };

    const createGroupModalResponse = () => {
        const { code, message } = createGroupResponse;
        return (
            <CustomModal show={true}>
                <span
                    className="btn-close"
                    onClick={() => {
                        handleCloseModal();
                        resetGroupResponse();
                    }}
                >
                    {'X'}
                </span>
                <div className="modal-response">
                    <p className="code">
                        {code === EResponseCode.SUCCESS
                            ? Wording.CreateGroupModal.succesMessage
                            : Wording.CreateGroupModal.failureMessage}
                    </p>

                    <p className="message">{message}</p>

                    <div className="search-rs-vl-btn">
                        <CustomButton {...elementMap.btn_hide_modal} />
                    </div>
                </div>
            </CustomModal>
        );
    };
    const isFull = isFullAuthorization(schemaProps);

    const renderAuthorizationSchema = () =>
        isFull && (
            <>
                <p className="title">
                    {Wording.CreateGroupModal.authtorisationTitle}
                </p>
                <div className="radio-buttons">
                    <CustomRadio
                        text={'Par défaut'}
                        value={'default'}
                        id={'default'}
                        checked={!authorisationType}
                        handleCheck={() => {
                            setType(!authorisationType);
                        }}
                    />

                    <CustomRadio
                        text={'Spécifique'}
                        value={'specific'}
                        id={'specific'}
                        checked={authorisationType}
                        handleCheck={() => {
                            setType(!authorisationType);
                        }}
                    />
                </div>

                {authorisationType && (
                    <AuthorizationSchemaGroup
                        getAuthorizationSchema={setSchema}
                    />
                )}
            </>
        );

    const renderErrors = () => {
        const errs: string[] = [];
        keys(errorsState).forEach(key => {
            if (key !== 'GROUP' && key !== 'SUB_GROUP') {
                errs.push(errorsState[key]);
            }
        });

        return (
            <>
                {errs.map((err, idx) => (
                    <p key={idx} className="create-group-error">
                        {err}
                    </p>
                ))}
            </>
        );
    };

    const renderSubGroup = () =>
        schemaProps.subGroupMode === EGroups.FULL && (
            <>
                <p>{Wording.CreateGroupModal.addSubGroup}</p>
                <CustomInput {...elementMap.subGroup} />
            </>
        );

    const renderTitleSelect = () =>
        isFull
            ? Wording.CreateGroupModal.addorselectGroup
            : Wording.CreateGroupModal.selectGroup;

    const fullName = renderFinalGroup();

    return (
        <div>
            <CustomModal
                customStyle={{
                    height: authorisationType ? '90%' : '60%',
                    width: authorisationType ? '65%' : '50%',
                    zIndex: 1005,
                    overflow: 'auto',
                }}
                className="create-group-modal"
                show={isOpen}
            >
                <span
                    className="btn-close"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    {'X'}
                </span>
                <div className="create-group-modal__content">
                    <p className="title">{Wording.CreateGroupModal.title}</p>

                    <div className="create-group-modal__content__group-selection">
                        <div>
                            <p>{renderTitleSelect()}</p>
                            <CustomSelect
                                options={getAllInitialGroups(groupList)}
                                placeholder=" "
                                onInputChange={(value: string) =>
                                    value.toUpperCase()
                                }
                                handleChangeSelect={(props: any) => {
                                    if (props && props.value) {
                                        setGroupName(props.value.toUpperCase());
                                    }
                                    if (props === null) {
                                        setGroupName('');
                                    }
                                }}
                                isClearable={isFull}
                                error={
                                    errorsState ? errorsState.GROUP : undefined
                                }
                                defaultValue={groupName}
                                value={groupName}
                                disabled={isLimitedGroup}
                            />
                        </div>

                        <div>{renderSubGroup()}</div>
                    </div>

                    {fullName && <p>Votre groupe: {fullName}</p>}

                    {renderAuthorizationSchema()}
                    {renderErrors()}

                    <div className="create-group-modal__content__buttons">
                        <CustomButton {...elementMap.btn_reset} />
                        <CustomButton {...elementMap.btn_validate} />
                    </div>
                </div>
            </CustomModal>
            {code &&
                message !== Wording.errorMessage &&
                createGroupModalResponse()}
        </div>
    );
};

export default CreateGroupModal;
