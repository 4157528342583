import { pdf } from '@react-pdf/renderer';
import React from 'react';
import ProofOfPayment from './ProofOfPayment';
import { ProofPaymentProps } from './type';

export default function(props: ProofPaymentProps) {
    pdf(<ProofOfPayment {...props} />)
        .toBlob()
        .then(blob => {
            window.open(URL.createObjectURL(blob));
        });
}
