import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Location } from 'history';
import { InputEvent, RootState } from 'Types';

import withLoader from '../../libraries/withLoader';
import AuthContainer, {
    IFields,
} from '../../containers/AuthContainer/AuthContainer';

import {
    fetchLoginAsync,
    resetAuthErrors,
    resetPasswordAsync,
} from '../../_actions/auth.actions';

import { isValidEmail } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';
import AuthService from '../../services/auth-service';
const Wording = WordingConstant.AuthPage;

const mapStateToProps = (state: RootState) => ({
    authState: state.authReducer,
});

const dispatchProps = {
    authDispatch: fetchLoginAsync.request,
    resetPasswordDispatch: resetPasswordAsync.request,
    resetAuthErrorsDispatch: () => resetAuthErrors(),
};

type InjectedProps = {
    location: Location;
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    InjectedProps;

type State = {
    fields: IFields;
    toggle: {
        passForgot: boolean;
    };
    slidingMenu: {
        isOpen: boolean;
    };
};

export const WrappedAuth = withLoader()(AuthContainer);

export class AuthPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            fields: {
                login: '',
                password: '',
                email: '',
                errorLogin: undefined,
                errorPassword: undefined,
                errorEmail: undefined,
            },
            toggle: {
                passForgot: false,
            },
            slidingMenu: {
                isOpen: false,
            },
        };
    }

    componentDidMount(): void {
        const { resetAuthErrorsDispatch } = this.props;
        if (resetAuthErrorsDispatch) resetAuthErrorsDispatch();
    }

    handleChange = (e: InputEvent, type: string): void => {
        const { value } = e.currentTarget;
        const { fields } = this.state;
        fields[type] = value;
        this.setState({ fields });
    };

    handleToggle = () => {
        const { toggle } = this.state;
        toggle.passForgot = !toggle.passForgot;
        this.setState({ toggle });
        const { resetAuthErrorsDispatch } = this.props;
        if (resetAuthErrorsDispatch) resetAuthErrorsDispatch();
    };

    handleButtonPassForgot = () => {
        const {
            fields: { email },
        } = this.state;
        const { resetPasswordDispatch } = this.props;
        resetPasswordDispatch({
            email,
        });
    };

    handleButtonLogin = () => {
        const { fields } = this.state;
        const { authDispatch } = this.props;

        localStorage.clear();

        authDispatch({
            user: fields.login.trim(),
            password: fields.password.trim(),
        });
    };

    handleInputError = (): boolean => {
        const {
            fields,
            toggle: { passForgot },
        } = this.state;

        if (passForgot) {
            const isEmail = isValidEmail(fields.email);
            fields.errorEmail = isEmail ? Wording.error.notEmail : undefined;
            this.setState({ fields });
            return isEmail;
        }
        fields.errorLogin =
            fields.login.length <= 2 ? Wording.error.notLogin : undefined;
        fields.errorPassword =
            fields.password.length < 6 ? Wording.error.notPassword : undefined;
        this.setState({ fields });
        return fields.login.length >= 2 && fields.password.length >= 6;
    };

    handleSlidingMenuOpen = () => {
        const { slidingMenu } = this.state;
        slidingMenu.isOpen = !slidingMenu.isOpen;
        this.setState({ slidingMenu });
    };

    sendError = (error: any) => {
        if (
            (error && error.ajaxError !== undefined) ||
            error === 'Unexpected token a in JSON at position 0'
        ) {
            return WordingConstant.Common.errorTitle;
        }
        if (error && error.search('message') !== -1) {
            return JSON.parse(error).message;
        }

        return error;
    };
    render() {
        const { fields, toggle, slidingMenu } = this.state;
        const {
            authState: { loading, error, message },
        }: Props = this.props;

        const isSignIn = AuthService.isAuthenticated();
        const isValidated = AuthService.isVlaidated();
        // Always redirect to dashboard if login succeess

        // Always redirect to createPassword if isValidated false
        if (!isValidated) return <Redirect to="/createPassword" />;
        else if (isSignIn) return <Redirect to="/" />;

        return (
            <WrappedAuth
                loading={loading}
                unauthorized={false}
                handleCloseModal={() => {}}
                responseMessage={{
                    errorMsg: this.sendError(error),
                    msg: message,
                }}
                toggle={toggle}
                fields={fields}
                handleInputChange={this.handleChange}
                handleToggle={this.handleToggle}
                handleButtonLogin={this.handleButtonLogin}
                handleButtonPassForgot={this.handleButtonPassForgot}
                handleInputError={this.handleInputError}
                handleSlidingMenu={this.handleSlidingMenuOpen}
                isSlidingMenuOpen={slidingMenu.isOpen}
            />
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(AuthPage);
