import { Epic } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators';
import {
    createAsyncAction,
    createStandardAction,
    isActionOf,
    ActionType,
} from 'typesafe-actions';

import { RootAction, RootState, Services } from 'Types';
import { TContract, TResponse } from 'Models';

import {
    FETCH_CONTRACT_REQUEST,
    FETCH_CONTRACT_SUCCESS,
    FETCH_CONTRACT_FAILURE,
    RESET_CONTRACT_STATE,
    SET_CONTRACT,
} from './actionTypes';

interface IPayload {
    customerNbr: string;
    contractNbr: string;
    contract?: TContract;
    error?: string;
}

interface IPayloadSetContract {
    contract: TContract;
}

const fetchContractAsync = createAsyncAction(
    FETCH_CONTRACT_REQUEST,
    FETCH_CONTRACT_SUCCESS,
    FETCH_CONTRACT_FAILURE,
)<IPayload, IPayload, TResponse>();

const resetContractState = createStandardAction(RESET_CONTRACT_STATE)();

const setContract = createStandardAction(SET_CONTRACT)<IPayloadSetContract>();

export type ContractAction =
    | ActionType<typeof fetchContractAsync>
    | ActionType<typeof resetContractState>
    | ActionType<typeof setContract>;

const preparePayloadGetContract = ({ customerNbr, contractNbr }: IPayload) => {
    return { customerNbr, contractNbr };
};

const mapGetContract = (action: RootAction, { apiRequest }: Services) => {
    const payload = preparePayloadGetContract(action.payload);
    return apiRequest<IPayload>({
        path: '/getContract',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap((response: IPayload) => {
            if (response) {
                return of(
                    fetchContractAsync.success({
                        ...response,
                        contractNbr: action.payload.contractNbr,
                    }),
                );
            }
            return throwError({ code: '200', message: response });
        }),
        catchError(error => {
            return of(fetchContractAsync.failure(error));
        }),
    );
};

const fetchContractEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    dependency,
) =>
    action$.pipe(
        filter(isActionOf(fetchContractAsync.request)),
        mergeMap(action => mapGetContract(action, dependency)),
    );

export {
    fetchContractEpic,
    fetchContractAsync,
    mapGetContract,
    resetContractState,
    setContract,
};
