import React from 'react';
import CustomerContracSearch, {
    Props as SearchProps,
} from './CustomerContracSearch';
import {
    fetchCustomerContractAsync,
    uploadAmendmentsAsync,
    resetUploadAmendments,
} from '../../../_actions/customerContract.actions';
import WordingConstant from '../../../utils/wording.json';
import { TSchemaProps, RootState } from 'Types';
import { compose } from 'recompose';

import withModal, {
    ExternalProps as ExternalPropsModal,
    InjectedProps as InjectedPropsModal,
} from '../../../libraries/withModal';
import { Subtract } from 'utility-types';
import {
    isUserLimitedBySchema,
    parseUploadAmendments,
} from '../../../utils/helpers';
import { EMenuLabels, ESubMenuCaseLabel } from '../../../utils/enums';
import { connect } from 'react-redux';

const Wording = WordingConstant.CustomerContracSearch;

const dispatchProps = {
    customerContractDispatch: fetchCustomerContractAsync.request,
    uploadAmendmentsDispatch: uploadAmendmentsAsync.request,
    resetUploadDispatch: resetUploadAmendments,
};

const mapStateToProps = (state: RootState) => ({
    updateResponse: state.customersContractReducer.updateResponse,
    schema: state.authReducer.autorizationsScheme,
    userId: state.authReducer.userId,
});

const WrappedSearchContainer = compose<
    SearchProps,
    Subtract<SearchProps, InjectedPropsModal> & ExternalPropsModal
>(withModal())(CustomerContracSearch);

type Props = {
    schemaProps: TSchemaProps;
    groupList: string[];
} & typeof dispatchProps &
    ReturnType<typeof mapStateToProps>;

const SearchContainer: React.FC<Props> = ({
    schemaProps,
    userId,
    groupList,
    schema,
    updateResponse,
    uploadAmendmentsDispatch,
    resetUploadDispatch,
}) => {
    const handleUpdate = (files: any) => {
        if (files.length > 0) {
            parseUploadAmendments(files[0]).then(parsedAmendments => {
                uploadAmendmentsDispatch({
                    riders: parsedAmendments,
                });
            });
        }
    };

    const userLimited = isUserLimitedBySchema(
        EMenuLabels.CASE,
        ESubMenuCaseLabel.SEARCH_CASE,
        schema,
    );

    const getDescriptionStyleByCode = () => {
        switch (updateResponse.code) {
            case '0':
            case '500':
                return { color: '#be2829', fontWeight: 800 };

            case '200':
                return { color: '#008000', fontWeight: 800 };

            default:
                return { color: '#be2829', fontWeight: 800 };
        }
    };

    return (
        <div className="search-container">
            <WrappedSearchContainer
                groupList={groupList}
                userId={userId}
                userLimited={userLimited}
                schemaProps={schemaProps}
                // Modal
                modalMessage={Wording.popup}
                showLogo={updateResponse.code === '200'}
                customDescription={'description'}
                updateResponse={updateResponse}
                handleValidate={handleUpdate}
                handleReset={() => resetUploadDispatch()}
                descriptionStyle={getDescriptionStyleByCode()}
            />
        </div>
    );
};

export default connect(mapStateToProps, dispatchProps)(SearchContainer);
