import React, { useState } from 'react';

import { InputEvent } from 'Types';
import { CustomInput, CustomButton, CustomModal } from '../../components/index';
import { isValidEmail } from '../../utils/helpers';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.ParentingPage;

export type Props = {
    fields: {
        email: string;
        error?: string;
    };
    handleInputChange(e: InputEvent, type: string): void;
    handleInputError(): boolean;
    handleShowModal(section: string): void;
};

const SponsorContainer: React.FC<Props> = ({
    fields: { email, error },
    handleInputChange,
    handleInputError,
    handleShowModal,
}) => {
    const isEmail = isValidEmail(email);

    return (
        <div className="sponsor-container">
            <h2 className="sponsor-container__subtitle">{Wording.subTitle}</h2>
            <p className="sponsor-container__subtitle2">
                {Wording.textParenting}
            </p>
            <p className="sponsor-container__inputTitle">
                {Wording.inputTitle}
            </p>
            <CustomInput
                placeholder={Wording.placholderInput}
                value={email}
                error={error}
                onInputChange={(e: InputEvent) => handleInputChange(e, 'email')}
            />
            <a
                className="sponsor-container__rule"
                href=" https://www.wekiwi.fr/conditions-parrainage"
                target="_blank"
            >
                {Wording.ruleDescription}
            </a>
            <CustomButton
                title={Wording.buttonValue}
                color={isEmail ? 'orange' : 'gray'}
                onClick={() => {
                    if (handleInputError()) {
                        handleShowModal('');
                    }
                }}
            />
        </div>
    );
};

export default SponsorContainer;
