import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { TResponse, TUser } from 'Models';
import * as actions from '../_actions/caseResult.action';

export type RetryFirstPaymentState = Readonly<{
    error?: string;
    retryFirstPaymentResponse: TResponse;
    code?: string;
    message: string;
}>;

const initialState = {
    error: undefined,
    code: '',
    message: '',
    retryFirstPaymentResponse: { code: '', message: '' },
};

type Actions =
    | actions.RetryFirstPaymentAction

const retryFirstPaymentReducer: Reducer<RetryFirstPaymentState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.caseResultRetryFirstPaymentAsync.success): {
            return {
                ...state,
                error: undefined,
                retryFirstPaymentResponse: {
                    code: action.payload.status as string,
                    message: action.payload.message as string,
                },
            };
        }

        case getType(actions.caseResultRetryFirstPaymentAsync.failure): {
            return {
                ...state,
                error: action.payload.message,
                retryFirstPaymentResponse: {
                    code: action.payload.code as string,
                    message: action.payload.message as string,
                },
            };
        }

        case getType(actions.retryFirstPayment): {
            return {
                ...state,
                error: undefined,
                retryFirstPaymentResponse: {
                    code: '',
                    message: '',
                },
            };
        }
        default:
            return { ...state };
    }
};

export default retryFirstPaymentReducer;
