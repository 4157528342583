import React from 'react';

type Params = {
    details: string;
    message: string;
};

// TODO Style the following
const ErrorScreen: React.FC<Params> = ({ details, message }) => {
    return (
        <div className="error-screen">
            <h1>{message}</h1>
            <h3>{details}</h3>
        </div>
    );
};

export default ErrorScreen;
