import React from 'react';

import { CustomModal } from '../../components';
import { getFormattedDate, getYoutubeId } from '../../utils/helpers';

type LinkItem = {
    link: string;
    title: string;
    date?: string;
};

type Props = {
    articles: LinkItem[];
    videos: LinkItem[];
};

type State = {
    showModal: boolean;
    videoId: string;
    title: string;
};
class ArticleVideoContainer extends React.Component<Props, State> {
    state = {
        showModal: false,
        videoId: '',
        title: '',
    };

    displayVideo = () => {
        const { showModal, videoId, title } = this.state;

        if (!showModal) {
            return null;
        }

        return (
            <CustomModal
                show={showModal}
                handleClick={() => this.handleShowModal('', '')}
            >
                <iframe
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                    frameBorder="0"
                    allowFullScreen
                    title={title}
                    width="100%"
                    height="100%"
                />
            </CustomModal>
        );
    };

    stopVideo = () => {
        const iframe = document.querySelector('iframe');
        const video = document.querySelector('video');
        if (iframe !== null) {
            const iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }
        if (video !== null) {
            video.pause();
        }
    };

    handleShowModal = (videoId: string, title: string) => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal, videoId, title });
        if (showModal) {
            this.stopVideo();
        }
    };

    render() {
        const { articles, videos } = this.props;

        return (
            <div className="article-video-container">
                {this.displayVideo()}
                <div className="article-video-container__videos">
                    {videos.map((item: LinkItem, index: number) => {
                        const videoId = getYoutubeId(item.link);
                        return (
                            <div
                                key={index}
                                className="article-video-container__videos__section"
                            >
                                <div
                                    className="article-video-container__videos__section__iframe"
                                    role="button"
                                    onClick={() =>
                                        this.handleShowModal(
                                            videoId,
                                            item.title,
                                        )
                                    }
                                >
                                    <img
                                        src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <p>{item.title}</p>
                            </div>
                        );
                    })}
                </div>
                <div className="article-video-container__articles">
                    {articles.map((item: LinkItem, index: number) => {
                        return (
                            <div
                                key={index}
                                className="article-video-container__articles__section"
                            >
                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {item.title}
                                </a>
                                <p>le {getFormattedDate(item.date || '')}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default ArticleVideoContainer;
