import React from 'react';

type Props = {
    title?: string;
    disabled?: boolean;
    onChange?: any;
    isLoading?: boolean;
    accept?: any;
};

const CustomUpload: React.FC<Props> = ({
    title,
    disabled,
    onChange,
    accept,
    isLoading,
}) => {
    const handleChange = (event: any) => {
        if (event.target.files.length > 0) {
            onChange(event.target.files);
        }
    };

    return (
        <div className="custom-upload">
            <input
                className="custom-upload__input"
                id="upload-button"
                type="file"
                multiple
                accept={accept}
                onChange={handleChange}
                onClick={(event: any) => {
                    event.target.value = null;
                }}
            />
            <label
                id="upload-btn-label"
                htmlFor="upload-button"
                className="custom-upload__button"
            >
                <span>{title}</span>
            </label>
        </div>
    );
};

export default CustomUpload;
