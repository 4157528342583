import React from 'react';

type Props = {
    title?: string;
    color?: string;
    disabled?: boolean;
    onClick?: any;
    arrowLeft?: boolean;
    arrowRight?: boolean;
    isLoading?: boolean;
    heavy?: boolean;
};

const CustomButtonNewGen: React.FC<Props> = ({
    title,
    color,
    disabled,
    onClick,
    arrowLeft,
    arrowRight,
    isLoading,
    heavy,
}) => {
    const classNames: string[] = [
        'custom-button-new-gen',
        `custom-button-new-gen--${color}`,
    ];
    if (!!disabled) classNames.push('custom-button-new-gen__disabled');
    if (isLoading) classNames.push('custom-button-new-gen__loading');
    if (!!heavy) classNames.push('custom-button-new-gen__heavy');
    return (
        <button
            className={classNames.join(' ')}
            type="button"
            onClick={!!disabled ? undefined : onClick}
        >
            {arrowLeft && <i className="custom-button-new-gen__arrowLeft" />}
            {isLoading ? 'Chargement...' : title}
            {/* {title} */}
            {arrowRight && <i className="custom-button-new-gen__arrowRight" />}
        </button>
    );
};

export default CustomButtonNewGen;
