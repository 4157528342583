import React, { ChangeEvent, FC } from 'react';
import _ from 'lodash';
import { SelectOption } from 'Types';
import { CustomInput, CustomSelect } from '../../components';
import { ECivilities } from '../../utils/network/types';
import WordingConstant from '../../utils/wording.json';

type Errors = Partial<{
    civility: string;
    firstName: string;
    lastName: string;
}>;

type Values = {
    civility: ECivilities | null;
    firstName: string;
    lastName: string;
};

type Props = {
    errors?: Errors;
    onInputChange: (input: Values) => void;
    values: Values;
};

const Wording = WordingConstant.AdminSubscriptionContainer.ClientData;
const baseClassName: string = 'coords-inputs';
const firstNameText: string = `${Wording.inputs.firstName}`;
const lastNameText: string = `${Wording.inputs.lastName}`;
const civilStatuses: Array<SelectOption<ECivilities>> = [
    {
        id: Wording.civilStatuses.mr,
        label: Wording.civilStatuses.mr,
        value: ECivilities.MR,
    },
    {
        id: Wording.civilStatuses.mme,
        label: Wording.civilStatuses.mme,
        value: ECivilities.MRS,
    },
    {
        id: Wording.civilStatuses.ms,
        label: Wording.civilStatuses.ms,
        value: ECivilities.MS,
    },
];

const CoordsInputs: FC<Props> = (props: Props) => {
    const { errors, onInputChange, values } = props;
    const handleInputChange = (updates: Partial<Values>) => {
        const input: Values = _.merge({}, values, { ...updates });
        onInputChange(input);
    };
    const handleCivilitySelection = (civility: SelectOption<ECivilities>) => {
        handleInputChange({ civility: civility.value });
    };
    const handleFirstNameInput = (event: ChangeEvent<HTMLInputElement>) => {
        handleInputChange({ firstName: event.target.value });
    };
    const handleLastNameInput = (event: ChangeEvent<HTMLInputElement>) => {
        handleInputChange({ lastName: event.target.value });
    };
    const civilityValue =
        _.find(
            civilStatuses,
            (status: SelectOption<ECivilities>) =>
                status.value === values.civility,
        ) || civilStatuses[0];
    return (
        <div className={baseClassName}>
            <CustomSelect
                error={(!!errors && errors.civility) || undefined}
                handleChangeSelect={handleCivilitySelection}
                options={civilStatuses}
                text={Wording.civilStatus}
                value={civilityValue}
            />
            <CustomInput
                error={(!!errors && errors.firstName) || undefined}
                onInputChange={handleFirstNameInput}
                text={firstNameText}
                value={values.firstName}
            />
            <CustomInput
                error={(!!errors && errors.lastName) || undefined}
                onInputChange={handleLastNameInput}
                text={lastNameText}
                value={values.lastName}
            />
        </div>
    );
};

export default CoordsInputs;
