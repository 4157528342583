import { combineEpics } from 'redux-observable';

import {
    fetchLoginEpic,
    resetPasswordEpic,
    reinitSubscriptionEmailEpic,
} from '../_actions/auth.actions';
import {
    fetchContextEpic,
    updateCustomerEpic,
    updateBillingEpic,
    updatePasswordEpic,
    createSponsorEpic,
} from '../_actions/profile.actions';
import { fetchContractEpic } from '../_actions/contract.actions';
import { fetchEventEpic, createEventEpic } from '../_actions/event.actions';
import {
    fetchMeterEpic,
    fetchMeterReadingEpic,
    createIndexEpic,
} from '../_actions/consumption.actions';
import { fetchFileEpic } from '../_actions/file.actions';
import {
    fetchPiecesEpic,
    fetchEPInvoiceEpic,
    createPaymentEpic,
    fetchBalanceEpic,
    getSupportingDocumentsEpic,
} from '../_actions/payment.actions';
import {
    fetchPackageEpic,
    fetchProductEpic,
    fetchOfferEpic,
    updatePackageEpic,
} from '../_actions/package.actions';

import {
    customerContractEpic,
    uploadAmendmentsEpic,
} from '../_actions/customerContract.actions';
import { orderEpic, searchClientEpic } from '../_actions/searchOrder.actions';
import { searchUserGroupEpic } from '../_actions/searchUserGroup.actions';
import { RetryFirstPaymentEpic } from '../_actions/caseResult.action';
import { createGroupEpic } from '../_actions/createGroup.actions';
import { getGroupEpic } from '../_actions/getGroup.actions';
import { createUserEpic } from '../_actions/createUser.actions';
import { createPasswordEpic } from '../_actions/createPassword.actions';
import { manageUserEpic } from '../_actions/manageUser.actions';
import {
    transferGetContractEpic,
    transferContractEpic,
} from '../_actions/transferContract.actions';
import { adminClientSpaceEpic } from '../_actions/administrationClientSpace.actions';

import { createCBPaymentEpic } from '../_actions/paymentCB.actions';

export default combineEpics(
    fetchLoginEpic,
    resetPasswordEpic,
    getSupportingDocumentsEpic,
    fetchContextEpic,
    fetchContractEpic,
    fetchEventEpic,
    createEventEpic,
    updateCustomerEpic,
    updateBillingEpic,
    fetchMeterEpic,
    fetchMeterReadingEpic,
    createIndexEpic,
    uploadAmendmentsEpic,
    updatePasswordEpic,
    createSponsorEpic,
    fetchFileEpic,
    fetchPiecesEpic,
    fetchEPInvoiceEpic,
    transferContractEpic,
    createPaymentEpic,
    fetchBalanceEpic,
    fetchPackageEpic,
    fetchProductEpic,
    fetchOfferEpic,
    updatePackageEpic,
    customerContractEpic,
    reinitSubscriptionEmailEpic,
    orderEpic,
    searchUserGroupEpic,
    createGroupEpic,
    getGroupEpic,
    createUserEpic,
    manageUserEpic,
    transferGetContractEpic,
    adminClientSpaceEpic,
    createCBPaymentEpic,
    searchClientEpic,
    createPasswordEpic,
    RetryFirstPaymentEpic,
);
