import React, { ChangeEvent, Component, Fragment } from 'react';
import _ from 'lodash';
import { SelectOption } from 'Types';
import {
    CustomButton,
    CustomInput,
    CustomRadio,
    CustomSelect,
    PostalCodeSelect,
} from '../../../../../components';
import WordingConstant from '../../../../../utils/wording.json';
import { buildClassName as bcn } from '../../../../../utils/helpers';
import { toIsc as isc, toColonHeading as tch } from '../../../../../utils/text';
import {
    EHeatingTypes,
    EHousingTypes,
    ERateOptions,
    EResidenceTypes,
    TAddress,
} from '../../../../../utils/network/types';

const Wording = WordingConstant.AdminSubscriptionContainer.CustomerFormModal;
const baseClassName = 'customer-survey-modal';
const buttonsClassName = 'buttons';
const modeToggleClassName = 'mode-toggle';
const rateRadiosClassName = 'rate-radios';

interface IProps {
    onCancel: () => void;
    onValidation: (
        accomodationType: EResidenceTypes,
        area: number,
        address: TAddress,
        consumption: number,
        heatingType: EHeatingTypes,
        inhabitants: number,
        livingIn: EHousingTypes,
        rateOption: ERateOptions,
    ) => void;
}

interface IState {
    accomodationType: SelectOption<EResidenceTypes> | null;
    area: SelectOption<number> | null;
    address: TAddress | null;
    consumption: string;
    heatingType: SelectOption<EHeatingTypes> | null;
    inhabitants: SelectOption<number> | null;
    livingIn: SelectOption<EHousingTypes> | null;
    rateOption: ERateOptions;
}

const initialState: IState = {
    accomodationType: null,
    area: null,
    address: null,
    consumption: '',
    heatingType: null,
    inhabitants: null,
    livingIn: null,

    rateOption: ERateOptions.UNKNOWN,
};

const accomodationOptions: Array<SelectOption<EResidenceTypes>> = [
    [Wording.accomodationTypes.primary, EResidenceTypes.PRINCIPAL],
    [Wording.accomodationTypes.secondary, EResidenceTypes.SECONDARY],
].map((accomodation, i) => ({
    id: i,
    label: accomodation[0],
    value: accomodation[1] as EResidenceTypes,
}));
const areaOptions: Array<SelectOption<number>> = [
    [0, 20],
    ..._.range(20, 200, 10).map(gap => [gap + 1, gap + 10]),
].map((opt, i) => ({
    id: i,
    label: `${opt[0]} ${Wording.to} ${opt[1]}`,
    value: opt[1],
}));
const heatingTypeOptions: Array<SelectOption<EHeatingTypes>> = [
    [Wording.heatingTypes.electricity, EHeatingTypes.ELECTRICITY],
    [Wording.heatingTypes.gas, EHeatingTypes.GAS],
    [Wording.heatingTypes.common, EHeatingTypes.COLLECTIVE],
].map((opt, i) => ({
    id: i,
    label: opt[0],
    value: opt[1] as EHeatingTypes,
}));
const inhabitantsOptions: Array<SelectOption<number>> = _.range(1, 11).map(
    (opt, i) => ({
        id: i,
        label: `${opt}`,
        value: opt,
    }),
);
const livingInOptions: Array<SelectOption<EHousingTypes>> = [
    [Wording.livingInTypes.apartment, EHousingTypes.APPARTMENT],
    [Wording.livingInTypes.house, EHousingTypes.HOUSE],
].map((opt, i) => ({
    id: i,
    label: opt[0],
    value: opt[1] as EHousingTypes,
}));

class CustomerSurveyModal extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { ...initialState };
        this.handleCancel = this.handleCancel.bind(this);
        this.handleValidationClick = this.handleValidationClick.bind(this);
        this.handleConsumptionInput = this.handleConsumptionInput.bind(this);
        this.handleAccomodationTypeSelection = this.handleAccomodationTypeSelection.bind(
            this,
        );
        this.handleAreaSelection = this.handleAreaSelection.bind(this);
        this.handleHeatingTypeSelection = this.handleHeatingTypeSelection.bind(
            this,
        );
        this.handleInhabitantsSelection = this.handleInhabitantsSelection.bind(
            this,
        );
        this.handleLivingInSelection = this.handleLivingInSelection.bind(this);
        this.handlePostalCodeSelection = this.handlePostalCodeSelection.bind(
            this,
        );
        this.handleRateOptionSelection = this.handleRateOptionSelection.bind(
            this,
        );
    }
    handleCancel() {
        const { onCancel } = this.props;
        this.setState({ ...initialState });
        onCancel();
    }
    handleRateOptionSelection(rateOption: ERateOptions) {
        this.setState({ rateOption });
    }
    handleValidationClick() {
        const { onValidation } = this.props;
        if (this.hasValidInput()) {
            const state = this.state;
            try {
                onValidation(
                    _.get(state, 'accomodationType.value', ''),
                    parseInt(_.get(state, 'area.value', '0'), 10),
                    _.get(state, 'address') as TAddress,
                    parseInt(_.get(state, 'consumption', '0'), 10),
                    _.get(state, 'heatingType.value', ''),
                    parseInt(_.get(state, 'inhabitants.value', '0'), 10),
                    _.get(state, 'livingIn.value', ''),
                    _.get(state, 'rateOption') as ERateOptions,
                );
            } catch (err) {
                console.error(err);
            }
        }
    }
    handleConsumptionInput(e: ChangeEvent<HTMLInputElement>) {
        const consumption = _.get(e, 'target.value', '');
        this.setState({ consumption });
    }
    handleAccomodationTypeSelection(opt: SelectOption<EResidenceTypes>) {
        this.setState({ accomodationType: opt });
    }
    handleAreaSelection(opt: SelectOption<number>) {
        this.setState({ area: opt });
    }
    handleHeatingTypeSelection(opt: SelectOption<EHeatingTypes>) {
        this.setState({ heatingType: opt });
    }
    handleInhabitantsSelection(opt: SelectOption<number>) {
        this.setState({ inhabitants: opt });
    }
    handleLivingInSelection(opt: SelectOption<EHousingTypes>) {
        this.setState({ livingIn: opt });
    }
    handlePostalCodeSelection(address: TAddress) {
        this.setState({ address });
    }

    hasValidInput() {
        const {
            accomodationType,
            area,
            address,
            heatingType,
            inhabitants,
            livingIn,
        } = this.state;
        const inputs = [
            accomodationType,
            area,
            address,
            heatingType,
            inhabitants,
            livingIn,
        ];
        const validCount = _.filter(inputs, input => !_.isEmpty(input)).length;
        const valid = [...inputs].length === validCount;
        return valid;
    }
    render() {
        const { address, rateOption } = this.state;
        const city = _.get(address, 'townName', '');
        const hasValidInput = this.hasValidInput();
        return (
            <div className={baseClassName}>
                <h3>{Wording.title}</h3>

                <Fragment>
                    <PostalCodeSelect
                        onSelection={this.handlePostalCodeSelection}
                        text={Wording.postalCode}
                    />
                    <CustomInput
                        disabled={true}
                        text={Wording.city}
                        value={city}
                    />
                    <CustomSelect
                        handleChangeSelect={this.handleLivingInSelection}
                        options={livingInOptions}
                        text={Wording.livingIn}
                    />
                    <CustomSelect
                        handleChangeSelect={
                            this.handleAccomodationTypeSelection
                        }
                        options={accomodationOptions}
                        text={Wording.accomodationType}
                    />
                    <CustomSelect
                        handleChangeSelect={this.handleAreaSelection}
                        options={areaOptions}
                        text={Wording.area}
                    />
                    <CustomSelect
                        handleChangeSelect={this.handleInhabitantsSelection}
                        options={inhabitantsOptions}
                        text={Wording.inhabitants}
                    />
                    <CustomSelect
                        handleChangeSelect={this.handleHeatingTypeSelection}
                        options={heatingTypeOptions}
                        text={Wording.heatingType}
                    />
                </Fragment>
                <div className={bcn([baseClassName, rateRadiosClassName])}>
                    <label>{isc(Wording.rateOptions.prompt)}</label>
                    <CustomRadio
                        checked={rateOption === ERateOptions.HIGH_LOW}
                        handleCheck={() => {
                            this.handleRateOptionSelection(
                                ERateOptions.HIGH_LOW,
                            );
                        }}
                        text={Wording.rateOptions.highLow}
                        value={ERateOptions.HIGH_LOW}
                    />
                    <CustomRadio
                        checked={rateOption === ERateOptions.TOTAL_HOUR}
                        handleCheck={() => {
                            this.handleRateOptionSelection(
                                ERateOptions.TOTAL_HOUR,
                            );
                        }}
                        text={Wording.rateOptions.totalHours}
                        value={ERateOptions.TOTAL_HOUR}
                    />
                    <CustomRadio
                        checked={rateOption === ERateOptions.UNKNOWN}
                        handleCheck={() => {
                            this.handleRateOptionSelection(
                                ERateOptions.UNKNOWN,
                            );
                        }}
                        text={Wording.rateOptions.unknown}
                        value={ERateOptions.UNKNOWN}
                    />
                </div>
                <div className={bcn([baseClassName, buttonsClassName])}>
                    <CustomButton
                        color={'gray'}
                        onClick={this.handleCancel}
                        title={Wording.cancel}
                    />
                    <CustomButton
                        color={hasValidInput ? 'orange' : 'gray'}
                        onClick={
                            hasValidInput
                                ? this.handleValidationClick
                                : () => {}
                        }
                        title={Wording.validate}
                    />
                </div>
            </div>
        );
    }
}

export default CustomerSurveyModal;
