import React, { Fragment } from 'react';

import { CustomTable } from '../../components';

import WordingConstant from '../../utils/wording.json';
const Wording = WordingConstant.RequestPage;

import * as helpers from '../../utils/helpers';

export const findLabel = (Arr: any[], categ: string) => {
    return Arr.find(x => x.value === categ)
        ? Arr.find(x => x.value === categ).label
        : '';
};

interface ISelect {
    id: string;
    value: string;
    label: string;
}

const compare = (a: any, b: any) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0;

const renderDataInOption = (tab: ISelect[]) =>
    tab
        .sort(compare)
        .map(
            (
                {
                    label,
                    value,
                }: {
                    id: string;
                    value: string;
                    label: string;
                },
                key: number,
            ) => (
                <option key={key} value={value}>
                    {label}
                </option>
            ),
        );

const columnsTable = [
    {
        Header: 'RÉFÉRENCE',
        accessor: 'eventNbr',
        width: 120,
    },
    {
        Header: 'CATÉGORIE',
        accessor: 'eventCategory',
        Cell: (props: any) => {
            return findLabel(Wording.categories, props.value);
        },
        filterMethod: (filter: any, row: any) => {
            if (filter.value === 'all') {
                return true;
            }
            return row.eventCategory === filter.value;
        },
        Filter: ({ filter, onChange }: any) => (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
            >
                <option value="all">Toutes les catégories</option>
                {renderDataInOption(Wording.categories)}
            </select>
        ),
    },
    {
        Header: 'SOUS-CATÉGORIE',
        accessor: 'eventSubcategory',
        Cell: (props: any) => {
            return findLabel(Wording.subCategories, props.value);
        },

        filterMethod: (filter: any, row: any) => {
            if (filter.value === 'all') {
                return true;
            }
            return row.eventSubcategory === filter.value;
        },
        Filter: ({ filter, onChange }: any) => (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
            >
                <option value="all">Toutes les sous-catégories</option>
                {renderDataInOption(Wording.subCategories)}
            </select>
        ),
    },
    {
        Header: 'DATE',
        accessor: 'referenceDate',
        filterable: false,
        Cell: (props: any) => {
            return helpers.getFormattedDate(props.value);
        },
        width: 125,
    },
    {
        Header: 'STATUT',
        accessor: 'eventStatusCode',
        Cell: (props: any) => {
            return findLabel(Wording.statusEvent, props.value);
        },
        filterMethod: (filter: any, row: any) => {
            if (filter.value === 'all') {
                return true;
            }
            return row.eventStatusCode === filter.value;
        },
        Filter: ({ filter, onChange }: any) => (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
            >
                <option value="all">Tout les statuts</option>
                {renderDataInOption(Wording.statusEvent)}
            </select>
        ),
        width: 200,
    },
];

const EventContainer: React.FC<{
    data: object[];
}> = ({ data }) => {
    return (
        <div className="request-container">
            <h2 className="request-container__sub-title">{Wording.subTitle}</h2>

            <CustomTable
                columns={columnsTable}
                filterable={true}
                data={data}
                defaultPageSize={data.length > 10 ? 10 : data.length}
                noDataText={Wording.noDataText}
                sortable={true}
                className="-striped -highlight"
                SubComponent={(row: any) => {
                    return (
                        <div className="sub-component">
                            {row.original.message}
                            <span className="line" />
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default EventContainer;
