import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { TPackage, TPrepaymentFrequency, TProduct, TOffer } from 'Models';

import * as actions from '../_actions/package.actions';

export type PackageState = Readonly<{
    loadingPackage: boolean;
    loadingOffer: boolean;
    pkg: Record<string, TPackage[]>;
    prepaymentFrequencies: Record<string, TPrepaymentFrequency[]>;
    offer: Record<string, TOffer>;
    products: TProduct[];
    updateResponse: {
        code: string;
        message: string;
    };
    description?: string;
}>;

export const initialState = {
    loadingPackage: false,
    loadingOffer: false,
    pkg: {},
    prepaymentFrequencies: {},
    offer: {},
    products: [],
    updateResponse: {
        code: '',
        message: '',
    },
};

type Actions = actions.PackageAction;

const packageReducer: Reducer<PackageState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.fetchPackageAsync.request):
            return {
                ...state,
                loadingPackage: true,
            };

        case getType(actions.fetchPackageAsync.failure):
            return {
                ...state,
                loadingPackage: false,
            };

        case getType(actions.fetchPackageAsync.success): {
            const {
                packagesList,
                prepaymentFrequencies,
                contractNbr,
            } = action.payload;
            const pf: Record<string, TPrepaymentFrequency[]> =
                state.prepaymentFrequencies;
            if (prepaymentFrequencies) {
                pf[contractNbr] = prepaymentFrequencies;
            }
            const pkg: Record<string, TPackage[]> = state.pkg;
            if (packagesList) {
                pkg[contractNbr] = packagesList;
            }
            return {
                ...state,
                pkg,
                prepaymentFrequencies: pf,
                loadingPackage: false,
            };
        }

        case getType(actions.fetchProductAsync.request):
            return {
                ...state,
                loadingOffer: true,
            };

        case getType(actions.fetchProductAsync.success): {
            return {
                ...state,
                products: action.payload.productsList || state.products,
                loadingOffer: false,
            };
        }

        case getType(actions.fetchProductAsync.failure):
        case getType(actions.fetchOfferAsync.failure): {
            return {
                ...state,
                loadingOffer: false,
            };
        }

        case getType(actions.fetchOfferAsync.success): {
            const { offers, contractNbr } = action.payload;
            const offer: Record<string, TOffer> = {
                ...state.offer,
            };
            if (offers && contractNbr) {
                offer[contractNbr] = offers[0];
            }
            return {
                ...state,
                offer,
                loadingOffer: false,
            };
        }

        case getType(actions.updatePackageAsync.failure): {
            return {
                ...state,
                updateResponse: {
                    code: '500',
                    message: 'failure',
                },
            };
        }

        case getType(actions.updatePackageAsync.success): {
            if (action.payload.code === 'KO') {
                return {
                    ...state,
                    description: action.payload.description,
                    updateResponse: {
                        code: '500',
                        message: 'Erreur serveur',
                    },
                };
            }

            if (action.payload.code === 'OK') {
                return {
                    ...state,
                    description: undefined,
                    updateResponse: {
                        code: '200',
                        message: 'success',
                    },
                };
            }
            return {
                ...state,
                description: undefined,
                updateResponse: {
                    code: '500',
                    message: 'Erreur serveur',
                },
            };
        }

        case getType(actions.resetUpdate):
            return {
                ...state,
                description: undefined,
                updateResponse: {
                    code: '',
                    message: '',
                },
            };

        default:
            return { ...state };
    }
};

export default packageReducer;
