import React, { useState } from 'react';
import { TContract, TEPInvoice } from 'Models';

import { CustomButton, CustomDayPicker } from '../../components';
import { isWeekend } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

import PaymentCBModal from '../PaymentCBModal';
import { useDispatch } from 'react-redux';
import { createPaymentCBAsync } from '../../_actions/paymentCB.actions';

const Wording = WordingConstant.FailPieceContainer;

export type ChangePiece = {
    piece: TEPInvoice;
    dueDate: string;
};

export type BaseProps = {
    handleChangeFailPiece: (piece: TEPInvoice, dueDate: string) => void;
    handleShowModal: (section: string) => void;
};

type FailPieceProps = {
    piece: TEPInvoice;
    handleOpenModal: (piece: TEPInvoice, dueDate: string) => void;
} & BaseProps;

const DISABLED = [
    { before: new Date(new Date().setDate(new Date().getDate() + 1)) },
    { after: new Date(new Date().setDate(new Date().getDate() + 15)) },
    {
        daysOfWeek: [0, 6],
    },
];

export const FailPiece = ({
    piece,
    handleChangeFailPiece,
    handleShowModal,
    handleOpenModal,
}: FailPieceProps) => {
    const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
    const [selectedDay, setSelectedDay] = useState<any>(undefined);

    const handleChangeDay = (date: Date) => {
        if (date.toLocaleDateString() < new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString() && date.toLocaleDateString() > new Date(new Date().setDate(new Date().getDate() + 15)).toLocaleDateString()) {
           return;
        }
        if (date.getTime() > new Date().getTime() && !isWeekend(date)) {
            setSelectedDay(date);
        }
        handleChangeFailPiece(piece, date.toLocaleDateString());
    };

    return (
        <div className="fail-piece-container">
            <div className="fail-piece-container__title">
                <span className="fail-piece-container__title__exclamation-point">
                    !
                </span>
                <p>
                    Votre prélèvement {piece.communication} du {piece.date} d’un{' '}
                    montant de {piece.amount}€ a été refusé
                </p>
            </div>

            <p>{Wording.title2}</p>

            {(calendarOpen && (
                <div className="fail-piece-container__calendar">
                    <CustomDayPicker
                        dateHighlighted={selectedDay}
                        handleChangeDay={handleChangeDay}
                        disabledDays={DISABLED}
                    />

                    <CustomButton
                        color="gray"
                        title={Wording.buttonClose}
                        onClick={() => setCalendarOpen(false)}
                    />
                    <CustomButton
                        color={selectedDay !== undefined ? 'orange' : 'gray'}
                        title={Wording.buttonSave}
                        onClick={
                            selectedDay !== undefined
                                ? () => handleShowModal('')
                                : () => {}
                        }
                    />
                </div>
            )) || (
                <div>
                    {
                        // piece.enableDirectDebit && (
                        <CustomButton
                            color="orange"
                            title={Wording.buttonChoice}
                            onClick={() => setCalendarOpen(true)}
                        />
                        // )
                    }
                    {
                        // piece.enableDebitCard && (
                        <CustomButton
                            color="orange"
                            title={Wording.buttonPaymentCB}
                            onClick={() => handleOpenModal(piece, '')}
                        />
                        // )
                    }
                </div>
            )}
        </div>
    );
};

export type ContainerProps = BaseProps & {
    pieces: TEPInvoice[];
    customerNumber: string;
    contract: TContract;
};

type FailPieceType = {
    piece: TEPInvoice;
    enableDebitCard: boolean;
    enableDirectDebit: boolean;
};

const FailPieceContainer = ({
    pieces,
    handleChangeFailPiece,
    handleShowModal,
}: ContainerProps) => {
    const dispatch = useDispatch();
    const [targetPiece, setTargetPiece] = useState<TEPInvoice>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCreatePaymentCB = (piece: TEPInvoice) => {
        setIsModalOpen(true);
        setTargetPiece(piece);
        dispatch(
            createPaymentCBAsync.request({
                dueDate: new Date().toString(),
                payment: piece,
            }),
        );
    };

    if (pieces && pieces.length > 0) {
        const failPieces: FailPieceType[] = [];
        pieces.forEach((p: TEPInvoice) => {
            // ('sent-cancelled' || 'sent-rejected') status payment déclenchant l'affichage de l'encart de paiement
            if (p.status !== 'sent-cancelled') {
                return;
            } else {
                if (
                    (p.replacing_payment_count &&
                        parseInt(p.replacing_payment_count, 10) > 0) ||
                    (p.energy_paid_number_replays &&
                        parseInt(p.energy_paid_number_replays, 10) > 0)
                ) {
                    return;
                }
            }
            const enableDebitCard: boolean = true;
            const enableDirectDebit: boolean = true;

            if (p && (enableDebitCard || enableDirectDebit)) {
                failPieces.push({
                    piece: p,
                    enableDirectDebit,
                    enableDebitCard,
                });
            }
        });

        return (
            <>
                {failPieces && failPieces.length > 0 && (
                    <h2>{Wording.incidentPaymentText}</h2>
                )}
                {failPieces.map((fp: FailPieceType, index: number) => {
                    return (
                        <FailPiece
                            key={index}
                            piece={fp.piece}
                            handleChangeFailPiece={handleChangeFailPiece}
                            handleShowModal={handleShowModal}
                            handleOpenModal={handleCreatePaymentCB}
                        />
                    );
                })}

                <PaymentCBModal
                    closeModal={() => setIsModalOpen(false)}
                    show={isModalOpen}
                    targetPiece={targetPiece}
                />
            </>
        );
    }
    return null;
};

export default FailPieceContainer;
