import _ from 'lodash';
import {
    TProduct,
    TSeasonalPackage,
    ETimeframes,
    TAddress,
    ESubscriptionTypes,
    EEnergyTypes,
    ERebateCodeTypes,
    TContract,
    EBillingModes,
} from './types';

function isCyclicalProduct(product: TProduct | null | undefined): boolean {
    if (!product || !product.productCode) return false;
    const isCyclical: boolean = (product.productCode as string).includes('CYC');
    return isCyclical;
}

function isEqualProduct(
    prod1: TProduct | undefined | null,
    prod2: TProduct | undefined | null,
): boolean {
    if (!prod1 || !prod2) return false;
    const rateLess1 = _.omit(prod1, 'additionalRates');
    const rateLess2 = _.omit(prod2, 'additionalRates');
    const hasEqualProducts: boolean = _.isEqual(rateLess1, rateLess2);
    return hasEqualProducts;
}

function isEqualAuthorizedPackages(
    auth1: TSeasonalPackage[] = [],
    auth2: TSeasonalPackage[] = [],
): boolean {
    if (auth1.length !== 2 || auth2.length !== 2) return false;
    const winter1: TSeasonalPackage | undefined = _.find(
        auth1,
        (pkg: TSeasonalPackage) => {
            return pkg.timeframe === ETimeframes.WINTER;
        },
    );
    const winter2: TSeasonalPackage | undefined = _.find(
        auth2,
        (pkg: TSeasonalPackage) => {
            return pkg.timeframe === ETimeframes.WINTER;
        },
    );
    const summer1: TSeasonalPackage | undefined = _.find(
        auth1,
        (pkg: TSeasonalPackage) => {
            return pkg.timeframe === ETimeframes.SUMMER;
        },
    );
    const summer2: TSeasonalPackage | undefined = _.find(
        auth2,
        (pkg: TSeasonalPackage) => {
            return pkg.timeframe === ETimeframes.SUMMER;
        },
    );
    return (
        !!summer1 &&
        !!summer2 &&
        !!winter1 &&
        !!winter2 &&
        _.isEqual(summer1, summer2) &&
        _.isEqual(winter1, winter2)
    );
}

function getAddressStreetLabel(address: TAddress | null | undefined): string {
    if (!address) return '';
    const num: string | undefined = _.get(address, 'number');
    const street: string = _.get(address, 'street', '');
    return [`${num || ''}`, street].join(' ').replace(/^\s+/g, '');
}

function parseOrderRebateCode(
    contracts: TContract[],
): {
    code: string;
    type: ERebateCodeTypes;
} | null {
    let orderCode: { code: string; type: ERebateCodeTypes } | null = null;
    _.forEach(contracts, (contract: TContract) => {
        let shouldBreak = false;
        _.forEach(contract.thirdParties || [], (thirdParty: any) => {
            const code = _.get(thirdParty, 'code');
            const type = _.get(thirdParty, 'role');
            if (!!code && !!type && type !== 'PARTNER') {
                orderCode = { code, type };
                shouldBreak = true;
                return false;
            }
        });
        if (shouldBreak) return false;
    });
    return orderCode;
}

function toEnergies(
    subscription: ESubscriptionTypes | null | undefined,
): EEnergyTypes[] {
    if (!subscription) return [];
    const energies: EEnergyTypes[] = [];
    if (subscription === ESubscriptionTypes.ELECTRICITY_AND_GAS) {
        energies.push(EEnergyTypes.ELECTRICTY, EEnergyTypes.GAS);
    } else if (subscription === ESubscriptionTypes.ELECTRICITY) {
        energies.push(EEnergyTypes.ELECTRICTY);
    } else if (subscription === ESubscriptionTypes.GAS) {
        energies.push(EEnergyTypes.GAS);
    }
    return energies;
}

function requiresPayment(contract: TContract | null | undefined): boolean {
    if (!contract) return false;
    const billingMode: EBillingModes | undefined = _.get(
        contract,
        'chosenProduct.billingModeCode',
    );
    return (
        !!contract.firstPaymentCB &&
        billingMode !== EBillingModes.CYCLICAL_BILLING
    );
}

export {
    getAddressStreetLabel,
    isCyclicalProduct,
    isEqualAuthorizedPackages,
    isEqualProduct,
    parseOrderRebateCode,
    toEnergies,
    requiresPayment,
};
