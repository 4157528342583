import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import Wording from '../../wording.json';

import Section from '../titles/Section';
import Object from '../titles/Object';
import Adresse from './Adresse';

import Offer from './Offer';
import Summary from './Summary';

import CustomTable from './CustomTable';
import { useProof } from '../../lib';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: '10px  20px',
        width: '73.5%',
    },
});

const {
    address: { beneficiary, clientTitle },
    sections: { offer, summary },
} = Wording;

const RightColumn = () => {
    const {
        customer: { name },
        address,
    } = useProof();

    return (
        <View style={styles.container}>
            <Object />

            <Adresse
                title={clientTitle}
                data={{
                    name: `${name.civility} ${name.firstName} ${name.lastName}`,
                    number_street: `${address.number} ${address.street}`,
                    zip_code_city: `${address.postalCode} ${address.townName}`,
                }}
            />

            <Adresse title={beneficiary.title} data={beneficiary.data} />

            <Section title={offer.title} />
            <Offer />

            <Section title={summary.title} />
            <Summary />

            <CustomTable />
        </View>
    );
};

export default RightColumn;
