import React from 'react';
import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Footer;
export default function footer() {
    return (
        <div className="footerAuth">
            <div className="footerAuth__articles">
                {Wording.footerAuth.articles.map((e, index) => {
                    return (
                        <React.Fragment key={index}>
                            <a
                                href={e.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {e.text}
                            </a>
                        </React.Fragment>
                    );
                })}
            </div>

            <div className="footerAuth__social">
                <a
                    href={Wording.socialLink.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-facebook']} alt="ic-facebook" />
                </a>
                <a
                    href={Wording.socialLink.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-instagram']} alt="ic-instagram" />
                </a>
                <a
                    href={Wording.socialLink.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-youtube']} alt="ic-youtube" />
                </a>
                <a
                    href={Wording.socialLink.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-linkedin']} alt="ic-linkedin" />
                </a>
            </div>
        </div>
    );
}
