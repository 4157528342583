import { Subject, of, from, throwError } from 'rxjs';
import {
    debounceTime,
    switchMap,
    distinctUntilChanged,
    mergeMap,
    catchError,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import Config from './config';
import { filteredArr, log } from './helpers';

interface IResProps {
    netArea: string;
    postalCode: string;
    townName: string;
}

class SearchService {
    private handleSearch$: any;
    private raw: boolean;

    constructor() {
        this.handleSearch$ = new Subject<string>().pipe(debounceTime(100));
        this.raw = false;
    }

    public setRaw(raw: boolean) {
        this.raw = raw;
        return this;
    }

    public unsubscribe = () => {
        this.handleSearch$.unsubscribe();
    };

    public search = (term: string) => {
        this.handleSearch$.next(term);
    };

    public doSearch = (term: string) => {
        const settings = {
            url: `${Config.URL_SUBSCRIPTION}/getTowns`,
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': Config.ApiKey,
            },
            responseType: 'json',
            method: 'post',
            crossDomain: true,
            body: {
                postalCode: term,
            },
        };
        const promise = ajax(settings).pipe(
            catchError(({ message }) => throwError({ error: message })),
            mergeMap(response => {
                if (response.response) {
                    response.response = filteredArr(
                        response.response,
                        'townName',
                    );
                    response.response.sort((a: IResProps, b: IResProps) => {
                        if (a.townName < b.townName) {
                            return -1;
                        }
                        if (a.townName > b.townName) {
                            return 1;
                        }
                        return 0;
                    });
                    if (this.raw) {
                        return of(
                            response.response.map((res: IResProps) => ({
                                postalCode: res.postalCode,
                                townName: res.townName,
                                netArea: res.netArea,
                            })),
                        );
                    }
                    return of(
                        response.response.map((res: IResProps) => ({
                            code: res.postalCode,
                            city: res.townName,
                            netArea: res.netArea,
                        })),
                    );
                }
                return of([]);
            }),
        );
        return from(promise);
    };

    public getResults() {
        return this.handleSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) => (term ? this.doSearch(term) : of([]))),
            catchError(error => {
                log('Error ', error);
                return of([]);
            }),
        );
    }
}

export default SearchService;
