import _, { isArray } from 'lodash';
import { throwError, Observable, from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import { log } from '../utils/helpers';
import { hasExpiredSession } from '../utils/storage';
import Config from '../utils/config';
import Lambda, { InvocationResponse } from 'aws-sdk/clients/lambda';
import { AWSError } from 'aws-sdk/lib/error';
import {
    RequestError,
    ParsingError,
    FunctionNameError,
} from '../utils/network/errors';

const errRegexp: RegExp = new RegExp(/^[45][\S]+/);
const codeMessageRegexp: RegExp = new RegExp(/code|message+/);

const invokeLambda = async (
    functionName: string,
    payload: object,
): Promise<any | RequestError> => {
    if (!functionName) {
        return new FunctionNameError();
    }
    const lambdaInstance = new Lambda(Config.aws);
    log(`lambda ${functionName}: payload ====>`, payload);
    return new Promise(resolve => {
        lambdaInstance.invoke(
            {
                FunctionName: functionName,
                Payload: JSON.stringify(payload),
                InvocationType: 'RequestResponse',
                LogType: 'None',
            },
            (err: AWSError, res: InvocationResponse) => {
                if (err) {
                    log(
                        `Error from lambda ${functionName} ====> `,
                        err.message,
                    );
                    resolve(new RequestError(err.message, err.code, null));
                } else {
                    try {
                        const response: any = JSON.parse(`${res.Payload}`);
                        log(`lambda ${functionName}: response ====>`, response);
                        const statusCode: string = `${_.get(
                            response,
                            'statusCode',
                            '',
                        )}`;
                        if (errRegexp.test(statusCode)) {
                            if (
                                response.body &&
                                typeof response.body === 'string'
                            ) {
                                const decodedJson = JSON.parse(response.body);

                                if (
                                    codeMessageRegexp.test(decodedJson.message)
                                ) {
                                    resolve(
                                        new RequestError(
                                            _.get(
                                                JSON.parse(decodedJson.message),
                                                'message',
                                                '',
                                            ),
                                            statusCode,
                                            null,
                                        ),
                                    );
                                }

                                resolve(
                                    new RequestError(
                                        _.get(decodedJson, 'message', ''),
                                        statusCode,
                                        null,
                                    ),
                                );
                            }
                            resolve(
                                new RequestError(
                                    _.get(response, 'body.message', ''),
                                    statusCode,
                                    null,
                                ),
                            );
                        } else {
                            resolve(response);
                        }
                    } catch (pErr) {
                        console.error(pErr);
                        resolve(new ParsingError());
                    }
                }
            },
        );
    });
};

const apiRequest = <T>({
    path,
    method,
    body,
    isSubscription,
    isPay,
    isPayEP,
    lambdaFunctionName,
}: any): Observable<T> => {
    const expires = Number(localStorage.getItem('expires'));
    if (expires && new Date().getTime() > expires) {
        localStorage.removeItem('expires');
        return Observable.create((observer: any) => {
            observer.error({
                code: '401',
                message: 'Session expired',
            });
        });
    }

    const renderChannel = () => {
        if (!!body.channel) {
            return body.channel;
        }

        const storageGroup = localStorage.getItem('userGroup');

        let ctrChan;
        if (storageGroup === null && !!body.contractChannel) {
            ctrChan = body.contractChannel;
            delete body.contractChannel;
        }

        if (ctrChan) return ctrChan;

        return storageGroup !== null ? storageGroup : 'WEB';
    };

    const modifiedBody = {
        ...body,
        channel: renderChannel(),
        seller: 'WEKIWI',
    };

    if (lambdaFunctionName) {
        // return from(invokeLambda(lambdaFunctionName, modifiedBody));
    }

    const url = `${
        isSubscription ? Config.URL_SUBSCRIPTION : Config.URL
    }${path}`;
    // if (isPay && !isSubscription) {
    //     url = `${Config.URL_PAY}${path}`;
    // } else {
    //     if (isPayEP) {
    //         url = `${Config.URL_ENERGYPAID}${path}`;
    //     }
    // }

    const settings = {
        method,
        url,
        headers: { 'Content-Type': 'application/json' },
        responseType: 'json',
        body: modifiedBody,
    } as any;

    const token = localStorage.getItem('token');
    if (token && path !== '/login' && !isPayEP) {
        const Authorization = 'Authorization';
        settings.headers[Authorization] = `Bearer ${token}`;
    }

    if (isPayEP) {
        settings.method = 'post';
        settings.headers.Accept = '*/*';
        settings.headers['Access-Control-Allow-Origin'] = '*';
        settings.headers['Cache-Control'] = 'no-cache';
        const ContentType = 'Content-Type';
        settings.headers[ContentType] = 'application/json';
    }

    settings.headers['x-api-key'] = Config.ApiKey;

    return ajax(settings).pipe(
        catchError(({ message, status, response }) => {
            const error = {
                message,
                code: String(status),
                errors: [],
            };
            if (response) {
                if (isArray(response)) {
                    error.errors = response as any;
                } else {
                    error.code = response.code;
                    error.message = response.message;
                }
            }
            log(`Error from API ${path}: `, error);
            return throwError(error);
        }),
        map(({ response }) => {
            if (!response) {
                return {
                    code: '500',
                    message: 'Internal Server Error',
                };
            }
            return response;
        }),
    );
};

const fetchApiRequestBlob = async (
    bodyRequest: any,
    path: string,
    configPath?: string,
): Promise<Blob | undefined> => {
    const settings = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyRequest),
    } as any;

    const token = localStorage.getItem('token');
    if (token) {
        const Authorization = 'Authorization';
        settings.headers[Authorization] = `Bearer ${token}`;
    }

    try {
        const response = await fetch(
            `${configPath ? configPath : Config.URL}${path}`,
            settings,
        );
        return response.blob();
    } catch (err) {
        log(`Error from ${path} (fetch) =>`, err);
        return undefined;
    }
};

export { fetchApiRequestBlob, invokeLambda };
export default apiRequest;
