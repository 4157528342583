import React from 'react';
import { connect } from 'react-redux';
import { RootState, TSchemaProps } from 'Types';
import SearchCaseContainer from '../../../containers/admin/SearchCaseContainer';
import CaseResult from '../../../containers/admin/SearchCaseContainer/CaseResult';
import { fetchOrderAsync } from '../../../_actions/searchOrder.actions';
import { getGroupAsync } from '../../../_actions/getGroup.actions';
import {
    caseResultRetryFirstPaymentAsync,
    retryFirstPayment,
} from '../../../_actions/caseResult.action';
import { isUserLimitedBySchema } from '../../../utils/helpers';
import { EMenuLabels, ESubMenuCaseLabel } from '../../../utils/enums';

const mapStateToProps = (state: RootState) => ({
    orderState: state.orderReducer.order,
    retryFirstPaymentState: state.retryFirstPaymentReducer.message,
    retryFirstPaymentResponse:
        state.retryFirstPaymentReducer.retryFirstPaymentResponse,
    groupListState: state.userGroupReducer.groupList,
    groupe: state.authReducer.groupe,
    schema: state.authReducer.autorizationsScheme,
    userId: state.authReducer.userId,
});

const dispatchProps = {
    fetchOrderAsynDispatch: fetchOrderAsync.request,
    getGroupAsyncDispatch: getGroupAsync.request,
    getRetryFirstPaymentAsyncDispatch: caseResultRetryFirstPaymentAsync.request,
    retryFirstPayment,
};

type Props = {
    schemaProps: TSchemaProps;
} & ReturnType<typeof mapStateToProps> &
    typeof dispatchProps;

const CasesPage: React.FC<Props> = ({
    fetchOrderAsynDispatch,
    orderState,
    groupListState,
    getGroupAsyncDispatch,
    retryFirstPaymentState,
    retryFirstPaymentResponse,
    retryFirstPayment,
    schemaProps,
    userId,
    schema,
    getRetryFirstPaymentAsyncDispatch,
}) => {
    const userLimited = isUserLimitedBySchema(
        EMenuLabels.CASE,
        ESubMenuCaseLabel.SEARCH_CASE,
        schema,
    );

    return (
        <div>
            <SearchCaseContainer
                fetchOrder={fetchOrderAsynDispatch}
                groupList={groupListState}
                userLimited={userLimited}
                userId={userId}
                schemaProps={schemaProps}
            />

            <CaseResult
                schemaProps={schemaProps}
                order={orderState as any}
                getGroup={getGroupAsyncDispatch}
                retryFirstPayment={getRetryFirstPaymentAsyncDispatch}
                retryFirstPaymentState={retryFirstPaymentState}
                retryFirstPaymentResponse={retryFirstPaymentResponse}
                resetFirstPayment={retryFirstPayment}
            />
        </div>
    );
};

export default connect(mapStateToProps, dispatchProps)(CasesPage);
