import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Subtract } from 'utility-types';

import { RootState, InputEvent } from 'Types';
import withBanner, {
    ExternalProps as ExternalPropsBanner,
} from '../../libraries/withBanner';
import withModal, {
    ExternalProps as ExternalPropsModal,
    InjectedProps as InjectedPropsModal,
} from '../../libraries/withModal';
import SettingContainer, {
    Props as ContainerProps,
} from '../../containers/SettingContainer/SettingContainer';

import {
    fetchContractAsync,
    resetContractState,
    setContract,
} from '../../_actions/contract.actions';
import {
    updateCustomerAsync,
    updatePasswordAsync,
    resetUpdate,
} from '../../_actions/profile.actions';

import WordingConstant from '../../utils/wording.json';
import { getTodayDate, findContractByNumber } from '../../utils/helpers';

const Wording = WordingConstant.SettingPage;

interface IFields {
    toggles: {
        isReceiveNews: boolean;
        isAuthorizeData: boolean;
        [key: string]: boolean;
    };
    password: {
        old: string;
        new: string;
        confirm: string;
        [key: string]: string;
    };
}

const mapStateToProps = (state: RootState) => ({
    profileState: state.profileReducer,
    contractState: state.contractReducer,
});

const dispatchProps = {
    contractDispatch: fetchContractAsync.request,
    updateCustomerDispatch: updateCustomerAsync.request,
    updatePasswordDispatch: updatePasswordAsync.request,
    resetUpdateDispatch: resetUpdate,
    resetContractDispatch: resetContractState,
    setContractDispatch: setContract,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const WrappedSetting = compose<
    ContainerProps,
    Subtract<ContainerProps, InjectedPropsModal> &
        ExternalPropsBanner &
        ExternalPropsModal
>(
    withBanner(),
    withModal(),
)(SettingContainer);

type State = {
    fields: IFields;
    errors: {
        showNewPass: boolean;
        showConfirmPass: boolean;
        [key: string]: boolean;
    };
};

export class SettingPage extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        const { profileState }: Props = this.props;

        this.state = {
            fields: {
                password: {
                    old: '',
                    new: '',
                    confirm: '',
                },
                toggles: {
                    isAuthorizeData: profileState.contact.marketing,
                    isReceiveNews: profileState.contact.newsletter,
                },
            },
            errors: {
                showNewPass: false,
                showConfirmPass: false,
            },
        };
    }

    handleToggle = (type: string) => {
        const { fields } = this.state;
        fields.toggles[type] = !fields.toggles[type];
        this.setState({ fields });
    };

    handleInput = (e: InputEvent, type: string) => {
        const { fields } = this.state;
        const { value } = e.currentTarget;
        fields.password[type] = value;
        this.setState({ fields });
    };

    handleError = (type: string, val: boolean) => {
        const { errors } = this.state;
        errors[type] = val;
        this.setState({ errors });
    };

    handleChangeBanner = (contractNumber: string) => {
        const {
            contractState: { contracts },
            contractDispatch,
            profileState: { customerNumber },
            setContractDispatch,
        } = this.props;
        const contract = findContractByNumber(contractNumber, contracts);
        if (contract.isFetched) {
            setContractDispatch({ contract });
        } else {
            contractDispatch({
                customerNbr: customerNumber,
                contractNbr: contractNumber,
            });
        }
    };

    handleUpdate = (section: string) => {
        const { fields } = this.state;
        const {
            updateCustomerDispatch,
            profileState,
            updatePasswordDispatch,
            contractState,
        } = this.props;

        if (section === 'customer') {
            updateCustomerDispatch({
                customer: {
                    customerNbr: profileState.customerNumber,
                    email: profileState.contact.email,
                    phone: profileState.contact.phone,
                    marketing: fields.toggles.isAuthorizeData,
                    newsletter: fields.toggles.isReceiveNews,
                },

                event: {
                    customerNbr: profileState.customerNumber,
                    contractNbr: contractState.selectedContractNumber,
                    category: WordingConstant.ProfilePage.categoryToSend,
                    date: getTodayDate(),
                    subCategory:
                        WordingConstant.ProfilePage.subCategories
                            .consumptionContactChange.value,
                    message:
                        WordingConstant.ProfilePage.subCategories
                            .consumptionContactChange.label,
                },
            });
        } else {
            const token = localStorage.getItem('accessToken');
            if (token) {
                updatePasswordDispatch({
                    token,
                    user: profileState.customerNumber,
                    oldPassword: fields.password.old,
                    newPassword: fields.password.new,
                });
            }

            fields.password.confirm = '';
            fields.password.new = '';
            fields.password.old = '';
        }
        this.setState({ fields });
    };

    render() {
        const { fields, errors } = this.state;
        const {
            profileState: { contact, updateResponse, customerType },
            contractState: { contracts, selectedContractNumber, error },
            resetUpdateDispatch,
            resetContractDispatch,
        }: Props = this.props;
        return (
            <WrappedSetting
                fields={fields}
                errors={errors}
                handleToggle={this.handleToggle}
                handleInput={this.handleInput}
                handleError={this.handleError}
                // Banner
                title={Wording.title}
                bannerError={error}
                handleCloseModalBanner={() => resetContractDispatch()}
                selectedContractNumber={selectedContractNumber}
                contracts={contracts}
                contact={contact}
                customerType={customerType}
                handleChangeBanner={this.handleChangeBanner}
                // Modal
                modalMessage={Wording.popup}
                updateResponse={updateResponse}
                handleValidate={this.handleUpdate}
                handleReset={resetUpdateDispatch}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    dispatchProps,
)(SettingPage);
