import React, { FC } from 'react';

import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        borderBottom: 3,
        borderBottomColor: '#000',
        marginTop: 5,
    },
    text: {
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 12,
    },
});

type Props = {
    title: string;
};

const Section: FC<Props> = ({ title }) => (
    <View style={styles.container}>
        <Text style={styles.text}>{title}</Text>
    </View>
);
export default Section;
