import React, { FC } from 'react';

type FieldProps = {
    name: string;
    value?: string;
};

const baseClassName = 'recap-field';

const RecapField: FC<FieldProps> = (props: FieldProps) => {
    const { name, value } = props;

    return (
        <div className={baseClassName}>
            <span>{`${name} :`}</span>
            <span>{value || '-'}</span>
        </div>
    );
};

export default RecapField;
