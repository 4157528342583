import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { TResponse, TUser } from 'Models';
import * as actions from '../_actions/searchUserGroup.actions';
import * as createGroupActions from '../_actions/createGroup.actions';
import * as getGroupActions from '../_actions/getGroup.actions';
import * as createUserActions from '../_actions/createUser.actions';
import * as manageUserActions from '../_actions/manageUser.actions';
import * as administrationClientSpace from '../_actions/administrationClientSpace.actions';

export type UserGroupState = Readonly<{
    error?: string;
    userList: TUser[];
    groupList: string[];
    administrationList: TUser[];
    manageUserResponse: TResponse;
    createUserResponse: TResponse;
    createGroupResponse: TResponse;
}>;

const initialState = {
    userList: [],
    error: undefined,
    groupList: [],
    administrationList: [],
    manageUserResponse: { code: '', message: '' },
    createUserResponse: { code: '', message: '' },
    createGroupResponse: { code: '', message: '' },
};

type Actions =
    | actions.searchUserGroupAction
    | createGroupActions.CreateGroupAction
    | getGroupActions.getGroupAction
    | createUserActions.CreateUserAction
    | createGroupActions.CreateGroupAction
    | manageUserActions.ManageUserAction
    | administrationClientSpace.AdminClientSpaceAction;

const userGroupReducer: Reducer<UserGroupState, Actions> = (
    state = initialState,
    action: Actions,
) => {
    switch (action.type) {
        case getType(actions.searchUserGroupAsync.success): {
            return {
                ...state,
                userList: action.payload.userList || [],
                error: undefined,
                manageUserResponse: {
                    code: action.payload.code as string,
                    message: action.payload.message as string,
                },
            };
        }

        case getType(actions.searchUserGroupAsync.failure): {
            return {
                ...state,
                userList: [],
                error: action.payload.message,
                manageUserResponse: {
                    code: action.payload.code as string,
                    message: action.payload.message as string,
                },
            };
        }

        // CREATE USER
        case getType(createUserActions.createUserAsync.success): {
            return {
                ...state,
                createUserResponse: {
                    code: action.payload.code,
                    message: action.payload.message,
                },
            };
        }

        case getType(createUserActions.createUserAsync.failure): {
            return {
                ...state,
                createUserResponse: {
                    code: action.payload.code as string,
                    message: action.payload.message as string,
                },
            };
        }
        case getType(createUserActions.resetCreateUserResponse): {
            return {
                ...state,
                createUserResponse: {
                    code: '',
                    message: '',
                },
            };
        }
        // CREATE USER END

        // CREATE GROUP
        case getType(createGroupActions.createGroupAsync.success): {
            return {
                ...state,
                createGroupResponse: {
                    code: action.payload.code,
                    message: action.payload.message,
                },
            };
        }

        case getType(createGroupActions.createGroupAsync.failure): {
            return {
                ...state,
                createGroupResponse: {
                    code: action.payload.code as string,
                    message: action.payload.message as string,
                },
            };
        }

        case getType(createGroupActions.resetGroupResponse): {
            return {
                ...state,
                createGroupResponse: {
                    code: '',
                    message: '',
                },
            };
        }
        // CREATE GROUP END

        case getType(manageUserActions.manageUserAsync.success): {
            return {
                ...state,
                manageUserResponse: {
                    code: action.payload.code,
                    message: action.payload.message,
                },
            };
        }

        case getType(manageUserActions.manageUserAsync.failure): {
            return {
                ...state,
                manageUserResponse: {
                    code: action.payload.code as string,
                    message: action.payload.message as string,
                },
            };
        }

        case getType(manageUserActions.resetManageUserResponse): {
            return {
                ...state,
                manageUserResponse: {
                    code: '',
                    message: '',
                },
            };
        }

        case getType(getGroupActions.getGroupAsync.success): {
            return {
                ...state,
                groupList: action.payload.groupList,
            };
        }

        case getType(getGroupActions.getGroupAsync.failure): {
            return {
                ...state,
                groupList: [],
            };
        }
        case getType(getGroupActions.resetGroupListResponse): {
            return {
                ...state,
                groupList: [],
            };
        }
        // AdministrationClientSpace
        case getType(administrationClientSpace.adminClientSpaceAsync.success): {
            return {
                ...state,
                administrationList: action.payload.userList || [],
                error: 'no matches found',
            };
        }

        case getType(administrationClientSpace.adminClientSpaceAsync.failure): {
            if (action.payload) {
                return {
                    ...state,
                    administrationList: [],
                    error: action.payload.message,
                };
            }
            return {
                ...state,
                administrationList: [],
                error: 'unexpected error',
            };
        }
        case getType(administrationClientSpace.resetAdminClientSpaceResponse): {
            return {
                ...state,
                administrationList: [],
                error: undefined,
            };
        }
        default:
            return { ...state };
    }
};

export default userGroupReducer;
