import React, { Component } from 'react';

type Props = {
    callback: () => void;
    children: React.ReactNode;
};
export default class OutsideClickClass extends Component<Props, {}> {
    wrapperRef: any;
    constructor(props: Props) {
        super(props);

        this.wrapperRef = null;
    }

    componentDidMount = () => {
        document.addEventListener('mousedown', this.handleClickOutside);
    };

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClickOutside);
    };

    setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    };

    handleClickOutside = (event: any) => {
        const { callback } = this.props;
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            callback();
        }
    };

    render() {
        return <div ref={this.setWrapperRef}>{this.props.children}</div>;
    }
}
