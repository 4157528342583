import React, { Dispatch } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { AsyncFunctionProxy, RootAction, RootState, TSchemaProps } from 'Types';
import { RouteComponentProps } from 'react-router-dom';
import AdminSubscriptionContainer from '../../../containers/admin/AdminSubscriptionContainer';
import { parseQueryParams } from '../../../utils/helpers';
import { hasExpiredSession } from '../../../utils/storage';
import {
    checkSponsor,
    getCalendarAppointment,
    getDeliveryPointPackages,
    getEstimatesPackages,
    getOffers,
    getOrder,
    getOrderStatus,
    getProducts,
    getSurveyPackages,
    paymentCB,
    saveOrder,
    searchAppointmentTimeslot,
    searchSiret,
    signOrder,
} from '../../../utils/network/request';
import { refreshSessionExpirationStatus } from '../../../_actions/auth.actions';
import {
    SessionExpiredError,
    RequestError,
} from '../../../utils/network/errors';

type StateProps = {
    group?: string;
    userId: string;
    groupList: string[];
};
type DispatchProps = {
    dispatchCheckSessionExpiration: (expired: boolean) => void;
};

type OwnProps = {
    schemaProps: TSchemaProps;
} & (Pick<RouteComponentProps, 'location'> | {});
type NetworkProps = {
    checkSponsor: AsyncFunctionProxy<typeof checkSponsor>;
    getCalendarAppointment: AsyncFunctionProxy<typeof getCalendarAppointment>;
    getDeliveryPointPackages: AsyncFunctionProxy<
        typeof getDeliveryPointPackages
    >;
    getEstimatesPackages: AsyncFunctionProxy<typeof getEstimatesPackages>;
    getOffers: AsyncFunctionProxy<typeof getOffers>;
    getOrder: AsyncFunctionProxy<typeof getOrder>;
    getOrderStatus: AsyncFunctionProxy<typeof getOrderStatus>;
    getProducts: AsyncFunctionProxy<typeof getProducts>;
    getSurveyPackages: AsyncFunctionProxy<typeof getSurveyPackages>;
    paymentCB: AsyncFunctionProxy<typeof paymentCB>;
    saveOrder: AsyncFunctionProxy<typeof saveOrder>;
    searchAppointmentTimeslot: AsyncFunctionProxy<
        typeof searchAppointmentTimeslot
    >;
    searchSiret: AsyncFunctionProxy<typeof searchSiret>;
    signOrder: AsyncFunctionProxy<typeof signOrder>;
};
type MergeProps = StateProps &
    NetworkProps & {
        orderNumber?: string | number;
        schemaProps: TSchemaProps;
    };

const mapStateToProps = (state: RootState): StateProps => ({
    group: state.authReducer.groupe,
    userId: state.authReducer.userId || '',
    groupList: state.userGroupReducer.groupList,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>): DispatchProps => {
    return {
        dispatchCheckSessionExpiration: (expired: boolean) => {
            dispatch(refreshSessionExpirationStatus(expired));
        },
    };
};

const mergeProps = (
    state: StateProps,
    dispatch: DispatchProps,
    own: OwnProps,
): MergeProps => {
    const { dispatchCheckSessionExpiration } = dispatch;
    const withCheck = <
        R,
        F extends (...args: any[]) => Promise<R | RequestError>
    >(
        fn: F,
    ): (() => Promise<R | RequestError>) => {
        return async (
            ...args: Parameters<typeof fn>
        ): Promise<R | RequestError> => {
            const expired = hasExpiredSession();
            dispatchCheckSessionExpiration(expired);
            return !expired ? await fn(...args) : new SessionExpiredError();
        };
    };

    const nextProps: MergeProps = {
        ...state,
        checkSponsor: withCheck(checkSponsor),
        getCalendarAppointment: withCheck(getCalendarAppointment),
        getDeliveryPointPackages: withCheck(getDeliveryPointPackages),
        getEstimatesPackages: withCheck(getEstimatesPackages),
        getOffers: withCheck(getOffers),
        getOrder: withCheck(getOrder),
        getOrderStatus: withCheck(getOrderStatus),
        getProducts: withCheck(getProducts),
        getSurveyPackages: withCheck(getSurveyPackages),
        paymentCB: withCheck(paymentCB),
        saveOrder: withCheck(saveOrder),
        searchAppointmentTimeslot: withCheck(searchAppointmentTimeslot),
        searchSiret: withCheck(searchSiret),
        signOrder: withCheck(signOrder),
        ...own,
    };
    const location = _.get(own, 'location');
    if (!!location) {
        const params = parseQueryParams(
            location,
            window.location.host,
            window.location.protocol,
        );
        const orderNumber: string | number | undefined = params.get(
            'orderNumber',
        );
        nextProps.orderNumber = orderNumber;
    }

    return nextProps;
};

export type ConnectedProps = MergeProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(AdminSubscriptionContainer);
