class AuthService {
    public static authenticate() {}

    public static logout(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('reduxState');
        localStorage.removeItem('expires');
        localStorage.removeItem('isValidated');
        localStorage.removeItem('isEmail');
    }

    public static isAuthenticated(): boolean {
        const token = localStorage.getItem('token');
        const accessToken = localStorage.getItem('accessToken');
        const userId = localStorage.getItem('userId');
        return (
            token !== undefined &&
            token !== null &&
            accessToken !== undefined &&
            accessToken !== null &&
            userId !== undefined &&
            userId !== null
        );
    }

    public static isVlaidated(): boolean {
        const isValidated = localStorage.getItem('isValidated');
        return (
            isValidated === null ||
            isValidated === undefined ||
            isValidated === 'true'
        );
    }
}

export default AuthService;
