import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';

import { useTimeline } from '../lib';

import Wording from '../wording.json';

import {
    getDateFormatted,
    findLastValidPiece,
    findLastValidTransaction,
} from '../../../../utils/helpers';

import {
    Table,
    Header,
    Cell,
    DataCell,
    Body,
} from '../../ProofOfPayment/components/Table';
import { TPiece, TTransaction, TEPInvoice } from 'Models';
import { ETimeframe } from '../../../../utils/enums';

type Frequencies =
    | 'MONTHLY'
    | 'BIMONTHLY'
    | 'QUARTERLY'
    | 'FOURMONTHLY'
    | 'BIANNUAL'
    | 'UNKNOWN';

const { frequencyMapping, timelineTable } = Wording;

const styles = StyleSheet.create({
    container: {
        width: '80%',
        marginLeft: '10%',
        marginTop: 25,
    },
    header: {
        backgroundColor: '#EFEFEF',
        padding: '5px 0',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 11,
    },
    body: {
        padding: '3px 10px',
    },
    rowStyle: {
        backgroundColor: '#000000',
        fontFamily: 'Montserrat',
        fontWeight: 800,
    },
    total: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        border: '1pt solid black',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 20,
        marginTop: 10,
        backgroundColor: '#EFEFEF',
    },
    totalTitle: {
        width: '80%',
        padding: '2px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: 11,
        height: '100%',
    },
    totalValue: {
        width: '20%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Montserrat',
        fontWeight: 100,
        fontSize: 11,
        height: '100%',
        borderLeft: '1pt solid black',
        backgroundColor: '#FFFFFF',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 5,
        fontFamily: 'Montserrat',
        flexDirection: 'row',
        fontSize: 6,
        width: '100%',
    },
    infoNB: {
        fontWeight: 800,
    },
    infoText: {
        fontWeight: 100,
    },
});

const TimelineTable = () => {
    const { payments, contract } = useTimeline();
    const lastValidPiece = findLastValidPiece(payments).lastValid as any;
    const dueDate = lastValidPiece
        ? (lastValidPiece as TEPInvoice).invoicing_period_start_date
        : contract.effectiveStartDate;
    // console.log('dueDate:', dueDate);
    // const dataTransaction = findLastValidTransaction(
    //    (lastValidPiece as TPiece).processusList[0].transactions,
    // );
    const chosenPackage =
        contract.chosenPackages && (contract.chosenPackages[0] as any);
    const rate = chosenPackage ? chosenPackage.amount : 0;
    // console.log('rate:', rate);
    const dataToDisplay = new Array();
    let globalAmount = 0;

    const frequency = () => {
        switch (contract.installmentFrequency) {
            case 'MONTHLY':
                return 1; // 12
            case 'BIMONTHLY':
                return 2; // 6
            case 'QUARTERLY':
                return 3; // 4
            case 'FOURMONTHLY':
                return 4; // 3
            default:
                return 0; // Error
        }
    };

    const getMonthDifference = (start: any, end: any) => {
        const startDate: Date = new Date(start);
        const endDate: Date = new Date(end);
        const timeDiff: number = Math.abs(
            endDate.getTime() - startDate.getTime(),
        );
        return Math.round(timeDiff / (1000 * 60 * 60 * 24) / 30) as number;
    };

    const energyType = (type: string) => {
        return 'EL' === type ? 'Electricité' : 'Gaz';
    };

    const frequencyWording = (value: number) => {
        switch (value) {
            case 1:
                return 'MONTHLY';
            case 2:
                return 'BIMONTHLY';
            case 3:
                return 'QUARTERLY';
            case 4:
                return 'FOURMONTHLY';
            default:
                return 'Inconnue';
        }
    };

    const dataObject = (
        energy: string,
        rate: number,
        installmentFrequency: string,
        date: string,
        amount: number,
    ) => {
        return {
            energyType: energyType(energy),
            rate,
            installmentFrequency:
                frequencyMapping[installmentFrequency as Frequencies],
            date,
            amountToPay: amount,
        };
    };

    const getPricePerMonth = (month: number) => {
        if (contract.chosenPackages && !contract.chosenPackages[1]) {
            return parseInt(rate, 10);
        }
        const secondChosenPackage =
            contract.chosenPackages && (contract.chosenPackages[1] as any);
        const summerPrice: number =
            chosenPackage.timeframe === ETimeframe.SUMMER
                ? chosenPackage.amount
                : secondChosenPackage.amount;
        const winterPrice: number =
            chosenPackage.timeframe === ETimeframe.WINTER
                ? chosenPackage.amount
                : secondChosenPackage.amount;
        return month <= 9 && month >= 3 ? summerPrice : winterPrice;
    };

    const getAmountPerFrequency = (month: number) => {
        let amount: number = 0;
        let i: number = month;
        while (i < month + frequency()) {
            amount = Number(amount) + Number(getPricePerMonth(i % 12));
            i = Number(i) + 1;
        }
        globalAmount = Number(globalAmount) + Number(amount);
        return amount;
    };

    const buildData = () => {
        let monthDiff = frequency();
        let date = new Date();
        date = new Date(dueDate);
        if (lastValidPiece) {
            // console.log('start', lastValidPiece.invoicing_period_start_date);
            // console.log('end', lastValidPiece.invoicing_period_end_date);
            monthDiff = getMonthDifference(
                lastValidPiece.invoicing_period_start_date,
                lastValidPiece.invoicing_period_end_date,
            );
            monthDiff = monthDiff === 0 ? 1 : monthDiff;
            // console.log('monthDiff', monthDiff);
            globalAmount = Number(globalAmount) + Number(lastValidPiece.amount);
            dataToDisplay.push(
                dataObject(
                    contract.energy,
                    lastValidPiece.amount / monthDiff,
                    frequencyWording(monthDiff),
                    date.toLocaleDateString(),
                    lastValidPiece.amount,
                ),
            );
        } else {
            dataToDisplay.push(
                dataObject(
                    contract.energy,
                    getPricePerMonth(date.getMonth()),
                    contract.installmentFrequency,
                    date.toLocaleDateString(),
                    getAmountPerFrequency(date.getMonth()),
                ),
            );
            // console.log('dataToDisplay', dataToDisplay);
        }
        date.setDate(date.getDate() + 1);
        date = new Date(
            date.getFullYear(),
            date.getMonth() + monthDiff,
            parseInt(contract.finance.debitDay, 10),
        );
        dataToDisplay.push(
            dataObject(
                contract.energy,
                getPricePerMonth(date.getMonth()),
                contract.installmentFrequency,
                date.toLocaleDateString(),
                getAmountPerFrequency(date.getMonth()),
            ),
        );
        let i: number = 2;
        while (i < 12 / frequency()) {
            date = new Date(
                date.getFullYear(),
                date.getMonth() + frequency(),
                parseInt(contract.finance.debitDay, 10),
            );
            dataToDisplay.push(
                dataObject(
                    contract.energy,
                    getPricePerMonth(date.getMonth()),
                    contract.installmentFrequency,
                    date.toLocaleDateString(),
                    getAmountPerFrequency(date.getMonth()),
                ),
            );
            i = Number(i) + 1;
        }
        // console.log('dataToDisplay', dataToDisplay);
    };

    if (chosenPackage && contract) {
        buildData();
    }

    return (
        <View style={styles.container}>
            <Table data={dataToDisplay}>
                <Header textAlign="center" style={styles.header}>
                    <Cell>Energie</Cell>
                    <Cell>Forfait</Cell>
                    <Cell>Fréquence de prélèvement</Cell>
                    <Cell>Date de prélèvement</Cell>
                    <Cell>Montant à régler</Cell>
                </Header>
                <Body style={styles.body}>
                    <DataCell textAlign="center" Cell={r => r.energyType} />
                    <DataCell textAlign="center" Cell={r => r.rate} />
                    <DataCell
                        textAlign="center"
                        Cell={r => r.installmentFrequency}
                    />
                    <DataCell textAlign="center" Cell={r => r.date} />

                    <DataCell textAlign="center" Cell={r => r.amountToPay} />
                </Body>
            </Table>
            <View style={styles.total}>
                <View style={styles.totalTitle}>
                    <Text>{timelineTable.total}</Text>
                </View>
                <View style={styles.totalValue}>
                    <Text>{globalAmount}</Text>
                </View>
            </View>
            <View style={styles.info}>
                <Text style={styles.infoNB}>NB : </Text>
                <View style={styles.infoText}>
                    <Text>
                        Les mensualités indiquées correspondant au forfait et à
                        la fréquence sélectionnée par le client. Ces prix
                        prennent en compte les remises auxquelles le
                    </Text>
                    <Text>
                        client est éligible. En cas de non-respect des
                        conditions d’égibilité, le prix du kWh sera facturé au
                        prix brut
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default TimelineTable;
