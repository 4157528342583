import React, { FC, Fragment, useEffect, CSSProperties } from 'react';
import { buildClassName as bcn } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

interface IProps {
    background?: boolean;
    centered?: boolean;
    children: JSX.Element[] | JSX.Element;
    handleClose?: () => void;
    id?: string;
    show?: boolean;
    showCloseButton?: boolean;
}

const Wording = WordingConstant.Common;
const baseClassName = 'custom-overlay';
const hiddenClassName = bcn([baseClassName, 'hidden'], '--');
const centeredClassName = bcn([baseClassName, 'centered'], '--');
const windowClassName = bcn([baseClassName, 'window']);
const contentClassName = bcn([windowClassName, 'content']);
const backgroundClassName = bcn([baseClassName, 'background']);
const closeButtonClassName = bcn([contentClassName, 'close-button']);

const CustomOverlay: FC<IProps> = (props: IProps) => {
    const {
        centered,
        children,
        handleClose,
        id,
        show,
        showCloseButton,
    } = props;
    let { background } = props;
    if (background === undefined) background = true;
    useEffect(() => {
        if (!!show && !!id && !centered) {
            const el: HTMLElement | null = document.getElementById(id);
            if (!!el) {
                const elHeight: number = el.getBoundingClientRect().height;
                const winHeight: number = window.innerHeight;
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: winHeight < elHeight ? 'start' : 'center',
                });
            }
        }
    }, [show]);
    let mainClassName = baseClassName;
    if (!show) mainClassName = hiddenClassName;
    else if (!!centered) mainClassName = centeredClassName;
    return (
        <div className={mainClassName}>
            {show && (
                <Fragment>
                    {background && <div className={backgroundClassName} />}
                    <div className={windowClassName}>
                        <div className={contentClassName} id={id}>
                            {showCloseButton && (
                                <span
                                    className={closeButtonClassName}
                                    onClick={handleClose}
                                    role="button"
                                    tabIndex={0}
                                >
                                    {Wording.popup.closeButton}
                                </span>
                            )}
                            {children}
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default CustomOverlay;
