import React from 'react';

import { CSVLink } from 'react-csv';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import CustomButton from '../../CustomButton';
import TablePagination from '../../TablePagination';
import resources from '../../../utils/resources';

export const LoadingComponent: React.FC<{ loading: boolean }> = ({
    loading,
}) => {
    if (loading) {
        return (
            <div className="custom-loading -loading -active">
                <div className="custom-loading-inner -loading-inner">
                    Chargement en cours...
                </div>
            </div>
        );
    }
    return null;
};

export type Props = {
    columns: object[];
    data: any;
    title?: string;
    loading?: boolean;
    noDataText?: string;
    sortable?: boolean;
    buttonTitle?: string;
    error?: string;
    SubComponent?: any;
    showPagination?: boolean;
    downloadData?: any;
    downloadHeaders?: any;
    getTrProps?: any;
    downloadFilename?: string;
    downloadButtonTitle?: string;
    className?: string;
    filterable?: boolean;
    collapseOnDataChange?: boolean;
    pageSize?: number;
    customElmTopAndBottom?: any;
    showPaginationTop?: boolean;
    ref?: any;
    TheadComponent?: any;
    customPageSIze?: any;

    handleButtonClick?(): void;
};

const CustomTable: React.FC<Props> = ({
    columns,
    data,
    title,
    loading,
    noDataText,
    sortable,
    buttonTitle,
    handleButtonClick,
    error,
    SubComponent,
    showPagination,
    className,
    downloadHeaders,
    downloadData,
    getTrProps,
    downloadFilename,
    filterable,
    downloadButtonTitle,
    customElmTopAndBottom,
    collapseOnDataChange,
    customPageSIze,
    ref,
    TheadComponent,
}) => {
    const renderCustomElement = (position: string) =>
        customElmTopAndBottom && (
            <div
                className={`custom-table__custom custom-table__custom-${position} `}
            >
                {customElmTopAndBottom}
            </div>
        );

    return (
        <div className="custom-table">
            {title && <h3>{title}</h3>}
            {renderCustomElement('top')}
            <ReactTable
                className={`custom-table__table custom-table__table-border-none ${
                    className ? className : ''
                }`}
                columns={columns}
                data={data}
                style={{ border: 'none' }}
                ref={ref}
                defaultPageSize={data.length < 10 ? data.length + 1 : 10}
                showPaginationBottom={showPagination}
                filterable={filterable}
                loading={loading}
                showPaginationTop={showPagination}
                LoadingComponent={LoadingComponent}
                TheadComponent={TheadComponent}
                PaginationComponent={TablePagination}
                noDataText={noDataText}
                collapseOnDataChange={
                    collapseOnDataChange ? collapseOnDataChange : false
                }
                resizable={false}
                sortable={sortable}
                SubComponent={SubComponent}
                showPagination={showPagination}
                getTrProps={getTrProps}
            />
            {renderCustomElement('bottom')}

            {!error && buttonTitle && (
                <CustomButton
                    title={buttonTitle}
                    color="orange"
                    onClick={handleButtonClick}
                />
            )}
            {downloadButtonTitle && downloadData && (
                <CSVLink
                    className="custom-table__downloadLink"
                    data={downloadData}
                    headers={downloadHeaders}
                    filename={downloadFilename}
                >
                    {downloadButtonTitle}
                </CSVLink>
            )}
        </div>
    );
};

export const withTriangle = (value: string | HTMLElement) => (
    <span className="with-triangle">
        {value} <img src={resources['ic-triangle']} alt="ic-triangle" />
    </span>
);

export default CustomTable;
