import _ from 'lodash';
import { getFormattedDateToBackEnd } from '../../helpers';
import { requiresPayment } from '../helpers';
import {
    ECivilities,
    ECustomerTypes,
    EEnergyTypes,
    TAddress,
    TContract,
    TCustomerContact,
    TOrder,
    ETimeslots,
    TThirdParty,
    ERebateCodeTypes,
    EPaymentModes,
    EBillingModes,
    EFirstPaymentCBStatuses,
    EEffectiveStartRanges,
    EtypePayment,
} from '../types';
import { EProcessTypes } from '../../enums';

function buildPendingOrder(
    client: {
        customerContact: TCustomerContact | null;
        customerType: ECustomerTypes;
        typePayment: EtypePayment;
    },
    offer: {
        contracts: TContract[];
    },
    situation: {
        billingAddress?: TAddress;
        coords?: {
            civility: ECivilities | null;
            firstName: string;
            lastName: string;
        };
        coholder?: Partial<TThirdParty>;
        effectiveStartRange?: EEffectiveStartRanges;
        energies: {
            EL?: {
                effectiveStartDate?: Date;
                express?: boolean;
                firstPaymentCB: boolean;
                timeslot?: ETimeslots;
                dueDate?: Date;
            };
            NG?: {
                effectiveStartDate?: Date;
                express?: boolean;
                firstPaymentCB: boolean;
                timeslot?: ETimeslots;
                dueDate?: Date;
            };
        };
        payment: {
            iban?: string;
        };
        processType: EProcessTypes | undefined;
    },
    options: {
        isSign: boolean;
        rebateCode?: {
            code: string;
            type: ERebateCodeTypes | null;
        };
        channel?: string;
    },
): TOrder | null {
    const order: any = {
        customer: {
            contact: client.customerContact,
            type: client.customerType,
            typePayment: client.typePayment,
        },
    };
    let firstPaymentCBStatus: boolean = false;
    let electricityContract: TContract | null = null;
    let gasContract: TContract | null = null;
    _.forEach(offer.contracts, (contract: TContract) => {
        if (
            !electricityContract &&
            contract.energy === EEnergyTypes.ELECTRICTY
        ) {
            electricityContract = contract;
        } else if (!gasContract && contract.energy === EEnergyTypes.GAS) {
            gasContract = contract;
        } else {
            return false;
        }
    });

    if (!!electricityContract) {
        if (
            !!situation.energies.EL &&
            !!situation.energies.EL.effectiveStartDate
        ) {
            _.set(
                electricityContract,
                'effectiveStartDate',
                getFormattedDateToBackEnd(
                    situation.energies.EL.effectiveStartDate,
                ),
            );
            _.set(
                electricityContract,
                'deliveryPoint.process.express',
                !!situation.energies.EL.express,
            );
            _.set(
                electricityContract,
                'firstPaymentCB',
                situation.energies.EL.firstPaymentCB,
            );
            _.set(
                electricityContract,
                'deliveryPoint.process.appointmentTimeslot',
                situation.energies.EL.timeslot,
            );

            if (
                situation.energies.EL.dueDate &&
                situation.processType === EProcessTypes.SWITCH
            ) {
                _.set(
                    electricityContract,
                    'dueDate',
                    getFormattedDateToBackEnd(situation.energies.EL.dueDate),
                );
            }
        }
    }
    if (!!gasContract) {
        if (
            !!situation.energies.NG &&
            !!situation.energies.NG.effectiveStartDate
        ) {
            _.set(
                gasContract,
                'effectiveStartDate',
                getFormattedDateToBackEnd(
                    situation.energies.NG.effectiveStartDate,
                ),
            );
            _.set(
                gasContract,
                'deliveryPoint.process.express',
                !!situation.energies.NG.express,
            );
            _.set(
                gasContract,
                'firstPaymentCB',
                situation.energies.NG.firstPaymentCB,
            );
            _.set(
                gasContract,
                'deliveryPoint.process.appointmentTimeslot',
                situation.energies.NG.timeslot,
            );

            if (
                situation.energies.NG.dueDate &&
                situation.processType === EProcessTypes.SWITCH
            ) {
                _.set(
                    gasContract,
                    'dueDate',
                    getFormattedDateToBackEnd(situation.energies.NG.dueDate),
                );
            }
        }
    }
    order.contracts = [electricityContract, gasContract].filter(
        contract => contract !== null,
    );
    _.forEach(order.contracts, (contract: TContract) => {
        const billingMode: EBillingModes = _.get(
            contract,
            'chosenProduct.billingModeCode',
        );
        if (requiresPayment(contract)) firstPaymentCBStatus = true;
        if (!!situation.processType) {
            _.set(
                contract,
                'deliveryPoint.process.type',
                situation.processType,
            );
        }
        if (!!situation.effectiveStartRange) {
            _.set(
                contract,
                'effectiveStartRange',
                situation.effectiveStartRange,
            );
        } else {
            delete contract.effectiveStartRange;
        }
        contract.thirdParties = [];
        if (!!options.rebateCode) {
            contract.thirdParties.push({
                code: options.rebateCode.code,
                role: options.rebateCode.type,
            });
        }

        if (options.isSign && options.channel && options.channel !== '') {
            contract.thirdParties.push({
                code: options.channel,
                role: 'PARTNER',
            });
        }

        if (
            !!situation.coholder &&
            !!situation.coholder.civility &&
            !!situation.coholder.firstName &&
            !!situation.coholder.lastName
        ) {
            contract.thirdParties.push({
                role: 'COHOLDER',
                civility: situation.coholder.civility,
                firstName: situation.coholder.firstName,
                lastName: situation.coholder.lastName,
            });
        }
    });
    _.set(order, 'customer.finance.paymentMode', EPaymentModes.DIRECT_DEBIT);

    if (situation.payment.iban && situation.payment.iban !== '') {
        _.set(order, 'customer.finance.ibanCode', situation.payment.iban);
    }

    if (firstPaymentCBStatus) {
        _.set(order, 'firstPaymentCBStatus', EFirstPaymentCBStatuses.PENDING);
    }
    _.set(
        order,
        'customer.finance.accountOwnerFirstName',
        _.get(client, 'customerContact.firstName', ''),
    );
    _.set(
        order,
        'customer.finance.accountOwnerLastName',
        _.get(client, 'customerContact.lastName', ''),
    );
    if (!!situation.billingAddress && !!situation.coords) {
        _.set(
            order,
            'customer.finance.billingContact.address',
            situation.billingAddress,
        );
        _.set(
            order,
            'customer.finance.billingContact.civility',
            situation.coords.civility,
        );
        _.set(
            order,
            'customer.finance.billingContact.firstName',
            situation.coords.firstName,
        );
        _.set(
            order,
            'customer.finance.billingContact.lastName',
            situation.coords.lastName,
        );
        _.set(
            order,
            'customer.finance.accountOwnerFirstName',
            situation.coords.firstName,
        );
        _.set(
            order,
            'customer.finance.accountOwnerLastName',
            situation.coords.lastName,
        );
    }

    _.set(order, 'origin', 'expertMode');

    return order;
}

export { buildPendingOrder };
